import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { firebaseAuth } from "../../../firebaseAuth";
import useModal from "../../../hooks/useModal";
import {
	GET_ORDER_HISTORY_KEY,
	useUpdateRequestContent,
} from "../../../query/public/orderHistoryDetailQuery";
import { useQueryClient } from "@tanstack/react-query";
import { modals } from "../../../hooks/Modals";
import { Alert, Box, Button, Stack, Typography } from "@mui/material";
import BackgroundBox from "../../common/BackgroundBox";
import ReadOnlyDeliveryAddressCard from "../../common/ReadOnlyDeliveryAddressCard";
import ImageUpload from "../ImageUpload";
import RequestContextField from "../RequestContextField";
import { Toast } from "../Toast";
import Footer from "./Footer";
import BranchCard from "./BranchCard";
import EstimateCard from "../../common/EstimateCard";
import { GET_REQUEST_IMAGES_KEY } from "../../../query/getImageQuery";
import { equalsArray } from "../../../lib/libs";
import LoadingButton from "@mui/lab/LoadingButton";

function Step3AccordionContent({
	items,
	images,
	isCurrentStep,
}) {
	const { orderId } = useParams();
	const [defaultImages, setDefaultImages] = useState(images);
	const [sendImages, setSendImages] = useState([]);
	const [requestContent, setRequestContent] = useState(items.rqstCntnt);
	const [errorMessage, setErrorMessage] = useState("");
	const [changeFlag, setChangeFlag] = useState(false);
	const [imageUploading, setImageUploading] = useState(false);
	const { openModal } = useModal();
	const { updateRequestContentMutate } = useUpdateRequestContent();
	const queryClient = useQueryClient();

	const addImagesHandler = useCallback((newImages) => {
		setSendImages([...newImages]);
	}, []);
	const setImageUploadingHandler = useCallback((flag) => {
		setImageUploading(flag);
	}, []);

	useEffect(() => {
		if(!equalsArray(images, defaultImages)) {
			setDefaultImages([...images]);
			setChangeFlag(false); // 서버에서 가져온 이미지 목록이 변경되었을 때
		}
	}, [images]);

	useEffect(() => {
		if(!changeFlag) {
			const fileNames = sendImages.map((file) => {
				return file.name;
			});

			if(!equalsArray(images, fileNames)) {
				setDefaultImages([...images]);
			}

			setRequestContent(items.rqstCntnt);
		}
	}, [changeFlag]);

	function handleRequest() {
		if(!requestContent) {
			setErrorMessage("요청사항을 입력해주세요");
			return;
		}

		openModal(modals.confirmModal, {
			submitTitle: "저장",
			content: (
				<Typography variant="dialogContent">요청사항을 저장하시겠어요?</Typography>
			),
			onSubmit: async (onClose, setLoadingDone) => {
				const formData = new FormData();

				for(let i = 0; i < sendImages.length; i++) {
					formData.append("files", sendImages[i]);
				}

				const contentsData = {
					content: requestContent,
					imagePath: items.rqstImgPth,
					orderId,
				};

				formData.append("contentsData", new Blob([JSON.stringify(contentsData)], { type: "application/json" }));

				updateRequestContentMutate(formData, {
					onSuccess: () => {
						Toast.success("저장이 완료되었어요");
						queryClient.invalidateQueries([...GET_ORDER_HISTORY_KEY, orderId]);
						queryClient.invalidateQueries([...GET_REQUEST_IMAGES_KEY, orderId]);
						onClose();
					},
					onError: () => {
						onClose();
						openModal(modals.confirmModal, {
							title: "죄송합니다, 저장이 실패했습니다",
							content: "잠시 후에 다시 시도해주시기 바랍니다.",
							closeTitle: "닫기",
						});
					},
					onSettled: () => {
						setLoadingDone();
					},
				});
			},
		});
	}

	function changeRequestContent(newValue) {
		setRequestContent(newValue);
		setErrorMessage("");
	}

	return (
		<Stack>
			<BackgroundBox mb={ 4 }>
				<ul>
					{ items.rqstRcvngPckUpYn === "N" ? (
						<React.Fragment>
							<li>
								<Typography variant="context">서비스 받으실 <strong>스킨만 포장</strong>하셔서 택배를 이용해 아래 주소로 보내주세요</Typography>
							</li>

							<li>
								<Typography variant="context">포장 시 메모지에 고객님의 <strong>성함</strong>을 적어서 넣어 주세요</Typography>
							</li>

							<li>
								<Typography variant="context"><strong>반드시 텐트를 보내주셔야 { items.ordrTpNm } 서비스를 받으실 수 있어요</strong></Typography>
							</li>
						</React.Fragment>
					) : (
						<li>
							<Typography variant="context">고객님의 텐트를 <strong>방문 수거</strong> 요청해주셨어요</Typography>
						</li>
					) }

					<li>
						<Typography variant="context">입고 완료 시 카카오 알림톡으로 안내해 드리겠습니다</Typography>
					</li>

					<li>
						<Typography variant="context"><strong>본 견적은 가견적이므로, 결제는 견적 완료 시에 부탁드립니다</strong></Typography>
					</li>
				</ul>
			</BackgroundBox>

			<Box mb={ 2 }>
				<BranchCard
					branchId={ items.brnchId }
					branchName={ items.brnchNm }
					address={ items.addr }
					addressDetail={ items.addrDtl }
					zoneCode={ items.znCd }
					bankName={ items.bnkNm }
					bankAccount={ items.bnkAccnt }
					bankAccountHolder={ items.bnkAccntHldr }
					telNumber={ items.brnchTelNo }
					queryKey={ GET_ORDER_HISTORY_KEY }
					updateFlag={ isCurrentStep }
					cardInfoShow
					phoneNumber={ items.brnchPhnNo }
				/>
			</Box>

			{ items.rqstRcvngPckUpYn === "Y" && isCurrentStep && (
				<Box mb={ 2 }>
					<ReadOnlyDeliveryAddressCard orderId={ orderId } title="방문수거 요청 주소지" />
				</Box>
			) }

			<EstimateCard
				showIcon
				orderType={ items.ordrTp }
				detail={ items.details[2].detail }
				total={ items.details[2].total }
				repairContent={ items.rprCntnt }
			/>

			<Box mt={ 2 }>
				<ImageUpload
					readOnly={ !changeFlag }
					images={ defaultImages }
					setImage={ addImagesHandler }
					setLoading={ setImageUploadingHandler }
				/>
			</Box>

			<Box>
				<RequestContextField
					readOnly={ !changeFlag }
					defaultValue={ requestContent }
					setText={ changeRequestContent }
				/>
			</Box>

			{ errorMessage !== "" && (
				<Alert severity="error" sx={ { marginTop: 1 } }>{ errorMessage }</Alert>
			) }

			{ isCurrentStep && (
				<Stack direction="row" spacing={ 1 } mt={ errorMessage !== "" ? 1 : 4 }>
					<Button
						variant="contained"
						size="large"
						fullWidth
						color="secondary"
						onClick={ () => setChangeFlag((prev) => !prev) }
					>
						{ changeFlag ? "취소" : "요청사항 수정" }
					</Button>

					{ changeFlag && (
						<LoadingButton
							variant="contained"
							size="large"
							fullWidth
							onClick={ handleRequest }
							loading={ imageUploading }
							color="washing"
						>
							저장
						</LoadingButton>
					) }
				</Stack>
			) }

			<Footer />
		</Stack>
	);
}

export default React.memo(Step3AccordionContent);