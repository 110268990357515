import React from "react";
import {
	Box,
	Card,
	CardMedia,
	Container,
	Typography
} from "@mui/material";
import TopImage from "../../../assets/images/top_img/top_01.jpg";
import VentureImage from "../../../assets/images/certified/venture_logo.png";

function Greeting() {
	return (
		<Container variant="contextRoot">
			<Card variant="topImage">
				<CardMedia
					image={ TopImage }
					title="텐트세탁소"
					sx={ { height: "200px" } }
				/>
			</Card>

			<Container variant="contextBox">
				<Typography variant="title">GREETING</Typography>

				<Typography variant="subTitle" mb={ 1 }>할 수 있다는 마음으로 언제나 정성을 다하는 기업</Typography>

				<Typography variant="context" mb={ 4 }>
					캠핑을 좋아하는 취미로 시작하여, 이 취미가 사업으로 성장해 오늘날까지 이르렀습니다.
					<br /><br />
					2012년, "텐트 관리업"이라는 용어도 생소하고 국내에서 벤치마킹할 업체도 없어 많은 시행착오를 겪었습니다.
					<br />
					그러나 할 수 있다는 마음으로 연구와 노력 끝에 국내 최초로 유일한 "텐트 세척 방법 및 텐트 세척 설비"에 대한 특허를 획득하였고, 동종 업계에서 유일하게 텐트와 아웃도어 제품에 적합한 세척제와 발수제를 개발하여 사용, 판매하고 있습니다.
					또한 수선, 리폼, 제작까지 원스톱으로 텐트를 관리해주는 서비스는 저희 "텐트 세탁소"만이 제공하고 있습니다.
					<br /><br />
					최근에는 이러한 노력들을 인정받아 동종 업계에서 최초로 벤처기업 인증도 받았습니다.
					<br /><br />
					앞으로도 "할 수 있다는 마음으로 언제나 정성을 다하는 기업"이 되기 위해 계속해서 노력하고 연구를 진행하여 텐트 관리업을 선도해 나갈 수 있는 기업이 되겠습니다.
					<br /><br />
					감사합니다.
				</Typography>

				<img src={ VentureImage } style={ { width: "100%" } } alt="벤처 기업 인증 마크" />
			</Container>
		</Container>
	);
}

export default Greeting;