import { createTheme } from "@mui/material";

const themeOptions = {
	palette: {
		mode: "dark",
		primary: {
			light: "#E98E65",
			main: "#E8410B",
			dark: "#C22C00",
			dark2: "#662A1B",
			contrastText: "#FFF",
		},
		washing: {
			main: "#E8410B",
			dark: "#C22C00",
			contrastText: "#FFF",
		},
		repair: {
			main: "#07753B",
			dark: "#005627",
			contrastText: "#FFF",
		},
		secondary: {
			main: "#4B4B4B",
			dark: "#434343",
			contrastText: "#EAEAEA",
		},
		background: {
			default: "#1E1E1E",
			paper: "#1E1E1E",
			dark: "#1A1B1C",
			light: "#303030",
		},
		text: {
			primary: "#EAEAEA",
			secondary: "#B9B9B9",
			hint: "#22194D",
		},
		type: {
			washing: "#E8410B",
			repair: "#07753B",
		},
		divider: "#3A3A3A",
	},
	typography: {
		fontFamily: "\"Pretendard\", Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, \"Helvetica Neue\", \"Segoe UI\", \"Apple SD Gothic Neo\", \"Noto Sans KR\", \"Malgun Gothic\", \"Apple Color Emoji\", \"Segoe UI Emoji\", \"Segoe UI Symbol\", sans-serif",
	},
	components: {
		MuiCssBaseline: {
			styleOverrides: {
				".customUl": {
					"ul": {
						margin: 0,
						padding: 0,
						listStyle: "none",
						"& > li": {
							display: "inline-flex",
							margin: "1px 0",
							width: "100%",
							"&::before": {
								content: "''",
								display: "inline-block",
								width: 3,
								height: 3,
								margin: "10px 6px 0 0",
								borderRadius: 2,
								backgroundColor: "#727272",
								verticalAlign: "top",
							},
						},
					},
				},
			},
		},
		MuiButtonBase: {
			styleOverrides: {
				root: {
					borderRadius: "4px",
					fontWeight: 600,
				},
			},
		},
		MuiButton: {
			defaultProps: {
				disableElevation: true,
			},
			styleOverrides: {
				root: {
					padding: "13px 0",
					fontSize: "16px",
					fontWeight: 700,
					"&.MuiButton-outlined": {
						"&.Mui-disabled": {
							color: "rgb(255 255 255 / 15%)",
							border: "1px solid rgb(75 75 75 / 35%)",
						},
					},
					"&.MuiButton-contained": {
						"&.Mui-disabled": {
							color: "rgb(255 255 255 / 15%)",
							backgroundColor: "rgb(255 255 255 / 8%)",
						},
					},
				},
			},
			variants: [
				{
					props: { size: "small" },
					style: {
						minWidth: 50,
						padding: "4px 10px",
						fontSize: 12,
					},
				},
				{
					props: { size: "medium" },
					style: {
						padding: "10px 0",
						fontSize: 13,
					},
				},
				{
					props: { size: "large" },
					style: {
						padding: "13px 0",
						fontSize: 14,
					},
				},
				{
					props: {
						color: "secondary",
						variant: "outlined",
					},
					style: {
						borderColor: "#4B4B4B",
						color: "#636363",
						"&:hover": {
							backgroundColor: "rgb(75 75 75 / 30%)",
						},
					},
				},
			],
		},
		MuiAppBar: {
			styleOverrides: {
				root: {
					height: "60px",
					backgroundImage: "none",
				},
			},
		},
		MuiInputBase: {
			defaultProps: {
				autoComplete: "off",
			},
			styleOverrides: {
				root: {
					"&.Mui-disabled, &.Mui-readOnly": {
						// backgroundColor: "#262729",
						// color: "rgb(234 234 234 / 30%)"
					},
				},
				multiline: {
					"&&&": {
						padding: 0,
					},
				},
			},
		},
		// MuiOutlinedInput: {
		// 	variants: [
		// 		{
		// 			props: { readOnly: true },
		// 			style: {
		// 				backgroundColor: "#010912"
		// 			}
		// 		},
		// 		{
		// 			props: { disabled: true },
		// 			style: {
		// 				backgroundColor: "#010912"
		// 			}
		// 		}
		// 	]
		// },
		MuiToolbar: {
			styleOverrides: {
				root: {
					height: "60px",
					minHeight: "unset !important",
					maxHeight: "60px",
				},
			},
		},
		MuiTypography: {
			defaultProps: {
				component: "div",
			},
			variants: [
				{
					props: { variant: "barTitle" },
					style: {
						fontSize: 24,
						fontWeight: "bold",
						color: "#FFF",
					},
				},
				{
					props: { variant: "barMenuName" },
					style: {
						fontSize: 18,
						fontWeight: "bold",
						color: "#EAEAEA",
					},
				},
				{
					props: { variant: "bigMenuTitle" },
					style: {
						fontSize: 16,
						fontWeight: "bold",
					},
				},
				{
					props: { variant: "smallMenuTitle" },
					style: {
						fontSize: 15,
						fontWeight: "normal",
					},
				},
				{
					props: { variant: "title" },
					style: {
						fontSize: 20,
						fontWeight: 600, // semiBold,
						marginBottom: 25,
					},
				},
				{
					props: { variant: "subTitle" },
					style: {
						fontSize: 17,
						fontWeight: 600,
					},
				},
				{
					props: { variant: "context" },
					style: {
						fontSize: 15,
						fontWeight: 400,
						color: "#B9B9B9",
						lineHeight: "22px",
						"& > strong": {
							color: "#E8410B",
							fontWeight: 700,
							textDecoration: "underline",
							textUnderlinePosition: "under",
						},
					},
				},
				{
					props: { variant: "smallContext" },
					style: {
						fontSize: 13,
						fontWeight: 400,
						color: "#B9B9B9",
						lineHeight: "22px",
					},
				},
				{
					props: { variant: "cardBold" },
					style: {
						fontSize: 15,
						fontWeight: 700,
						color: "#EAEAEA",
						lineHeight: "22px",
					},
				},
				{
					props: { variant: "cardContext" },
					style: {
						fontSize: 13,
						fontWeight: 400,
						color: "#EAEAEA",
						lineHeight: "22px",
					},
				},
				{
					props: {
						variant: "cardContext",
						color: "seconds",
					},
					style: {
						fontSize: 13,
						fontWeight: 400,
						color: "#B9B9B9",
						lineHeight: "22px",
					},
				},
				{
					props: {
						variant: "cardContext",
						type: "title",
					},
					style: ({ theme }) => ({
						// textAlign: "right",
						// marginRight: 10,
						color: theme.palette.text.secondary,
					}),
				},
				{
					props: { variant: "sideContext" },
					style: {
						fontSize: 12,
						fontWeight: 400,
						color: "#939393",
						lineHeight: "18px",
					},
				},
				{
					props: { variant: "accordionTitle" },
					style: {
						color: "#E8410B",
						fontSize: 14,
						fontWeight: 700,
					},
				},
				{
					props: { variant: "accordionContext" },
					style: {
						color: "#B9B9B9",
						fontSize: 12,
					},
				},
				{
					props: { variant: "dialogTitle" },
					style: {
						fontSize: 17,
						fontWeight: 700,
						// color: "#1E1E1E",
						lineHeight: "22px",
						padding: "22px 10px 5px 24px",
					},
				},
				{
					props: { variant: "dialogContent" },
					style: {
						fontSize: 15,
						fontWeight: 500,
						// color: "#1E1E1E",
						color: "#B9B9B9",
						lineHeight: "22px",
					},
				},
			],
		},
		MuiSvgIcon: {
			defaultProps: {
				inheritViewBox: true,
			},
			variants: [
				{
					props: { variant: "menuControl" },
					style: {
						cursor: "pointer",
						"&:hover": {
							fill: "#FFF",
						},
					},
				},
				{
					props: { variant: "bigMenuControl" },
					style: {
						width: "16px",
						height: "8px",
						"&:hover": {
							fill: "#FFF",
						},
					},
				},
				{
					props: { variant: "homeIcon" },
					style: {
						width: "36px",
						height: "36px",
						cursor: "pointer",
						fill: "none",
					},
				},
				{
					props: { variant: "guideStep" },
					style: {
						width: "200px",
						height: "200px",
						fill: "none",
						marginTop: "30px",
						marginBottom: "10px",
					},
				},
				{
					props: { variant: "infoIcon" },
					style: {
						width: 12,
						height: 12,
						fill: "#D9D9D9",
						// position: "absolute",
						// top: 5,
						// right: 5,
						marginRight: 5,
						cursor: "pointer",
					},
				},
				{
					props: { variant: "cardIcon" },
					style: {
						fill: "none",
					},
				},
			],
		},
		MuiListItemButton: {
			defaultProps: {
				disableTouchRipple: true,
			},
		},
		MuiContainer: {
			variants: [
				{
					props: { variant: "contextRoot" },
					style: {
						width: "100%",
						minHeight: "calc(100vh - 60px)",
						// height: "calc(100vh - 60px)",
						padding: "0 !important",
						backgroundColor: "#1E1E1E",
					},
				},
				{
					props: { variant: "contextBox" },
					style: {
						minHeight: "calc(100% - 200px)",
						padding: "50px 15px 30px !important",
					},
				},
			],
		},
		MuiCard: {
			styleOverrides: {
				root: {
					backgroundColor: "#1A1B1C",
				},
			},
			variants: [
				{
					props: { variant: "topImage" },
					style: {
						borderRadius: "12px 12px 0 0",
						borderWidth: 0,
					},
				},
				{
					props: { variant: "orderCard" },
					style: {
						padding: "20px 27px",
					},
				},
				{
					props: { variant: "secondary" },
					style: {
						backgroundColor: "#2d2d2d",
						// backgroundColor: "#303030",
						borderWidth: 0,
					},
				},
			],
		},
		MuiCardActions: {
			variants: [
				{
					props: { variant: "adminStepCard" },
					style: {
						padding: "0 16px 16px",
						justifyContent: "flex-end",
					},
				},
			],
		},
		MuiFormControl: {
			styleOverrides: {
				root: {
					"& > .MuiFormLabel-root": {
						transform: "translate(30px, 12px) scale(1)",
						"&.MuiInputLabel-sizeSmall": {
							transform: "translate(15px, 8px) scale(1)",
						},
						"&.MuiInputLabel-shrink": {
							transform: "translate(30px, -9px) scale(0.75)",
							"&.MuiInputLabel-sizeSmall": {
								transform: "translate(15px, -9px) scale(0.75)",
							},
						},
					},
					"& .MuiInputBase-root": {
						borderRadius: "4px",
						backgroundColor: "#141516",
						// boxShadow: "inset 3px 3px 6px #070809, inset -3px -3px 7px #212324",
						"&.MuiAutocomplete-inputRoot": {
							padding: 0,
						},
						"& > .MuiInputBase-input": {
							padding: "12px 30px !important",
							"&.MuiInputBase-inputAdornedEnd": {
								paddingRight: "0 !important",
							},
							"&.MuiInputBase-inputSizeSmall": {
								padding: "8.5px 14px",
							},
						},
						"& > .MuiInputBase-inputMultiline": {
							padding: "5px 18px",
						},
					},
					"& .MuiOutlinedInput-root": {
						"&.MuiInputBase-sizeSmall fieldset": {
							padding: "0 9px",
						},
						"& fieldset, &.Mui-disabled fieldset": {
							padding: "0 22px",
							borderColor: "#282828",
						},
						"&:hover fieldset": {
							borderColor: "#3B3B3B",
						},
						"&:focus-visible fieldset, &.Mui-focused fieldset": {
							borderColor: "#494949",
							borderWidth: "1px",
						},
					},
				},
			},
		},
		MuiFormControlLabel: {
			styleOverrides: {
				label: ({ theme }) => ({
					"&.Mui-disabled": {
						color: theme.palette.secondary.main,
					},
				}),
			},
		},
		MuiFormLabel: {
			styleOverrides: {
				asterisk: ({ theme }) => ({
					color: theme.palette.primary.main,
				}),
			},
		},
		MuiPaper: {
			styleOverrides: {
				root: {
					backgroundImage: "none",
					border: "1px solid #3A3A3A",
					"&.MuiAlert-root": {
						borderWidth: 0,
					},
				},
			},
		},
		MuiPopper: {
			styleOverrides: {
				root: {
					"& .MuiPaper-root": {
						marginTop: "7px",
						backgroundColor: "#141516",
					},
				},
			},
		},
		MuiMenu: {
			styleOverrides: {
				root: {
					"& .MuiPaper-root": {
						marginTop: "7px",
						backgroundColor: "#141516",
					},
				},
			},
		},
		MuiMenuItem: {
			styleOverrides: {
				root: {
					paddingLeft: "30px",
					borderRadius: 0,
					"&.Mui-selected": {
						backgroundColor: "#2F2F2F",
						"&.Mui-focusVisible": {
							backgroundColor: "#2F2F2F",
						},
						"&:hover": {
							backgroundColor: "#000",
						},
					},
				},
			},
		},
		MuiTableContainer: {
			styleOverrides: {
				root: {
					border: "1px solid #262727",
					borderLeftWidth: 0,
					borderRightWidth: 0,
				},
			},
		},
		MuiTableBody: {
			styleOverrides: {
				root: {
					"& .MuiTableRow-root": {
						"&:not(:last-child) .MuiTableCell-root": {
							borderColor: "#494949",
						},
					},
				},
			},
		},
		MuiTableRow: {
			styleOverrides: {
				root: {
					"& .MuiTableCell-root": {
						":not(:last-child)": {
							borderRight: "1px solid #494949",
						},
					},
				},
			},
		},
		MuiTableCell: {
			styleOverrides: {
				root: {
					backgroundColor: "#1A1B1C",
					color: "#EAEAEA",
					paddingTop: 12,
					paddingBottom: 12,
					padding: "18px 3px",
				},
			},
			variants: [
				{
					props: { variant: "header" },
					style: {
						fontSize: 15,
						fontWeight: 700,
						textAlign: "center",
						letterSpacing: 3,
					},
				},
				{
					props: { variant: "body" },
					style: {
						fontSize: 14,
					},
				},
				{
					props: { variant: "firstGroup" },
					style: {
						fontSize: 14,
						backgroundColor: "#242424",
						textAlign: "center",
					},
				},
				{
					props: { variant: "secondGroup" },
					style: {
						fontSize: 14,
						backgroundColor: "#2F2F2F",
						textAlign: "center",
					},
				},
			],
		},
		MuiTooltip: {
			defaultProps: {
				enterTouchDelay: 0
			},
			styleOverrides: {
				popper: {
					// zIndex: 999,
					margin: "0 !important",
				},
				tooltip: {
					backgroundColor: "rgba(217, 217, 217, 0.80)",
					color: "#1E1E1E",
					fontSize: 13,
					padding: "12px 14px",
					backdropFilter: "blur(2px)",
					width: "max-content",
					// maxWidth: 260,
					borderRadius: 12,
					"&&&.MuiTooltip-tooltipPlacementBottom": {
						marginTop: 5,
					},
					// ".MuiTooltip-popper[data-popper-placement='bottom-start'] &": {
					// 	borderRadius: "0 12px 12px 12px",
					// 	marginTop: 4,
					// 	marginLeft: 12
					// },
					// ".MuiTooltip-popper[data-popper-placement='bottom-end'] &": {
					// 	borderRadius: "12px 0 12px 12px",
					// 	marginTop: 4,
					// 	marginRight: 12
					// }
				},
				arrow: {
					color: "rgba(217, 217, 217, 0.80)",
				},
			},
		},
		MuiTable: {
			styleOverrides: {
				root: {
					borderCollapse: "separate",
				},
			},
		},
		MuiStack: {
			variants: [
				{
					props: { variant: "imageBox" },
					style: {
						flexDirection: "column",
					},
				},
			],
		},
		MuiDialog: {
			styleOverrides: {
				paper: {
					backgroundColor: "#1A1B1C",
					// backgroundColor: "#D9D9D9",
					// color: "#1E1E1E",
					borderWidth: 0,
					borderRadius: 12,
					"& .MuiDialogContent-root": {
						padding: "20px 24px 25px 24px",
						fontSize: 15,
						fontWeight: 500,
						color: "#B9B9B9",
						// color: "#1E1E1E",
						lineHeight: "22px",
					},
					"& .MuiDialogActions-root": {
						padding: "0 24px 15px 24px",
					},
				},
			},
		},
		MuiChip: {
			defaultProps: {
				size: "small",
			},
			styleOverrides: {
				root: {
					fontSize: 12,
				},
				iconSmall: {
					color: "#EAEAEA",
					fontSize: 12,
					marginRight: -2,
					marginLeft: 7,
				},
			},
			variants: [
				{
					props: { variant: "outlined" },
					style: {
						backgroundColor: "#373737",
						border: "1px solid #616161",
						color: "#EAEAEA",
					},
				},
				{
					props: {
						variant: "outlined",
						color: "primary",
					},
					style: {
						backgroundColor: "rgba(232, 65, 11, 0.15)",
						borderColor: "#E8410B",
						color: "#E8410B",
					},
				},
			],
		},
		MuiSlider: {
			styleOverrides: {
				root: {
					height: 2,
				},
				rail: ({ theme }) => ({
					opacity: 1,
					backgroundColor: theme.palette.secondary.main,
				}),
				track: {
					border: "none",
				},
				mark: ({ theme }) => ({
					backgroundColor: theme.palette.secondary.main,
					width: 1,
					height: 8,
					opacity: .8,
				}),
				markActive: {
					opacity: 1,
					backgroundColor: "currentColor",
				},
				thumb: {
					backgroundColor: "#FFF",
					boxShadow: "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)",
					"&:focus, &:hover, &.Mui-active": {
						boxShadow: "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)",
						"@media (hover: none)": {
							boxShadow: "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)",
						},
					},
				},
				valueLabel: ({ theme }) => ({
					lineHeight: 1.2,
					fontSize: 12,
					background: "unset",
					padding: 0,
					width: 32,
					height: 32,
					borderRadius: "50% 50% 50% 0",
					backgroundColor: theme.palette.primary.main,
					transformOrigin: "bottom left",
					transform: "translate(50%, -100%) rotate(-45deg) scale(0)",
					"&:before": {
						display: "none"
					},
					"&.MuiSlider-valueLabelOpen": {
						transform: "translate(50%, -100%) rotate(-45deg) scale(1)",
					},
					"& > *": {
						transform: "rotate(45deg)",
					},
				}),
			},
		},
		MuiDatePickerToolbar: {
			defaultProps: {
				toolbarFormat: "MM월 D일"
			},
		},
		MuiPickersToolbar: {
			styleOverrides: {
				root: ({ theme }) => ({
					backgroundColor: theme.palette.secondary.main,
					"& > .MuiPickersToolbar-content": {
						color: "#FFF"
					},
					"& > .MuiTypography-root": {
						color: "#B9B9B9"
					}
				})
			}
		},
		MuiPickersDay: {
			styleOverrides: {
				root: {
					borderRadius: 10

				}
			}
		},
		MuiPickersMonth: {
			styleOverrides: {
				monthButton: {
					borderRadius: 10
				}
			}
		}
	},
};

export const customMuiTheme = createTheme(themeOptions);