import { Button, Card, CardContent, Skeleton, Stack, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { modals } from "../../../hooks/Modals";
import useModal from "../../../hooks/useModal";
import { updateOrder } from "../../../lib/firestore/Order";
import { getRealtimeOrder } from "../../../lib/firestore/Order";
import { HomeRounded } from "@mui/icons-material";
import { Toast } from "../Toast";

function DeliveryAddressCard({ updateFlag }) {
	const { orderId } = useParams();
	const [address, setAddress] = useState(null);
	const unsubscribeRef = useRef(null);
	const { openModal } = useModal();

	useEffect(() => {
		getRealtimeOrder({
			orderId,
			unsubscribeRef,
			setData: (addressData) => {
				setAddress({ ...addressData });
			}
		});

		return () => {
			unsubscribeRef.current?.();
		};
	}, []);

	function isEqualsAddress(standardAddress, address) {
		if(standardAddress.zoneCode !== address.zoneCode) {
			return false;
		}
		if(standardAddress.name !== address.receiverName) {
			return false;
		}
		if(standardAddress.phoneNumber !== address.receiverPhoneNumber) {
			return false;
		}
		if(standardAddress.address !== address.address) {
			return false;
		}
		if(standardAddress.addressDetail !== address.addressDetail) {
			return false;
		}

		return true;
	}

	function handleClick() {
		openModal(modals.addressesModal, {
			title: "주소지 변경",
			submitTitle: "저장",
			onSubmit: async (onClose, newAddress, setLoadingDone) => {
				if(isEqualsAddress(newAddress, address)) {
					Toast.info("다른 주소지를 선택해주세요");
					setLoadingDone();
				} else {
					await updateOrder(orderId, {
						receiverName: newAddress.name,
						receiverPhoneNumber: newAddress.phoneNumber,
						zoneCode: newAddress.zoneCode,
						address: newAddress.address,
						addressDetail: newAddress.addressDetail
					});

					Toast.success("주소지가 변경되었어요");
					onClose();
				}
			}
		});
	}

	return (
		address === null ? (
			<Skeleton variant="rounded" width="100%" height="200px" />
		) : (
			<Card>
				<CardContent>
					<Stack>
						<Stack
							direction="row"
							alignItems="center"
							justifyContent="space-between"
							mb={ 2 }
						>
							<Stack direction="row" spacing={ 1 }>
								<HomeRounded fill="none" />
								<Typography variant="cardBold">텐트 받을 주소지</Typography>
							</Stack>

							{ updateFlag && (
								<Button variant="contained" size="small" color="secondary" onClick={ handleClick }>주소지 변경</Button>
							) }
						</Stack>

						<Typography variant="cardContext">{ address.receiverName }</Typography>

						<Typography variant="cardContext">{ address.receiverPhoneNumber }</Typography>

						<Typography variant="cardContext">[{ address.zoneCode }] { address.address }, { address.addressDetail }</Typography>
					</Stack>
				</CardContent>
			</Card>
		)
	);
}

export default DeliveryAddressCard;