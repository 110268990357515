import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import {
  Box,
  Checkbox,
  Container,
  FormControlLabel,
  InputLabel,
  Paper,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import RoundTextField from "../../components/common/FormTextField";
import RoundPasswordField from "../../components/common/RoundPasswordField";
import { useForm } from "react-hook-form";
import {
  getLoginFailCount,
} from "../../api/admin/login";
import { Toast } from "../../components/public/Toast";
import { useLoginMutation } from "../../query/admin/loginQuery";
import { useNavigate } from "react-router-dom";
import PointerCursorLink from "../../components/common/PointerCursorLink";
import { LOGIN_DEFAULT_URL } from "../../api/admin/defaultUrl";
import RoundLoadingButton from "../../components/common/RoundLoadingButton";
import { Refresh } from "@mui/icons-material";
import {
  getCookie,
  removeCookie,
  setCookie,
} from "../../hooks/cookies";
import {
  useAdminUserDispatch,
  useAdminUserState
} from "../../hooks/admin/adminAuthContextProvider";
import { adminAccessToken } from "../../lib/authToken";
import { getAdminAccessToken } from "../../lib/config/exportToken";

function Login() {
  const SAVE_ID_KEY = "USER_ID";

  const [saveId, setSaveId] = useState(false);
  const loading = useRef(false);
  const { loginMutate } = useLoginMutation();
  const navigate = useNavigate();
  const loginFailCount = useRef(0);
  const [updateCount, setUpdateCount] = useState(0);
  const [isShowCaptcha, setIsShowCaptcha] = useState(false);
  const [loginStatus, setLoginStatus] = useState(null);
  const errorMessage = useRef("");
  const {
    handleSubmit,
    control,
    getValues,
    setValue,
  } = useForm();
  const { setLoggedIn } = useAdminUserDispatch();
  const { loginFlag } = useAdminUserState();

  function onChangeCheckbox({ target: { checked } }) {
    setSaveId(checked);
  }

  const getCaptchaImage = useMemo(() => {
    let rand = Math.random();

    return (
      <img
        style={ { marginRight: ".5rem" } }
        src={ LOGIN_DEFAULT_URL + "/captcha?rand=" + rand }
        alt="캡차 이미지"
      />
    );
  }, [isShowCaptcha, updateCount]);

  useEffect(() => {
    if(loginFlag) {
      goMenuPage();
      Toast.info("이미 로그인이 되어있습니다.");
    } else {
      getLoginFailCount().then((count) => {
        showCaptcha(count);
      });

      const saveId = getCookie(SAVE_ID_KEY);

      if(saveId) {
        setSaveId(true);
        setValue("id", saveId);
      }
    }
  }, []);

  function showCaptcha(count) {
    loginFailCount.current = count;

    if(loginFailCount.current > 4) {
      setIsShowCaptcha(true);
    }
  }

  async function onSubmit(data) {
    loading.current = true;

    data = {
      username: data.id,
      password: data.pw,
      answer: data.captcha
    };

    login({
      loginParam: { ...data },
      onComplete: () => loading.current = false,
      curSaveId: saveId
    });
  }

  const login = useCallback(({ loginParam, onComplete, curSaveId }) => {
    loginMutate(loginParam, {
      onSuccess: (res) => {
        const { data, headers } = res;
        onComplete();

        if(data.success) {
          if(curSaveId) {
            const expires = new Date();

            expires.setFullYear(expires.getFullYear() + 1);
            setCookie(SAVE_ID_KEY, getValues("id"), {
              path: "/",
              expires,
            });
          } else {
            removeCookie(SAVE_ID_KEY);
          }

          setLoginStatus(true);
          goMenuPage();

          setLoggedIn({
	          role: data.role,
	          branchId: data.branchId
					});
          adminAccessToken.setToken(getAdminAccessToken(headers));
        } else {
          showCaptcha(Number(data.loginFailCnt));
          let msg;

          switch(data.reason) {
            case "password": {
              if(loginFailCount.current < 5) {
                msg = "아이디 또는 비밀번호가 일치하지 않습니다.";
              } else {
                msg = "입력 정보가 5회 이상 일치하지 않습니다. \n보안 코드를 입력해주세요.";
                updateCaptcha();
              }
              break;
            }
            case "rsaError": {
              msg = "보안 정보가 올바르지 않습니다.";
              break;
            }
            case "answerEmpty": {
              msg = "보안 코드를 입력해 주세요.";
              updateCaptcha();
              break;
            }
            case "answerValid": {
              msg = "보안 코드가 올바르지 않습니다.";
              updateCaptcha();
              break;
            }
            case "notExistsId": {
              msg = "존재하지 않는 아이디 입니다.";
              break;
            }
	          case "disabledId": {
							msg = "계정이 비활성화 되었습니다. 관리자에게 문의하세요.";
							break;
	          }
            case "cnfmValid": {
              msg = "승인 대기 중입니다.";
              break;
            }
          }

          errorMessage.current = msg;
          setLoginStatus(false);
        }
      },
      onError: () => {
        onComplete();
        loginFailCount.current = ++loginFailCount.current;
        setLoginStatus(false);
      },
    });
  }, [loginMutate]);

  function goSignUpPage() {
    navigate("/admin/signUp");
  }

  function goMenuPage() {
    navigate("/admin");
  }

  function updateCaptcha() {
    setUpdateCount((prev) => ++prev);
    setValue("captcha", "");
  }

  return (
    <Container maxWidth="sm">
      <Grid
        container
        sx={ {
          minHeight: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        } }
      >
        <Box sx={ { margin: "1rem" } }>
          <LoginBackground>
            <form onSubmit={ handleSubmit(onSubmit) }>
              <Grid container>
                <Grid xs={ 12 }>
                  <Typography variant="h6">로그인</Typography>
                </Grid>

                <Grid xs={ 12 } sx={ { paddingTop: "2.5rem" } }>
                  <TextFieldWrap>
                    <InputLabel shrink htmlFor="idForm">아이디</InputLabel>
                    <RoundTextField
                      id="idForm"
                      size="small"
                      name="id"
                      control={ control }
                      rules={ { required: "아이디를 입력하세요." } }
                    />
                  </TextFieldWrap>
                </Grid>

                <Grid xs={ 12 } sx={ { paddingTop: "1rem" } }>
                  <TextFieldWrap>
                    <InputLabel shrink htmlFor="pwForm">비밀번호</InputLabel>
                    <RoundPasswordField
                      id="pwForm"
                      size="small"
                      name="pw"
                      control={ control }
                      rules={ { required: "비밀번호를 입력하세요." } }
                    />
                  </TextFieldWrap>
                </Grid>

                {
                  isShowCaptcha && (
                    <React.Fragment>
                      <Grid xs={ 12 } sx={ { paddingTop: "1rem" } }>
                        <TextFieldWrap>
                          <InputLabel shrink htmlFor="captchaForm">보안 코드</InputLabel>
                          <RoundTextField
                            id="captchaForm"
                            size="small"
                            name="captcha"
                            control={ control }
                            rules={ { required: "아래 이미지를 보이는 대로 입력하세요." } }
                          />
                        </TextFieldWrap>
                      </Grid>

                      <Grid
                        container
                        xs={ 12 }
                        sx={ {
                          paddingTop: ".8rem",
                          alignItems: "center",
                          justifyContent: "center",
                        } }
                      >
                        { getCaptchaImage }

                        <RoundLoadingButton
                          size="small"
                          variant="contained"
                          disableElevation
                          onClick={ updateCaptcha }
                        >
                          <Refresh/>
                        </RoundLoadingButton>
                      </Grid>
                    </React.Fragment>
                  )
                }

                <Grid xs={ 12 } sx={ { paddingTop: ".4rem" } }>
                  <FormControlLabel
                    label="아이디 저장"
                    control={ <Checkbox checked={ saveId } onChange={ onChangeCheckbox }/> }
                    sx={ {
                      "&.Mui-disabled": {
                        color: "#ccd7dd",
                      },
                      "&.Mui-checked": {
                        color: "#1c7dee",
                      },
                    } }
                  />
                </Grid>

                <Grid xs={ 12 } sx={ { paddingTop: "1rem" } }>
                  <RoundLoadingButton
                    variant="contained"
                    size="large"
                    type="submit"
                    disableElevation
                    fullWidth
                    loading={ loading.current || false }
                  >
                    로그인
                  </RoundLoadingButton>

                  {
                    loginStatus !== null && !loginStatus && (
                      <Typography
                        noWrap
                        variant="caption"
                        display="inline"
                        sx={ { color: "#ee3a85", whiteSpace: "pre-line" } }
                      >
                        { errorMessage.current }
                      </Typography>
                    )
                  }
                </Grid>

                <Grid
                  xs={ 12 }
                  sx={ {
                    paddingTop: ".7rem",
                    textAlign: "center",
                  } }>
                  <Typography
                    variant="overline"
                    display="inline"
                    mr={ 1 }
                  >
                    계정이 없으십니까?
                  </Typography>
                  <PointerCursorLink
                    variant="overline"
                    underline="hover"
                    onClick={ goSignUpPage }
                  >
                    회원 등록
                  </PointerCursorLink>
                </Grid>
              </Grid>
            </form>
          </LoginBackground>
        </Box>
      </Grid>
    </Container>
  );
}

const LoginBackground = styled((props) => (
  <Paper elevation={ 3 } { ...props } />
))(() => ({
  padding: "2.5rem",
}));

const TextFieldWrap = styled.div`
  display: flex;
  flex-direction: column;
`;

export default Login;