import qs from "query-string";
import axios from "../axios";
import { REQUEST_ADD_TENT_DEFAULT_URL } from "./defaultUrl";

export async function getRequestData(estimateId) {
	const res = await axios.post(REQUEST_ADD_TENT_DEFAULT_URL + "/listAll/getRequestData", qs.stringify({ estimateId }));
	return res?.data;
}

export async function getEstimateDetail(estimateId) {
	const res = await axios.post(REQUEST_ADD_TENT_DEFAULT_URL + "/listAll/getEstimateDetail", qs.stringify({ estimateId }));
	return res?.data;
}

export async function getBrandData() {
	const res = await axios.post(REQUEST_ADD_TENT_DEFAULT_URL + "/listAll/getBrand");
	return res?.data;
}

export async function getTentData(brandId) {
	const res = await axios.post(REQUEST_ADD_TENT_DEFAULT_URL + "/listAll/getTent", qs.stringify({ brandId }));
	return res?.data;
}

export async function insertBrand(brandName) {
	const res = await axios.post(REQUEST_ADD_TENT_DEFAULT_URL + "/insertBrand", qs.stringify({ brandName }));
	return res?.data;
}

export async function insertTent(requestData) {
	const res = await axios.post(REQUEST_ADD_TENT_DEFAULT_URL + "/insertTent", qs.stringify({ ...requestData }));
	return res?.data;
}

export async function updateEstimate(requestData) {
	const res = await axios.post(REQUEST_ADD_TENT_DEFAULT_URL + "/update/updateEstimate", qs.stringify({ ...requestData }));
	return res?.data;
}

export async function updateRequestAddTent(requestData) {
	const res = await axios.post(REQUEST_ADD_TENT_DEFAULT_URL + "/setRequestComplete", qs.stringify({ ...requestData }));
	return res?.data;
}