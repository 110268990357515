import React, {
	useCallback, useMemo, useRef,
	useState,
} from "react";
import { styled } from "@mui/material/styles";
import {
	Alert,
	Box,
	Button, Card, CardContent,
	Dialog,
	DialogActions,
	DialogContent, Divider, FormControl, FormControlLabel, InputAdornment, InputLabel, RadioGroup, TextField,
	Typography,
} from "@mui/material";
import { FREE_DELIVERY } from "../../../lib/config/settings";
import BackgroundBox from "../BackgroundBox";
import RadioButton from "../RadioButton";

export default function ChangeDeliveryType({ onSubmit, onClose, deliveryType, deliveryDivision, total, deliveryCost, cancelFlag, ...props }) {
	const delivery = useMemo(() => ([
		{ value: "delivery-free", label: "무료배송", deliveryType: 1, deliveryDivision: 3 },
		{ value: "delivery-before", label: "선불배송", deliveryType: 1, deliveryDivision: 1 },
		{ value: "delivery-after", label: "착불배송", deliveryType: 1, deliveryDivision: 2 },
		{ value: "pickUp", label: "방문수령", deliveryType: 2 },
	]), []);
	const beforeSelect = useRef(deliveryType === "2" ? "pickUp" : deliveryDivision === "1" ? "delivery-before" : "delivery-after");
	const [select, setSelect] = useState(beforeSelect.current);
	const [cost, setCost] = useState(deliveryCost || 0);
  const [isLoading, setIsLoading] = useState(false);
  const setLoadingDone = useCallback(() => {
    setIsLoading(false);
  }, []);

  async function handledSubmit() {
		if(select !== "pickUp" && cost <= 0) {
			return ;
		}

		const data = {
			deliveryType: getDeliveryData(select).deliveryType,
			deliveryDivision: getDeliveryData(select)?.deliveryDivision,
			cost: select === "pickUp" ? 0 : cost
		};

	  setIsLoading(true);
    await onSubmit(onClose, setLoadingDone, data);
  }

  function handleClose() {
    onClose();
  }

	function getDeliveryData(value) {
		return delivery.find((item) => item.value === value);
	}

	function handleChange({ target : { value } }) {
		setSelect(value);
	}

	function handleCostChange({ target: { value } }) {
		setCost(value);
	}

  return (
    <Dialog
      open
      maxWidth="xs"
      fullWidth
    >
	    <Typography variant="dialogTitle">변경하실 수령 방법을 선택해주세요</Typography>

      <DialogContent>
	      <BackgroundBox className="customUl" mb={ 1 }>
		      <ul>
			      { !cancelFlag && total >= FREE_DELIVERY && (
				      <li>
					      <Typography variant="dialogContext">최종 견적 비용이 { FREE_DELIVERY.toLocaleString() }원 이상이기 때문에 선불, 착불 배송을 선택하실 수 없습니다</Typography>
				      </li>
			      ) }

			      <li>
				      <Typography variant="context">
					      <strong>{ getDeliveryData(beforeSelect.current).label }</strong>에서 <strong>{ getDeliveryData(select).label }</strong>으로 변경하시겠어요?
				      </Typography>
			      </li>
		      </ul>
	      </BackgroundBox>

	      <FormControl>
		      <RadioGroup
			      value={ select }
			      onChange={ handleChange }
		      >
			      { delivery.map(({ value, label, deliveryType, deliveryDivision }) => (
				      <FormControlLabel
					      key={ value }
					      control={ <RadioButton /> }
					      value={ value }
					      label={ label }
					      disabled={ !cancelFlag && total >= FREE_DELIVERY && deliveryType === 1 && deliveryDivision !== 3 }
				      />
			      )) }
		      </RadioGroup>
	      </FormControl>

	      <Card sx={ { mt: 1 } }>
		      <CardContent>
			      <Typography variant="cardBold" mb={ 1 }>배송비</Typography>

			      <TextField
				      type="number"
				      fullWidth
				      value={ select === "pickUp" ? 0 : cost }
				      onChange={ handleCostChange }
				      disabled={ select === "pickUp" }
				      InputProps={ {
					      endAdornment: <InputAdornment position="end">원</InputAdornment>,
				      } }
			      />

			      {/*<Typography variant="cardContext" mt={ 0.5 }>* 최종 견적 금액이 10만원 이상 시 무료배송이므로 배송비 설정 불가</Typography>*/}

			      { select !== "pickUp" && cost <= 0 && (
				      <Typography variant="cardContext" sx={{ color: (theme) => theme.palette.primary.main, mt: 0.5 }}>* 배송비를 입력해주세요.</Typography>
			      ) }

		      </CardContent>
	      </Card>
      </DialogContent>

      <DialogActions>
	      <Button
		      size="small"
		      variant="outlined"
		      color="secondary"
		      disabled={ isLoading }
		      onClick={ handleClose }
	      >
		      취소
	      </Button>

	      <Button
		      size="small"
		      variant="contained"
		      disabled={ (select !== "pickUp" && cost <= 0) || isLoading }
		      onClick={ handledSubmit }
	      >
		      변경
	      </Button>
      </DialogActions>
    </Dialog>
  );
}