import { Stack, Typography } from "@mui/material";
import { DatePicker, pickersFadeTransitionGroupClasses } from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { isEmptyString } from "../../lib/libs";

function DateRange({ startDate, endDate, substractDay = 7, format = "YYYY-MM-DD", onChange }) {
	// const now = dayjs(new Date());
	const [sDate, setSDate] = useState(startDate ? dayjs(startDate) : null);
	const [eDate, setEDate] = useState(endDate ? dayjs(endDate) : null);

	useEffect(() => {
		setSDate(isEmptyString(startDate) ? null : dayjs(startDate));
		setEDate(isEmptyString(endDate) ? null : dayjs(endDate));
	}, [startDate, endDate]);

	useEffect(() => {
		const start = (sDate !== null && sDate.isValid()) ? sDate.format(format) : "";
		const end = (eDate !== null && eDate.isValid()) ? eDate.format(format) : "";

		if(typeof onChange === "function") {
			if(!(start === startDate && end === endDate)) {
				onChange([start, end]);
			}
		}
	}, [sDate, eDate]);

	return (
		<Stack direction="row" spacing={ 1 } alignItems="center">
			<DatePicker
				label="시작일"
				format={ format }
				views={ ["year", "month", "day"] }
				value={ sDate }
				onChange={(newValue) => {
					setSDate(newValue);

					if(eDate !== null && dayjs(dayjs(newValue).format(format)).isAfter(eDate.format(format))) {
						setEDate(null)
					}
				}}
				localeText={{
					toolbarTitle: sDate?.isValid() ? sDate.format("YYYY") : "____"
				}}
			/>

			<Typography variant="context">~</Typography>

			<DatePicker
				label="종료일"
				format={ format }
				views={ ["year", "month", "day"] }
				value={ eDate }
				showDaysOutsideCurrentMonth
				shouldDisableDate={ day => {
					return sDate !== null ? dayjs(dayjs(day).format(format)).isBefore(sDate.format(format)) : null;
				}}
				onChange={(newValue) => setEDate(newValue)}
				localeText={{
					toolbarTitle: eDate?.isValid() ? eDate.format("YYYY") : "____"
				}}
			/>
		</Stack>
	);
}

export default DateRange;