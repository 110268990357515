import { Box, Button, Dialog, DialogActions, DialogContent, Typography } from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { usePublicUserDispatch, usePublicUserState } from "../../../hooks/public/publicAuthContextProvider";
import { getRealtimeUser } from "../../../lib/firestore/User";
import SelectUserAddress from "../../public/orderHistoryDetail/SelectUserAddress";
import UserAddress from "../../public/profile/UserAddress";

function AddressesModal({ onSubmit, onClose, title, submitTitle, content, selectedFirst = false, ...props }) {
	const [isLoading, setIsLoading] = useState(false);
	const [selectIndex, setSelectIndex] = useState(selectedFirst ? 0 : null);
	const { user } = usePublicUserState();
	const { setLoggedIn } = usePublicUserDispatch();
	const unsubscribeRef = useRef(null);
	const setLoadingDone = useCallback(() => {
		setIsLoading(false);
	}, []);

	useEffect(() => {
		getRealtimeUser(unsubscribeRef, (userData) => {
			setLoggedIn(userData);
		});

		return () => {
			unsubscribeRef.current?.();
		};
	}, []);

	async function handleSubmit() {
		setIsLoading(true);

		await onSubmit(onClose, user.address[selectIndex], setLoadingDone);
	}

	return (
		<Dialog
			open
			maxWidth="xs"
			fullWidth
		>
			<Typography variant="dialogTitle">{ title }</Typography>

			<DialogContent>
				<Typography variant="dialogContent" mb={ 1.5 }>{ content }</Typography>

				<SelectUserAddress
					selectIndex={ selectIndex }
					setSelectIndex={ setSelectIndex }
				/>
			</DialogContent>

			<DialogActions>
				<Button
					size="small"
					variant="outlined"
					color="secondary"
					disabled={ isLoading }
					onClick={ onClose }
				>
					닫기
				</Button>

				<Button
					size="small"
					variant="contained"
					disabled={ selectIndex === null || isLoading }
					onClick={ handleSubmit }
				>
					{ submitTitle }
				</Button>
			</DialogActions>
		</Dialog>
	);
}

export default AddressesModal;