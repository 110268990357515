import { Edit, RemoveCircle } from "@mui/icons-material";
import {
    Button,
    Container,
    IconButton,
    Skeleton,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { createColumnHelper, flexRender, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import React, { useMemo } from "react";
import CheckBox from "../../components/common/CheckBox";
import { modals } from "../../hooks/Modals";
import useModal from "../../hooks/useModal";
import { FormColumnType, FormDialogType } from "../../lib/config/formDialogType";
import {
    GET_BRAND_KEY,
    useBrandQuery,
    useDeleteBrandMutation,
    useInsertBrandMutation,
    useUpdateBrandMutation,
} from "../../query/admin/brandQuery";

export default function Brand() {
  const { openModal } = useModal();
  const { insertMutate } = useInsertBrandMutation();
  const { updateMutate } = useUpdateBrandMutation();
  const { deleteMutate } = useDeleteBrandMutation();
  const queryClient = useQueryClient();

  function getColumnDef() {
    return table.options.columns.reduce((acc, cur) => {
      return [
        ...acc,
        { id: cur.id || cur.accessorKey, ...cur }
      ];
    }, []);
  }

  function addModalOpen() {
    openModal(modals.formModal, {
      type: FormDialogType.ADD,
      columnDef: getColumnDef(),
      onSubmit: async (formData, onClose, showToast, complete) => {
        insertMutate(formData, {
          onSuccess: ({ cd }) => {
            if(cd === "1") {
              onClose();
              return queryClient.invalidateQueries(GET_BRAND_KEY);
            }
          },
          onSettled: (data, error) => {
            complete();
            showToast({ cd: (error === null) ? data.cd : 0 });
          }
        });
      }
    });
  }

  function EditRowCell({ info, rowData }) {
    function editModalOpen() {
      openModal(modals.formModal, {
        type: FormDialogType.EDIT,
        rowData,
        columnDef: getColumnDef(),
        onSubmit: (formData, onClose, showToast, complete) => {
          updateMutate(formData, {
            onSuccess: ({ cd }) => {
              if(cd === "1") {
                onClose();
                return queryClient.invalidateQueries(GET_BRAND_KEY);
              }
            },
            onSettled: (data, error) => {
              complete();
              showToast({ cd: (error === null) ? data.cd : 0 });
            }
          });
        },
      });
    }

    function deleteModalOpen() {
      openModal(modals.formModal, {
        type: FormDialogType.DELETE,
        rowData,
        columnDef: getColumnDef(),
        onSubmit: (formData, onClose, showToast, complete) => {
          deleteMutate(formData, {
            onSuccess: ({ cd }) => {
              if(cd === "1") {
                onClose();
                return queryClient.invalidateQueries(GET_BRAND_KEY);
              }
            },
            onSettled: (data, error) => {
              complete();
              showToast({ cd: (error === null) ? data.cd : 0 });
            }
          });
        }
      });
    }

    return (
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={ .5 }
      >
        <IconButton size="small" onClick={ editModalOpen }>
          <Edit sx={ { color: (theme) => theme.palette.text.secondary } } />
        </IconButton>

        <IconButton size="small" onClick={ deleteModalOpen }>
          <RemoveCircle sx={ { color: (theme) => theme.palette.text.secondary } } />
        </IconButton>
      </Stack>
    );
  }

  const columnDefs = useMemo(() => {
    const ch = new createColumnHelper();

    return [
      ch.accessor("brndId", {
        header: "브랜드 ID",
        cell: info => info.getValue(),
        fEditable: false,
        fPk: true
      }),
      ch.accessor("brndNm", {
        header: "브랜드",
        cell: info => info.getValue(),
        align: "left",
        fType: FormColumnType.TEXT,
        fRequired: true,
        fMaxByte: 100,
      }),
      ch.accessor("useYn", {
        header: "사용",
	      cell: info => <CheckBox readOnly checked={ info.getValue() } />,
        size: 1,
        fType: FormColumnType.CHECKBOX,
        fDefaultValue: true
      }),
      ch.accessor("updtDtm", {
        header: "수정 일자",
        cell: info => info.getValue(),
        fEditable: false
      }),
      ch.accessor("updtUsrId", {
        header: "수정자 ID",
        cell: info => info.getValue(),
        fEditable: false
      }),
      ch.accessor("oprtrNm", {
        header: "수정자 명",
        cell: info => info.getValue(),
        align: "left",
        fEditable: false
      }),
      ch.display({
        id: "edit",
        header: "편집",
        cell: info => <EditRowCell info={ info } rowData={ info?.row?.original } />,
        size: 1,
        fEditable: false
      }),
    ];
  }, []);

  const {
    isBrandLoading,
    brandData
  } = useBrandQuery();

  const table = useReactTable({
    data: brandData || [],
    columns: columnDefs,
    getCoreRowModel: getCoreRowModel(),
    initialState: {
      columnVisibility: {
        brndId: false,
        updtUsrId: false,
        updtDtm: false,
        oprtrNm: false
      }
    },
  });

  return (
    <Container variant="contextRoot">
      <Container variant="contextBox">
        { isBrandLoading ? (
            <Skeleton variant="rounded" width="100%" height="100%" />
          ) : (
						<React.Fragment>
							<TableContainer sx={{ overflow: "visible" }}>
								<Table>
									<TableHead sx={{ position: "sticky", top: 0, zIndex: 1000 }}>
										{ table.getHeaderGroups().map(headerGroup => (
											<TableRow key={ headerGroup.id }>
												{ headerGroup.headers.map(header => (
													<TableCell
														key={ header.id }
														variant="header"
														colSpan={ header.colSpan }
														sx={{ width: header.getSize() }}
													>
														{ header.isPlaceholder ? null : (
															flexRender(header.column.columnDef.header, header.getContext())
														) }
													</TableCell>
												)) }
											</TableRow>
										)) }
									</TableHead>

									<TableBody>
										{ table.getRowModel().rows.map(row => {
											return (
												<TableRow key={ row.id }>
													{ row.getVisibleCells().map((cell, index) => {
														return index === 0 ? (
															<TableCell
																key={ cell.id }
																variant="firstGroup"
																align={ cell.column.columnDef.align || "center" }
																sx={{ position: "relative" }}
															>
																{ flexRender(cell.column.columnDef.cell, cell.getContext()) }
															</TableCell>
														) : (
															<TableCell
																key={ cell.id }
																variant="body"
																align={ cell.column.columnDef.align || "center" }
															>
																{ flexRender(cell.column.columnDef.cell, cell.getContext()) }
															</TableCell>
														)
													}) }
												</TableRow>
											);
										}) }

										<TableRow
											sx={{
												position: "sticky",
												bottom: 0
											}}
										>
											<TableCell colSpan={ 4 } sx={{ padding: 0 }}>
												<Button variant="contained" size="large" fullWidth onClick={ addModalOpen }>추가</Button>
											</TableCell>
										</TableRow>
									</TableBody>
								</Table>
							</TableContainer>
						</React.Fragment>
        ) }
      </Container>
    </Container>
  )
}