import React, {
	useMemo
} from "react";
import {
	Box,
	Container,
	Divider,
	Stack,
	Typography
} from "@mui/material";
import useKakaoMap from "../../../hooks/public/useKakaoMap";
import BranchInfo from "../../../components/public/BranchInfo";
import TopImage01 from "../../../assets/images/main_shop/top_01.jpg";
import TopImage03 from "../../../assets/images/main_shop/top_03.jpg";
import SwiperTopCard from "../../../components/public/SwiperTopCard";
import { isMobile } from "react-device-detect";

function MainShop() {
	useKakaoMap({
		locationName: "텐트세탁소 본점",
		lat: 37.3119023,
		lng: 126.9786985
	});

	const topImages = useMemo(() => ([
		{ image: TopImage01, backgroundPositionY: "-14px" },
		// { image: TopImage02 },
		{ image: TopImage03, backgroundPositionY: 0 },
	]), []);

	const info = useMemo(() => ({
		tel: "070-4644-0174",
		cafe: "https://cafe.naver.com/tentwashing"
	}), []);

	return (
		<Container variant="contextRoot">
			<SwiperTopCard images={ topImages } />

			<Container variant="contextBox">
				<Stack direction="column" spacing={ 3 }>
					<BranchInfo info={ info } />

					<Divider />

					<Typography variant="context">
						전화: { info.tel }
					</Typography>

					<Typography variant="context">
						경기도 수원시 장안구 천천로240, 하늘연 1층
					</Typography>

					<Box
						sx={ {
							width: "calc(100% + 30px)",
							height: "200px",
							position: "relative",
							left: "-15px"
						} }
					>
						<div id="map" style={ { width: "100%", height: "100%", border: !isMobile && "1px solid #1E1E1E" } } />
					</Box>
				</Stack>
			</Container>
		</Container>
	);
}

export default MainShop;