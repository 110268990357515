import React, { useEffect, useMemo, useRef, useState } from "react";
import ReadOnlyPickUpLocation from "../../admin/ReadOnlyPickUpLocation";
import CheckBox from "../../common/CheckBox";
import StyledSelectBox from "../StyledSelectBox";
import { Box, Card, CardContent, Divider, FormControlLabel, Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

function RequestBranch({ branchData, defaultBranch }, ref) {
	const { branchRef, pickUpRef } = ref;
	const [branchId, setBranchId] = useState(defaultBranch || "10");
	const [requiredWeek, setRequiredWeek] = useState();
	const [pickUp, setPickUp] = useState();
	const [checkPickUp, setCheckPickUp] = useState(false);
	const pickUpCard = useMemo(() => ({
		30: (
			<React.Fragment>
				<ReadOnlyPickUpLocation
					weekString="화요일"
					startTime="10:00"
					cost={ 20000 }
					detail={[
						{ lctnNm: "파주" },
						{ lctnNm: "고양" },
						{ lctnNm: "서울북부", dtlLctnNm: "도봉구,은평구,강북구,노원구,종로구,성북구,중랑구,서대문구,중구,동대문구,마포구,용산구,성동구,광진구" },
					]}
				/>

				<Divider variant="middle" flexItem />

				<ReadOnlyPickUpLocation
					weekString="수요일"
					startTime="10:00"
					cost={ 20000 }
					detail={[
						{ lctnNm: "구리" },
						{ lctnNm: "하남" },
						{ lctnNm: "서울남부", dtlLctnNm: "강서구,양천구,구로구,영등포구,금천구,동작구,관악구,서초구,강남구,송파구,강동구" },
					]}
				/>

				<Divider variant="middle" flexItem />

				<ReadOnlyPickUpLocation
					weekString="목요일"
					startTime="10:00"
					cost={ 20000 }
					detail={[
						{ lctnNm: "포천" },
						{ lctnNm: "동두천" },
						{ lctnNm: "양주" },
						{ lctnNm: "의정부" },
					]}
				/>
			</React.Fragment>
		)
	}), []);

	useEffect(() => {
		handleChange(defaultBranch || "10");
	}, [defaultBranch]);

	function handleChange(value) {
		const item = branchData.find((item) => item.value === value);
		const weeks = item?.week;
		let weeksString = "";

		if(weeks) {
			const weekSplit = weeks.split(",");

			if(weekSplit[0] === weekSplit[1]) {
				weeksString = weekSplit[0];
			} else {
				for(let week of weekSplit) {
					if(weeksString !== "") {
						weeksString += " ~ ";
					}

					weeksString += week;
				}
			}
		}

		setBranchId(value);
		setPickUp(item?.pckUpSrvcYn === "Y");
		setRequiredWeek(weeksString || undefined);
	}

	return (
		<React.Fragment>
			<StyledSelectBox
				title="세탁 의뢰 지점"
				items={ branchData }
				value={ branchId }
				onChange={ handleChange }
				ref={ branchRef }
			/>

			{ requiredWeek !== undefined && (
				<WeeksBox>
					<Typography variant="cardBold" className="title">예상 소요 기간</Typography>
					<Typography variant="cardBold" className="day">{ `약 ${ requiredWeek }주` }</Typography>
				</WeeksBox>
			) }

			{ pickUp && (
				<React.Fragment>
					{ Object.keys(pickUpCard).includes(branchId) && (
						<Card sx={ { mt: 2, height: 400, overflowY: "scroll" } }>
							<CardContent>
								<Typography variant="cardBold" mb={ 3 }>수거 서비스 안내</Typography>

								<Stack spacing={ 2 }>
									{ pickUpCard[branchId] }
								</Stack>
							</CardContent>
						</Card>
					) }

					<FormControlLabel
						sx={ { mt: 1 } }
						label="수거 서비스를 이용할게요"
						checked={ checkPickUp }
						onChange={ ({ target: { checked } }) => setCheckPickUp(checked) }
						control={ <CheckBox /> }
						ref={ pickUpRef }
					/>
				</React.Fragment>
			) }
		</React.Fragment>
	);
}

export const WeeksBox = styled(Box)(({ theme }) => ({
	...theme.components.MuiButtonBase.styleOverrides.root,
	...theme.components.MuiButton.styleOverrides.root,
	backgroundColor: theme.palette.secondary.main,
	width: "100%",
	display: "flex",
	alignItems: "center",
	justifyContent: "space-around",
	marginTop: 20,
	"& > .title": {
		fontWeight: "normal",
	},
	"& > .week": {
		fontSize: 16,
	},
}));

export default React.forwardRef(RequestBranch);