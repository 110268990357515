import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import history from "../lib/history";

function UseBackLocation() {
	const [locationKey, setLocationKey] = useState([]);
	const navigate = useNavigate();

	useEffect(() => {
		const historyEvent = history.listen(({ action, location }) => {
			if(action === "PUSH") {
				setLocationKey([location.key]);
			}

			if(action === "POP") {
				if(locationKey[1] === location.key) {
					// 앞으로 가기
					setLocationKey(([_, ...keys]) => keys);
				} else {
					// 뒤로 가기
					setLocationKey((keys) => [location.key, ...keys]);
					
					const path = localStorage.getItem("before_page");
					navigate(path || "/");
				}
			}
		});

		return () => {
			historyEvent();
		}
	}, [locationKey, history]);
}

export default UseBackLocation;