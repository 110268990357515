import { Card, CardContent, Skeleton, Stack,  Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React, { useEffect, useState } from "react";
import { getOrder } from "../../lib/firestore/Order";
import { HomeRounded } from "@mui/icons-material";

function ReadOnlyDeliveryAddressCard({ title, orderId }) {
	const [address, setAddress] = useState(null);

	useEffect(() => {
		async function getAddress() {
			const order = (await getOrder(orderId)).data();

			setAddress({ ...order });
		}

		getAddress();
	}, []);

	return (
		address === null ? (
			<Skeleton variant="rounded" width="100%" height="160px" />
		) : (
			<Card>
				<CardContent>
					<Stack>
						<Stack
							direction="row"
							alignItems="center"
							justifyContent="space-between"
							mb={ 2 }
						>
							<Stack direction="row" spacing={ 1 }>
								<HomeRounded fill="none" />
								<Typography variant="cardBold">{ title || "텐트 받을 주소지" }</Typography>
							</Stack>
						</Stack>

						<Grid container>
							<React.Fragment>
								<Grid xs={ 3 }>
									<Typography variant="cardContext" type="title">수령인</Typography>
								</Grid>

								<Grid xs={ 9 }>
									<Typography variant="cardContext">{ address.receiverName }</Typography>
								</Grid>
							</React.Fragment>

							<React.Fragment>
								<Grid xs={ 3 }>
									<Typography variant="cardContext" type="title">휴대폰</Typography>
								</Grid>

								<Grid xs={ 9 }>
									<Typography variant="cardContext">{ address.receiverPhoneNumber }</Typography>
								</Grid>
							</React.Fragment>

							<React.Fragment>
								<Grid xs={ 3 }>
									<Typography variant="cardContext" type="title">주소</Typography>
								</Grid>

								<Grid xs={ 9 }>
									<Typography variant="cardContext">[{ address.zoneCode }] { address.address }, { address.addressDetail }</Typography>
								</Grid>
							</React.Fragment>
						</Grid>
					</Stack>
				</CardContent>
			</Card>
		)
	);
}

export default React.memo(ReadOnlyDeliveryAddressCard);