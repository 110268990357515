import qs from "query-string";
import axios from "../axios";

const DEFAULT_LIST_ALL_URL = "/api/admin/estimate/listAll";

export async function getBrands() {
	const res = await axios.post(DEFAULT_LIST_ALL_URL + "/getBrand", {});
	return res?.data;
}

export async function getTent(brndId) {
	const res = await axios.post(DEFAULT_LIST_ALL_URL + "/getTent", qs.stringify({ brndId }));
	return res?.data;
}

export async function getEstimate(tntId) {
	const res = await axios.post(DEFAULT_LIST_ALL_URL + "/getTentInfo", qs.stringify({ tntId }));
	return res?.data;
}