import { ExpandMoreRounded } from "@mui/icons-material";
import {
	Box,
	Button,
	Card,
	CardActions,
	CardContent,
	Chip,
	IconButton,
	Stack,
	Tooltip,
	Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useMemo, useState } from "react";
import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import { useAdminUserState } from "../../../hooks/admin/adminAuthContextProvider";
import { modals } from "../../../hooks/Modals";
import useModal from "../../../hooks/useModal";
import { isEmptyString } from "../../../lib/libs";
import Role from "../../../lib/Role";
import { useDeleteOrderMutation } from "../../../query/admin/orderQuery";
import { useRequestImages } from "../../../query/getImageQuery";
import EstimateCard from "../../common/EstimateCard";
import ImageUpload from "../../public/ImageUpload";
import { Toast } from "../../public/Toast";
import CardTitle from "./CardTitle";
import ContentField from "./ContentField";

function Step1Card({
	orderId,
	estimateId,
	userId,
	nickname,
	orderType,
	date,
	brandName,
	tentName,
	imagePath,
	requestContent,
	requestAddTentFlag,
	requestAddTentCompleteFlag,
	estimateDetail,
	branchName,
	refreshQuery,
}) {
	const [open, setOpen] = useState(false);
	const {
		      isRequestImagesLoading,
		      requestImagesData,
	      } = useRequestImages(orderId, imagePath, open, true);
	const navigate = useNavigate();
	const { role, branchId } = useAdminUserState();
	const isAddingTent = requestAddTentFlag && !requestAddTentCompleteFlag;
	const { openModal } = useModal();
	const { setDeleteOrderMutate } = useDeleteOrderMutation();
	const title = useMemo(() => {
		let name = "";

		if(!isEmptyString(brandName)) {
			name += brandName;
		}

		if(!isEmptyString(tentName)) {
			if(!isEmptyString(name)) {
				name += " - ";
			}

			name += tentName;
		}

		return name;
	}, [brandName, tentName]);

	function handleClick() {
		navigate(`/admin/order/saveEstimate/${ estimateId }`);
	}

	function handleAddTentClick() {
		navigate(`/admin/requestAddTent/${ estimateId }`);
	}

	function handleDelete() {
		openModal(modals.confirmModal, {
			submitTitle: "삭제",
			content: "해당 주문을 삭제하시겠어요?",
			onSubmit: (onClose, setLoadingDone) => {
				const request = { orderId, estimateId };

				setDeleteOrderMutate(request, {
					onSuccess: () => {
						refreshQuery();
						Toast.success("삭제가 완료되었어요.");
						onClose();
					},
					onError: () => {
						onClose();
						openModal(modals.confirmModal, {
							title: "죄송합니다, 삭제가 실패했습니다",
							content: "잠시 후에 다시 시도해주시기 바랍니다.",
							closeTitle: "닫기",
						});
					},
					onSettled: () => {
						setLoadingDone();
					}
				})
			}
		});
	}

	return (
		<Card>
			<CardContent>
				<CardTitle
					orderType={ orderType }
					date={ date }
					nickname={ nickname }
					userId={ userId }
				/>

				<Stack
					direction="row"
					alignItems="center"
					spacing={ 1 }
					mb={ open ? 3 : 1 }
				>
					<Chip label={ `${ branchName }${ isAddingTent ? " - 텐트 등록 중" : "" }` } color="secondary" />

					<Stack
						direction="row"
						justifyContent="space-between"
						alignItems="center"
						sx={ { width: "100%" } }
					>
						{ (title + branchName + (isAddingTent ? " - 텐트 등록 중" : "")).length <= (isMobile ? 28 : 29) ? (
							<Typography variant="cardBold">{ title }</Typography>
						) : (
							<Tooltip title={ title } arrow>
								<Typography variant="cardBold">{ title.substring(0, (isMobile ? 28 : 29) - (branchName.length + (isAddingTent ? 10 : 0))) }...</Typography>
							</Tooltip>
						) }

						<ExpandButton size="small" more={ open ? 1 : 0 } onClick={ () => setOpen((prev) => !prev) }>
							<ExpandMoreRounded />
						</ExpandButton>
					</Stack>
				</Stack>

				{ open && (
					<React.Fragment>
						{ orderType === "1" && requestAddTentFlag && (
							<Box mb={ 1 }>
								<EstimateCard
									title="가견적 요청 서비스"
									orderType={ orderType }
									detail={ estimateDetail }
									shownPrice={ false }
								/>
							</Box>
						) }

						{ !isRequestImagesLoading && requestImagesData.length > 0 && (
							<ImageUpload
								readOnly
								images={ requestImagesData }
							/>
						) }

						{ !isEmptyString(requestContent) && (
							<ContentField value={ requestContent } />
						) }
					</React.Fragment>
				) }
			</CardContent>

			<CardActions>
				<Stack
					sx={ { width: "100%" } }
					direction="row"
					justifyContent="flex-end"
					spacing={ 1 }
				>
					<Button
						variant="contained"
						size="small"
						color="secondary"
						onClick={ handleDelete }
					>
						삭제
					</Button>

					{ role === Role.ROLE_ADMIN && (
						requestAddTentFlag && !requestAddTentCompleteFlag && (
							<Button
								variant="contained"
								size="small"
								onClick={ handleAddTentClick }
							>
								텐트 등록
							</Button>
						)
					) }

					{ requestAddTentFlag && !requestAddTentCompleteFlag ? (
						<Tooltip
							arrow
							placement="left"
							title="텐트가 등록된 후에 시도해주세요"
						>
							<span>
								<Button
									variant="contained"
									size="small"
									disabled
								>
									가견적 등록
								</Button>
							</span>
						</Tooltip>
					) : (
						<Button
							variant="contained"
							size="small"
							onClick={ handleClick }
						>
							가견적 등록
						</Button>
					) }
				</Stack>
			</CardActions>
		</Card>
	);
}

const ExpandButton = styled(IconButton)(({ more }) => ({
	backgroundColor: "rgba(255,255,255,0.15)",
	padding: 1,
	"& > .MuiSvgIcon-root": {
		transition: "all 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
		transform: more && "rotate(180deg)",
	},
}));

export default React.memo(Step1Card);