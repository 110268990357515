import { Box, Skeleton, Stack, Typography } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import React, { useRef, useState } from "react";
import FilterSmall, { FILTER_TYPE_NAME, getFilterData } from "../../../components/admin/order/FilterSmall";
import Step6Card from "../../../components/admin/order/Step6Card";
import { GET_HISTORY_STEP, useOrderHistoryStepQuery } from "../../../query/admin/orderQuery";

function Step6() {
	const enabled = useRef([FILTER_TYPE_NAME.NICKNAME, FILTER_TYPE_NAME.PAYMENT]);
	const [filter, setFilter] = useState(getFilterData(enabled.current));
	const {
		      isLoading,
		      data,
	      } = useOrderHistoryStepQuery(6, filter);
	const queryClient = useQueryClient();

	function submit(filter) {
		setFilter((prev) => ({
			...prev,
			...filter
		}));
	}

	async function refreshQuery() {
		await queryClient.invalidateQueries([...GET_HISTORY_STEP, 6, filter]);
	}

	return (
		<Stack spacing={ 2 }>
			<FilterSmall handleSubmit={ submit } enabled={ enabled.current } />

			{ !isLoading && data.length === 0 && (
				<Box py={ 2 }>
					<Typography align="center" variant="context">주문 내역이 없어요</Typography>
				</Box>
			) }

			{ isLoading ? (
				<Skeleton variant="rounded" width="100%" height="200px" />
			) : (
				data.map((item) => (
					<Step6Card
						key={ item.ordrId }
						orderId={ item.ordrId }
						userId={ item.usrId }
						nickname={ item.usrNcknm }
						estimateId={ item.estmtId }
						orderType={ item.ordrTp }
						brandName={ item.brndNm }
						tentName={ item.tntNm }
						date={ item.hstry }
						imagePath={ item.rqstImgPth }
						requestContent={ item.rqstCntnt }
						estimateFixImagePath={ item.estmtFxImgPth }
						estimateFixContent={ item.estmtFxCntnt }
						tentId={ item.tntId }
						totalAmount={ item.ttlAmnt }
						receptionNumber={ item.rcptnNo }
						details={ item.details }
						deliveryCost={ item.rcvngDlvrDvsn === "2" && Number(item.rcvngDlvryCst) }
						outputDeliveryCost={ item.dlvrDvsn === "1" && Number(item.dlvryCst) }
						paymentComplete={ item.pymntYn === "Y" }
						branchName={ item.brnchNm }
						refreshQuery={ refreshQuery }
					/>
				))
			) }
		</Stack>
	);
}

export default Step6;