import { Box, Button, Card, CardActions, CardContent, Chip, Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import { modals } from "../../../hooks/Modals";
import { usePublicUserState } from "../../../hooks/public/publicAuthContextProvider";
import useModal from "../../../hooks/useModal";
import { deleteAddress, updateAddress } from "../../../lib/firestore/User";
import { Toast } from "../Toast";
import UserAddressAddUpdateCard from "./UserAddressAddUpdateCard";

function UserAddressCard({ address, index, addFlag, updateFlag, isUpdate, handleStart, handleCancel, select = false, selected, setSelect }) {
	const { user } = usePublicUserState();
	const { openModal } = useModal();

	async function handleDelete() {
		openModal(modals.confirmModal, {
			content: "이 배송지를 삭제하시겠어요?",
			submitTitle: "삭제",
			onSubmit: async (onClose) => {
				await deleteAddress(address)
					.then(() => {
						Toast.success("삭제되었어요");
						onClose();

						if(typeof selected === "function") {
							setSelect(null);
						}
					});
			}
		});
	}

	async function handleUpdate(data) {
		const changeAddress = [...user.address];

		if(user.address[index].defaultFlag !== data.defaultFlag && data.defaultFlag) {
			changeAddress.splice(index, 1);
			changeAddress.map((item) => {
				item.defaultFlag = false;

				return item;
			});
			changeAddress.unshift(data);
		} else {
			changeAddress[index] = data;
		}

		await updateAddress(changeAddress).then(() => {
			handleCancel();
			Toast.success("저장되었어요");
		});
	}

	return (
		<Box>
			{ !isUpdate ? (
				<CardWrap
					select={ select ? 1 : 0 }
					className={ selected ? "active" : "" }
					onClick={ () => typeof setSelect === "function" && setSelect(index) }
				>
					<CardContent>
						<Stack spacing={ 1.5 }>
							<Stack
								direction="row"
								alignItems="center"
								justifyContent="flex-start"
								spacing={ 1 }
							>
								<Typography variant="cardBold">{ address.name }</Typography>
								{ address.defaultFlag && (
									<Chip variant="outlined" color="primary" label="기본 배송지" />
								) }
							</Stack>

							<Typography variant="cardContext">{ address.phoneNumber }</Typography>

							<Typography variant="cardContext">[{ address.zoneCode }] { address.address }, { address.addressDetail }</Typography>
						</Stack>
					</CardContent>

					<CardActions>
						<Stack direction="row" justifyContent="flex-end" spacing={ 1 } sx={{ width: "100%" }}>
							<Button variant="outlined" size="small" color="secondary" disabled={ addFlag || updateFlag && !isUpdate } onClick={ handleDelete }>삭제</Button>
							<Button variant="contained" size="small" disabled={ addFlag || updateFlag && !isUpdate } onClick={ handleStart }>수정</Button>
						</Stack>
					</CardActions>
				</CardWrap>
			) : (
				<UserAddressAddUpdateCard
					isAdd={ false }
					defaultValue={ address }
					handleCancel={ handleCancel }
					submit={ handleUpdate }
				/>
			)}
		</Box>
	);
}

const CardWrap = styled(Card)(({ theme, select }) => ({
	cursor: select ? "pointer" : "default",
	"&:hover, &.active": {
		borderColor: select && theme.palette.primary.main,
		backgroundColor: select && "rgba(232, 65, 11, 0.15)",
	}
}));

export default UserAddressCard;