import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";

function BackgroundBox({ children, ...props }) {
	return (
		<CustomBox
			p={ 2 }
			{...props}
		>
			{ children }
		</CustomBox>
	);
}

const CustomBox = styled(Box)(({theme}) => ({
	backgroundColor: theme.palette.background.light,
	// borderRadius: 4,
	"& ul": {
		margin: 0,
		padding: 0,
		listStyle: "none",
		"& > li": {
			display: "inline-flex",
			margin: "1px 0",
			"&::before": {
				content: "''",
				display: "inline-block",
				width: 3,
				height: 3,
				margin: "10px 6px 0 0",
				borderRadius: 2,
				backgroundColor: "#727272",
				verticalAlign: "top"
			}
		}
	}
}));

export default BackgroundBox;