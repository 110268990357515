import React, {
	useEffect,
	useMemo,
	useRef,
	useState
} from "react";
import {
	Box,
	Container,
	Stack,
	Typography
} from "@mui/material";
import ContentImage01 from "../../../assets/images/products/ppakkeulo/01.jpeg";
import ContentImage02 from "../../../assets/images/products/ppakkeulo/02.jpeg";
import ContentImage03 from "../../../assets/images/products/ppakkeulo/03.jpeg";
import ContentImage04 from "../../../assets/images/products/ppakkeulo/04.jpeg";
import ContentImage05 from "../../../assets/images/products/ppakkeulo/05.jpeg";
import ContentImage06 from "../../../assets/images/products/ppakkeulo/06.jpeg";
import ContentImage07 from "../../../assets/images/products/ppakkeulo/07.jpeg";
import ContentImage08 from "../../../assets/images/products/ppakkeulo/08.jpeg";
import Q01 from "../../../assets/images/products/ppakkeulo/q_01.jpg";
import ProductFixedButton from "../../../components/public/ProductFixedButton";
import QnAAccordion from "../../../components/public/QnAAccordion";
import ProductNav from "../../../components/public/ProductNav";
import BigImage from "../../../components/public/BigImage";

function Bakkeulo() {
	const [currentTab, setCurrentTab] = useState(0);
	const tabRef = useRef([]);
	const QandA = useMemo(() => ([
		{
			title: "Q. 텐트세탁하면 텐트가 망가지나요?",
			context: (
				<React.Fragment>
					발수가 중요한 텐트는 왜 신경써서 세탁해야 할까요?
					<br /><br />
					원인 첫 번째, 멤브레인 파괴로 인한 기능 저하
					<br />
					멤브레인이란, 혼합물을 분리할 수 있는 액체막 또는 고체막으로 필터 역할을 합니다.
					멤브레인은 잘못된 세재의 사용으로 파괴의 원인이 되어 아웃도어 의류 및 장비의 투습, 발수, 속건 등의 핵심 기능의 유지가 어려워지게 됩니다.
					<br /><br />
					원인 두 번째, 노폐물로 인한 섬유조직 손상으로 아웃도어 기능 저하
					<br />
					물에 잘 분해되지 않는 노폐물들은 섬유 조직 사이에 끼어 조직을 훼손시킵니다. 조금씩 코팅된 발수층 부분까지 손상이 이어지며 자연스레 기능은 저하됩니다.

					<Box mt={ 3 } sx={ { textAlign: "center" } }>
						<BigImage src={ Q01 } width={ 70 } />
					</Box>

					㈜마운틴티씨에스(텐트세탁소)는 오랜 기간 세탁제와 발수제를 개발하여 최대한의 텐트 손상을 줄이기 위해 노력했습니다.
					<br />
					대부분의 텐트세탁업체가 단순 기본 세탁에도 전용세제가 아닌 계면활성 세제를 사용하여 발수력이나 텐트에 변형을 주는 사례들이 많습니다.
					저희 업체는 텐트에 무리를 주는 세제를 제한하고 있으며 환경부 인증을 받은 아웃도어 전용세제를 사용합니다.
					<br />
					단, 장박이나 관리 소홀로 인해 곰팡이나 찌든 때로 텐트가 손상되어 들어오는 경우는 특수세제를 사용하게 되므로 추가적인 손상이 있을 수 있습니다.
					손상이 큰 경우 세탁이 불가할 수 있습니다.
				</React.Fragment>
			)
		},
		{
			title: "Q. 세탁을 하면 오염이 모두 제거되나요?",
			context: (
				<React.Fragment>
					텐트 오염의 원인은 다양하고 오염 이후에 관리 환경이나 방치 기간에 변수가 많기 때문에 완벽하게 제거된다고 말씀드릴 수는 없지만 오랜 노하우와 기술력으로 만족스러운 결과물이 나올 수 있게 노력하겠습니다.
					<br />
					믿고 맡겨주신다면 입고 후 검수 과정에서 전문 상담원이 텐트 상태와 처리 과정, 유의 사항을 친절하게 안내해 드리고 있습니다.
				</React.Fragment>
			)
		}
	]), []);
	const navItems = useMemo(() => (["제품 설명", "사용 방법", "세탁 시 주의사항", "자주 묻는 질문"]), []);

	return (
		<Container variant="contextRoot">
			<Container variant="contextBox">
				<ProductNav
					items={ navItems }
					currentTab={ currentTab }
					setCurrentTab={ setCurrentTab }
					tabRef={ tabRef }
				/>

				<Box mb={ 6 }>
					{/* 제품 설명 */ }
					<Stack variant="imageBox" ref={ el => tabRef.current[0] = el }>
						<BigImage src={ ContentImage01 } />
						<img src={ ContentImage02 } style={ { width: "100%" } } />
						<img src={ ContentImage03 } style={ { width: "100%" } } />
					</Stack>

					{/* 사용 방법 */ }
					<Stack variant="imageBox" ref={ el => tabRef.current[1] = el }>
						<img src={ ContentImage04 } style={ { width: "100%" } } />
					</Stack>

					{/* 텐트 세탁 시 주의 사항 */ }
					<Stack variant="imageBox" ref={ el => tabRef.current[2] = el }>
						<img src={ ContentImage05 } style={ { width: "100%" } } />
						<img src={ ContentImage06 } style={ { width: "100%" } } />
						<BigImage src={ ContentImage07 } />
						<BigImage src={ ContentImage08 } />
					</Stack>
				</Box>

				<Box mb={ 3 } ref={ el => tabRef.current[3] = el }>
					<Typography variant="title">자주 묻는 질문</Typography>

					<QnAAccordion items={ QandA } />
				</Box>

				<ProductFixedButton url="https://smartstore.naver.com/rhou2002/products/6911307944" />
			</Container>
		</Container>
	);
}

export default Bakkeulo;