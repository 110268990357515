import React, {
	useEffect,
	useState
} from "react";
import {
	Backdrop,
	Box,
	Collapse, Divider,
	Drawer,
	List,
	ListItem,
	ListItemButton,
	ListItemText, Stack,
	SvgIcon,
	Typography,
	useTheme,
} from "@mui/material";
import { isMobile } from "react-device-detect";
import { styled } from "@mui/material/styles";
import LogoImage from "../../assets/images/menu_logo.png";
import {
	Link,
	useLocation,
	useNavigate
} from "react-router-dom";
import { menuStructure } from "../../lib/menuStructure";
import { ReactComponent as CloseSvg } from "../../assets/images/close.svg";
import { ReactComponent as ExpendLess } from "../../assets/images/menu_expend_less.svg";
import { ReactComponent as ExpendMore } from "../../assets/images/menu_expend_more.svg";
import { modals } from "../../hooks/Modals";
import { logout } from "../../api/public/auth";
import { firebaseAuth } from "../../firebaseAuth";
import {
	accessToken,
	firebaseToken
} from "../../lib/authToken";
import useModal from "../../hooks/useModal";
import { usePublicUserState } from "../../hooks/public/publicAuthContextProvider";
import UserBox from "./UserBox";

function MenuBar({ open, close }) {
	const [openMenu, setOpenMenu] = useState({ bigPath: "", smallPath: "" });
	const navigate = useNavigate();
	const { pathname } = useLocation();
	const { loginFlag } = usePublicUserState();

	useEffect(() => {
		const [, bigPath, smallPath] = pathname.split("/");

		setOpenMenu((prev) => ({
			...prev,
			bigPath,
			smallPath
		}));

		close();
	}, [pathname]);

	return (
		<React.Fragment>
			<MenuBackdrop open={ open } onClick={ close } />

			<RightMenuWrapper
				open={ open }
				ismobile={ isMobile ? 1 : 0 }
			>
				<MenuHeader>
					<SvgIcon
						component={ CloseSvg }
						variant="menuControl"
						onClick={ close }
						sx={ { width: "18px", height: "18px" } }
					/>
				</MenuHeader>

				<MenuLogo>
					<img src={ LogoImage } alt="텐트세탁소" />
				</MenuLogo>

				<Box mb={ 2 }>
					<UserBox />
				</Box>

				<List>
					{ menuStructure.map(({ title, url, sub, hidden, hiddenBeforeLogin }, index) => {
						if(hidden) {
							return ;
						}
						if(hiddenBeforeLogin && !loginFlag) {
							return ;
						}

						function bigMenuClick() {
							if(sub === undefined) {
								navigate(url);
							} else {
								setOpenMenu((prev) => ({
									...prev,
									bigPath: prev.bigPath === url ? "" : url
								}));
							}
						}

						return (
							<React.Fragment key={ `menu_big_${ index }` }>
								<ListItemButton onClick={ bigMenuClick }>
									<ListItemText
										primaryTypographyProps={ {
											variant: "bigMenuTitle"
										} }
										primary={ title }
									/>

									{ !!sub && (
										<SvgIcon
											variant="bigMenuControl"
											component={ openMenu.bigPath === url ? ExpendLess : ExpendMore }
										/>
									) }
								</ListItemButton>

								{ sub && (
									<Collapse in={ openMenu.bigPath === url } timeout="auto" unmountOnExit>
										<List component="div" disablePadding>
											{ sub.map(({ title, url: smallUrl, hidden: smallHidden }, s_index) => {
												if(smallHidden) {
													return ;
												}

												function smallMenuClick() {
													navigate(`/${ url }/${ smallUrl }`);
												}

												const isSamePath = pathname === `/${ url }/${ smallUrl }`;

												return (
													<SmallMenu
														key={ `menu_small_${ index }_${ s_index }` }
														onClick={ smallMenuClick }
													>
														{ isSamePath ? (
															<ListItemText
																primary={ title }
																primaryTypographyProps={ {
																	variant: "smallMenuTitle",
																	sx: { fontWeight: "bold" }
																} }
															/>
														) : (
															<ListItemText
																secondary={ title }
																secondaryTypographyProps={ {
																	variant: "smallMenuTitle"
																} }
															/>
														) }
													</SmallMenu>
												);
											}) }
										</List>
									</Collapse>
								) }
							</React.Fragment>
						);
					}) }

					{/*{ loginFlag && (*/}
					{/*	<ListItemButton onClick={ logoutHandler }>*/}
					{/*		<ListItemText*/}
					{/*			primaryTypographyProps={ {*/}
					{/*				variant: "bigMenuTitle"*/}
					{/*			} }*/}
					{/*			primary={ "로그아웃" }*/}
					{/*		/>*/}
					{/*	</ListItemButton>*/}
					{/*) }*/}
				</List>
			</RightMenuWrapper>
		</React.Fragment>
	);
}

const MenuBackdrop = styled((props) => (
	<Backdrop { ...props } />
))(({ theme }) => ({
	zIndex: theme.zIndex.drawer - 1
}));

const RightMenuWrapper = styled((props) => (
	<Drawer
		anchor={ "right" }
		variant={ "persistent" }
		{ ...props }
	/>
))(({ ismobile }) => ({
	"&&&": {
		width: "200px",
		"& .MuiDrawer-paper": {
			position: !ismobile && "absolute",
			right: 0,
			width: "200px"
		}
	}
}));

const MenuHeader = styled("div")(({ theme }) => ({
	display: "flex",
	alignItems: "center",
	justifyContent: "flex-end",
	fontSize: "16px",
	padding: "0 30px",
	color: theme.palette.text.secondary,
	...theme.mixins.toolbar
}));

const MenuLogo = styled("div")(() => ({
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
	marginBottom: "10px",
	"& img": {
		width: "165px"
	}
}));

const SmallMenu = styled((props) => (
	<ListItemButton { ...props } />
))(() => ({
	paddingLeft: "40px",
	"& .MuiTypography-root": {
		transition: "color .2s cubic-bezier(0.4, 0, 0.2, 1) 0"
	},
	"&:hover .MuiTypography-root": {
		color: "#FFF"
	}
}));

export default MenuBar;