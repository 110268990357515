import { useMutation } from "@tanstack/react-query";
import * as API from "../../api/admin/signUp";

export function useSignUpMutation() {
    const {
        data: signUpData,
        isError: signUpIsError,
        isSuccess: signUpIsSuccess,
        mutate: signUpMutate
    } = useMutation(API.signUp);

    return { signUpData, signUpIsError, signUpIsSuccess, signUpMutate };
}