import { useLocation, useParams } from "react-router-dom";

export const menuStructure = [
	{
		title: "회사소개",
		url: "company",
		sub: [
			{
				title: "인사말",
				url: "greeting"
			},
			{
				title: "연혁",
				url: "history"
			},
			{
				title: "사업소개",
				url: "introduce"
			}
		]
	},
	{
		title: "보도 및 홍보",
		url: "news"
	},
	{
		title: "텐트",
		url: "tent",
		sub: [
			{
				title: "세탁/수선안내",
				url: "guide"
			},
			{
				// required login
				title: "세탁견적",
				url: "estimate",
				requiredLogin: true
			},
			{
				// required login
				title: "수선견적",
				url: "repair",
				requiredLogin: true
			},
			{
				// required login
				title: "주문 및 가견적 내역",
				url: "orderHistory",
				requiredLogin: true
			},
			{
				// required login
				title: "주문내역",
				url: "orderHistoryDetail",
				requiredLogin: true,
				hidden: true
			}
		]
	},
	{
		title: "제품",
		url: "products",
		sub: [
			{
				title: "또르르 플러스",
				url: "ttoleuleu_plus"
			},
			{
				title: "바끄로",
				url: "bakkeulo"
			},
			{
				title: "사르르",
				url: "saleuleu"
			}
		]
	},
	{
		title: "지점",
		url: "branch",
		sub: [
			{
				title: "지점문의",
				url: "questions"
			},
			{
				title: "본점",
				url: "mainShop"
			},
			{
				title: "남양주 1호점",
				url: "branch1"
			},
			{
				title: "남양주 2호점",
				url: "branch2"
			},
			{
				title: "용인점",
				url: "branch3"
			},
			{
				title: "평택점",
				url: "branch4"
			}
		]
	},
	{
		title: "로그인",
		url: "login",
		hidden: true,
	},
	{
		title: "마이페이지",
		url: "profile",
		requiredLogin: true,
		hidden: true,
	},
	{
		title: "관리자",
		url: "admin",
		requiredLogin: true,
		hidden: true,
		sub: [
			{
				title: "브랜드 관리",
				url: "brand",
				requiredLogin: true
			},
			{
				title: "텐트 관리",
				url: "tent",
				requiredLogin: true
			},
			{
				title: "가격 관리",
				url: "amount",
				requiredLogin: true
			},
			{
				title: "견적",
				url: "estimate",
				requiredLogin: true
			},
			{
				title: "주문",
				url: "order",
				requiredLogin: true,
				sub: [
					{
						title: "가견적 요청",
						url: "step1",
						requiredLogin: true,
					},
					{
						title: "가견적 완료",
						url: "step2",
						requiredLogin: true,
					},
					{
						title: "접수",
						url: "step3",
						requiredLogin: true,
					},
					{
						title: "입고",
						url: "step4",
						requiredLogin: true,
					},
					{
						title: "견적 완료",
						url: "step5",
						requiredLogin: true,
					},
					{
						title: "작업 대기",
						url: "step6",
						requiredLogin: true,
					},
					{
						title: "작업 중",
						url: "step7",
						requiredLogin: true,
					},
					{
						title: "완료",
						url: "step8",
						requiredLogin: true,
					},
					{
						title: "수령 완료",
						url: "complete",
						requiredLogin: true,
					}
				]
			},
			{
				title: "지점 관리",
				url: "branch",
				requiredLogin: true
			},
			{
				title: "주문 내역",
				url: "history",
				requiredLogin: true
			},
			// {
			// 	title: "방문 수거 서비스 정보",
			// 	url: "pickUpService",
			// 	requiredLogin: true
			// },
			{
				title: "배너",
				url: "banner",
				requiredLogin: true
			},
			{
				title: "로그인",
				url: "login"
			},
			{
				title: "회원 등록",
				url: "signUp",
			}
		]
	}
];

export function useMenuInfo() {
	const { pathname } = useLocation();
	const params = useParams();
	const isParams = Object.keys(params).length;
	const [, bigPath, smallPath] = pathname.split("/");

	if(pathname === "/" || pathname === "/xe" || pathname === "/xe/") {
		return {
			title: ""
		};
	}

	if(pathname.indexOf("/login") > -1 || pathname.indexOf("/admin/login") > -1) {
		return {
			title: "로그인"
		};
	}

	try {
		const { title: bigTitle, url: bigUrl, requiredLogin: bigRequiredLogin, hidden, sub: subMenu } = menuStructure.filter(({ url }) => bigPath === url)[0];

		if(subMenu === undefined) {
			if(`/${bigUrl}` !== pathname) {
				return { title: "" };
			}

			return {
				title: bigTitle,
				requiredLogin: bigRequiredLogin,
				hidden
			};
		}

		const subMenuFilter = subMenu?.filter(({ url, paramName }) => {
			if(paramName && isParams) {
				return `${smallPath}/${params[paramName]}` === url;
			} else {
				return smallPath === url;
			}
		});

		if(!subMenuFilter.length) {
			return {
				title: bigTitle,
				requiredLogin: bigRequiredLogin
			};
		}

	const { title: smallTitle, requiredLogin: smallRequiredLogin } = subMenuFilter[0];

		return {
			title: smallTitle,
			requiredLogin: smallRequiredLogin
		};
	} catch(e) {
		return {
			title: "",
		};
	}
}