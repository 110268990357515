import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { isMobile } from "react-device-detect";

function ScrollToTop() {
	const { pathname } = useLocation();

	useEffect(() => {
		if(isMobile){
			window.scrollTo(0, 0);
		} else {
			document.getElementsByClassName("simplebar-content-wrapper")[0].scrollTo(0, 0);
		}
	}, [pathname]);

	return null;
}

export default ScrollToTop;