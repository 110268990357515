import { Box, Chip, Divider, Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Unstable_Grid2";
import { AddRounded } from "@mui/icons-material";
import React, { useState } from "react";
import { isEmptyString } from "../../lib/libs";

function UpdatePickUpLocation({
	groupId,
	weekString,
	startTime,
	cost,
	detail : defaultDetail,
	use,
}) {
	const [detail, setDetail] = useState(defaultDetail);
	const [newLocation, setNewLocation] = useState([]);

	function getTimeString() {
		const [hour, minutes] = startTime.split(":");
		const time = Number(hour) < 12 ? "오전" : "오후";

		return `${ time } ${ hour }시 ${ Number(minutes) > 0 ? (minutes + "분") : "" }`;
	}

	function addLocation() {
		setNewLocation((prev) => ([
			...prev,
			{ name: "", detail: "" }
		]));
	}

	function onDelete(deleteIndex) {
		setDetail((prev) => prev.filter((_, index) => index !== deleteIndex));
	}

	return (
		<Stack spacing={ 2 }>
			<Grid container spacing={ .5 }>
				<Grid xs={ 3 }>
					<Chip color="secondary" label="서비스 지역" variant="outlined" sx={ { width: "100%" } } />
				</Grid>

				<Grid xs={ 9 }>
					<Chip color="secondary" label="상세 서비스 지역" variant="outlined" sx={ { width: "100%" } } />
				</Grid>

				{ detail.map(({ lctnNm, dtlLctnNm }, index) => (
					<React.Fragment key={ lctnNm }>
						<Grid xs={ 3 }>
							<Chip color="secondary" label={ lctnNm } sx={ { width: "100%" } } onDelete={ () => onDelete(index) } />
						</Grid>

						<Grid xs={ 9 }>
							{ !isEmptyString(dtlLctnNm) && (
								<Typography variant="cardContext">{ dtlLctnNm.split("/").join(", ") }</Typography>
							) }
						</Grid>
					</React.Fragment>
				)) }

				{/*{ addLocation.map(({ name, detail }) => {*/}

				{/*}) }*/}

				<Grid xs={ 12 } mt={ 1 }>
					<Chip
						label="지역 추가"
						variant="outlined"
						color="primary"
						onClick={ addLocation }
						sx={ {
							width: "100%",
							cursor: "pointer",
							"&:hover": {
								backgroundColor: "rgb(232 65 11 / 30%)",
								fontWeight: "700",
							},
						} }
					/>
				</Grid>

			</Grid>

			<Divider variant="middle" flexItem />

			<Grid container spacing={ 1 } mt={ 2 } alignItems="center">
				<Grid xs={ 3 }>
					<TitleBox>수거 요일</TitleBox>
				</Grid>
				<Grid xs={ 9 }>
					<Typography variant="cardContext">{ weekString }</Typography>
				</Grid>

				<Grid xs={ 3 }>
					<TitleBox>수거 시간</TitleBox>
				</Grid>
				<Grid xs={ 9 }>
					<Typography variant="cardContext">{ getTimeString() } ~</Typography>
				</Grid>

				<Grid xs={ 3 }>
					<TitleBox>요금</TitleBox>
				</Grid>
				<Grid xs={ 9 }>
					<Typography variant="cardContext">{ cost.toLocaleString() }원</Typography>
				</Grid>

				<Grid xs={ 3 }>
					<TitleBox>사용 여부</TitleBox>
				</Grid>
				<Grid xs={ 9 }>
					<Typography variant="cardContext">{ use ? "사용 중" : "사용 안 함" }</Typography>
				</Grid>
			</Grid>
		</Stack>
	);
}

function TextBox({ text }) {

}

const TitleBox = styled(Box)(({ theme }) => ({
	fontSize: "13px",
	fontWeight: 400,
	color: theme.palette.text.primary,
	lineHeight: "22px",
	backgroundColor: "#373737",
	padding: "2px 10px",
	borderRadius: 3,
	border: "1px solid #616161",
	textAlign: "center",
}));

const CustomBox = styled(Box)(({ theme }) => ({
	fontSize: "12px",
	fontWeight: 400,
	color: theme.palette.text.primary,
	lineHeight: "22px",
	backgroundColor: "#4B4B4B",
	padding: "2px 10px",
	borderRadius: 100,
	border: "1px solid #616161",
	textAlign: "center",
}));

export default UpdatePickUpLocation;