import { useMutation, useQuery } from "@tanstack/react-query";
import { getThisEstimate } from "../../api/admin/fixEstimate";
import * as API from "../../api/admin/fixEstimate";

export const GET_ESTIMATE_KEY = ["getFixEstimate"];

export function useThisEstimateQuery(estimateId) {
	const {
		      isLoading,
		      isError,
		      isSuccess,
		      data,
	      } = useQuery([...GET_ESTIMATE_KEY], () => API.getThisEstimate(estimateId), {
		retry: 0,
		cacheTime: 0,
		refetchOnWindowFocus: false,
	});

	return {
		isLoading,
		isError,
		isSuccess,
		data,
	};
}

export function useBrandQuery(brndId = "") {
	const {
		      isLoading: isBrandLoading,
		      isError: isBrandError,
		      isSuccess: isBrandSuccess,
		      data: brandData,
	      } = useQuery(["getBrand"], API.getBrands, {
		enabled: brndId === "",
		// staleTime: 30 * 1000
		refetchOnWindowFocus: false,
	});

	return {
		isBrandLoading,
		isBrandError,
		isBrandSuccess,
		brandData,
	};
}

export function useTentQuery(brndId = "") {
	const {
		      isLoading: isTentLoading,
		      isFetching: isTentFetching,
		      isError: isTentError,
		      isSuccess: isTentSuccess,
		      data: tentData,
	      } = useQuery(["getTent", brndId], () => API.getTent(brndId), {
		// enabled: brndId !== "",
		// staleTime: 30 * 1000
		refetchOnWindowFocus: false,
	});

	return {
		isTentLoading,
		isTentFetching,
		isTentError,
		isTentSuccess,
		tentData,
	};
}

export function useEstimateQuery(tntId = "") {
	const {
		      isLoading: isEstimateLoading,
		      isFetching: isEstimateFetching,
		      isError: isEstimateError,
		      isSuccess: isEstimateSuccess,
		      data: estimateData,
	      } = useQuery(["getEstimate", tntId], () => API.getEstimate(tntId), {
		enabled: tntId !== "",
		refetchOnWindowFocus: false,
	});

	return {
		isEstimateLoading,
		isEstimateFetching,
		isEstimateError,
		isEstimateSuccess,
		estimateData,
	};
}

export function useSaveMutate() {
	const { mutate: saveMutate } = useMutation({
		mutationFn: API.save,
	});

	return { saveMutate };
}