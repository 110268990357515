import React, {
	useEffect,
	useState
} from "react";
import ImageUpload from "./ImageUpload";
import {
	Card,
	CardContent,
	InputBase,
	Typography
} from "@mui/material";

function RequestContextField({ setText, maxLength = 500, defaultValue = "", readOnly = false, title = "요청사항" }) {
	const [value, setValue] = useState(defaultValue);

	function handleChange({ target: { value } }) {
		setValue(value);
		setText(value);
	}

	return (
		<Card sx={ { position: "relative" } }>
			<CardContent>
				<Typography variant="cardBold" mb={ 2 } sx={ { color: "#B9B9B9" } }>{ title }</Typography>

				{ !readOnly && (
					<Typography
						variant="smallContext"
						sx={ {
							position: "absolute",
							right: 16,
							top: 16
						} }
					>
						{ value.length } / { maxLength }
					</Typography>
				) }

				<InputBase
					fullWidth
					multiline
					readOnly={ readOnly }
					minRows={ 1 }
					sx={ { fontSize: 15, color: "#B9B9B9", lineHeight: "22px" } }
					onChange={ handleChange }
					value={ value }
					inputProps={ { maxLength } }
				/>
			</CardContent>
		</Card>
	);
}

export default RequestContextField;