import {
	Alert, Box,
	Button,
	Card,
	CardActions,
	CardContent,
	FormControlLabel,
	Stack,
	TextField,
	Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useState } from "react";
import { modals } from "../../../hooks/Modals";
import { usePublicUserState } from "../../../hooks/public/publicAuthContextProvider";
import useModal from "../../../hooks/useModal";
import { addPhoneNumberHyphen, isEmptyString, validationPhoneNumber } from "../../../lib/libs";
import CheckBox from "../../common/CheckBox";

function UserAddressAddUpdateCard({ isAdd = true, defaultValue = null, handleCancel, submit }) {
	const { user } = usePublicUserState();
	const { openModal } = useModal();
	const [localAddressData, setLocalAddressData] = useState(defaultValue);
	const [error, setError] = useState([]);

	function handleChange(type, { target: { value } }) {
		if(type === "phoneNumber") {
			value = addPhoneNumberHyphen(value);
		}

		removeError(type);
		setLocalAddressData((prev) => ({
			...prev,
			[type]: value,
		}));
	}

	function handlePostcodeOpen() {
		openModal(modals.postcodeModal, {
			onSubmit: (data) => {
				removeError("zoneCode");
				setLocalAddressData((prev) => ({
					...prev,
					...data
				}));
			},
		});
	}

	function addError(key, message) {
		removeError(key);
		setError((prev) => ([
			...prev,
			{ key, message }
		]));
	}

	function removeError(key) {
		setError((prev) => prev?.filter((item) => item.key !== key));
	}

	function isError(key) {
		return !!error?.find((item) => item.key === key);
	}

	function validationCheck() {
		if(isEmptyString(localAddressData?.name)) {
			addError("name", "수령인을 입력해주세요");
			return false;
		} else if(localAddressData.name.length > 10) {
			addError("name", "10자까지 입력할 수 있어요");
			return false;
		}

		if(isEmptyString(localAddressData?.phoneNumber)) {
			addError("phoneNumber", "휴대폰 번호를 입력해주세요");
			return false;
			// 전화번호 형식 체크도 필요
		} else if(!validationPhoneNumber(localAddressData.phoneNumber)) {
			addError("phoneNumber", "휴대폰 번호를 알맞게 입력해주세요");
			return false;
		}

		if(isEmptyString(localAddressData?.zoneCode)) {
			addError("zoneCode", "우편번호 찾기를 해주세요");
			return false;
		}

		if(isEmptyString(localAddressData?.addressDetail)) {
			addError("addressDetail", "상세 주소를 입력해주세요");
			return false;
		} else if(localAddressData.addressDetail.length > 100) {
			addError("addressDetail", "100자까지 입력할 수 있어요");
			return false;
		}

		return true;
	}

	function handleSubmit() {
		if(validationCheck()) {
			submit(localAddressData);
		}
	}

	return (
		<Card sx={{ mt: user.address.length > 0 ? 1 : 0 }}>
			<CardContent>
				<Stack spacing={ 1.5 }>
					<Typography variant="cardBold" sx={ { "&&&": { mb: 2 } } }>{ isAdd ? "신규 배송지" : "배송지 수정"}</Typography>

					<TextField
						variant="outlined"
						size="small"
						label="수령인"
						fullWidth
						error={ isError("name") }
						value={ localAddressData?.name || "" }
						onChange={ e => handleChange("name", e) }
					/>

					<TextField
						variant="outlined"
						size="small"
						label="휴대폰"
						fullWidth
						error={ isError("phoneNumber") }
						inputProps={{ maxLength: 13 }}
						value={ localAddressData?.phoneNumber || "" }
						onChange={ e => handleChange("phoneNumber", e) }
					/>

					<Stack direction="row" spacing={ 1 }>
						<TextField
							variant="outlined"
							size="small"
							label="우편번호"
							fullWidth
							error={ isError("zoneCode") }
							InputProps={ { readOnly: true } }
							value={ localAddressData?.zoneCode || "" }
						/>

						<Button variant="outlined" size="small" sx={ { width: "45%" } } onClick={ handlePostcodeOpen }>우편번호 찾기</Button>
					</Stack>

					<TextField
						variant="outlined"
						size="small"
						label="주소"
						fullWidth
						error={ isError("zoneCode") }
						InputProps={ { readOnly: true } }
						value={ localAddressData?.address || "" }
					/>

					<TextField
						variant="outlined"
						size="small"
						label="상세 주소"
						fullWidth
						error={ isError("addressDetail") }
						value={ localAddressData?.addressDetail || "" }
						onChange={ e => handleChange("addressDetail", e) }
					/>

					{ (isAdd || defaultValue?.defaultFlag !== true) && (
						<FormControlLabel
							label="기본 배송지로 설정"
							value="end"
							labelPlacement="end"
							checked={ localAddressData?.defaultFlag || false }
							onChange={ () => setLocalAddressData((prev) => ({ ...prev, defaultFlag: !prev?.defaultFlag })) }
							control={ <CheckBox /> }
						/>
					) }
				</Stack>
			</CardContent>

			{ error.length > 0 && (
				<Stack spacing={ 1 } mb={ 1 }>
					{ error.map(({ key, message }) => (
						<Alert key={key} severity="error">{ message }</Alert>
					))}
				</Stack>
			) }

			<CardActions>
				<Stack direction="row" justifyContent="flex-end" spacing={ 1 } sx={ { width: "100%" } }>
					<Button variant="outlined" size="small" color="secondary" onClick={ handleCancel }>취소</Button>
					<Button variant="contained" size="small" onClick={ handleSubmit }>저장</Button>
				</Stack>
			</CardActions>
		</Card>
	);
}

export default UserAddressAddUpdateCard;