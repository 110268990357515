import Grid from "@mui/material/Unstable_Grid2";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Box, Button, Card, CardContent, Divider, Link, Stack, SvgIcon, Typography } from "@mui/material";
import { ReactComponent as BranchIcon } from "../../../assets/images/icon/branch.svg";
import { useParams } from "react-router-dom";
import useModal from "../../../hooks/useModal";
import { modals } from "../../../hooks/Modals";
import { getBranch } from "../../../api/public/estimate2";
import RequestBranch from "../estimate/RequestBranch";
import { useUpdateBranch } from "../../../query/public/orderHistoryDetailQuery";
import { useQueryClient } from "@tanstack/react-query";
import { Toast } from "../Toast";

function BranchCard ({
	branchId,
	branchName,
	address,
	addressDetail,
	zoneCode,
	bankName,
	bankAccount,
	bankAccountHolder,
	telNumber,
	queryKey,
	cardInfoShow = false,
	phoneNumber,
	updateFlag = true
}) {
	const [branchData, setBranchData] = useState([]);
	const { orderId } = useParams();
	const { openModal } = useModal();
	const { updateBranchMutate } = useUpdateBranch();
	const branchRef = useRef(null);
	const queryClient = useQueryClient();

	useEffect(() => {
		getBranchData();
	}, []);

	const getBranchData = useCallback(async () => {
		setBranchData(await getBranch());
	}, []);

	function handleClick () {
		openModal(modals.confirmModal, {
			title: "의뢰 지점 변경",
			submitTitle: "저장",
			content: (
				<RequestBranch ref={ branchRef } branchData={ branchData } defaultBranch={ branchId } />
			),
			onSubmit: async (onClose, setLoadingDone) => {
				const newBranchId = branchRef.current.getElementsByTagName("input")[0].value;
				const formData = {
					orderId,
					branchId: newBranchId,
				};

				updateBranchMutate(formData, {
					onSuccess: () => {
						onClose();
						queryClient.invalidateQueries([...queryKey, orderId]);
						Toast.success("지점이 변경되었어요");
					},
					onError: () => {
						onClose();
						openModal(modals.confirmModal, {
							title: "죄송합니다, 지점 변경이 실패했습니다",
							content: "잠시 후에 다시 시도해주시기 바랍니다.",
							closeTitle: "닫기",
						});
					},
					onSettled: () => {
						setLoadingDone();
					},
				});
			},
		});
	}

	return (
		<Card>
			<CardContent>
				<Stack
					direction="row"
					alignItems="center"
					justifyContent="space-between"
					mb={ 2 }
				>
					<Stack direction="row" spacing={ 1 }>
						<SvgIcon component={ BranchIcon } sx={ { fill: "none" } } />
						<Typography variant="cardBold">{ branchName }</Typography>
					</Stack>

					{/*{ updateFlag && (*/}
					{/*	<Button variant="contained" size="small" color="secondary" onClick={ handleClick }>지점 변경</Button>*/}
					{/*) }*/}
				</Stack>

				<Grid container mb={ cardInfoShow ? 1 : 0 }>
					<React.Fragment>
						<Grid xs={ 3 }>
							<Typography variant="cardContext" type="title">주소</Typography>
						</Grid>

						<Grid xs={ 9 }>
							<Typography variant="cardContext">({ zoneCode }) { address } { addressDetail }</Typography>
						</Grid>
					</React.Fragment>

					<React.Fragment>
						<Grid xs={ 3 }>
							<Typography variant="cardContext" type="title">문의 전화</Typography>
						</Grid>
						<Grid xs={ 9 }>
							<Typography variant="cardContext">
								<Link
									href={`tel:${ telNumber }`}
									underline="always"
									color="inherit"
								>
									{ telNumber }
								</Link>
							</Typography>
						</Grid>

						{ phoneNumber && (
							<React.Fragment>
								<Grid xs={ 3 }>
									<Typography variant="cardContext" type="title">휴대폰 번호</Typography>
								</Grid>

								<Grid xs={ 9 }>
									<Typography variant="cardContext">
										<Link
											href={`tel:${ phoneNumber }`}
											underline="always"
											color="inherit"
										>
											{ phoneNumber }
										</Link>
									</Typography>
								</Grid>
							</React.Fragment>
						) }
					</React.Fragment>

					{ bankName && bankAccount && bankAccountHolder && (
						<React.Fragment>
							<Grid xs={ 12 } my={ 1 }>
								<Divider flexItem />
							</Grid>

							<Grid xs={ 12 }>
								<Typography variant="cardContext" type="title">계좌이체 시</Typography>
							</Grid>

							<Grid xs={ 3 }>
								<Typography variant="cardContext" type="title">계좌</Typography>
							</Grid>

							<Grid xs={ 9 }>
								<Typography variant="cardContext">{ bankName }은행 { bankAccount }</Typography>
							</Grid>

							<Grid xs={ 3 }>
								<Typography variant="cardContext" type="title">예금주</Typography>
							</Grid>

							<Grid xs={ 9 }>
								<Typography variant="cardContext">{ bankAccountHolder }</Typography>
							</Grid>

							<Grid xs={ 12 }>
								<Typography variant="cardContext">현금영수증, 사업자 지출증빙, 세금계산서 발행 시 고객명과 전화번호, 사업자 등록번호를 문자로 보내주세요.</Typography>
							</Grid>
						</React.Fragment>
					) }

					{ cardInfoShow && (
						<React.Fragment>
							<Grid xs={ 12 } my={ 1 }>
								<Divider />
							</Grid>

							<Grid xs={ 12 }>
								<Typography variant="cardContext" type="title">카드 결제 시</Typography>
							</Grid>

							<Grid xs={ 12 }>
								<Typography variant="cardContext">카드사명, 카드번호, 유효기간 정보 또는 실물 카드 사진을 찍어서 문자로 보내주시고 할부 개월도 알려주세요.</Typography>
							</Grid>
						</React.Fragment>
					) }
				</Grid>
			</CardContent>
		</Card>
	);
}

export default BranchCard;