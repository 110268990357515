import React, {
	useEffect,
} from "react";
import { isMobile } from "react-device-detect";
import throttle from "./throttle";

function useScrollNavigation(tabRef, setCurrentTab) {
	const throttledScroll = throttle(() => {
		const body = isMobile ? window : document.getElementsByClassName("simplebar-content-wrapper")[0];
		const clientHeight = isMobile ? document.body.clientHeight : body.scrollHeight;
		const innerHeight = isMobile ? body.innerHeight : body.clientHeight;
		const scrollTop = isMobile ? body.scrollY : body.scrollTop;

		if(clientHeight - (innerHeight + 10) <= scrollTop) {
			setCurrentTab(tabRef.current.length - 1);
			return ;
		}

		tabRef.current.forEach((ref, index) => {
			if(ref.offsetTop - 120 < scrollTop) {
				setCurrentTab(index);
			}
		});
	}, 200);

	useEffect(() => {
		const body = isMobile ? window : document.getElementsByClassName("simplebar-content-wrapper")[0];

		body.addEventListener("scroll", throttledScroll);

		return () => body.removeEventListener("scroll", throttledScroll);
	}, []);
}

export default useScrollNavigation;