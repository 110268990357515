import React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import {
	Stack,
	Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";

function HistoryContents({
	year,
	hist,
}) {
	return (
		<Grid container>
			<Grid xs={ 3 }><Typography variant="title" sx={ { marginBottom: 0 } }>{ year }</Typography></Grid>

			<Grid xs={ 9 }>
				<Stack direction="column" spacing={ 1 }>
					{ hist.map(({ month, text }, index) => {
						const m = month.toString().padStart(2, "0");

						return (
							<Typography
								key={ `${ year }_${ month }_${ index }` }
								variant="context"
								component="dl"
								sx={ { lineHeight: "30px" } }
							>
								<Month sx={ { letterSpacing: month > 10 && "2px" } }><strong>{ m }월.</strong></Month>
								<Text>{ text }</Text>
							</Typography>
						);
					}) }
				</Stack>
			</Grid>
		</Grid>
	);
}

const Month = styled("dt")(() => ({
	float: "left",
	padding: 0,
	margin: 0,
	width: "20%",
}));

const Text = styled("dd")(() => ({
	float: "left",
	padding: 0,
	margin: 0,
	width: "80%",
}));

export default HistoryContents;