import { FactCheckRounded } from "@mui/icons-material";
import { Button, Card, CardContent, Stack, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React, { useEffect, useState } from "react";
import { modals } from "../../../hooks/Modals";
import useModal from "../../../hooks/useModal";
import { getOrder } from "../../../lib/firestore/Order";
import { getUser } from "../../../lib/firestore/User";
import { useChangeDeliveryTypeMutation } from "../../../query/admin/orderQuery";
import { Toast } from "../../public/Toast";

function WaitDeliveryInfoCard({
	userId,
	orderId,
	deliveryType,
	deliveryTypeName,
	deliveryDivision,
	deliveryDivisionName,
	deliveryCost,
	disabled,
	total,
	cancelFlag,
	refreshQuery,
}) {
	const [address, setAddress] = useState(null);
	const [user, setUser] = useState(null);
	const { setChangeDeliveryTypeMutate } = useChangeDeliveryTypeMutation();
	const { openModal } = useModal();

	useEffect(() => {
		async function getAddress() {
			if(deliveryType === "1" && address === null) {
				const order = (await getOrder(orderId)).data();

				setAddress({ ...order });
			}
		}

		async function getUserData() {
			if(deliveryType === "2" && user === null) {
				const user = (await getUser(userId)).data();

				setUser({ ...user });
			}
		}

		getAddress();
		getUserData();
	}, []);

	function changeDelivery() {
		openModal(modals.changeDeliveryType, {
			deliveryType,
			deliveryDivision,
			deliveryCost,
			total,
			cancelFlag,
			onSubmit: (onClose, setLoadingDone, data) => {
				// {
				// 	"deliveryType": 1,
				// 	"deliveryDivision": 3,
				// 	"cost": 5000
				// }
				const requestData = {
					...data,
					orderId,
				};

				setChangeDeliveryTypeMutate(requestData, {
					onSuccess: async () => {
						Toast.success("저장이 완료되었어요");
						refreshQuery();
						onClose();
					},
					onError: async () => {
						onClose();
						openModal(modals.confirmModal, {
							title: "죄송합니다, 저장이 실패했습니다",
							content: "잠시 후에 다시 시도해주시기 바랍니다.",
							closeTitle: "닫기",
						});
					},
					onSettled: () => {
						setLoadingDone();
					}
				});

			}
		});
	}

	return (
		<Card>
			<CardContent>
				<Stack
					direction="row"
					alignItems="center"
					justifyContent="space-between"
					mb={ 2 }
				>
					<Stack direction="row" spacing={ 1 }>
						<FactCheckRounded fill="none" />
						<Typography variant="cardBold">수령 정보</Typography>
					</Stack>

					<Button variant="contained" color="secondary" size="small" onClick={ changeDelivery } disabled={ disabled }>수령 방법 변경</Button>
				</Stack>

				<Grid container>
					<React.Fragment>
						<Grid xs={ 3 }>
							<Typography variant="cardContext" type="title">수령 방법</Typography>
						</Grid>
						<Grid xs={ 9 }>
							<Typography variant="cardContext">{ deliveryTypeName } { deliveryType === "1" && `(${ deliveryDivisionName }${ deliveryCost ? ` : ${ deliveryCost.toLocaleString() }원` : "" })` }</Typography>
						</Grid>
					</React.Fragment>

					{ deliveryType === "2" && user !== null && (
						<React.Fragment>
							<Grid xs={ 3 }>
								<Typography variant="cardContext" type="title">닉네임</Typography>
							</Grid>
							<Grid xs={ 9 }>
								<Typography variant="cardContext">{ user.nickname }</Typography>
							</Grid>

							<Grid xs={ 3 }>
								<Typography variant="cardContext" type="title">연락처</Typography>
							</Grid>
							<Grid xs={ 9 }>
								<Typography variant="cardContext">{ user.phoneNumber }</Typography>
							</Grid>
						</React.Fragment>
					) }

					{ deliveryType === "1" && address !== null && (
						<React.Fragment>
							<Grid xs={ 3 }>
								<Typography variant="cardContext" type="title">수령인</Typography>
							</Grid>
							<Grid xs={ 9 }>
								<Typography variant="cardContext">{ address.receiverName }</Typography>
							</Grid>

							<Grid xs={ 3 }>
								<Typography variant="cardContext" type="title">휴대폰</Typography>
							</Grid>
							<Grid xs={ 9 }>
								<Typography variant="cardContext">{ address.receiverPhoneNumber }</Typography>
							</Grid>

							<Grid xs={ 3 }>
								<Typography variant="cardContext" type="title">주소</Typography>
							</Grid>
							<Grid xs={ 9 }>
								<Typography variant="cardContext">[{ address.zoneCode }] { address.address }, { address.addressDetail }</Typography>
							</Grid>
						</React.Fragment>
					) }
				</Grid>
			</CardContent>
		</Card>
	);
}

export default React.memo(WaitDeliveryInfoCard);