import axios from "./axios";

export async function getRequestImages(orderId, isAdmin) {
	const res = await axios.get(`/api/${ isAdmin ? "admin" : "user" }/getRequestContentImages`, { params: { orderId } });
	return res?.data;
}

export async function getEstimateFixImages(orderId, isAdmin) {
	const res = await axios.get(`/api/${ isAdmin ? "admin" : "user" }/getEstimateFixContentImages`, { params: { orderId } });
	return res?.data;
}

export async function getWorkingImages(orderId, isAdmin) {
	const res = await axios.get(`/api/${ isAdmin ? "admin" : "user" }/getWorkingContentImages`, { params: { orderId } });
	return res?.data;
}

export async function getImage(imageUrl, isAdmin) {
	const res = await axios.get(`/api/${ isAdmin ? "admin" : "user" }/imagePath/${ imageUrl }`, { responseType: "blob" });
	return res?.data;
}