import { Box, useMediaQuery } from "@mui/material";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Unstable_Grid2";
import React, { useEffect, useState } from "react";

import { BrowserView, isMobile, MobileView } from "react-device-detect";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";
import { getKakaoAuthUri, getNaverAuthUri } from "../../api/public/auth";
import Loading from "../../components/common/Loading";
import MainSideBackground from "../../components/common/MainSideBackground";
import MenuBar from "../../components/public/MenuBar";
import TitleBar from "../../components/public/TitleBar";
import SEOMeta from "../../components/public/SEOMeta";
import ScrollToTop from "../../components/common/ScrollToTop";
import { usePublicUserDispatch, usePublicUserState } from "../../hooks/public/publicAuthContextProvider";
import isKakaoInAppBrowser from "../../lib/isKakaoInAppBrowser";
import { useMenuInfo } from "../../lib/menuStructure";
import { usePublicSilentRefresh } from "../../query/silentRefreshQuery";
import "./PublicGlobal.css";

function PublicMainLayout() {
	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const mediaQuery = useMediaQuery("(min-width:1520px)");
	const { loginFlag } = usePublicUserState();
	const { setLoggedIn, setLoggedOut } = usePublicUserDispatch();
	const [isLoading, setIsLoading] = useState(!loginFlag);
	const navigate = useNavigate();
	const { pathname, search } = useLocation();
	const { title, requiredLogin } = useMenuInfo();

	function openMenu() {
		setIsMenuOpen(true);
	}

	function closeMenu() {
		setIsMenuOpen(false);
	}

	useEffect(() => {
		if(pathname.indexOf("tent/orderHistoryDetail") >= 0 && isKakaoInAppBrowser()) {
			// 카카오 인앱 브라우저로 주문 내역 페이지 진입 시 카카오로 로그인한 이력이 있는지 확인 후 자동 로그인
			const loginProvider = new URLSearchParams(search).get("login");

			localStorage.setItem("before_page", pathname);

			if(loginProvider === "NAVER") {
				window.location.href = getNaverAuthUri();
			} else {
				window.location.href = getKakaoAuthUri() + "&prompt=none";
			}
		}
	}, []);

	useEffect(() => {
		if (!isLoading) {
			if (requiredLogin && !loginFlag) {
				// toast.info("로그인을 먼저 해주세요");
				navigate("/login");
			} else {
				if (pathname.indexOf("/login") < 0) {
					localStorage.setItem("before_page", pathname);
				}
			}
		}
	}, [isLoading, title, loginFlag]);

	usePublicSilentRefresh({
		enabled: pathname.indexOf("/login?code") < 0 && requiredLogin,
		onSuccess: function () {
			setIsLoading(false);
			setLoggedIn();
		},
		onError: function () {
			setIsLoading(false);
			setLoggedOut();
		},
	});

	return (
		<React.Fragment>
			<ScrollToTop />

			<SEOMeta />

			{/* 모바일 화면 */ }
			<MobileView>
				<Root ismobile={ isMobile ? 1 : 0 }>
					<TitleBar openMenu={ openMenu } isMobile={ isMobile } />
					<MenuBar
						open={ isMenuOpen }
						close={ closeMenu }
					/>
					{ isLoading ? <Loading /> : <Outlet /> }
				</Root>
			</MobileView>

			{/* 브라우저 화면 */ }
			<BrowserView>
				<Root>
					<ContentWrap mediaquery={ mediaQuery ? 1 : 0 }>
						<LeftContentWrap mediaquery={ mediaQuery ? 1 : 0 }>
							<MainSideBackground />
						</LeftContentWrap>

							<LikeMobileDisplay>
								<SimpleBar forceVisible="y">
									<TitleBar openMenu={ openMenu } />

									{ isLoading ? <Loading /> : <Outlet /> }
								</SimpleBar>

								<MenuBar
									open={ isMenuOpen }
									close={ closeMenu }
								/>
							</LikeMobileDisplay>
					</ContentWrap>
				</Root>
			</BrowserView>
		</React.Fragment>
	);
}

const Root = styled(Box)(({ ismobile }) => ({
	"&&&": {
		width: "100%",
		// height: ismobile && "100vh",
		backgroundColor: ismobile ? "#1e1e1e" : "#F5F5F5",
		height: ismobile && "auto",
		minHeight: ismobile && "100vh"
	}
}));

const ContentWrap = styled(({ mediaquery, ...props }) => (
	<Grid
		container
		justifyContent={ !mediaquery && "center" }
		alignItems={ !mediaquery && "center" }
		{ ...props }
	/>
))(() => ({
	"&&&": {
		height: "100%",
		gap: "70px",
		bottom: 0
	}
}));

const LeftContentWrap = styled(Grid)(({ mediaquery }) => ({
	"&&&": {
		position: "relative",
		width: "1030px",
		height: "auto",
		display: mediaquery ? "flex" : "none",
		alignItems: "end"
	}
}));

const LikeMobileDisplay = styled(Grid)(({ theme }) => ({
	"&&&": {
		backgroundColor: theme.palette.background.default,
		minWidth: "420px",
		height: "100vh",
		minHeight: "900px",
		overflow: "hidden",
		position: "relative"
	}
}));

export default PublicMainLayout;