import LoadingButton from "@mui/lab/LoadingButton";
import { Box, Button, Card, CardActions, CardContent, Chip, Divider, Stack, Typography } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import React, { useCallback, useEffect, useState } from "react";
import { modals } from "../../../hooks/Modals";
import useModal from "../../../hooks/useModal";
import { equalsArray, isEmptyString } from "../../../lib/libs";
import {
	GET_HISTORY_STEP,
	useSetWorkingCompleteMutation,
	useUpdateWorkingContentMutation,
} from "../../../query/admin/orderQuery";
import {
	GET_WORKING_IMAGES_KEY,
	useRequestImages,
	useWorkingImages,
} from "../../../query/getImageQuery";
import EstimateCard from "../../common/EstimateCard";
import ImageUpload from "../../public/ImageUpload";
import RequestContextField from "../../public/RequestContextField";
import { Toast } from "../../public/Toast";
import CardSubTitle from "./CardSubTitle";
import CardTitle from "./CardTitle";
import ContentField from "./ContentField";

function Step7Card({
	orderId,
	userId,
	nickname,
	orderType,
	date,
	brandName,
	tentName,
	imagePath,
	requestContent,
	workingImagePath,
	workingContent,
	receptionNumber,
	detail,
	branchName,
	refreshQuery,
}) {
	const [open, setOpen] = useState(false);
	const {
		      isRequestImagesLoading,
		      requestImagesData,
	      } = useRequestImages(orderId, imagePath, open, true);
	const {
		      isWorkingImagesLoading,
		      workingImagesData,
	      } = useWorkingImages(orderId, workingImagePath, open, true);
	const [defaultImages, setDefaultImages] = useState(workingImagesData);
	const [sendImages, setSendImages] = useState([]);
	const [sendRequestContent, setSendRequestContent] = useState(workingContent);
	const [changeFlag, setChangeFlag] = useState(false);
	const [imageUploading, setImageUploading] = useState(false);
	const { openModal } = useModal();
	const { updateWorkingContentMutate } = useUpdateWorkingContentMutation();
	const { setWorkingCompleteMutate } = useSetWorkingCompleteMutation();
	const queryClient = useQueryClient();

	const addImagesHandler = useCallback((newImages) => {
		setSendImages([...newImages]);
	}, []);
	const setImageUploadingHandler = useCallback((flag) => {
		setImageUploading(flag);
	}, []);
	const changeRequestContent = useCallback((newContent) => {
		setSendRequestContent(newContent);
	}, []);

	useEffect(() => {
		if(!equalsArray(workingImagesData, defaultImages)) {
			setDefaultImages([...workingImagesData]);
			setChangeFlag(false);
		}
	}, [workingImagesData]);

	useEffect(() => {
		if(!changeFlag) {
			const fileNames = sendImages.map((file) => {
				return file.name;
			});

			if(!equalsArray(workingImagesData, fileNames)) {
				setDefaultImages([...workingImagesData]);
			}

			setSendRequestContent(workingContent);
		}
	}, [changeFlag]);

	function handleRequest() {
		openModal(modals.confirmModal, {
			submitTitle: "저장",
			content: (
				<Typography variant="dialogContent">안내사항을 저장하시겠어요?</Typography>
			),
			onSubmit: async (onClose, setLoadingDone) => {
				const formData = new FormData();

				for(let i = 0; i < sendImages.length; i++) {
					formData.append("files", sendImages[i]);
				}

				const contentsData = {
					content: sendRequestContent,
					imagePath: workingImagePath,
					orderId,
				};

				formData.append("contentsData", new Blob([JSON.stringify(contentsData)], { type: "application/json" }));

				updateWorkingContentMutate(formData, {
					onSuccess: () => {
						Toast.success("저장이 완료되었어요");
						queryClient.invalidateQueries([...GET_WORKING_IMAGES_KEY, orderId]);
						setChangeFlag(false);
						refreshQuery();
						onClose();
					},
					onError: () => {
						onClose();
						openModal(modals.confirmModal, {
							title: "죄송합니다, 저장이 실패했습니다",
							content: "잠시 후에 다시 시도해주시기 바랍니다.",
							closeTitle: "닫기",
						});
					},
					onSettled: () => {
						setLoadingDone();
					},
				});
			},
		});
	}

	function handleComplete() {
		openModal(modals.confirmModal, {
			content: (
				<Typography variant="context">작업을 완료하시겠어요?</Typography>
			),
			submitTitle: "작업 완료",
			onSubmit: async (onClose, setLoadingDone) => {
				setWorkingCompleteMutate(orderId, {
					onSuccess: () => {
						Toast.success("저장이 완료되었어요");
						// queryClient.invalidateQueries([...GET_HISTORY_STEP, 7]);
						refreshQuery();
						onClose();
					},
					onError: () => {
						onClose();
						openModal(modals.confirmModal, {
							title: "죄송합니다, 저장이 실패했습니다",
							content: "잠시 후에 다시 시도해주시기 바랍니다.",
							closeTitle: "닫기",
						});
					},
					onSettled: () => {
						setLoadingDone();
					},
				});
			},
		});
	}

	return (
		<Card>
			<CardContent>
				<CardTitle
					orderType={ orderType }
					date={ date }
					nickname={ nickname }
					userId={ userId }
				/>

				<CardSubTitle
					receptionNumber={ receptionNumber }
					brandName={ brandName }
					tentName={ tentName }
					branchName={ branchName }
					open={ open }
					setOpen={ setOpen }
				/>

				{ open && (
					<React.Fragment>
						{ orderType === "1" && (
							<EstimateCard
								title=""
								orderType={ orderType }
								shownPrice={ false }
								detail={ detail }
							/>
						) }

						{ (!isRequestImagesLoading && requestImagesData.length > 0) || !isEmptyString(requestContent) ? (
							<Box mt={ 2 }>
								<Divider>
									<Chip label="고객님의 요청사항" variant="outlined" />
								</Divider>
							</Box>
						) : (<></>) }

						{ !isRequestImagesLoading && requestImagesData?.length > 0 && (
							<Box mt={ 2 }>
								<ImageUpload
									readOnly
									images={ requestImagesData }
								/>
							</Box>
						) }

						{ requestContent && (
							<Box mt={ 2 }>
								<ContentField value={ requestContent } />
							</Box>
						) }

						<Box mt={ 2 }>
							<Divider>
								<Chip label="작업 중 안내사항" variant="outlined" />
							</Divider>
						</Box>

						<Box mt={ 2 }>
							<ImageUpload
								maxLength={ 100 }
								readOnly={ !changeFlag }
								images={ defaultImages }
								setImage={ addImagesHandler }
								setLoading={ setImageUploadingHandler }
							/>
						</Box>

						<Box>
							<RequestContextField
								title="작업 중 안내사항"
								readOnly={ !changeFlag }
								defaultValue={ workingContent }
								setText={ changeRequestContent }
							/>
						</Box>
					</React.Fragment>
				) }
			</CardContent>

			<CardActions variant="adminStepCard">
				<Stack spacing={ 1.5 } sx={ { width: "100%" } }>
					{ open && (
						<Stack direction="row" spacing={ 1 } sx={ { width: "100%" } }>
							<Button
								variant="contained"
								fullWidth
								color="secondary"
								onClick={ () => setChangeFlag((prev) => !prev) }
							>
								{ changeFlag ? "취소" : "안내사항 수정" }
							</Button>

							{ changeFlag && (
								<LoadingButton
									variant="contained"
									fullWidth
									onClick={ handleRequest }
									loading={ imageUploading }
									color="washing"
								>
									저장
								</LoadingButton>
							) }
						</Stack>
					) }

					<Button variant="contained" onClick={ handleComplete }>작업 완료</Button>
				</Stack>
			</CardActions>
		</Card>
	);
}

export default Step7Card;