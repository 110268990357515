import React, { useMemo } from "react";
import {
	Box,
	Container,
	Divider,
	Stack,
	Typography
} from "@mui/material";
import TopImage01 from "../../../assets/images/branch_01/top_01.jpg";
import TopImage02 from "../../../assets/images/branch_01/top_02.jpg";
import useKakaoMap from "../../../hooks/public/useKakaoMap";
import SwiperTopCard from "../../../components/public/SwiperTopCard";
import BranchInfo from "../../../components/public/BranchInfo";
import NewsCard from "../../../components/public/NewsCard";
import News02 from "../../../assets/images/introduce/news_02.jpg";
import useKakaoSdk from "../../../hooks/useKakaoSdk";

function Branch1() {
	useKakaoMap({
		locationName: "텐트세탁소 남양주 1호점",
		lat: 37.6119878,
		lng: 127.2111169
	});

	const topImages = useMemo(() => ([
		{
			image: TopImage01,
			backgroundPositionY: "-44px"
		},
		{
			image: TopImage02,
			backgroundPositionY: "-44px"
		}
	]), []);

	const info = useMemo(() => ({
		tel: "010-3363-2468",
		cafe: "https://cafe.naver.com/namyangjutcs"
	}), []);

	return (
		<Container variant="contextRoot">
			<SwiperTopCard images={ topImages } />

			<Container variant="contextBox">
				<Stack direction="column" spacing={ 3 }>
					<BranchInfo info={ info } />

					<Divider />

					<NewsCard
						title="[기술자들] 완벽한 캠핑을 위한 텐트 기술"
						thumbnail={ News02 }
						channel="YTN 사이언스"
						programName="황금나침반"
						date="2022.07.09"
						url="https://youtu.be/1zJdOy3wiVk"
						// youtubeId={ newsYoutubeId.news02 }
					/>

					<Divider />

					<Typography variant="context">
						전화: { info.tel }
					</Typography>

					<Typography variant="context">
						남양주시 와부읍 율석리 783-3
					</Typography>

					<Box
						sx={ {
							width: "calc(100% + 30px)",
							height: "200px",
							position: "relative",
							left: "-15px"
						} }
					>
						<div id={ "map" } style={ { width: "100%", height: "100%" } } />
					</Box>
				</Stack>
			</Container>
		</Container>
	);
}

export default Branch1;