import React from "react";
import { Stack, Typography } from "@mui/material";

function Footer() {
	return (
		<Stack spacing={ 1 } mt={ 6 }>
			<Typography variant="smallContext">본 견적은 가 견적이며 텐트 입고 후 검수 과정에서 금액이 변동될 수 있습니다.</Typography>
			<Typography variant="smallContext">10만원 이상 결제 시 세탁/수선 완료 후 무료로 발송 해드립니다.</Typography>
			<Typography variant="smallContext">입고 및 검수 후 변심으로 서비스를 받지 않을 경우 배송비는 본인이 부담하셔야 합니다.</Typography>
			<Typography variant="smallContext">입고 후 완료까지 기간은 상황에 따라 달라질 수 있지만 보통 2~3주 소요됩니다.</Typography>
			<Typography variant="smallContext">택배 발송 하실 때 부피나 무게로 인한 택배사 문제로 폴대는 받지 않습니다.</Typography>
			<Typography variant="smallContext">운송료를 착불로 하여 배송한 경우 운송료가 최종 견적에 포함됩니다.</Typography>
		</Stack>
	);
}

export default Footer;