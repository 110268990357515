import {
	useQuery,
} from "@tanstack/react-query";
import * as API from "../api/common";

export const GET_REQUEST_IMAGES_KEY = ["requestContentImages"];
export const GET_ESTIMATE_FIX_IMAGES_KEY = ["estimateFixContentImages"];
export const GET_WORKING_IMAGES_KEY = ["workingContentImages"];

export function useRequestImages(orderId, requestImagePath, open = true, isAdmin = false) {
	const {
		      isLoading: isRequestImagesLoading,
		      isError: isRequestImagesError,
		      isSuccess: isRequestImagesSuccess,
		      data: requestImagesData,
	      } = useQuery([...GET_REQUEST_IMAGES_KEY, orderId], () => API.getRequestImages(orderId, isAdmin), {
		enabled: !!orderId && !!requestImagePath && !!open,
		retry: 0,
		cacheTime: 0,
		initialData: [],
	});

	return {
		isRequestImagesLoading,
		isRequestImagesError,
		isRequestImagesSuccess,
		requestImagesData,
	};
}

export function useEstimateFixImages(orderId, imagePath, open = true, isAdmin = false) {
	const {
		      isLoading: isEstimateFixImagesLoading,
		      isError: isEstimateFixImagesError,
		      isSuccess: isEstimateFixImagesSuccess,
		      data: estimateFixImagesData,
	      } = useQuery([...GET_ESTIMATE_FIX_IMAGES_KEY, orderId], () => API.getEstimateFixImages(orderId, isAdmin), {
		enabled: !!orderId && !!imagePath && !!open,
		retry: 0,
		cacheTime: 0,
		initialData: [],
	});

	return {
		isEstimateFixImagesLoading,
		isEstimateFixImagesError,
		isEstimateFixImagesSuccess,
		estimateFixImagesData,
	};
}

export function useWorkingImages(orderId, imagePath, open = true, isAdmin = false) {
	const {
		      isLoading: isWorkingImagesLoading,
		      isError: isWorkingImagesError,
		      isSuccess: isWorkingImagesSuccess,
		      data: workingImagesData,
	      } = useQuery([...GET_WORKING_IMAGES_KEY, orderId], () => API.getWorkingImages(orderId, isAdmin), {
		enabled: !!orderId && !!imagePath && !!open,
		retry: 0,
		cacheTime: 0,
		refetchOnWindowFocus: false,
		initialData: [],
	});

	return {
		isWorkingImagesLoading,
		isWorkingImagesError,
		isWorkingImagesSuccess,
		workingImagesData,
	};
}