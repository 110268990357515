import React, {
	useEffect,
	useRef,
	useState
} from "react";
import {
	AppBar,
	Box,
	Container,
	SvgIcon,
	Toolbar,
	Typography,
	useScrollTrigger
} from "@mui/material";
import { ReactComponent as MenuIcon } from "../../assets/images/menu.svg";
import Grid from "@mui/material/Unstable_Grid2";
import useWindowSize from "../../hooks/useTitleBarSize";
import { styled } from "@mui/material/styles";
import {
	useLocation,
	useNavigate
} from "react-router-dom";
import MenuBar from "./MenuBar";
import { ReactComponent as HomeSvg } from "../../assets/images/home.svg";
import { useMenuInfo } from "../../lib/menuStructure";

function TitleBar({ openMenu, isMobile = false }) {
	const navigator = useNavigate();
	const location = useLocation();
	const isMain = location.pathname === "/" || location.pathname === "/xe" || location.pathname === "/xe/";
	const { title } = useMenuInfo();

	function goMainPage() {
		navigator("/");
	}

	function onMenuClick() {
		if(title !== "로그인") {
			openMenu();
		}
	}

	return (
		<React.Fragment>
			<FixedAppBar
				ismobile={ isMobile ? 1 : 0 }
				ismain={ isMain ? 1 : 0 }
				elevation={ isMain ? 0 : 4 }
			>
				<Toolbar>
					<FlexBox>
						{ isMain ? (
							<Typography variant="barTitle">텐트세탁소</Typography>
						) : (
							<React.Fragment>
								<SvgIcon
									component={ HomeSvg }
									variant="homeIcon"
									onClick={ goMainPage }
								/>

								<Typography variant="barMenuName">{ title }</Typography>
							</React.Fragment>
						) }

						<SvgIcon
							component={ MenuIcon }
							variant="menuControl"
							onClick={ onMenuClick }
							sx={ { width: "22px", height: "22px", visibility: title === "로그인" ? "hidden" : "visible" } }
						/>
					</FlexBox>
				</Toolbar>
			</FixedAppBar>
		</React.Fragment>
	);
}

const FixedAppBar = styled((props) => (
	<AppBar position={ "sticky" } { ...props } />
))(({ ismobile, ismain }) => ({
	"&&&": {
		width: ismobile ? "100%" : "419px",
		right: "auto",
		left: 0,
		top: 0,
		backgroundColor: ismain && "transparent",
		borderWidth: 0
	}
}));

const FlexBox = styled(Box)(() => ({
	width: "100%",
	display: "flex",
	justifyContent: "space-between",
	alignItems: "center"
}));

export default TitleBar;