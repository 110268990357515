import {
    Alert,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent, FormControl, FormControlLabel, InputAdornment, InputLabel, RadioGroup,
    Skeleton,
    Stack, TextField,
    Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import * as API from "../../../api/admin/order";
import { isEmptyString } from "../../../lib/libs";
import StyledSelectBox from "../../public/StyledSelectBox";
import BackgroundBox from "../BackgroundBox";
import RadioButton from "../RadioButton";

function DeliveryReceptionModal({ onSubmit, onClose, title, submitTitle, branchId, upTo10M, cancelFlag, deliverDivision, deliveryCost, ...props }) {
	const [deliveryDivision, setDeliveryDivision] = useState(null);
	const [deliveryList, setDeliveryList] = useState(null);
	const [error, setError] = useState([]);
	const [input, setInput] = useState({
		deliveryId: "",
		// deliveryDivision: !cancelFlag && upTo10M ? "3" : deliverDivision,
		// cost: deliveryCost || 0,
		invoiceNumber: ""
	});

	const [isLoading, setIsLoading] = useState(false);
	const setLoadingDone = useCallback(() => {
		setIsLoading(false);
	}, []);

	useEffect(() => {
		async function getData() {
			const defaultId = await API.getDefaultDeliveryId(branchId);
			const deliveryDivision = await API.getDeliveryDivision();
			const deliveryList = await API.getDeliveryList();

			setDeliveryDivision([...deliveryDivision]);
			setDeliveryList([...deliveryList]);

			onChange("deliveryId", defaultId);
		}

		getData();
	}, []);

	function addError({ type, message }) {
		removeError(type);
		setError((prev) => ([
			...prev,
			{ type, message }
		]));
	}

	function removeError(type) {
		setError((prev) => prev?.filter((item) => item.type !== type));
	}

	function checkRequired() {
		if(isEmptyString(input.deliveryId + "")) {
			addError({ type: "deliveryId", message: "배송사를 선택해주세요" });
			return false;
		}

		if(isEmptyString(input.invoiceNumber + "")) {
      addError({ type: "invoiceNumber", message: "송장번호를 입력해주세요" });
			return false;
		}

		// if(isEmptyString(input.deliveryDivision + "")) {
    //   addError({ type: "deliveryDivision", message: "배송비 지불 유형을 선택해주세요" });
		// 	return false;
		// }
		//
		// if(input.deliveryDivision !== "3" && input.cost < 1) {
    //   addError({ type: "cost", message: "배송비를 입력해주세요" });
		// 	return false;
		// }

		return true;
	}

	async function handleSubmit() {
		if(!checkRequired()) {
			return;
		}

		const { label: deliveryName } = deliveryList.find((item) => item.value === input.deliveryId);
		// const { label: divisionName } = deliveryDivision.find((item) => item.value === input.deliveryDivision);

		setIsLoading(true);
		await onSubmit({ ...input, deliveryName }, onClose, setLoadingDone);
	}

	function onChange(name, newValue) {
    removeError(name);
		setInput((prev) => ({
			...prev,
			[name]: newValue
		}));
	}

	function invoiceChange({ target: { value } }) {
		onChange("invoiceNumber", value.replaceAll(/[^0-9a-zA-Z]/g, "").toUpperCase());
	}

	function deliveryDivisionChange({ target: { value } }) {
		onChange("deliveryDivision", value);

		if(value === "3") {
			onChange("cost", 0);
		}
	}

	function costChange({ target: { value }}) {
		onChange("cost", Number(value.substring(0, 6)));
	}

	return (
		<Dialog
			open
			maxWidth="xs"
			fullWidth
		>
			<Typography variant="dialogTitle">배송 접수를 완료하셨나요?</Typography>

			<DialogContent>
				<Stack spacing={ 2 }>
					<BackgroundBox sx={{ "&&&": { mb: 2.5 } }}>
						<Typography variant="dialogContent" color="primary">* 완료 버튼 클릭 시 고객님께 카카오 알림톡을 발송합니다</Typography>
					</BackgroundBox>

					<Box>
						<InputLabel shrink required>배송사</InputLabel>
						{ deliveryList === null ? (
							<Skeleton variant="rounded" width="100%" height="60px" />
						) : (
							<StyledSelectBox
								title=""
								items={ deliveryList }
								onChange={ (newValue) => onChange("deliveryId", newValue) }
								value={ input.deliveryId }
							/>
						) }
					</Box>

					<Box>
						<InputLabel shrink required>송장번호</InputLabel>
						<TextField
							fullWidth
							value={ input.invoiceNumber }
							onChange={ invoiceChange }
							helperText="숫자, 영문자만 입력해주세요"
						/>
					</Box>

					{/*<Box>*/}
					{/*	<InputLabel shrink required>배송비 지불 유형</InputLabel>*/}

					{/*	{ deliveryDivision === null ? (*/}
					{/*		<Skeleton variant="rounded" width="100%" height="60px" />*/}
					{/*	) : (*/}
					{/*		<FormControl>*/}
					{/*			<RadioGroup*/}
					{/*				row*/}
					{/*				value={ input.deliveryDivision }*/}
					{/*				onChange={ deliveryDivisionChange }*/}
					{/*			>*/}
					{/*				{ deliveryDivision.map(({ value, label }) => (*/}
					{/*					<FormControlLabel*/}
					{/*						key={ `deliveryDivision_${ label }` }*/}
					{/*						control={ <RadioButton /> }*/}
					{/*						value={ value }*/}
					{/*						label={ label }*/}
					{/*						disabled={ value === "3" && cancelFlag }*/}
					{/*					/>*/}
					{/*				)) }*/}
					{/*			</RadioGroup>*/}
					{/*		</FormControl>*/}
					{/*	) }*/}
					{/*</Box>*/}

					{/*{ input.deliveryDivision !== "3" && (*/}
					{/*	<Box>*/}
					{/*		<InputLabel shrink required>배송비</InputLabel>*/}

					{/*		<TextField*/}
					{/*			type="number"*/}
					{/*			fullWidth*/}
					{/*			value={ input.cost }*/}
					{/*			onChange={ costChange }*/}
					{/*			InputProps={ {*/}
					{/*				endAdornment: <InputAdornment position="end">원</InputAdornment>,*/}
					{/*			} }*/}
					{/*		/>*/}
					{/*	</Box>*/}
					{/*) }*/}
				</Stack>

				{ error.length > 0 && (
					<Stack spacing={ 1 } mt={ 2 }>
						{ error.map(({ type, message }) => (
							<Alert key={ type } severity="error">{ message }</Alert>
						)) }
					</Stack>
				) }
			</DialogContent>

			<DialogActions>
				<Button
					size="small"
					variant="outlined"
					color="secondary"
					disabled={ isLoading }
					onClick={ onClose }
				>
					취소
				</Button>

				<Button
					size="small"
					variant="contained"
					disabled={ isLoading }
					onClick={ handleSubmit }
				>
					완료
				</Button>
			</DialogActions>
		</Dialog>
	);
}

export default DeliveryReceptionModal;