import dayjs from "dayjs";

export function equalsArray(arrayA, arrayB) {
	if(!(Array.isArray(arrayA) && Array.isArray(arrayB))) {
		return false;
	}

	arrayA.sort();
	arrayB.sort();

	return arrayA.length === arrayB.length && arrayA.every((item, i) => item === arrayB[i]);
}

export function isEmptyString(string) {
	return string === false || string === undefined || !string?.trim();
}

export function isEmptyObject(object) {
	return Object.keys(object).length < 1;
}

export function addPhoneNumberHyphen(value) {
	if(isEmptyString(value)) {
		return "";
	}

	if(value.replace(/^(010|011|016|017|018|019)-[^0][0-9]{3,4}-[0-9]{4}$/g, "").length > 0) {
		if(checkPhoneNumber(value)) {
			const number = value.replace(/[^0-9]/g, "");
			let tel = "";

			if(number.length < 4) {
				return number;
			} else if(number.length < 7) {
				tel = `${ number.substring(0, 3) }-${ number.substring(3) }`;
			} else if(number.length < 11) {
				tel = `${number.substring(0, 3)}-${number.substring(3, 6)}-${number.substring(6)}`;
			} else {
				tel =`${number.substring(0, 3)}-${number.substring(3, 7)}-${number.substring(7)}`;
			}

			return tel;
		} else {
			return value.replace(/[^0-9|^-]*$/, "");
		}
	} else {
		return "";
	}
}

function checkPhoneNumber(number) {
	const regExp = /^[0-9|-]*$/;

	return regExp.test(number);
}

export function validationPhoneNumber(number) {
	return /^(010|011|016|017|018|019)-[^0][0-9]{3,4}-[0-9]{4}$/.test(number);
}

export function beforeDateString(before, after = new Date()) {
	const diff = (after - new Date(before)) / 1000;
	const times = [
		{ name: "년", milliseconds: 60 * 60 * 24 * 365 },
		{ name: "월", milliseconds: 60 * 60 * 24 * 30 },
		{ name: "일", milliseconds: 60 * 60 * 24 },
		{ name: "시간", milliseconds: 60 * 60 },
		{ name: "분", milliseconds: 60 }
	];

	for(const { name, milliseconds } of times) {
		const betweenTime = Math.floor(diff / milliseconds);

		if(betweenTime > 0) {
			return `${betweenTime}${name} 전`;
		}
	}

	return "방금 전";
}