import { Box, Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Unstable_Grid2";
import React from "react";
import { isEmptyString } from "../../lib/libs";

function ReadOnlyPickUpLocation({ weekString, startTime, cost, detail, use }) {
	const title = detail.reduce((acc, cur) => {
		acc.push({ location: cur.lctnNm, hasDetail: !isEmptyString(cur.dtlLctnNm) });
		return acc;
	}, []);

	function getTimeString() {
		const [hour, minutes] = startTime.split(":");
		const time = Number(hour) < 12 ? "오전" : "오후";

		return `${ time } ${ hour }시 ${ Number(minutes) > 0 ? (minutes + "분") : "" }`;
	}

	return (
		<Stack>
			<Stack direction="row" justifyContent="space-between" mb={ 1 }>
				<Stack
					direction="row"
					alignItems="center"
					spacing={ 1.5 }
				>
					<TitleLine />

					<Stack direction="row" spacing={ .3 }>
						{ title.map(({ location, hasDetail }, index) => (
							<React.Fragment key={ location }>
								<Typography variant="cardBold" sx={{ textDecoration: hasDetail && "underline", textUnderlinePosition: "under" }}>{ location }</Typography>

								{ index < title.length - 1 && (
									<Typography variant="cardBold">·</Typography>
								) }
							</React.Fragment>
						)) }
					</Stack>
				</Stack>
			</Stack>

			{ detail.map(({ lctnNm, dtlLctnNm }) => (
				!isEmptyString(dtlLctnNm) && (
					<Stack direction="row" spacing={ 1 } ml={ 1 } key={ `${ lctnNm }_detail` }>
						<Typography variant="cardContext" sx={{ minWidth: "fit-content", fontWeight: "600" }}>{ lctnNm } : </Typography>

						<Typography variant="cardContext">{ dtlLctnNm.split(",").join(", ") }</Typography>
					</Stack>
				)
			)) }

			<Grid container spacing={ 1 } mt={ 2 } alignItems="center">
				<Grid xs={ 3 }>
					<TitleBox>수거 요일</TitleBox>
				</Grid>
				<Grid xs={ 3 }>
					<Typography variant="cardContext">{ weekString }</Typography>
				</Grid>

				<Grid xs={ 3 }>
					<TitleBox>수거 시간</TitleBox>
				</Grid>
				<Grid xs={ 3 }>
					<Typography variant="cardContext">{ getTimeString() } ~</Typography>
				</Grid>

				<Grid xs={ 3 }>
					<TitleBox>요금</TitleBox>
				</Grid>
				<Grid xs={ 3 }>
					<Typography variant="cardContext">{ cost.toLocaleString() }원</Typography>
				</Grid>

				{ use !== undefined && (
					<React.Fragment>
						<Grid xs={ 3 }>
							<TitleBox>사용 여부</TitleBox>
						</Grid>
						<Grid xs={ 3 }>
							<Typography variant="cardContext">{ use ? "사용 중" : "사용 안 함" }</Typography>
						</Grid>
					</React.Fragment>
				) }
			</Grid>
		</Stack>
	);
}

const TitleLine = styled(Box)(({ theme }) => ({
	height: 18,
	border: `1px solid ${ theme.palette.text.primary }`
}));

const TitleBox = styled(Box)(({ theme }) => ({
	fontSize: "13px",
	fontWeight: 400,
	color: theme.palette.text.primary,
	lineHeight: "22px",
	backgroundColor: "#373737",
	padding: "2px 10px",
	borderRadius: 3,
	border: "1px solid #616161",
	textAlign: "center"
}));

const UseFlagBox = styled(TitleBox)(() => ({
	fontWeight: 700,
	backgroundColor: "rgba(232, 65, 11, 0.15)",
	borderColor: "#E8410B",
	color: "#E8410B",
}));

export default ReadOnlyPickUpLocation;