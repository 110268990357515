import React, { useCallback, useEffect, useState } from "react";
import { Box, Button, Card, CardActions, CardContent, MenuItem, Stack, TextField, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { getBranch } from "../../../api/public/profile";
import { usePublicUserState } from "../../../hooks/public/publicAuthContextProvider";
import { updateUser } from "../../../lib/firestore/User";
import { Toast } from "../Toast";

function ProfileItem ({ name, value }) {
	return (
		<Grid container xs={ 12 } spacing={ 3 }>
			<Typography variant="cardContext" color="seconds" component={ Grid } xs={ 3 }>{ name }</Typography>
			<Typography variant="cardContext" component={ Grid } xs={ 9 }>{ value }</Typography>
		</Grid>
	);
}

function UserInfoCard () {
	const { user } = usePublicUserState();
	const [updateFlag, setUpdateFlag] = useState(false);
	const [branchData, setBranchData] = useState([]);
	const [localUserData, setLocalUserData] = useState(null);

	useEffect(() => {
		async function getBranchData() {
			setBranchData(await getBranch());
		}

		getBranchData();
	}, []);

	const getBranchName = useCallback((branchId) => {
		return branchData?.find(({ value, label }) => value === branchId)?.label || "";
	}, [branchData]);

	function handleStartUpdate() {
		setUpdateFlag(true);
		setLocalUserData({
			// nickname: user.nickname,
			name: user.name,
			favoriteBranch: user.favoriteBranch
		});
	}

	function handleCancelUpdate() {
		setUpdateFlag(false);
		setLocalUserData(null);
	}

	function handleInputChange(type, { target: { value } }) {
		setLocalUserData((prev) => ({
			...prev,
			[type]: value
		}));
	}

	function handleSubmit() {
		updateUser({
			name: localUserData.name,
			// nickname: localUserData.nickname,
			favoriteBranch: localUserData.favoriteBranch
		}).then(() => {
			handleCancelUpdate();
			Toast.success("저장되었어요");
		});
	}

	return (
		<Card>
			<CardContent>
				<Box sx={ { flexGrow: 1 } }>
					<Grid container gap={ !updateFlag ? 3 : 5 } rowGap={ !updateFlag ? .5 : 2 }>
						{ !updateFlag ? (
							<React.Fragment>
								<ProfileItem name="이름" value={ user.name } />
								<ProfileItem name="연락처" value={ user.phoneNumber } />
								<ProfileItem name="이메일" value={ user.email } />
								<ProfileItem name="선호 지점" value={ getBranchName(user.favoriteBranch) } />
							</React.Fragment>
						) : (
							<React.Fragment>
								<Grid xs={ 12 }>
									<TextField variant="outlined" fullWidth size="small" label="이름" value={ localUserData.name } onChange={ e => handleInputChange("name", e)  } />
								</Grid>

								<Grid xs={ 12 }>
									<TextField variant="outlined" fullWidth size="small" label="선호 지점" value={ localUserData.favoriteBranch } select onChange={ e => handleInputChange("favoriteBranch", e) }>
										{ branchData?.map(({ value, label }) => (
											<MenuItem key={ value } value={ value }>{ label }</MenuItem>
										)) }
									</TextField>
								</Grid>
							</React.Fragment>
						) }
					</Grid>
				</Box>
			</CardContent>

			<CardActions>
				<Stack
					sx={ { width: "100%" } }
					direction="row"
					justifyContent="flex-end"
					spacing={ 1 }
				>
					{ !updateFlag ? (
						<Button variant="contained" size="small" onClick={ handleStartUpdate }>수정</Button>
					) : (
						<React.Fragment>
							<Button variant="outlined" size="small" color="secondary" onClick={ handleCancelUpdate }>취소</Button>
							<Button variant="contained" size="small" onClick={ handleSubmit }>저장</Button>
						</React.Fragment>
					) }
				</Stack>
			</CardActions>
		</Card>
	);
}

export default UserInfoCard;