import React, {
	useEffect,
	useState
} from "react";

function UseTitleBarSize(displayElement, mediaQuery) {
	const [left, setLeft] = useState(0);

	useEffect(() => {
		function handleResize() {
			if(displayElement.current) {
				setLeft(displayElement.current.offsetLeft);
			}
		}

		window.addEventListener("resize", handleResize);
		handleResize();

		return () => window.removeEventListener("resize", handleResize);
	}, []);

	useEffect(() => {
		// trigger resize event
		const resizeEvent = new Event("resize", {
			view: Window
		});

		window.dispatchEvent(resizeEvent);
	}, [mediaQuery]);

	return left;
}

export default UseTitleBarSize;