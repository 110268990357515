import React, {
	useEffect,
	useState
} from "react";
import {
	FormControl,
	InputLabel,
	MenuItem,
	Select
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { ReactComponent as Arrow } from "../../assets/images/icon/inputbox_arrow.svg";

const StyledSelectBox = React.forwardRef(({title, items = [], value = "", onChange}, ref) => {
	const [select, setSelect] = useState(value);

	useEffect(() => {
		setSelect(value);
	}, [value]);

	function handleChange({ target: { value } }) {
		setSelect(value);
		onChange(value);
	}

	return (
		<FormControl fullWidth>
			<InputLabel>{ title }</InputLabel>

			<StyledSelect
				label={ title }
				IconComponent={ Arrow }
				value={ select }
				onChange={ handleChange }
				ref={ ref }
				MenuProps={ {
					PaperProps: {
						style: {
							maxHeight: 390
						}
					}
				} }
			>
				{ items.map(({ label, value }) => (
					<MenuItem key={ `${ title }_${ value }` } value={ value }>{ label }</MenuItem>
				)) }
			</StyledSelect>
		</FormControl>
	);
});

const StyledSelect = styled(Select)(({ theme }) => ({
	"& .MuiSelect-icon": {
		top: "calc(50% - 4.5px)",
		right: "20px",
		fill: "#D9D9D9",
		transition: "all .2s ease",
		"&.MuiSelect-iconOpen": {
			fill: theme.palette.primary.dark
		}
	}
}));

export default StyledSelectBox;