import { Stack, SvgIcon, Tooltip, Typography } from "@mui/material";
import React, { useMemo, useState } from "react";
import {
	ReactComponent as Repair,
} from "../../../assets/images/history/07_repair.svg";
import {
	ReactComponent as Washing,
} from "../../../assets/images/history/07_washing.svg";
import { ReactComponent as CancelMark } from "../../../assets/images/icon/cancel_mark.svg";
import { getUser } from "../../../lib/firestore/User";
import { isEmptyString } from "../../../lib/libs";

function CardTitle({ orderType, name, nickname, userId, date, cancel = false }) {
	const [phoneNumber, setPhoneNumber] = useState("");
	const orderTypeIcon = useMemo(() => ({
		"1": Washing,
		"2": Repair,
	}), []);

	async function handleClick() {
		if(!isEmptyString(phoneNumber)) {
			document.location.href = `tel:${ phoneNumber }`;
		} else {
			await getPhoneNumber();
		}
	}

	async function getPhoneNumber() {
		if(isEmptyString(phoneNumber)) {
			const user = (await getUser(userId)).data();
			setPhoneNumber(user.phoneNumber);
		}
	}

	return (
		<Stack
			direction="row"
			justifyContent="space-between"
			alignItems="center"
			mb={ 2 }
		>
			<Stack direction="row" alignItems="center" spacing={ 2 } sx={{ position: "relative" }}>
				<SvgIcon
					variant="cardIcon"
					component={ orderTypeIcon[orderType] }
					sx={ { width: "22px", height: "22px", stroke: (theme) => theme.palette.type[orderType === "1" ? "washing" : "repair"] } }
				/>

				{ cancel && (
					<SvgIcon
						variant="cardIcon"
						component={ CancelMark }
						sx={ {
							position: "absolute",
							width: "30px",
							height: "30px",
							marginLeft: "-3.5px !important"
						} }
					/>
				) }

				<Tooltip title={ isEmptyString(phoneNumber) ? "클릭하면 고객님의 연락처로 바뀌어요" : phoneNumber }>
					<Typography
						variant="smallContext"
						sx={ {
							fontSize: 14,
							cursor: "pointer",
						} }
						onClick={ handleClick }
					>
						{ nickname }
					</Typography>
				</Tooltip>
			</Stack>

			<Typography variant="smallContext" sx={{ fontSize: 14 }}>{ date }</Typography>
		</Stack>
	);
}

export default CardTitle;