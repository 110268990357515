import { Box, CircularProgress, Container, Stack, TextField, Typography } from "@mui/material";
import React, {
	useCallback,
	useEffect,
	useRef,
	useState
} from "react";
import { useNavigate } from "react-router-dom";
import axios from "../../api/axios";
import { signInWithCustomToken, updateProfile } from "firebase/auth";
import LoadingLogin from "../../components/common/LoadingLogin";
import { Toast } from "../../components/public/Toast";
import { firebaseAuth } from "../../firebaseAuth";
import * as API from "../../api/public/auth";
import { accessToken as AT, firebaseToken as FT } from "../../lib/authToken";
import {
	usePublicUserDispatch,
	usePublicUserState
} from "../../hooks/public/publicAuthContextProvider";
import { getAccessToken } from "../../lib/config/exportToken";
import {
	getCurrentUser,
	setUser,
	updateUser,
	User
} from "../../lib/firestore/User";
import useModal from "../../hooks/useModal";
import { modals } from "../../hooks/Modals";
import { getBranch } from "../../api/public/login";
import StyledSelectBox from "../../components/public/StyledSelectBox";
import { isEmptyString } from "../../lib/libs";

function KakaoCallback() {
	const navigate = useNavigate();
	const { loginFlag } = usePublicUserState();
	const { setLoggedIn, setLoggedOut } = usePublicUserDispatch();
	const searchParams = new URLSearchParams(window.location.search);
	const code = searchParams.get("code");
	const error = searchParams.get("error");
	const { openModal } = useModal();
	const [favoriteBranch, setFavoriteBranch] = useState("10");
	const branchRef = useRef(null);
	const nameRef = useRef(null);

	if(!code) {
		navigate("/login");
	}

	useEffect(() => {
		login();
	}, []);

	function onBranchChange(value) {
		setFavoriteBranch(value);
	}

	const updateUserInfo = useCallback(async () => {
		const branchData = await getBranch();

		openModal(modals.confirmModal, {
			// title: "선호 지점 선택",
			submitTitle: "저장",
			closeTitle: null,
			content: (
				<React.Fragment>
					선호 지점을 선택해주세요.<br /><br />
					<StyledSelectBox
						title={ "선호 지점" }
						items={ branchData }
						onChange={ onBranchChange }
						value={ favoriteBranch }
						ref={ branchRef }
					/>

					<br /><br /><br />

					이름을 입력해주세요.<br /><br />
					<TextField
						label="이름"
						fullWidth
						ref={ nameRef }
						inputProps={ { maxLength: 10 } }
						required
					/>
				</React.Fragment>
			),
			onSubmit: async (onClose, setLoadingDone) => {
				const name = nameRef.current.getElementsByTagName("input")[0].value;
				const favoriteBranch = branchRef.current.getElementsByTagName("input")[0].value;

				if(isEmptyString(name)) {
					setLoadingDone();
					Toast.error("이름을 입력해주세요");
					return ;
				}

				await updateUser({
					favoriteBranch,
					name
				});

				onClose();
				navigate(localStorage.getItem("before_page") || "/");
			},
			beforeClose: () => {
				navigate(localStorage.getItem("before_page") || "/");
			}
		});
	}, [loginFlag, favoriteBranch]);

	const login = useCallback(async () => {
		try {
			const res = await axios.post("/api/auth/login/kakao", { code });
			const {
				data: {
					cd,
					user: {
						firebaseToken,
						// provider,
						uid,
						nickname,
						email,
						phoneNumber
					}
				}
			} = res;

			if(cd === "1") {
				await signInWithCustomToken(firebaseAuth, firebaseToken)
					.then(async (userCredential) => {
						const { data: { cd }, headers } = await API.login();

						if(cd === "1") {
							const inDbUser = await getCurrentUser();

							if(!inDbUser.exists()) {
								const user = new User(uid, email, nickname, "", phoneNumber, null, []);
								await setUser(user);
							} else {
								const user = inDbUser.data();

								if(user.phoneNumber !== phoneNumber) {
									await updateUser({ phoneNumber });
								}
							}

							AT.setToken(getAccessToken(headers));
							setLoggedIn({});
							localStorage.setItem("LP", "K");

							if(!(inDbUser.data() && inDbUser.data().favoriteBranch && inDbUser.data().name)) {
								updateUserInfo();
							} else {
								navigate(localStorage.getItem("before_page") || "/");
							}
						} else {
							AT.setToken("");
							setLoggedOut();
							navigate("/login");
						}
					});
			}
		} catch(e) {
			navigate("/login");
		}
	}, [code]);

	return <LoadingLogin />;
}

export default KakaoCallback;