import React, {
	useEffect,
	useMemo
} from "react";
import {
	Box,
	Card,
	CardMedia,
	Container,
	Divider,
	Stack,
	Typography
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import useKakaoMap from "../../../hooks/public/useKakaoMap";
import TopImage01 from "../../../assets/images/branch_02/top_01.jpeg";
import TopImage02 from "../../../assets/images/branch_02/top_02.jpeg";
import TopImage03 from "../../../assets/images/branch_02/top_03.jpeg";
import TopImage04 from "../../../assets/images/branch_02/top_04.jpeg";
import TopImage05 from "../../../assets/images/branch_02/top_05.jpeg";
import TopImage06 from "../../../assets/images/branch_02/top_06.jpeg";
import TopImage07 from "../../../assets/images/branch_02/top_07.jpeg";
import SwiperTopCard from "../../../components/public/SwiperTopCard";
import BranchInfo from "../../../components/public/BranchInfo";

function Branch2() {
	useKakaoMap({
		locationName: "텐트세탁소 남양주 2호점",
		lat: 37.657949,
		lng: 127.3299342
	});

	const topImages = useMemo(() => ([
		{
			image: TopImage01,
			backgroundPositionY: "-5px"
		},
		{
			image: TopImage02,
		},
		{
			image: TopImage03,
			subTitle: "세탁 전 위생적인 보관",
			backgroundPositionY: "-160px"
		},
		{
			image: TopImage04,
			subTitle: "세탁물 개별 보관",
			backgroundPositionY: "-140px"
		},
		{
			image: TopImage05,
			subTitle: "세탁 후 건조"
		},
		{
			image: TopImage06,
			subTitle: "발수 코팅",
			backgroundPositionY: "-240px"
		},
		{
			image: TopImage07,
			subTitle: "2차 건조",
			backgroundPositionY: "-100px"
		},
	]), []);

	const info = useMemo(() => ({
		tel: "010-4606-0907",
		cafe: "https://cafe.naver.com/tentcareservice",
		blog: "https://blog.naver.com/kwakyoungkoo",
		kakao: "http://pf.kakao.com/_xdaAxlxj",
		insta: "https://www.instagram.com/mr_ykkwak"
	}), []);

	return (
		<Container variant="contextRoot">
			<SwiperTopCard images={topImages} />

			<Container variant="contextBox">
				<Stack direction="column" spacing={ 3 }>
					{/*<Stack direction={ "row" } spacing={ 1 }>*/}
					{/*	<Box sx={ { whiteSpace: "nowrap" } }>*/}
					{/*		<Typography variant={ "context" }>*/}
					{/*			지점소개:*/}
					{/*		</Typography>*/}
					{/*	</Box>*/}

					{/*	<Box>*/}
					{/*		<Typography variant={ "context" }>*/}
					{/*			세탁하는 곳이므로 작업공간의 청결에 많은 신경을 쓰고 세탁물에 추가 오염이 생기지 않도록 보관부터 세탁까지 많은 신경을 쓰고 있습니다.*/}
					{/*			공장 주변을 막고 있는 건물들이 없어 바람도 잘 통하여 세탁물의 건조에도 더없이 좋은 곳입니다.*/}
					{/*			<br /><br />*/}
					{/*			세탁, 건조, 발수, 포장 등 모든 업무가 가능할 만큼 넓은 공간에서 고객님 한 분 한 분의 소중한 텐트를 정성스럽게 세탁하고 있는*/}
					{/*			<strong> "텐트세탁소 남양주 2호점"</strong>을 기억해 주시고 필요하시거나 궁금하실 때 언제든 문의주시면 정성껏 상담, 답변드리겠습니다.*/}
					{/*			<br /><br />*/}
					{/*			감사합니다.*/}
					{/*		</Typography>*/}
					{/*	</Box>*/}
					{/*</Stack>*/}

					{/*<Divider />*/}

					<BranchInfo info={info} />

					<Divider />

					<Typography variant="context">
						전화: { info.tel }
					</Typography>

					<Typography variant="context" component={ Grid } xs={ 9 }>
						경기도 남양주시 화도읍 경춘로 2177번길 43-14
					</Typography>

					<Box
						sx={ {
							width: "calc(100% + 30px)",
							height: "200px",
							position: "relative",
							left: "-15px"
						} }
					>
						<div id="map" style={ { width: "100%", height: "100%" } } />
					</Box>
				</Stack>
			</Container>
		</Container>
	);
}

export default Branch2;