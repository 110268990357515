import { HomeRounded } from "@mui/icons-material";
import { Card, CardContent, Divider, Stack, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React, { useEffect, useState } from "react";
import { getOrder } from "../../../lib/firestore/Order";

function DeliveryDetailInfoCard({ orderId, deliveryType, deliveryTypeName, deliverDivision, deliverDivisionName }) {
	// const [address, setAddress] = useState(null);
	//
	// useEffect(() => {
	// 	async function getAddress() {
	// 		const order = (await getOrder(orderId)).data();
	//
	// 		setAddress({ ...order });
	// 	}
	//
	// 	getAddress();
	// }, []);

	return (
		<Card>
			<CardContent>
				<Stack
					direction="row"
					alignItems="center"
					justifyContent="space-between"
					mb={ 2 }
				>
					<Stack direction="row" spacing={ 1 }>
						<HomeRounded fill="none" />
						<Typography variant="cardBold">배송 정보</Typography>
					</Stack>
				</Stack>

				<Grid container>
					<Grid xs={ 4 }>
						<Typography variant="cardContext" type="title">배송 방법</Typography>
					</Grid>

					<Grid xs={ 8 }>
						<Typography variant="cardContext">{ deliveryTypeName }</Typography>
					</Grid>

					{ deliveryType === "1" && (
						<React.Fragment>
							<Grid xs={ 4 }>
								<Typography variant="cardContext" type="title">배송비 지불 유형</Typography>
							</Grid>

							<Grid xs={ 8 }>
								<Typography variant="cardContext">{ deliverDivisionName }</Typography>
							</Grid>
						</React.Fragment>
					) }

					{/*		<Grid xs={ 12 } my={ 1 }>*/}
					{/*			<Divider />*/}
					{/*		</Grid>*/}

					{/*		<Grid xs={ 4 }>*/}
					{/*			<Typography variant="cardContext" type="title">수령인</Typography>*/}
					{/*		</Grid>*/}

					{/*		<Grid xs={ 8 }>*/}
					{/*			<Typography variant="cardContext">{ address?.receiverName }</Typography>*/}
					{/*		</Grid>*/}

					{/*		<Grid xs={ 4 }>*/}
					{/*			<Typography variant="cardContext" type="title">휴대폰</Typography>*/}
					{/*		</Grid>*/}

					{/*		<Grid xs={ 8 }>*/}
					{/*			<Typography variant="cardContext">{ address?.receiverPhoneNumber }</Typography>*/}
					{/*		</Grid>*/}

					{/*		<Grid xs={ 4 }>*/}
					{/*			<Typography variant="cardContext" type="title">주소</Typography>*/}
					{/*		</Grid>*/}

					{/*		<Grid xs={ 8 }>*/}
					{/*			<Typography variant="cardContext">[{ address?.zoneCode }] { address?.address }, { address?.addressDetail }</Typography>*/}
					{/*		</Grid>*/}
					{/*	</React.Fragment>*/}
					{/*) }*/}
				</Grid>
			</CardContent>
		</Card>
	);
}

export default DeliveryDetailInfoCard;