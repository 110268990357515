import { useMutation, useQuery } from "@tanstack/react-query";
import { getHistoryStep } from "../../api/admin/order";
import * as API from "../../api/admin/order";
import { useAdminUserState } from "../../hooks/admin/adminAuthContextProvider";
import Role from "../../lib/Role";

export const GET_ORDER_STEP = ["getOrderStep"];
export const GET_HISTORY_STEP = ["getHistoryStep"];
export const GET_ESTIMATE_LISE = ["getEstimate"];
export const GET_COMPLETE_HISTORY_STEP = ["getCompleteHistoryStep"];

export function useOrderStepQuery(currentStep, filter = {}) {
	const {
		      role,
		      branchId,
	      } = useAdminUserState();
	const {
		      isLoading,
		      isError,
		      isSuccess,
		      data,
	      } = useQuery([...GET_ORDER_STEP, currentStep, filter], () => API.getStep(currentStep, role === Role.ROLE_BRANCH ? branchId : undefined, filter), {
		retry: 0,
		cacheTime: 0,
	});

	return {
		isLoading,
		isError,
		isSuccess,
		data,
	};
}

export function useOrderHistoryStepQuery(currentStep, filter = {}) {
	const {
		      role,
		      branchId,
	      } = useAdminUserState();
	const {
		      isLoading,
		      isError,
		      isSuccess,
		      data,
	      } = useQuery([...GET_HISTORY_STEP, currentStep, filter], () => API.getHistoryStep(currentStep, role === Role.ROLE_BRANCH ? branchId : undefined, filter), {
		retry: 0,
		cacheTime: 0,
	});

	return {
		isLoading,
		isError,
		isSuccess,
		data,
	};
}

export function useCompleteOrderStep(filter) {
	const {
		      role,
		      branchId,
	      } = useAdminUserState();
	const {
		      isLoading,
		      isError,
		      isSuccess,
		      data,
	      } = useQuery([...GET_COMPLETE_HISTORY_STEP, filter], () => API.getCompleteStep(role === Role.ROLE_BRANCH ? branchId : undefined, filter), {
		retry: 0,
		cacheTime: 0,
	});

	return {
		isLoading,
		isError,
		isSuccess,
		data,
	};
}

export function useEstimateQuery(estimateId, open = true) {
	const {
		      isLoading: isEstimateLoading,
		      isError: isEstimateError,
		      isSuccess: isEstimateSuccess,
		      data: estimateData,
	      } = useQuery([...GET_ESTIMATE_LISE, estimateId], () => API.getEstimate(estimateId), {
		enabled: open,
		retry: 0,
		cacheTime: 0,
	});

	return {
		isEstimateLoading,
		isEstimateError,
		isEstimateSuccess,
		estimateData,
	};
}

export function useSetReceiveMutation() {
	const { mutate } = useMutation({
		mutationFn: API.setReceive,
	});

	return { mutate };
}

export function useSetPaymentCompleteMutation() {
	const { mutate } = useMutation({
		mutationFn: API.setPaymentComplete,
	});

	return { mutate };
}

export function useSetWorkingStartMutation() {
	const { mutate: startWorkingMutate } = useMutation({
		mutationFn: API.setWorkingStart,
	});

	return { startWorkingMutate };
}

export function useUpdateWorkingContentMutation() {
	const { mutate: updateWorkingContentMutate } = useMutation({
		mutationFn: API.updateWorkingContent,
	});

	return { updateWorkingContentMutate };
}

export function useSetWorkingCompleteMutation() {
	const { mutate: setWorkingCompleteMutate } = useMutation({
		mutationFn: API.setWorkingComplete,
	});

	return { setWorkingCompleteMutate };
}

export function useSetDeliveryReceptionMutation() {
	const { mutate: setDeliveryReceptionMutate } = useMutation({
		mutationFn: API.setDeliveryReception,
	});

	return { setDeliveryReceptionMutate };
}

export function useSetRequestPickUpMutation() {
	const { mutate: setRequestPickUpMutate } = useMutation({
		mutationFn: API.setRequestPickUp,
	});

	return { setRequestPickUpMutate };
}

export function useSetPickUpConfirmMutation() {
	const { mutate: setPickUpConfirmMutate } = useMutation({
		mutationFn: API.setPickUpConfirm,
	});

	return { setPickUpConfirmMutate };
}

export function useSetRefundMutation() {
	const { mutate: setRefundMutate } = useMutation({
		mutationFn: API.setRefund,
	});

	return { setRefundMutate };
}

export function useChangeDeliveryTypeMutation() {
	const { mutate: setChangeDeliveryTypeMutate } = useMutation({
		mutationFn: API.changeDeliveryType,
	});

	return { setChangeDeliveryTypeMutate };
}

export function useDeleteOrderMutation() {
	const { mutate: setDeleteOrderMutate } = useMutation({
		mutationFn: API.deleteOrder
	});

	return { setDeleteOrderMutate };
}

export function useCancelOrderMutate() {
	const { mutate: cancelOrderMutate } = useMutation({
		mutationFn: API.cancelOrder
	});

	return { cancelOrderMutate };
}

export function useRequestWorkMutate() {
	const { mutate: requestWorkMutate } = useMutation({
		mutationFn: API.requestWork
	});

	return { requestWorkMutate };
}

export function useRequestOrderMutate() {
	const { mutate: requestOrderMutate } = useMutation({
		mutationFn: API.requestOrder
	});

	return { requestOrderMutate };
}