import { AddOutlined, RemoveOutlined } from "@mui/icons-material";
import {
	Container,
	FormControl,
	IconButton,
	Skeleton,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
} from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import {
	createColumnHelper,
	flexRender,
	getCoreRowModel,
	getExpandedRowModel,
	useReactTable,
} from "@tanstack/react-table";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { ModifyDropDownButton } from "../../components/admin/ModifyDropDownButton";
import CheckBox from "../../components/common/CheckBox";
import StyledSelectBox from "../../components/public/StyledSelectBox";
import TentAutocomplete from "../../components/public/estimate/TentAutocomplete";
import { modals } from "../../hooks/Modals";
import useModal from "../../hooks/useModal";
import { FormColumnType, FormDialogType } from "../../lib/config/formDialogType";
import {
	GET_TENT_AMOUNT_KEY,
	useBrandQuery,
	useDeleteGroupMutation,
	useDeleteItemMutation,
	useInsertGroupMutation,
	useInsertItemMutation,
	useTentInfoQuery,
	useTentQuery,
	useUpdateGroupMutation,
	useUpdateItemMutation,
} from "../../query/admin/amountQuery";

export default function Amount({ tentId }) {
	const [brndId, setBrndId] = useState("");
	const [tntInfo, setTntInfo] = useState(null);
	const [expanded, setExpanded] = useState({});
	const [insertItem, setInsertItem] = useState({
		target: "",
		name: "",
	});

	const { brandData } = useBrandQuery(brndId);
	const {
		      isTentFetching,
		      tentData,
	      } = useTentQuery(brndId);
	const {
		      isTentInfoLoading,
		      tentInfoData,
	      } = useTentInfoQuery(tntInfo?.tntId);
	const { insertGroupMutate } = useInsertGroupMutation();
	const { updateGroupMutate } = useUpdateGroupMutation();
	const { deleteGroupMutate } = useDeleteGroupMutation();
	const { insertItemMutate } = useInsertItemMutation();
	const { updateItemMutate } = useUpdateItemMutation();
	const { deleteItemMutate } = useDeleteItemMutation();
	const queryClient = useQueryClient();
	const { openModal } = useModal();
	const tentSelectRef = useRef(null);

	function EditRowCell({ info, rowData }) {
		const isTentSelected = tentSelectRef.current !== null;

		function getColumnDef() {
			/**
			 * [ 그룹 ]
			 * grpNm
			 *
			 * [ 항목 ]
			 * itmNm, itmTp, useYn, (선택한 텐트가 있을 경우 amnt)
			 */

			return table.options.columns.reduce((acc, cur) => {
				if(info.row.original.sub) { // group
					if(cur.fEditable === undefined && cur.fGroupEditable !== true) {
						return [
							...acc,
							{
								...cur,
								fEditable: false,
								id: cur.id || cur.accessorKey,
							},
						];
					}
				} else { // item
					if(cur.fEditable === undefined && cur.fItemEditable !== true) {
						return [
							...acc,
							{
								...cur,
								fEditable: false,
								id: cur.id || cur.accessorKey,
							},
						];
					} else {
						if(!isTentSelected && cur.accessorKey === "amnt") {
							return [
								...acc,
								{
									...cur,
									fEditable: false,
									id: cur.id || cur.accessorKey,
								},
							];
						}
					}
				}

				return [
					...acc,
					{
						...cur,
						id: cur.id || cur.accessorKey,
					},
				];
			}, []);
		}

		function addModalOpen() {
			openModal(modals.formModal, {
				type: FormDialogType.ADD,
				columnDef: getColumnDef(),
				onSubmit: async (formData, onClose, showToast, complete) => {
					function onSuccess({ cd }) {
						if(cd === "1") {
							onClose();
							setInsertItem(() => ({
								target: formData.grpNm ? "group" : "item",
								name: formData.grpNm || formData.itmNm,
							}));
							return queryClient.invalidateQueries([...GET_TENT_AMOUNT_KEY, tntInfo?.tntId || ""]);
						}
					}

					function onSettled(data, error) {
						complete();
						showToast({ cd: (error === null) ? data.cd : 0 });
					}

					if(info.row.original.sub) { // group
						insertGroupMutate(formData, {
							onSuccess,
							onSettled,
						});
					} else { // item
						formData = {
							...formData,
							grpId: rowData.grpId,
							tntId: tntInfo?.tntId || "",
							brndId: tntInfo?.brndId || "",
						};

						insertItemMutate(formData, {
							onSuccess,
							onSettled,
						});
					}
				},
			});
		}

		function updateModalOpen() {
			openModal(modals.formModal, {
				type: FormDialogType.EDIT,
				rowData,
				columnDef: getColumnDef(),
				onSubmit: (formData, onClose, showToast, complete) => {
					function onSuccess({ cd }) {
						if(cd === "1") {
							onClose();
							return queryClient.invalidateQueries([...GET_TENT_AMOUNT_KEY, tntInfo?.tntId || ""]);
						}
					}

					function onSettled(data, error) {
						complete();
						showToast({ cd: (error === null) ? data.cd : 0 });
					}

					if(info.row.original.sub) {
						updateGroupMutate(formData, {
							onSuccess,
							onSettled,
						});
					} else {
						formData = {
							...formData,
							tntId: tntInfo?.tntId || "",
							brndId: tntInfo?.brndId || "",
						};

						updateItemMutate(formData, {
							onSuccess,
							onSettled,
						});
					}
				},
			});
		}

		function deleteModalOpen() {
			openModal(modals.formModal, {
				type: FormDialogType.DELETE,
				columnDef: getColumnDef(),
				rowData,
				onSubmit: (formData, onClose, showToast, complete) => {
					function onSuccess({ cd }) {
						if(cd === "1") {
							onClose();
							return queryClient.invalidateQueries([...GET_TENT_AMOUNT_KEY, tntInfo?.tntId || ""]);
						}
					}

					function onSettled(data, error) {
						complete();
						showToast({ cd: (error === null) ? data.cd : 0 });
					}

					if(info.row.original.sub) {
						deleteGroupMutate(formData, {
							onSuccess,
							onSettled,
						});
					} else {
						deleteItemMutate(formData, {
							onSuccess,
							onSettled,
						});
					}
				},
			});
		}

		function onClick(type) {
			if(type === "add") {
				addModalOpen();
			} else if(type === "update") {
				updateModalOpen();
			} else if(type === "delete") {
				deleteModalOpen();
			}
		}

		const dummyItemFlag = rowData.sub === undefined && rowData.itmId === undefined;

		return rowData.grpId !== "notingGroup" ? (
			<ModifyDropDownButton updateItem={ !dummyItemFlag } deleteItem={ !dummyItemFlag } onClick={ onClick } />
		) : null;
	}

	const columnDefs = useMemo(() => {
		const ch = new createColumnHelper();

		return [
			ch.display({
				id: "expander",
				header: "",
				cell: ({ row }) => {
					return row.getCanExpand() && (
						<IconButton
							size="small"
							onClick={ row.getToggleExpandedHandler() }
						>
							{ row.getIsExpanded() ? (
								<RemoveOutlined size="small" sx={ { color: (theme) => theme.palette.text.secondary } } />
							) : (
								<AddOutlined size="small" sx={ { color: (theme) => theme.palette.text.secondary } } />
							) }
						</IconButton>
					);
				},
				size: 1,
				fEditable: false,
			}),
			ch.accessor("grpId", {
				cell: info => info.getValue(),
				fEditable: false,
				fPk: true,
			}),
			ch.accessor("itmId", {
				cell: info => info.getValue(),
				fEditable: false,
				fPk: true,
			}),
			ch.accessor("grpNm", {
				header: "그룹",
				cell: info => info.row.original.sub ? info.getValue() : "",
				align: "left",
				fType: FormColumnType.TEXT,
				fRequired: true,
				fMaxByte: 100,
				fGroupEditable: true,
			}),
			ch.accessor("grpRmk", {
				header: "상세 설명",
				cell: info => info.getValue(),
				align: "left",
				fType: FormColumnType.MULTILINE,
				fMaxByte: 200,
				fGroupEditable: true,
			}),
			ch.accessor("itmNm", {
				header: "항목",
				cell: info => info.row.original.sub ? info.row.original.grpNm : info.getValue(),
				align: "left",
				fType: FormColumnType.TEXT,
				fRequired: true,
				fItemEditable: true,
			}),
			ch.accessor("itmTp", {
				header: "수량",
				cell: info => info.row.original.sub ? "" : info.getValue() === "1" ? "복수" : info.getValue() === "2" ? "단수" : "",
				fType: FormColumnType.SELECT,
				fRequired: true,
				fSelectItems: [
					{
						value: 1,
						label: "복수",
					},
					{
						value: 2,
						label: "단수",
					},
				],
				fItemEditable: true,
				size: 70,
			}),
			ch.accessor("amnt", {
				header: "가격",
				cell: info => info.row.original.sub ? "" : info.getValue()?.toLocaleString(),
				fType: FormColumnType.NUMBER,
				align: "right",
				fMaxByte: 8,
				fItemEditable: true,
				size: 70,
			}),
			ch.accessor("itmRmk", {
				header: "상세 설명",
				cell: info => info.getValue(),
				align: "left",
				fType: FormColumnType.MULTILINE,
				fMaxByte: 200,
				fItemEditable: true,
			}),
			ch.accessor("dcYn", {
				header: "할인",
				cell: info => <CheckBox readOnly checked={ info.getValue() } />,
				fType: FormColumnType.CHECKBOX,
				fItemEditable: true,
				fDefaultValue: true,
				size: 0,
			}),
			ch.accessor("useYn", {
				header: "사용",
				cell: info => <CheckBox readOnly checked={ info.getValue() } />,
				fType: FormColumnType.CHECKBOX,
				fItemEditable: true,
				fDefaultValue: true,
				size: 0,
			}),
			ch.display({
				id: "edit",
				header: "편집",
				cell: info => <EditRowCell info={ info } rowData={ info?.row?.original } />,
				fEditable: false,
				size: 1,
			}),
		];
	}, [tntInfo]);

	const table = useReactTable({
		data: tentInfoData,
		columns: columnDefs,
		getCoreRowModel: getCoreRowModel(),
		getExpandedRowModel: getExpandedRowModel(),
		onExpandedChange: setExpanded,
		state: { expanded },
		getSubRows: (row) => row.sub,
		initialState: {
			columnVisibility: {
				grpId: false,
				itmId: false,
				tntId: false,
				itmCnt: false,
				grpNm: false,
				grpRmk: false,
				itmRmk: false
			},
		},
	});

	useEffect(() => {
		const el = document.getElementById("currentInsert");

		if(el) {
			el.scrollIntoView();
			window.scrollBy(0, -60);
			setInsertItem(() => ({
				target: "",
				name: "",
			}));
		}
	});

	useEffect(() => {
		table.toggleAllRowsExpanded(true);
	}, [table.getIsAllRowsExpanded]);

	useEffect(() => {
		if(tentId && tentData) {
			setTntInfo(() => ({ ...tentData.find((item) => item.tntId === tentId) }));
		}
	}, [tentId, tentData]);

	function onBrandChange(value) {
		setBrndId(value);
		setTntInfo(null);
	}

	function onTentChange(newValue) {
		setTntInfo(newValue);
		setBrndId(newValue?.brndId || "");
		tentSelectRef.current = newValue;
	}

	return (
		<Container variant="contextRoot">
			<Container variant="contextBox">
				<Stack spacing={ 2 } mb={ 4 }>
					<StyledSelectBox
						title="브랜드"
						items={ brandData }
						onChange={ onBrandChange }
						value={ brndId }
					/>

					<TentAutocomplete
						isLoading={ isTentFetching }
						items={ tentData }
						value={ tntInfo }
						onChange={ onTentChange }
					/>

					{ tntInfo && (
						<FormControl fullWidth>
							<TextField
								label="사이즈"
								value={ tntInfo.sz }
								disabled
							/>
						</FormControl>
					) }
				</Stack>

				{ tntInfo && isTentInfoLoading ? (
					<Skeleton variant="rounded" width="100%" height="100%" />
				) : (
					<TableContainer
						sx={ {
							overflow: "visible",
							marginBottom: 6,
						} }
					>
						<Table>
							<TableHead
								sx={ {
									position: "sticky",
									top: 0,
									zIndex: 1000,
								} }
							>
								{ table.getHeaderGroups().map(headerGroup => (
									<TableRow key={ headerGroup.id }>
										{ headerGroup.headers.map(header => (
											<TableCell
												key={ header.id }
												variant="header"
												colSpan={ header.colSpan }
												sx={ { width: header.getSize() } }
											>
												{ header.isPlaceholder ? null : (
													flexRender(header.column.columnDef.header, header.getContext())
												) }
											</TableCell>
										)) }
									</TableRow>
								)) }
							</TableHead>

							<TableBody>
								{ table.getRowModel().rows.map(row => {
									return (
										<React.Fragment key={ row.id }>
											<TableRow
												id={
													insertItem.target === "group" ?
														(row.depth === 0 && insertItem.name === row.original.grpNm ? "currentInsert" : null) :
														(row.depth === 1 && insertItem.name === row.original.itmNm ? "currentInsert" : null)
												}
											>
												{/*<TableRow id={ row.original.grpNm === insertItem ? "currentInsert" : null }>*/ }
												{ row.getVisibleCells().map((cell, index) => {
													if(row.original.sub) { // group
														if(index === 0 || index === 1 || index === row.getVisibleCells().length - 1) {
															return (
																<TableCell
																	key={ cell.id }
																	variant="secondGroup"
																	colSpan={ index === 1 && row.getVisibleCells().length - 2 || 0 }
																	sx={ {
																		textAlign: cell.column.columnDef.align || "center",
																		paddingX: index === 1 ? 2 : .5,
																	} }
																>
																	{ flexRender(cell.column.columnDef.cell, cell.getContext()) }
																</TableCell>
															);
														}
													} else { // item
														return (
															<TableCell
																key={ cell.id }
																variant="body"
																align={ cell.column.columnDef.align || "center" }
															>
																{ flexRender(cell.column.columnDef.cell, cell.getContext()) }
															</TableCell>
														);
													}
												}) }
											</TableRow>
										</React.Fragment>
									);
								}) }
							</TableBody>
						</Table>
					</TableContainer>
				) }
			</Container>
		</Container>
	);
}