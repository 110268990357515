import { Stack } from "@mui/material";
import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Step1 } from "../../../assets/images/history/01.svg";
import { ReactComponent as Step2 } from "../../../assets/images/history/02.svg";
import { ReactComponent as Step3 } from "../../../assets/images/history/03.svg";
import { ReactComponent as Step4 } from "../../../assets/images/history/04.svg";
import { ReactComponent as Step5 } from "../../../assets/images/history/05.svg";
import { ReactComponent as Step6 } from "../../../assets/images/history/06.svg";
import { ReactComponent as Step7_1 } from "../../../assets/images/history/07_washing.svg";
import { ReactComponent as Step8 } from "../../../assets/images/history/08.svg";
import { LocalShippingRounded } from "@mui/icons-material";
import StepButton from "./StepButton";

function StepBar({ currentStep, setCurrentStep }) {
	const navigate = useNavigate();
	const stepData = useMemo(() => ([
		{ step: 1, component: Step1, text: "가견적\\A요청" },
		{ step: 2, component: Step2, text: "가견적\\A완료" },
		{ step: 3, component: Step3, text: "접수" },
		{ step: 4, component: Step4, text: "입고" },
		{ step: 5, component: Step5, text: "견적\\A완료" },
		{ step: 6, component: Step6, text: "작업\\A대기" },
		{ step: 7, component: Step7_1, text: "작업중" },
		{ step: 0, component: LocalShippingRounded, text: "배송\\A대기" },
		{ step: 8, component: Step8, text: "완료" }
	]), []);

	function onClick(step) {
		if(step !== currentStep) {
			setCurrentStep(step);
			navigate(`/admin/order${ step === 0 ? "/complete" : "/step" + step }`);
		}
	}

	return (
		<Stack
			direction="row"
			justifyContent="space-between"
			alignItems="center"
			pb={ 5 }
			mb={ 3 }
			// spacing={ .5 }
		>
			{ stepData.map(({ step, text, component }, index) => (
				<StepButton
					key={ index }
					text={ text }
					icon={ component }
					isActive={ currentStep === step }
					onClick={ () => onClick(step) }
				/>
			)) }
		</Stack>
	);
}

export default StepBar;