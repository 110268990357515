import React, {
	useCallback, useEffect, useMemo,
	useState,
} from "react";
import { styled } from "@mui/material/styles";
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent, Divider, FormControlLabel, InputLabel, RadioGroup, Stack, TextField,
	Typography,
} from "@mui/material";
import { isEmptyString } from "../../../lib/libs";
import {
	FILTER_INIT,
	FILTER_TYPE,
	FILTER_TYPE_NAME,
	FilterItem,
	FilterSwiperWrap, getFilterData,
} from "../../admin/order/FilterSmall";
import { FreeMode, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import DateRange from "../../public/DateRange";
import BackgroundBox from "../BackgroundBox";
import CheckBox from "../CheckBox";
import RadioButton from "../RadioButton";

export default function FilterModal({
	onSubmit,
	onClose,
	filterData,
	setFilterData,
	enabled = [],
	...props
}) {
	const [filter, setFilter] = useState(filterData);
	const typeNameArray = Object.keys(FILTER_TYPE_NAME);

	useEffect(() => {
		document.querySelector(".swiper").swiper.update();
	}, [filter]);

	async function handledSubmit() {
		onClose();
		await onSubmit(filter);
	}

	function handleClear() {
		setFilter((prev) => ({
			...prev,
			...getFilterData(enabled),
		}));
	}

	function handleClose() {
		onClose();
	}

	const smallItem = useMemo(() => (
		typeNameArray.map((typeName) => {
			const {
				      getData,
				      setData,
				      isEmpty: customIsEmpty,
							isRadio
			      } = FILTER_TYPE[typeName];
			if(!enabled.includes(typeName)) {
				return ;
			}

			const data = getData(filter);
			const isEmpty = customIsEmpty !== undefined ? customIsEmpty(data) : isEmptyString(data);

			if(isEmpty) {
				return;
			}

			return (
				<SwiperSlide key={ `miniFilterItem_${ typeName }` }>
					<FilterItem
						type={ typeName }
						value={ data }
						onClick={ () => {
							if(isRadio) {
								return;
							}

							if(typeName === FILTER_TYPE_NAME.PAYMENT) {
								setData(setFilter, false);
							} else if(typeName === FILTER_TYPE_NAME.DATE_RANGE) {
								setData(setFilter, ["", ""]);
							} else {
								setData(setFilter);
							}
						} }
					/>
				</SwiperSlide>
			);
		})
	), [filter]);

	function isEmptySmallItem() {
		const item = smallItem.filter((item) => item !== undefined);

		return item.length < 1;
	}

	function enabledTypeLength() {
		return typeNameArray.filter((item) => enabled.includes(item)).length;
	}

	return (
		<Dialog
			open
			maxWidth="xs"
			fullWidth
		>
			<Typography variant="dialogTitle">필터</Typography>

			<DialogContent>
				{ !isEmptySmallItem() && (
					<React.Fragment>
						<Box mb={ 2 }>
							<FilterSwiperWrap
								slidesPerView="auto"
								spaceBetween={ 10 }
								modules={ [FreeMode, Pagination] }
								freeMode
							>
								{ smallItem }
							</FilterSwiperWrap>
						</Box>

						<Divider sx={ { mb: 2 } } />
					</React.Fragment>
				) }

				{ typeNameArray.map((typeName, index) => {
					const {
						      label,
						      getData,
						      setData,
							    isRadio,
									radioType,
					      } = FILTER_TYPE[typeName];
					const data = getData(filter);

					if(!enabled.includes(typeName)) {
						return;
					}

					return (
						<Box mb={ 2 } key={ typeName }>
							<Typography variant="dialogContent" mb={ 1 }>{ label }</Typography>

							{ typeName === FILTER_TYPE_NAME.NICKNAME && (
								<TextField
									fullWidth
									value={ data }
									onChange={ ({ target: { value } }) => setData(setFilter, value) }
								/>
							) }

							{ typeName === FILTER_TYPE_NAME.DATE_RANGE && (
								<DateRange
									startDate={ data[0] }
									endDate={ data[1] }
									onChange={ (dateRange) => setData(setFilter, dateRange) }
								/>
							) }

							{ typeName === FILTER_TYPE_NAME.RECEPTION_NUMBER && (
								<TextField
									fullWidth
									type="number"
									value={ data }
									onChange={ ({ target: { value } }) => setData(setFilter, value) }
								/>
							) }

							{ isRadio && (
								<RadioGroup
									row
									value={ data }
									onChange={ ({ target: { value }}) => setData(setFilter, value) }
								>
									{ radioType.map((item, index) => (
										<FormControlLabel
											key={ item }
											label={ item }
											control={ <RadioButton /> }
											value={ index + 1 }
										/>
									)) }
								</RadioGroup>
							) }

							{ enabledTypeLength() > 1 && index < enabledTypeLength() && (
								<Divider sx={ { mt: 2 } } />
							) }
						</Box>
					);
				}) }
			</DialogContent>

			<DialogActions>
				<Button
					size="small"
					variant="outlined"
					color="secondary"
					onClick={ handleClose }
				>
					닫기
				</Button>

				<Divider orientation="vertical" variant="middle" flexItem sx={ { marginLeft: 1 } } />

				<Button
					size="small"
					variant="contained"
					color="secondary"
					onClick={ handleClear }
				>
					필터 초기화
				</Button>

				<Button
					size="small"
					variant="contained"
					onClick={ handledSubmit }
				>
					조회
				</Button>
			</DialogActions>
		</Dialog>
	);
}