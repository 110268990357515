import {
	Box,
	Button,
	DialogContent,
	InputAdornment,
	InputLabel,
	MenuItem,
	Slider,
	Stack,
	Typography,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React, { Fragment } from "react";
import styled from "styled-components";
import { modals } from "../../../hooks/Modals";
import useKakaoMap from "../../../hooks/public/useKakaoMap";
import useModal from "../../../hooks/useModal";
import { FormColumnType, FormDialogType } from "../../../lib/config/formDialogType";
import { setRules } from "../../../lib/config/setFormRules";
import FormCheckbox from "../../common/FormCheckbox";
import FormRangeSlider from "../../common/FormRangeSlider";
import FormTextField from "../../common/FormTextField";

export default function FormModalBody({
	type,
	columnDef,
	onSubmit,
	formId,
	readOnly,
	...useFormRest
}) {
	const {
		      handleSubmit,
		      control,
		      getValues,
		      setValue,
		      ...restProps
	      } = useFormRest;
	const { openModal } = useModal();

	function handlePostCode() {
		openModal(modals.postcodeModal, {
			onSubmit: ({ zoneCode, address }) => {
				setValue("znCd", zoneCode);
				setValue("addr", address);
				setValue("addrDtl", "");
			},
		});
	}

	function getFields() {
		return columnDef.map((def, index) => {
			/*			const {
							fType,
							fEditable     = true,
							fRequired     = false,
							fPk           = false,
							fMaxByte      = false,
							fMinByte      = false,
							fNoSpace      = false,
							fFixByte      = false,
							fDefaultValue = false,
							fSelectItems  = [{ value, label }]
						} = def;*/

			const editable = def.fEditable === undefined || def.fEditable === true;
			const pk = def.fPk !== undefined && def.fPk === true;
			const required = def.fRequired !== undefined && def.fRequired === true;

			if(editable) {
				return (
					<Grid xs={ 12 } sx={ { marginTop: "1rem" } } key={ "formField_" + index }>
						<Stack direction="column">
							<StyledInputLabel
								required={ pk || required }
								htmlFor={ def.id + "Form" }
								shrink
							>
								{ def.header || "" }
							</StyledInputLabel>

							{ def.fType === FormColumnType.TEXT && (
								<FormTextField
									id={ def.id + "Form" }
									name={ def.id }
									size="small"
									control={ control }
									rules={ setRules(def) }
									fullWidth
									trueOnlyShowMsg={ def.helperMsg }
									InputProps={ {
										readOnly: readOnly || def.readOnly,
										endAdornment: def.endLabelText &&
											<InputAdornment position="end">{ def.endLabelText }</InputAdornment>,
										startAdornment: def.startLabelText &&
											<InputAdornment position="start">{ def.startLabelText }</InputAdornment>,
									} }
								/>
							) }

							{ def.fType === FormColumnType.ADDRESS && (
								<Stack direction="row" spacing={ 1.5 }>
									<FormTextField
										id={ def.id + "Form" }
										name={ def.id }
										size="small"
										control={ control }
										rules={ setRules(def) }
										fullWidth
										InputProps={ {
											readOnly: readOnly || def.readOnly,
											endAdornment: def.endLabelText &&
												<InputAdornment position="end">{ def.endLabelText }</InputAdornment>,
											startAdornment: def.startLabelText &&
												<InputAdornment position="start">{ def.startLabelText }</InputAdornment>,
										} }
									/>

									<Button
										fullWidth
										variant="contained"
										size="small"
										sx={ { width: "30%" } }
										onClick={ handlePostCode }
									>
										우편번호 찾기
									</Button>
								</Stack>
							) }

							{ def.fType === FormColumnType.MULTILINE && (
								<FormTextField
									id={ def.id + "Form" }
									name={ def.id }
									size="small"
									control={ control }
									rules={ setRules(def) }
									fullWidth
									multiline
									rows={ 3 }
									trueOnlyShowMsg={ def.helperMsg }
									InputProps={ {
										readOnly: readOnly || def.readOnly,
									} }
								/>
							) }

							{ def.fType === FormColumnType.CHECKBOX && (
								<FormCheckbox
									id={ def.id + "Form" }
									name={ def.id }
									control={ control }
									rules={ setRules(def) }
									readOnly={ readOnly || def.readOnly }
								/>
							) }

							{ def.fType === FormColumnType.SELECT && (
								<FormTextField
									id={ def.id + "Form" }
									name={ def.id }
									size="small"
									control={ control }
									rules={ setRules(def) }
									fullWidth
									select
									trueOnlyShowMsg={ def.helperMsg }
									InputProps={ {
										readOnly: readOnly || def.readOnly,
									} }
								>
									{ def.fSelectItems.map((item, index) => {
										return (
											<MenuItem key={ `${ def.id }_${ index }` } value={ item.value }>
												{ item.label }
											</MenuItem>
										);
									}) }
								</FormTextField>
							) }

							{ def.fType === FormColumnType.NUMBER && (
								<FormTextField
									id={ def.id + "Form" }
									name={ def.id }
									size="small"
									control={ control }
									rules={ setRules(def) }
									fullWidth
									type="number"
									InputProps={ {
										readOnly: readOnly || def.readOnly,
										endAdornment: def.endLabelText &&
											<InputAdornment position="end">{ def.endLabelText }</InputAdornment>,
										startAdornment: def.startLabelText &&
											<InputAdornment position="start">{ def.startLabelText }</InputAdornment>,
									} }
									trueOnlyShowMsg={ def.helperMsg }
								/>
							) }

							{ def.fType === FormColumnType.RANGE_NUMBER && (
								<FormRangeSlider
									id={ def.id + "Form" }
									name={ def.id }
									control={ control }
									rules={ setRules(def) }
									disabled={ readOnly || def.readOnly }
									min={ def?.rangeLimit[0] || 0 }
									max={ def?.rangeLimit[1] || 100 }
									rangeUnit={ def.rangeUnit || ""}
									marks
								/>
							) }
						</Stack>
					</Grid>
				);
			} else {
				return <Fragment key={ "formNothing_" + index } />;
			}
		});
	}

	return (
		<DialogContent>
			<Box
				component="form"
				autoComplete="off"
				onSubmit={ handleSubmit(onSubmit) }
				id={ formId }
			>
				<Grid container>
					{ type.name === FormDialogType.DELETE.name ? (
						<Grid xs={ 12 }>
							<Typography variant="body2">선택한 데이터를 삭제하시겠어요?</Typography>
						</Grid>
					) : (
						<Grid xs={ 12 } container>
							{ getFields() }
						</Grid>
					) }
				</Grid>
			</Box>
		</DialogContent>
	);
}

const StyledInputLabel = styled(InputLabel)(() => ({
	"&.Mui-required": {
		".MuiInputLabel-asterisk": {
			color: "#EE3A85",
			fontWeight: "bold",
		},
	},
}));