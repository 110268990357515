import axios from "../axios";
import qs from "query-string";
import { BRANCH_DEFAULT_URL } from "./defaultUrl";

export async function getBrands() {
    const res = await axios.post(BRANCH_DEFAULT_URL + "/listAll/getBranch");
    return res?.data;
}


export async function getDelivery() {
    const res = await axios.post(BRANCH_DEFAULT_URL + "/listAll/getDelivery");
    return res?.data;
}

export async function insertBranch(requestData) {
    const res = await axios.post(BRANCH_DEFAULT_URL + "/insert", qs.stringify({ ...requestData }));
    return res?.data;
}

export async function updateBranch(requestData) {
    const res = await axios.post(BRANCH_DEFAULT_URL + "/update", qs.stringify({ ...requestData }));
    return res?.data;
}

export async function deleteBranch(requestData) {
    const res = await axios.post(BRANCH_DEFAULT_URL + "/delete", qs.stringify({ ...requestData }));
    return res?.data;
}