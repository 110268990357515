import { Box, Button, Card, CardActions, CardContent, Chip, Skeleton, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useEstimateQuery } from "../../../query/admin/orderQuery";
import { useRequestImages } from "../../../query/getImageQuery";
import EstimateCard from "../../common/EstimateCard";
import ImageUpload from "../../public/ImageUpload";
import CardSubTitle from "./CardSubTitle";
import CardTitle from "./CardTitle";
import ContentField from "./ContentField";

function Step4Card({
	orderId,
	estimateId,
	userId,
	nickname,
	orderType,
	date,
	brandName,
	tentName,
	imagePath,
	requestContent,
	totalAmount,
	receptionNumber,
	branchName,
	repairContent,
	receivingPrice,
}) {
	const [open, setOpen] = useState(false);
	const navigate = useNavigate();
	const {
		      isEstimateLoading,
		      estimateData,
	      } = useEstimateQuery(estimateId, open);
	const {
		      isRequestImagesLoading,
		      requestImagesData,
	      } = useRequestImages(orderId, imagePath, open, true);

	function handleClick() {
		navigate(`/admin/order/fixEstimate/${ estimateId }`);
	}

	return (
		<Card>
			<CardContent>
				<CardTitle
					orderType={ orderType }
					date={ date }
					nickname={ nickname }
					userId={ userId }
				/>

				<CardSubTitle
					receptionNumber={ receptionNumber }
					brandName={ brandName }
					tentName={ tentName }
					branchName={ branchName }
					open={ open }
					setOpen={ setOpen }
				/>

				{ open && (
					<React.Fragment>
						{ isEstimateLoading ? (
							<Skeleton variant="rounded" width="100%" height="100px" />
						) : (
							<EstimateCard
								orderType={ orderType }
								detail={ estimateData }
								total={ totalAmount }
								repairContent={ repairContent }
								receivingPrice={ receivingPrice }
							/>
						) }

						{ !isRequestImagesLoading && requestImagesData.length > 0 && (
							<Box mt={ 2 }>
								<ImageUpload
									readOnly
									images={ requestImagesData }
								/>
							</Box>
						) }

						{ requestContent && (
							<Box mt={ 2 }>
								<ContentField value={ requestContent } />
							</Box>
						) }
					</React.Fragment>
				) }
			</CardContent>

			<CardActions variant="adminStepCard">
				<Button variant="contained" size="small" onClick={ handleClick }>확정 견적 등록</Button>
			</CardActions>
		</Card>
	);
}

export default Step4Card;