import React from "react";
import {
	ButtonBase,
	Stack,
	SvgIcon,
	Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import {
	ReactComponent as Washing
} from "../../assets/images/icon/washing.svg";
import {
	ReactComponent as Repair
} from "../../assets/images/icon/repair.svg";
import { useNavigate } from "react-router-dom";

function EstimateButton({ buttonType, icon, text, onClick }) {
	return (
		<CustomButton
			disableRipple={ true }
			onClick={ onClick }
			className={ buttonType }
		>
			<SvgIcon component={ icon } />

			<Typography>{ text }</Typography>
		</CustomButton>
	);
}

function EstimateButtons() {
	const navigate = useNavigate();

	return (
		<Stack direction="row" spacing={ 2 } mb={ 7 }>
			<EstimateButton
				buttonType="washing"
				icon={ Washing }
				text="세탁견적"
				onClick={ () => navigate("/tent/estimate") }
			/>

			<EstimateButton
				buttonType="repair"
				icon={ Repair }
				text="수선견적"
				onClick={ () => navigate("/tent/repair") }
			/>
		</Stack>
	);
}

const CustomButton = styled(ButtonBase)(({ theme }) => ({
	width: "100%",
	color: theme.palette.text.primary,
	padding: "20px 15px 13px",
	transition: "all .1s ease",
	backgroundColor: "#272727",
	border: `1px solid ${ theme.palette.divider }`,
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	gap: 15,
	"&.washing:hover:not(.active)": {
		backgroundColor: "#392016",
		"& .MuiTypography-root": {
			boxShadow: "0 0 10px 0 rgba(245, 123, 54, 0.50)"
		}
	},
	"&.repair:hover:not(.active)": {
		backgroundColor: "#132719",
		"& .MuiTypography-root": {
			boxShadow: "0 0 10px 0 rgba(47, 167, 74, 0.50)"
		}
	},
	"& .MuiSvgIcon-root": {
		width: "54px",
		height: "52px",
		fill: "none",
		"path": {
			transition: "all .2s ease"
		}
	},
	"& .MuiTypography-root": {
		fontSize: 16,
		fontWeight: 600,
		width: "100%",
		borderRadius: theme.components.MuiButtonBase.styleOverrides.root.borderRadius,
		padding: "6px 0"
	},
	"&.repair .MuiTypography-root": {
		backgroundColor: theme.palette.type.repair
	},
	"&.washing .MuiTypography-root": {
		backgroundColor: theme.palette.type.washing
	}
}));

export default EstimateButtons;