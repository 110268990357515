import React, { useState } from "react";
import {
	Box,
	ClickAwayListener, Stack,
	SvgIcon,
	Tooltip,
} from "@mui/material";
import { ReactComponent as Info } from "../../assets/images/icon/info.svg";

function InfoTooltip({ rmk, defaultState = false, children }) {
	const [open, setOpen] = useState(defaultState);

	function handleOpen() {
		setOpen(true);
	}

	function handleClose() {
		setOpen(false);
	}

	return (
		<ClickAwayListener onClickAway={ handleClose }>
			<Tooltip
				title={ rmk }
				placement="bottom-start"
				PopperProps={ { disablePortal: true } }
				enterDelay={ 80 }
				open={ open }
				onClose={ handleClose }
				disableFocusListener
				disableHoverListener
				disableTouchListener
			>
				<Box
					onClick={ handleOpen }
					sx={{ cursor: "pointer" }}
				>
					<SvgIcon component={ Info } variant="infoIcon" />

					{ children }
				</Box>
			</Tooltip>
		</ClickAwayListener>
	);
}

export default InfoTooltip;