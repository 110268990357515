import {
	Button, ClickAwayListener,
	Container,
	Skeleton,
	Stack, Tooltip,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useQueryClient } from "@tanstack/react-query";
import React, { useEffect, useRef, useState } from "react";
import { AddRounded } from "@mui/icons-material";
import DndBanner from "../../components/admin/banner/DndBanner";
import UpdateBanner from "../../components/admin/UpdateBanner";
import BannerItem from "../../components/admin/banner/BannerItem";
import { Toast } from "../../components/public/Toast";
import { modals } from "../../hooks/Modals";
import useModal from "../../hooks/useModal";
import { USE_BANNER_QUERY_KEY, useBannerQuery, useUpdateOrderMutate } from "../../query/admin/bannerQuery";
import { isMobile } from "react-device-detect";

function Banner() {
	const { isLoading, data } = useBannerQuery();
	const { updateOrderMutate } = useUpdateOrderMutate();
	const [updateOrder, setUpdateOrder] = useState(false);
	const [startNewBanner, setStartNewBanner] = useState(false);
	const [tooltip, setTooltip] = useState(true);
	const orderRef = useRef();
	const { openModal } = useModal();
	const queryClient = useQueryClient();

	useEffect(() => {
		if(startNewBanner) {
			const body = isMobile ? window : document.getElementsByClassName("simplebar-content-wrapper")[0];
			const top = isMobile ? body.outerHeight : body.offsetHeight;

			body.scrollTo({
				top,
				behavior: "smooth",
			});
		}
	}, [startNewBanner]);

	function handleStartNewBanner() {
		setStartNewBanner(true);
	}

	function handleCancelNewBanner() {
		setStartNewBanner(false);
	}

	function handleOrderUpdate() {
		openModal(modals.confirmModal, {
			submitTitle: "순서 저장",
			content: "표출 순서를 저장하시겠어요?",
			onSubmit: async (onClose, setLoadingDone) => {
				const updateOrderItem = orderRef.current?.getUpdateOrderItem(data);

				if(updateOrderItem) {
					updateOrderMutate(updateOrderItem, {
						onSuccess: () => {
							queryClient.invalidateQueries([...USE_BANNER_QUERY_KEY]);
							Toast.success("저장이 완료되었어요.");
							onClose();
							setUpdateOrder(false);
						},
						onError: () => {
							onClose();
							openModal(modals.confirmModal, {
								title: "죄송합니다, 저장이 실패했습니다",
								content: "잠시 후에 다시 시도해주시기 바랍니다.",
								closeTitle: "닫기",
							});
						},
						onSettled: () => {
							setLoadingDone();
						}
					});
				} else {
					Toast.success("저장이 완료되었어요.");
					onClose();
					setUpdateOrder(false);
				}
			}
		});
	}

	function handlePreview() {
		setTooltip(false);

		if(data.length > 0) {
			openModal(modals.noticeModal, {
				bannerList: data.map((item) => ({ id: item.bnnrId, image: item.imgPth })),
				preview: true
			});
		}
	}

	return (
		<Container variant="contextRoot">
			<Container variant="contextBox" sx={{ minHeight: "100vh", position: "relative" }}>
				<Stack direction="row" justifyContent="space-between">
					{ updateOrder ? (
						<Stack direction="row" spacing={ 1 }>
							<Button
								size="small"
								variant="contained"
								color="secondary"
								onClick={ () => setUpdateOrder(false) }
							>
								순서 변경 취소
							</Button>

							<Button
								size="small"
								variant="contained"
								onClick={ handleOrderUpdate }
							>
								순서 저장
							</Button>
						</Stack>
					) : (
						<Button
							size="small"
							variant="contained"
							color="secondary"
							disabled={ startNewBanner || (!isLoading && data.length < 1) }
							onClick={ () => setUpdateOrder(true) }
						>
							순서 변경
						</Button>
					) }

					<Button size="small" variant="contained" disabled={ updateOrder || startNewBanner || isLoading } onClick={ handleStartNewBanner }>
						<AddRounded size="small" sx={ { marginRight: .5 } } />
						새 배너 추가
					</Button>
				</Stack>

				{ isLoading ? (
					<Skeleton variant="rounded" width="100%" height="180px" sx={{ mt: 2 }} />
				) : (
					!updateOrder ? (
						<Stack spacing={ 1.5 } mt={ 2 }>
							{ data?.map((item) => (
								<BannerItem
									key={ item.bnnrId }
									bannerId={ item.bnnrId }
									imagePath={ item.imgPth }
									startDate={ item.strtDtm }
									endDate={ item.endDtm }
									display={ item.dsplyYn === "Y" }
									startNewBanner={ startNewBanner }
								/>
							)) }

							{ startNewBanner && (
								<UpdateBanner
									type="add"
									cancel={ handleCancelNewBanner }
								/>
							) }
						</Stack>
					) : (
						<DndBanner bannerItems={ data } ref={ orderRef } />
					)
				) }

				<ClickAwayListener onClickAway={ () => setTooltip(false) }>
					<div>
						<Tooltip
							arrow
							title="'표출 안 함' 표시된 배너도 함께 보여져요!"
							placement="top"
							PopperProps={ { disablePortal: true } }
							enterDelay={ 80 }
							open={ tooltip }
							onClose={ () => setTooltip(false) }
							disableFocusListener
							disableHoverListener
							disableTouchListener
						>
							<BottomButton variant="contained" size="large" fullWidth disabled={ startNewBanner } onClick={ handlePreview }>미리보기</BottomButton>
						</Tooltip>
					</div>
				</ClickAwayListener>
			</Container>
		</Container>
	);
}

const BottomButton = styled(Button) (() => ({
	position: "sticky",
	bottom: "0",
	margin: "18px 0"
}));

export default Banner;