import React, { useState } from "react";
import { Box, Card, CardContent } from "@mui/material";
import EstimateCard from "../../common/EstimateCard";
import DeliveryInfoCard from "../../public/orderHistoryDetail/DeliveryInfoCard";
import CardSubTitle from "./CardSubTitle";
import CardTitle from "./CardTitle";
import CompleteEstimateCard from "./CompleteEstimateCard";
import DeliveryConfirmIntoCard from "./DeliveryConfirmIntoCard";

function Step8Card({
	userId,
	nickname,
	orderType,
	date,
	brandName,
	tentName,
	receptionNumber,
	branchName,
	cancelFlag,
	total,
	detail,
	deliverDivision,
	deliverDivisionName,
	deliveryCost,
	receivingDeliveryCost,
	deliveryReceptionFlag,
	deliveryName,
	invoiceNumber,
	deliveryType,
	deliveryTypeName,
	deliveryStartDate,
	requestPickUpDate,
	receiveFlag,
	confirmType,
}) {
	const [open, setOpen] = useState(false);

	return (
		<Card>
			<CardContent>
				<CardTitle
					orderType={ orderType }
					date={ date }
					nickname={ nickname }
					userId={ userId }
					cancel={ cancelFlag }
				/>

				<CardSubTitle
					receptionNumber={ receptionNumber }
					brandName={ brandName }
					tentName={ tentName }
					branchName={ branchName }
					open={ open }
					setOpen={ setOpen }
				/>

				{ open && (
					<React.Fragment>
						{/*{ !cancelFlag && (*/}
						{/*	<EstimateCard*/}
						{/*		title="최종 견적"*/}
						{/*		orderType={ orderType }*/}
						{/*		detail={ detail }*/}
						{/*		total={ total }*/}
						{/*		showIcon*/}
						{/*		isComplete*/}
						{/*	/>*/}
						{/*	// <BackgroundBox mt={ 2 }>*/}
						{/*	// 	<Stack direction="row" spacing={ 1 }>*/}
						{/*	// 		<Typography variant="cardBold">견적 합계:</Typography>*/}
						{/*	//*/}
						{/*	// 		<Typography*/}
						{/*	// 			variant="cardBold"*/}
						{/*	// 			sx={ { color: (theme) => theme.palette.type.washing } }*/}
						{/*	// 		>*/}
						{/*	// 			{ (total).toLocaleString() } 원*/}
						{/*	// 		</Typography>*/}
						{/*	// 	</Stack>*/}
						{/*	// </BackgroundBox>*/}
						{/*) }*/}

						{/*<EstimateCard*/}
						{/*	title="최종 견적"*/}
						{/*	orderType={ orderType }*/}
						{/*	detail={ detail }*/}
						{/*	total={ total }*/}
						{/*	showIcon*/}
						{/*	isComplete*/}
						{/*/>*/}

						<CompleteEstimateCard
							orderType={ orderType }
							detail={ detail }
							total={ total }
							deliveryCost={ deliveryCost }
							outputDeliveryCost={ receivingDeliveryCost }
							deliverDivision={ deliverDivision }
						/>

						{/*{ paymentFlag && !deliveryReceptionFlag && (*/}
						{/*	<Box mt={ 2 }>*/}
						{/*		<ReadOnlyDeliveryAddressCard orderId={ orderId } />*/}
						{/*	</Box>*/}
						{/*) }*/}

						<Box mt={ 2 }>
							<DeliveryConfirmIntoCard
								confirmFlag={ receiveFlag }
								confirmTypeName={ (confirmType === "1" || confirmType === "2") ? "수동" : "자동" }
								deliveryType={ deliveryType }
								deliveryTypeName={ deliveryTypeName }
								deliveryDate={ deliveryType === "1" ? deliveryStartDate : requestPickUpDate }
								date={ date }
							/>
						</Box>

						{ deliveryReceptionFlag && (
							<Box mt={ 2 }>
								<DeliveryInfoCard
									date={ deliveryStartDate }
									deliveryName={ deliveryName }
									deliverDivision={ deliverDivision }
									deliverDivisionName={ deliverDivisionName }
									invoiceNumber={ invoiceNumber }
									cost={ deliveryCost }
								/>
							</Box>
						) }

						{/*{ !cancelFlag && !isEmptyString(requestContent) && (*/}
						{/*	<Box mt={ 2 }>*/}
						{/*		<ContentField value={ requestContent } />*/}
						{/*	</Box>*/}
						{/*) }*/}
					</React.Fragment>
				) }
			</CardContent>

			{/*{ paymentFlag && !deliveryReceptionFlag && (*/}
			{/*	<CardActions variant="adminStepCard">*/}
			{/*		<Button variant="contained" size="small" onClick={ handleReceptionDelivery }>배송 접수 완료</Button>*/}
			{/*		<Button variant="contained" size="small" onClick={ handlePickUp }>방문수령</Button>*/}
			{/*	</CardActions>*/}
			{/*) }*/}
		</Card>
	);
}

export default Step8Card;