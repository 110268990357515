import { Box, Skeleton, Stack, Typography } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import React, { useRef, useState } from "react";
import FilterSmall, { FILTER_INIT, FILTER_TYPE_NAME, getFilterData } from "../../../components/admin/order/FilterSmall";
import Step3Card from "../../../components/admin/order/Step3Card";
import { GET_ORDER_STEP, useOrderStepQuery } from "../../../query/admin/orderQuery";

function Step3() {
	const enabled = useRef([FILTER_TYPE_NAME.NICKNAME, FILTER_TYPE_NAME.PICK_UP]);
	const [filter, setFilter] = useState(getFilterData(enabled.current));
	const {
		      isLoading,
		      data,
	      } = useOrderStepQuery(3, filter);
	const queryClient = useQueryClient();

	function submit(filter) {
		setFilter((prev) => ({
			...prev,
			...filter
		}));
	}

	async function refreshQuery() {
		await queryClient.invalidateQueries([...GET_ORDER_STEP, 3, filter]);
	}

	return (
		<Stack spacing={ 1 }>
			<FilterSmall handleSubmit={ submit } enabled={ enabled.current } />

			{ !isLoading && data.length === 0 && (
				<Box py={ 2 }>
					<Typography align="center" variant="context">주문 내역이 없어요</Typography>
				</Box>
			) }

			{ isLoading ? (
				<Skeleton variant="rounded" width="100%" height="200px" />
			) : (
				data.map((item) => (
					<Step3Card
						key={ item.ordrId }
						orderId={ item.ordrId }
						userId={ item.usrId }
						nickname={ item.usrNcknm }
						estimateId={ item.estmtId }
						orderType={ item.ordrTp }
						brandName={ item.brndNm }
						tentName={ item.tntNm }
						date={ item.hstry }
						imagePath={ item.rqstImgPth }
						requestContent={ item.rqstCntnt }
						tentId={ item.tntId }
						totalAmount={ item.ttlAmnt }
						branchId={ item.brnchId }
						branchName={ item.brnchNm }
						repairContent={ item.rprCntnt }
						requestReceivePickUp={ item.rqstRcvngPckUpYn === "Y" }
						refreshQuery={ refreshQuery }
					/>
				))
			) }
		</Stack>
	);
}

export default Step3;