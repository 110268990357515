import { Backdrop } from "@mui/material";
import React from "react";

function BigImageModal({ onClose, imageSrc, width }) {
	return (
		<Backdrop
			open
			onClick={ onClose }
			sx={ {
				overflowY: "scroll",
				cursor: "pointer",
				padding: "40px 0",
				backdropFilter: "blur(1.5px)",
				zIndex: (theme) => theme.zIndex.drawer
			} }
		>
			<img
				src={ imageSrc }
				style={ {
					width: width + "%",
					margin: "auto"
				} }
			/>
		</Backdrop>
	);
}

export default BigImageModal;