import React, { useCallback, useEffect, useRef, useState } from "react";
import {
	Button,
	Container,
	FormControl,
	FormControlLabel,
	Stack,
	TextField,
	Typography,
	Alert,
	ClickAwayListener,
	Tooltip, Box,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { getBranch } from "../../../api/public/estimate2";
import { WeeksBox } from "../../../components/public/estimate/RequestBranch";
import ImageUpload from "../../../components/public/ImageUpload";
import RequestContextField
	from "../../../components/public/RequestContextField";
import { Toast } from "../../../components/public/Toast";
import { Order, setOrder } from "../../../lib/firestore/Order";
import { getCurrentUser, updateUser } from "../../../lib/firestore/User";
import { isEmptyString } from "../../../lib/libs";
import {
	useBrandQuery, useSetOrderMutation,
	useTentQuery
} from "../../../query/public/estimateQuery2";
import StyledSelectBox from "../../../components/public/StyledSelectBox";
import TentAutocomplete
	from "../../../components/public/estimate/TentAutocomplete";
import CheckBox from "../../../components/common/CheckBox";
import Footer2 from "../../../components/public/estimate/Footer2";
import useModal from "../../../hooks/useModal";
import { modals } from "../../../hooks/Modals";

function Repair() {
	// Brand
	const [brandId, setBrandId] = useState("");
	const { brandData } = useBrandQuery(brandId);
	// Tent
	const [tentInfo, setTentInfo] = useState(null);
	const { isTentFetching, tentData } = useTentQuery(brandId);
	// Other tent
	const [checkOtherTent, setCheckOtherTent] = useState(false);
	const [otherTentInfo, setOtherTentInfo] = useState({
		brand: "",
		tent: ""
	});
	const [otherTentHelper, setOtherTentHelper] = useState(false);
	// Etc Request Content
	const [uploadImage, setUploadImage] = useState([]);
	const [etcRequestContent, setEtcRequestContent] = useState("");
	// Request
	const { openModal } = useModal();
	const { setOrderMutate } = useSetOrderMutation();
	const [requestErrorMessage, setRequestErrorMessage] = useState([]);
	const navigate = useNavigate();
	const requiredWeekRef = useRef();
	const userNameRef = useRef();
	const [mainBranchData, setMainBranchData] = useState(null);

	useEffect(() => {
		getRequiredWeekData();
	}, []);

	const getRequiredWeekData = useCallback(async () => {
		const mainBranchData = (await getBranch()).find((item) => item.value === "10");
		const weeks = mainBranchData.week;
		let weeksString = "";

		if(weeks) {
			const weekSplit = weeks.split(",");

			if(weekSplit[0] === weekSplit[1]) {
				weeksString = weekSplit[0];
			} else {
				for(let week of weekSplit) {
					if(weeksString !== "") {
						weeksString += " ~ ";
					}

					weeksString += week;
				}
			}
		}

		requiredWeekRef.current = weeksString || undefined;
		setMainBranchData(mainBranchData);
	}, []);

	function onBrandChange(value) {
		setBrandId(value);
		setTentInfo(null);
	}

	function onTentChange(newValue) {
		setTentInfo(newValue);
		setBrandId(newValue?.brndId || "");
		removeErrorMessage("tentInfo");
	}

	function onOtherTentChange({ target: { checked } }) {
		setCheckOtherTent(checked);

		if(checked) {
			otherTentHelperOpen();
		} else {
			otherTentHelperClose();
			removeErrorMessage("otherTentInfo");
		}
	}

	function onOtherTentInfoChange({ target: { value } }, label) {
		setOtherTentInfo((prev) => ({
			...prev,
			[label]: value
		}));
		removeErrorMessage("otherTentInfo");
	}

	function onEtcRequestContentsChange(value) {
		setEtcRequestContent(value);
		removeErrorMessage("etcRequestContent");
	}

	function otherTentHelperOpen() {
		setOtherTentHelper(true);
	}

	function otherTentHelperClose() {
		setOtherTentHelper(false);
	}

	function addErrorMessage(key, message) {
		removeErrorMessage(key);
		setRequestErrorMessage((prev) => ([
			...prev,
			{ key, message }
		]));
	}

	function removeErrorMessage(key) {
		setRequestErrorMessage((prev) => prev?.filter((item) => item.key !== key));
	}

	async function handleRequestEstimate() {
		if(checkOtherTent) {
			if(!otherTentInfo.tent && !otherTentInfo.brand) {
				addErrorMessage("otherTentInfo", "브랜드나 텐트 명 중 하나를 입력해주세요");
				return;
			}
		} else {
			if(!tentInfo) {
				addErrorMessage("tentInfo", "텐트를 선택해주세요");
				return;
			}
		}

		if(!etcRequestContent) {
			addErrorMessage("etcRequestContent", "요청사항을 입력해주세요");
			return;
		}

		setRequestErrorMessage([]);

		await openChecklist();

		const user = (await getCurrentUser()).data();

		openModal(modals.confirmModal, {
			title: `세탁 의뢰 지점 안내${ isEmptyString(user.name) ? " 및 이름 입력" : "" }`,
			submitTitle: "요청",
			content: (
				<Stack>
					{ isEmptyString(user.name) && (
						<Box mb={ 2 }>
							<TextField
								label="이름"
								fullWidth
								ref={ userNameRef }
								inputProps={ { maxLength: 10 } }
								required
							/>
						</Box>
					) }

					{ requiredWeekRef.current !== undefined && (
						<WeeksBox>
							<Typography variant="cardBold" className="title">{ mainBranchData.label } 예상 소요 기간</Typography>
							<Typography variant="cardBold" className="day">약 { requiredWeekRef.current }주</Typography>
						</WeeksBox>
					) }
				</Stack>
			),
			onSubmit: async (onClose, setLoadingDone) => {
				const userName = userNameRef.current?.getElementsByTagName("input")[0].value;

				if(isEmptyString(user.name)) {
					if(isEmptyString(userName)) {
						setLoadingDone();
						Toast.error("고객님의 성함을 입력해주세요");
						return;
					} else {
						await updateUser({
							name: userName
						});
					}
				}

				openModal(modals.deliveryTypeModal, {
					onSubmit: async (firstClose, setFirstLoadingDone, type) => {
						const order = new Order("", user.uid, user.phoneNumber);
						const formData = new FormData();

						// if(type !== 2) {
						// 	await openGetAddressModal({
						// 		order,
						// 		beforeClose: () => {
						// 			setFirstLoadingDone();
						// 		}
						// 	});
						// }

						await openGetAddressModal({
							order,
							delivery: type !== 2,
							beforeClose: () => {
								setFirstLoadingDone();
							}
						});

						for(let i = 0; i < uploadImage.length; i++) {
							formData.append("files", uploadImage[i]);
						}

						const contentsData = {
							id: user.uid,
							nickname: user.name || userName,
							tentId: tentInfo?.tntId,
							other: checkOtherTent,
							otherTentName: checkOtherTent ? otherTentInfo.tent : "",
							otherBrandName: checkOtherTent ? otherTentInfo.brand : "",
							type: 2,
							requestContent: etcRequestContent,
							deliveryType: type !== 2 ? 1 : 2, // 1: 배송, 2: 방문수령
							deliveryDivision: type === 1 ? 1 : type === 3 ? 2 : "", // 1: 선불배송, 2: 착불배송
						};

            contentsData.branchName = mainBranchData?.label || "";
            contentsData.address = mainBranchData?.addr || "";
            contentsData.addressDetail = mainBranchData?.addrDtl || "";
            contentsData.phoneNumber = user.phoneNumber;
            contentsData.brandName = checkOtherTent ? (contentsData.otherBrandName || "___") : tentInfo.brndNm;
            contentsData.tentName = checkOtherTent ? (contentsData.otherTentName || "___") : tentInfo.tntNm;

						formData.append("contentsData", new Blob([JSON.stringify(contentsData)], { type: "application/json" }));

						setOrderMutate(formData, {
							onSuccess: async ({ orderId }) => {
								order.orderId = orderId;
								await setOrder(orderId, order);

								Toast.success("주문이 완료되었어요");
								navigate(`/tent/orderHistoryDetail/${ orderId }`);
								firstClose();
							},
							onError: () => {
								firstClose();
								openModal(modals.confirmModal, {
									title: "죄송합니다, 주문이 실패했습니다",
									content: "잠시 후에 다시 시도해주시기 바랍니다.",
									closeTitle: "닫기"
								});
							},
							onSettled: () => {
								setFirstLoadingDone();
							}
						});
					},
					beforeClose: () => {
						onClose();
					}
				});
			}
		});
	}

	async function openGetAddressModal({ order, delivery, beforeClose }) {
		return new Promise((resolve) => {
			openModal(modals.addressesModal, {
				title: delivery ? "배송을 희망하는 주소지를 선택해주세요" : "주소지를 선택해주세요",
				submitTitle: "선택",
				closeTitle: "취소",
				content: "주소지 변경은 작업 완료 전까지 가능합니다.",
				selectedFirst: true,
				onSubmit: async (onClose, address) => {
					order.receiverName = address.name;
					order.receiverPhoneNumber = address.phoneNumber;
					order.zoneCode = address.zoneCode;
					order.address = address.address;
					order.addressDetail = address.addressDetail;

					resolve(order);
					onClose();
				},
				beforeClose: () => {
					beforeClose();
				}
			});
		});
	}

	async function openChecklist() {
		return new Promise((resolve) => {
			openModal(modals.checkListModal, {
				onSubmit: (onClose) => {
					resolve();
					onClose();
				}
			});
		});
	}

	return (
		<Container variant="contextRoot">
			<Container variant="contextBox">
				<Stack spacing={ 2 } mb={ 4 }>
					{ checkOtherTent ? (
						<React.Fragment>
							<FormControl fullWidth>
								<TextField
									label="브랜드"
									value={ otherTentInfo.brand }
									onChange={ e => onOtherTentInfoChange(e, "brand") }
									error={ !otherTentInfo.tent && !otherTentInfo.brand }
									helperText={ !otherTentInfo.tent && !otherTentInfo.brand && "브랜드나 텐트 명 중 하나를 입력해주세요" }
								/>
							</FormControl>

							<FormControl fullWidth>
								<TextField
									label="텐트"
									value={ otherTentInfo.tent }
									onChange={ e => onOtherTentInfoChange(e, "tent") }
									error={ !otherTentInfo.brand && !otherTentInfo.tent }
									helperText={ !otherTentInfo.brand && !otherTentInfo.tent && "브랜드나 텐트 명 중 하나를 입력해주세요" }
								/>
							</FormControl>
						</React.Fragment>
					) : (
						<React.Fragment>
							<StyledSelectBox
								title="브랜드"
								items={ brandData }
								onChange={ onBrandChange }
								value={ brandId }
							/>

							<TentAutocomplete
								isLoading={ isTentFetching }
								items={ tentData }
								value={ tentInfo }
								onChange={ onTentChange }
							/>

							{ tentInfo && tentInfo.sz && (
								<FormControl fullWidth>
									<TextField
										label="사이즈"
										value={ tentInfo.sz }
										disabled
									/>
								</FormControl>
							) }

							{ tentInfo && tentInfo.rmk && (
								<FormControl fullWidth>
									<TextField
										label="특이사항"
										value={ tentInfo.rmk }
										disabled
										multiline
									/>
								</FormControl>
							) }
						</React.Fragment>
					) }

					<ClickAwayListener onClickAway={ otherTentHelperClose }>
						<Tooltip
							title="브랜드 및 텐트명을 입력해주세요"
							placement="bottom-end"
							PopperProps={ { disablePortal: true } }
							enterDelay={ 80 }
							open={ otherTentHelper }
							onClose={ otherTentHelperClose }
							disableFocusListener
							disableHoverListener
							disableTouchListener
						>
							<FormControlLabel
								label="내 텐트가 목록에 없어요!"
								value="start"
								labelPlacement="start"
								checked={ checkOtherTent }
								onChange={ onOtherTentChange }
								control={ <CheckBox /> }
							/>
						</Tooltip>
					</ClickAwayListener>
				</Stack>

				<Typography variant="title">텐트 이미지 첨부</Typography>

				<ImageUpload setImage={ setUploadImage } />

				<Box mb={ 4 }>
					<RequestContextField setText={ onEtcRequestContentsChange } />
				</Box>

				{ requestErrorMessage.length > 0 && (
					<Stack spacing={ 1 } mb={ 1 }>
						{ requestErrorMessage.map(({ key, message }) => (
							<Alert key={ key } severity="error">{ message }</Alert>
						)) }
					</Stack>
				) }

				<Button variant="contained" size="large" fullWidth onClick={ handleRequestEstimate }>접수</Button>

				<Footer2 />
			</Container>
		</Container>
	);
}

export default Repair;