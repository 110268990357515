import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import BackgroundBox from "../../common/BackgroundBox";

function Step4AccordionContent() {
	return (
		<BackgroundBox>
			<Typography variant="context">고객님의 텐트가 잘 도착했어요</Typography>
			<Typography variant="context">정확한 견적을 위해 검수 중이에요</Typography>
			<Typography variant="context">견적 완료 시 카카오 알림톡으로 안내해 드리겠습니다</Typography>
		</BackgroundBox>
	);
}

export default Step4AccordionContent;