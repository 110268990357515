import {
  createContext,
  useContext,
  useMemo,
  useState
} from "react";
import Modals from './Modals'

// 현재 open 된 modals
export const FormModalsStateContext = createContext([]);

// modal control 함수
export const FormModalsDispatchContext = createContext({
  open: () => {},
  close: () => {}
});

export const FormModalsProvider = ({ children }) => {
  const [openedModals, setOpenedModals] = useState([]);
  function open(Component, props) {
    setOpenedModals((prevModals) => {
      return [...prevModals, { Component, props }];
    });
  }

  function close(Component) {
    setOpenedModals((prevModals) => {
	    prevModals.pop();

			return [...prevModals];
    });
  }

  const dispatch = useMemo(() => ({ open, close }), []);

  return (
    <FormModalsDispatchContext.Provider value={ dispatch }>
      <FormModalsStateContext.Provider value={ openedModals }>
        { children }
        <Modals />
      </FormModalsStateContext.Provider>
    </FormModalsDispatchContext.Provider>
  );
}

export function useFormModalsState() {
  return useContext(FormModalsStateContext);
}

export function useFormModalsDispatch() {
  return useContext(FormModalsDispatchContext);
}