import { Button, Container, IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import StepBar from "../../../components/admin/order/StepBar";
import { UndoRounded } from "@mui/icons-material";

function OrderLayout() {
	const { pathname } = useLocation();
	const [currentStep, setCurrentStep] = useState(null);
	const navigate = useNavigate();

	useEffect(() => {
		const index = pathname.lastIndexOf("step");
		const step = index < 0 ? 0 : pathname.substring(index + 4);

		setCurrentStep(Number(step));

		localStorage.setItem("currentStep", step);
	}, [pathname]);

	return (
		<Container variant="contextRoot">
			<Container variant="contextBox">
				<StepBar
					setCurrentStep={ setCurrentStep }
					currentStep={ currentStep }
				/>

				<BackButton onClick={ () => navigate("/admin") }>
					<UndoRounded />
				</BackButton>

				<Outlet />
			</Container>
		</Container>
	);
}

const BackButton = styled(IconButton)(() => ({
	position: "fixed",
	bottom: "30px",
	backgroundColor: "rgb(137 137 137 / .5)",
	backdropFilter: "blur(4px)",
	zIndex: 1200,
}));

export default OrderLayout;