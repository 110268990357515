import { styled } from "@mui/material/styles";
import React from "react";
import { isMobile } from "react-device-detect";
import { modals } from "../../hooks/Modals";
import useModal from "../../hooks/useModal";

function BigImage({ src, alwaysOpen = false, width = 100, height }) {
	const { openModal } = useModal();

	function showBigImage() {
		if(!isMobile || alwaysOpen) {
			openModal(modals.imageModal, {
				imageSrc: src,
				width: isMobile ? 80 : 40
			});
		}
	}

	return (
		<Img src={ src } onClick={ showBigImage } defaultwidth={ width } defaultheight={ height } />
	);
}

const Img = styled("img")(({ defaultwidth, defaultheight }) => ({
	position: "relative",
	cursor: !isMobile && "pointer",
	width: `${ defaultwidth }%`,
	height: defaultheight ? `${defaultheight}%` : "auto",
	left: 0,
	// verticalAlign: "bottom",
	transition: "all .2s ease",
	"&:hover": {
		width: !isMobile && `${ defaultwidth + 2 }% !important`,
		left: !isMobile && "-1%"
	}
}));

export default BigImage;