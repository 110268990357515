import styled from "styled-components";
import { LoadingButton } from "@mui/lab";

export default function RoundLoadingButton(props) {
    return <StyledLoadingButton { ...props } />;
}

const StyledLoadingButton = styled(LoadingButton)(() => ({
    "&&&": {
        // borderRadius: "4rem",
        padding: "8.5px 14px"
    }
}));