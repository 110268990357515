import {
	useMutation,
	useQuery,
} from "@tanstack/react-query";
import * as API from "../../api/admin/branch";

export const GET_BRANCH_KEY = ["admin", "getBranch"];
export function useBranchQuery() {
	const {
    isLoading: isBranchLoading,
    isError: isBranchError,
    isSuccess: isBranchSuccess,
    data: branchData,
  } = useQuery(GET_BRANCH_KEY, API.getBrands, {
		retry: 0,
		refetchOnWindowFocus: false,
		select: (data) => {
			return data.map((item) => {
				return {
					...item,
					useYn: item.useYn === "Y",
					pckUpSrvcYn: item.pckUpSrvcYn === "Y",
				};
			});
		},
	});

	return {
		isBranchLoading,
		isBranchError,
		isBranchSuccess,
		branchData,
	};
}

export function useInsertBranchMutation() {
	const { mutate: insertMutate } = useMutation({
		mutationFn: (data) => {
			return API.insertBranch({
				...data,
				useYn: data.useYn ? "Y" : "N",
				pckUpSrvcYn: data.pckUpSrvcYn ? "Y" : "N",
			});
		}
	});

	return {
		insertMutate,
	};
}

export function useUpdateBranchMutation() {
	const { mutate: updateMutate } = useMutation({
		mutationFn: (data) => {
			return API.updateBranch({
				...data,
				useYn: data.useYn ? "Y" : "N",
				pckUpSrvcYn: data.pckUpSrvcYn ? "Y" : "N",
			});
		}
	});

	return {
		updateMutate,
	};
}

export function useDeleteBranchMutation() {
	const { mutate: deleteMutate } = useMutation(API.deleteBranch);

	return {
		deleteMutate,
	};
}