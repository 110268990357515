import { Edit, RemoveCircle } from "@mui/icons-material";
import {
    Button,
    Container,
    IconButton,
    Skeleton,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { createColumnHelper, flexRender, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import React, { useEffect, useMemo, useState } from "react";
import { getDelivery } from "../../api/admin/branch";
import CheckBox from "../../components/common/CheckBox";
import { modals } from "../../hooks/Modals";
import useModal from "../../hooks/useModal";
import { FormColumnType, FormDialogType } from "../../lib/config/formDialogType";
import {
	GET_BRANCH_KEY,
	useBranchQuery,
	useDeleteBranchMutation,
	useInsertBranchMutation,
	useUpdateBranchMutation,
} from "../../query/admin/branchQuery";

export default function Branch() {
  const { openModal } = useModal();
  const { insertMutate } = useInsertBranchMutation();
  const { updateMutate } = useUpdateBranchMutation();
  const { deleteMutate } = useDeleteBranchMutation();
  const queryClient = useQueryClient();
	const [deliveryData, setDeliveryData] = useState([]);

	useEffect(() => {
		async function getDeliveryData() {
			const data = await getDelivery();

			setDeliveryData(data);
		}

		getDeliveryData();
	}, []);

  function getColumnDef() {
    return table.options.columns.reduce((acc, cur) => {
      return [
        ...acc,
        { id: cur.id || cur.accessorKey, ...cur }
      ];
    }, []);
  }

  function addModalOpen() {
    openModal(modals.formModal, {
      type: FormDialogType.ADD,
      columnDef: getColumnDef(),
      onSubmit: async (formData, onClose, showToast, complete) => {
	      formData.rqrdPrdRpr = formData.rqrdPrdRpr.join(",");
	      formData.rqrdPrdWshng = formData.rqrdPrdWshng.join(",");

        insertMutate(formData, {
          onSuccess: ({ cd }) => {
            if(cd === "1") {
              onClose();
              return queryClient.invalidateQueries([...GET_BRANCH_KEY]);
            }
          },
          onSettled: (data, error) => {
            complete();
            showToast({ cd: (error === null) ? data.cd : 0 });
          }
        });
      }
    });
  }

  function EditRowCell({ info, rowData }) {
    function editModalOpen() {
      openModal(modals.formModal, {
        type: FormDialogType.EDIT,
        rowData,
        columnDef: getColumnDef(),
        onSubmit: (formData, onClose, showToast, complete) => {
					formData.rqrdPrdRpr = formData.rqrdPrdRpr.join(",");
					formData.rqrdPrdWshng = formData.rqrdPrdWshng.join(",");

          updateMutate(formData, {
            onSuccess: ({ cd }) => {
              if(cd === "1") {
                onClose();
                return queryClient.invalidateQueries([...GET_BRANCH_KEY]);
              }
            },
            onSettled: (data, error) => {
              complete();
              showToast({ cd: (error === null) ? data.cd : 0 });
            }
          });
        },
      });
    }

    function deleteModalOpen() {
      openModal(modals.formModal, {
        type: FormDialogType.DELETE,
        rowData,
        columnDef: getColumnDef(),
        onSubmit: (formData, onClose, showToast, complete) => {
          deleteMutate(formData, {
            onSuccess: ({ cd }) => {
              if(cd === "1") {
                onClose();
                return queryClient.invalidateQueries([...GET_BRANCH_KEY]);
              }
            },
            onSettled: (data, error) => {
              complete();
              showToast({ cd: (error === null) ? data.cd : 0 });
            }
          });
        }
      });
    }

    return (
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={ .5 }
      >
        <IconButton size="small" onClick={ editModalOpen }>
          <Edit sx={ { color: (theme) => theme.palette.text.secondary } } />
        </IconButton>

        <IconButton size="small" onClick={ deleteModalOpen }>
          <RemoveCircle sx={ { color: (theme) => theme.palette.text.secondary } } />
        </IconButton>
      </Stack>
    );
  }

  const columnDefs = useMemo(() => {
    const ch = new createColumnHelper();

    return [
      ch.accessor("brnchId", {
        header: "지점 ID",
        cell: info => info.getValue(),
        fEditable: false,
        fPk: true
      }),
      ch.accessor("brnchNm", {
        header: "지점",
        cell: info => info.getValue(),
        align: "left",
        fType: FormColumnType.TEXT,
        fRequired: true,
        fMaxByte: 100,
      }),
      ch.accessor("telNo", {
        header: "번호",
        cell: info => info.getValue(),
        align: "left",
	      fType: FormColumnType.TEXT,
	      fRequired: true,
	      fTelNumber: true,
	      fMaxByte: 100,
      }),
      ch.accessor("phnNo", {
        header: "휴대폰 번호",
        cell: info => info.getValue(),
        align: "left",
	      fType: FormColumnType.TEXT,
	      fRequired: true,
	      fTelNumber: true,
	      fMaxByte: 100,
      }),
	    ch.accessor("znCd", {
		    header: "우편번호",
		    cell: info => info.getValue(),
		    align: "left",
		    fType: FormColumnType.ADDRESS,
		    fMaxByte: 5,
		    readOnly: true
	    }),
	    ch.accessor("addr", {
		    header: "주소",
		    cell: info => info.getValue(),
		    align: "left",
		    fType: FormColumnType.TEXT,
		    fMaxByte: 200,
		    readOnly: true
	    }),
	    ch.accessor("addrDtl", {
        header: "상세 주소",
        cell: info => info.getValue(),
        align: "left",
        fType: FormColumnType.TEXT,
	      fMaxByte: 200,
      }),
      ch.accessor("bnkNm", {
        header: "은행",
        cell: info => info.getValue(),
        align: "left",
        fType: FormColumnType.TEXT,
        fMaxByte: 10,
	      endLabelText: "은행",
	      customRules: [{
					name: "bankName",
		      message: "단어 \"은행\"을 포함하지 않은 이름을 입력하세요",
		      fn: (v) => {
						return v.indexOf("은행") < 0;
		      }
	      }]
      }),
      ch.accessor("bnkAccnt", {
        header: "계좌",
        cell: info => info.getValue(),
        align: "left",
        fType: FormColumnType.TEXT,
        fMaxByte: 100,
	      customRules: [{
					name: "account",
		      message: "올바른 계좌번호를 입력하세요",
		      fn: (v) => {
						return /^[0-9\-]+$/.test(v);
		      }
	      }]
      }),
      ch.accessor("bnkAccntHldr", {
        header: "예금주",
        cell: info => info.getValue(),
        align: "left",
        fType: FormColumnType.TEXT,
        fMaxByte: 40,
      }),
      ch.accessor("rqrdPrdWshng", {
        header: "세탁 소요 기간(주)",
        cell: info => info.getValue(),
        align: "left",
        fType: FormColumnType.RANGE_NUMBER,
	      rangeLimit: [0, 15],
	      rangeUnit: "주"
      }),
      ch.accessor("rqrdPrdRpr", {
        header: "수선 소요 기간(주)",
        cell: info => info.getValue(),
        align: "left",
        fType: FormColumnType.RANGE_NUMBER,
	      rangeLimit: [0, 15],
	      rangeUnit: "주"
      }),
      ch.accessor("dlvryId", {
        header: "기본 택배사",
        cell: info => info.getValue(),
        align: "left",
        fType: FormColumnType.SELECT,
	      fSelectItems: deliveryData,
        fMaxByte: 2,
      }),
      ch.accessor("pckUpSrvcYn", {
        header: "수거 서비스 제공",
	      cell: info => <CheckBox readOnly checked={ info.getValue() } />,
        size: 1,
        fType: FormColumnType.CHECKBOX,
        fDefaultValue: false
      }),
      // ch.accessor("pckUpSrvcLctn", {
      //   header: "수거 서비스 지역",
	    //   cell: info => info.getValue(),
      //   size: 1,
      //   fType: FormColumnType.MULTILINE,
	    //   fMaxByte: 500,
	    //   customRules: [{
		  //     name: "pckUpSrvcLctn",
		  //     message: "수거 가능한 서비스 지역을 입력해주세요",
		  //     fn: (v) => {
			// 			const pickUpService = document.getElementById("pckUpSrvcYnForm").checked;
			//
			//       return pickUpService ? v.trim().length > 0 : true ;
		  //     }
	    //   }],
	    //   helperMsg: {
			// 		flag: true,
		  //     msg: "여러 지역인 경우 지역 사이에 띄어쓰기 없이 /로 구분해주세요 (ex. 수원/안양)"
	    //   }
      // }),
      ch.accessor("useYn", {
        header: "사용",
	      cell: info => <CheckBox readOnly checked={ info.getValue() } />,
        size: 1,
        fType: FormColumnType.CHECKBOX,
        fDefaultValue: true
      }),
      ch.display({
        id: "edit",
        header: "편집",
        cell: info => <EditRowCell info={ info } rowData={ info?.row?.original } />,
        size: 1,
        fEditable: false
      }),
    ];
  }, [deliveryData]);

  const {
    isBranchLoading,
    branchData
  } = useBranchQuery();

  const table = useReactTable({
    data: branchData || [],
    columns: columnDefs,
    getCoreRowModel: getCoreRowModel(),
    initialState: {
      columnVisibility: {
        brnchId: false,
	      bnkNm: false,
	      bnkAccnt: false,
	      bnkAccntHldr: false,
	      rqrdPrdWshng: false,
	      rqrdPrdRpr: false,
	      dlvryId: false,
	      addr: false,
	      addrDtl: false,
	      znCd: false,
	      pckUpSrvcYn: false,
        phnNo: false,
	      // pckUpSrvcLctn: false,
      }
    },
  });

  return (
    <Container variant="contextRoot">
      <Container variant="contextBox">
        { isBranchLoading ? (
            <Skeleton variant="rounded" width="100%" height="100%" />
          ) : (
						<React.Fragment>
							<TableContainer sx={{ overflow: "visible" }}>
								<Table>
									<TableHead sx={{ position: "sticky", top: 0, zIndex: 1000 }}>
										{ table.getHeaderGroups().map(headerGroup => (
											<TableRow key={ headerGroup.id }>
												{ headerGroup.headers.map(header => (
													<TableCell
														key={ header.id }
														variant="header"
														colSpan={ header.colSpan }
														sx={{ width: header.getSize() }}
													>
														{ header.isPlaceholder ? null : (
															flexRender(header.column.columnDef.header, header.getContext())
														) }
													</TableCell>
												)) }
											</TableRow>
										)) }
									</TableHead>

									<TableBody>
										{ table.getRowModel().rows.map(row => {
											return (
												<TableRow key={ row.id }>
													{ row.getVisibleCells().map((cell, index) => {
														return index === 0 ? (
															<TableCell
																key={ cell.id }
																variant="firstGroup"
																align={ cell.column.columnDef.align || "center" }
																sx={{ position: "relative" }}
															>
																{ flexRender(cell.column.columnDef.cell, cell.getContext()) }
															</TableCell>
														) : (
															<TableCell
																key={ cell.id }
																variant="body"
																align={ cell.column.columnDef.align || "center" }
															>
																{ flexRender(cell.column.columnDef.cell, cell.getContext()) }
															</TableCell>
														)
													}) }
												</TableRow>
											);
										}) }

										<TableRow
											sx={{
												position: "sticky",
												bottom: 0
											}}
										>
											<TableCell colSpan={ 4 } sx={{ padding: 0 }}>
												<Button variant="contained" size="large" fullWidth onClick={ addModalOpen }>추가</Button>
											</TableCell>
										</TableRow>
									</TableBody>
								</Table>
							</TableContainer>
						</React.Fragment>
        ) }
      </Container>
    </Container>
  )
}