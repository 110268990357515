import qs from "query-string";
import axios from "../axios";
import { BANNER_DEFAULT_URL } from "./defaultUrl";

export async function getData() {
	const res = await axios.post(BANNER_DEFAULT_URL + "/listAll");
	return res?.data;
}

export async function insert(formData) {
	const res = await axios.post(BANNER_DEFAULT_URL + "/insert", formData, {
		headers: { "Content-Type": "multipart/form-data" }
	});
	return res?.data;
}

export async function getImage(imageUrl) {
	const res = await axios.get(`/api/admin/imagePath/${ imageUrl }`, { responseType: "blob" });
	return res?.data;
}

export async function updateOrder(list) {
	const res = await axios.post(BANNER_DEFAULT_URL + "/updateOrder", list, {
		paramsSerializer: (params) => qs.stringify(params)
	});
	return res?.data;
}

export async function deleteBanner(bannerId) {
	const res = await axios.post(BANNER_DEFAULT_URL + "/delete", qs.stringify({ bannerId }));
	return res?.data;
}