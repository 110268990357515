import axios from "../axios";
import qs from "query-string";
import { FIX_ESTIMATE_DEFAULT_URL } from "./defaultUrl";

export async function getThisEstimate(estimateId) {
	const res = await axios.post(FIX_ESTIMATE_DEFAULT_URL + "/getEstimate", qs.stringify({ estimateId }));
	return res?.data;
}

export async function getBrands() {
	const res = await axios.post(FIX_ESTIMATE_DEFAULT_URL + "/listAll/getBrand", {});
	return res?.data;
}

export async function getTent(brndId) {
	const res = await axios.post(FIX_ESTIMATE_DEFAULT_URL + "/listAll/getTent", qs.stringify({ brndId }));
	return res?.data;
}

export async function getEstimate(tntId) {
	const res = await axios.post(FIX_ESTIMATE_DEFAULT_URL + "/listAll/getTentInfo", qs.stringify({ tntId }));
	return res?.data;
}

export async function save(data) {
	const res = await axios.post(FIX_ESTIMATE_DEFAULT_URL + "/save", data, {
		headers: { "Content-Type": "multipart/form-data" }
	});
	return res?.data;
}