import axios from "../axios";
import qs from "query-string";
import { BRAND_DEFAULT_URL } from "./defaultUrl";

export async function getBrands() {
    const res = await axios.post(BRAND_DEFAULT_URL + "/listAll/getBrands", {});
    return res?.data;
}

export async function insertBrand(brandData) {
    const res = await axios.post(BRAND_DEFAULT_URL + "/insert", qs.stringify(brandData));
    return res?.data;
}

export async function updateBrand(brandData) {
    const res = await axios.post(BRAND_DEFAULT_URL + "/update", qs.stringify(brandData));
    return res?.data;
}

export async function deleteBrand(brandData) {
    const res = await axios.post(BRAND_DEFAULT_URL + "/delete", qs.stringify(brandData));
    return res?.data;
}