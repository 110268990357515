import axios from "../axios";
import { SIGN_UP_DEFAULT_URL } from "./defaultUrl";

export async function checkId(oprtrId) {
    const res = await axios.post(SIGN_UP_DEFAULT_URL + "/checkId", { oprtrId });
    return res?.data.cnt;
}

export async function getBranch() {
    const res = await axios.post(SIGN_UP_DEFAULT_URL + "/listAll/getBranch", {});
    return res?.data;
}

export async function signUp(userData) {
    const res = await axios.post(SIGN_UP_DEFAULT_URL + "/signUp", { ...userData });
    return res?.data;
}