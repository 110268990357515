import React from "react";
import {
	Box,
	Card,
	CardMedia,
	Container,
	Link,
	Stack,
	SvgIcon,
	Typography,
	useTheme
} from "@mui/material";
import TopImage from "../../../assets/images/top_img/top_04.jpg";
import { ReactComponent as Step01_ICON } from "../../../assets/images/branch_info/step_01_icon.svg";
import { ReactComponent as Step01_TEXT } from "../../../assets/images/branch_info/step_01_text.svg";
import Step01_BG from "../../../assets/images/branch_info/step_01_bg.png";
import { ReactComponent as Step02_ICON } from "../../../assets/images/branch_info/step_02_icon.svg";
import { ReactComponent as Step02_TEXT } from "../../../assets/images/branch_info/step_02_text.svg";
import Step02_BG from "../../../assets/images/branch_info/step_02_bg.png";
import { ReactComponent as Step03_ICON } from "../../../assets/images/branch_info/step_03_icon.svg";
import { ReactComponent as Step03_TEXT } from "../../../assets/images/branch_info/step_03_text.svg";
import Step03_BG from "../../../assets/images/branch_info/step_03_bg.png";
import { ReactComponent as Step04_ICON } from "../../../assets/images/branch_info/step_04_icon.svg";
import { ReactComponent as Step04_TEXT } from "../../../assets/images/branch_info/step_04_text.svg";
import Step04_BG from "../../../assets/images/branch_info/step_04_bg.png";
import { ReactComponent as Step05_ICON } from "../../../assets/images/branch_info/step_05_icon.svg";
import { ReactComponent as Step05_TEXT } from "../../../assets/images/branch_info/step_05_text.svg";
import Step05_BG from "../../../assets/images/branch_info/step_05_bg.png";
import { ReactComponent as Step06_ICON } from "../../../assets/images/branch_info/step_06_icon.svg";
import { ReactComponent as Step06_TEXT } from "../../../assets/images/branch_info/step_06_text.svg";
import Step06_BG from "../../../assets/images/branch_info/step_06_bg.png";

function getStepImage({ Icon, Background, Text }) {
	return (
		<Card sx={ { borderRadius: "10px 10px 0 0", position: "relative" } }>
			<SvgIcon
				component={ Icon }
				sx={ {
					width: "140px",
					height: "140px",
					position: "absolute",
					left: "calc(50% - 70px)",
					top: "calc(50% - 70px)",
					backdropFilter: "blur(4px)",
					fill: "none"
				} }
			/>

			<CardMedia
				image={ Background }
				sx={ { height: "220px" } }
			/>

			<SvgIcon
				component={ Text }
				sx={ {
					position: "absolute",
					right: "20px",
					bottom: "20px",
					width: "inherit",
					height: "inherit"
				} }
			/>
		</Card>
	);
}

function Questions() {
	function goPage() {
		window.open("https://franchise.ftc.go.kr/mnu/00013/program/userRqst/view.do?firMstSn=148818");
	}

	return (
		<Container variant="contextRoot">
			<Card variant="topImage">
				<CardMedia
					image={ TopImage }
					title="questions"
					sx={ { height: "200px" } }
				/>
			</Card>

			<Container variant="contextBox">
				<Stack direction="column" spacing={ 9 }>
					<Box>
						{ getStepImage({
							Icon: Step01_ICON,
							Background: Step01_BG,
							Text: Step01_TEXT
						}) }

						<Typography variant="context" mt={ 3 }>
							・ 가맹 개설 담당자와 1:1 상담
						</Typography>
						<Typography variant="context" mt={ 1 }>
							・ 가맹 희망서 작성
						</Typography>
					</Box>

					<Box>
						{ getStepImage({
							Icon: Step02_ICON,
							Background: Step02_BG,
							Text: Step02_TEXT
						}) }

						<Typography variant="context" mt={ 3 }>
							・ 전문적인 가맹개설팀에 의한 철저한 상권조사
						</Typography>
						<Typography variant={ "context" } mt={ 1 }>
							・ 분석 / 입지 선정
						</Typography>
					</Box>

					<Box>
						{ getStepImage({
							Icon: Step03_ICON,
							Background: Step03_BG,
							Text: Step03_TEXT
						}) }

						<Typography variant="context" mt={ 3 }>
							・ 본사 내부 검토 후 개설 여부 승인
						</Typography>
						<Typography variant="context" mt={ 1 }>
							・ 개설 점포 실측 확인
						</Typography>
					</Box>

					<Box>
						{ getStepImage({
							Icon: Step04_ICON,
							Background: Step04_BG,
							Text: Step04_TEXT
						}) }

						<Typography variant="context" mt={ 3 }>
							・ 약 7일 내외 소요 (현장 상황에 따라 변동 가능)
						</Typography>
					</Box>

					<Box>
						{ getStepImage({
							Icon: Step05_ICON,
							Background: Step05_BG,
							Text: Step05_TEXT
						}) }

						<Typography variant="context" mt={ 3 }>
							・ 기기 및 장비 입고
						</Typography>
						<Typography variant="context" mt={ 1 }>
							・ 세탁 이론 및 CS 교육
						</Typography>
					</Box>

					<Box>
						{ getStepImage({
							Icon: Step06_ICON,
							Background: Step06_BG,
							Text: Step06_TEXT
						}) }

						<Typography variant="context" mt={ 1 }>
							・ 지점 문의: <Link href="tel:070-4644-0174" underline="none" color="inherit">070-4644-0174</Link>
						</Typography>
						<Typography variant="context" mt={ 1 }>
							・ 공정거래위원회 <Link onClick={ goPage }  color="inherit" sx={{ cursor: "pointer" }}>정보공개서</Link>
						</Typography>
					</Box>
				</Stack>
			</Container>
		</Container>
	);
}

export default Questions;