import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Unstable_Grid2";
import React, { useMemo } from "react";
import {
	Card, CardContent, Chip, Divider, Stack, SvgIcon, Typography,
} from "@mui/material";
import {
	ReactComponent as Repair,
} from "../../../assets/images/history/07_repair.svg";
import {
	ReactComponent as Washing,
} from "../../../assets/images/history/07_washing.svg";

function CompleteEstimateCard({
	orderType,
	detail,
	total,
	deliveryCost = 0,
	deliverDivision,
	outputDeliveryCost = 0,
}) {
	const totalCost = total + deliveryCost + (deliverDivision === "1" ? outputDeliveryCost : 0);
	const serviceTotal = detail.reduce((acc, cur) => {
		return acc + cur.amnt;
	}, 0);
	const components = useMemo(() => ({
		"1": Washing,
		"2": Repair,
	}), []);

	return (
		<Card>
			<CardContent>
				<Stack
					direction="row"
					justifyContent="space-between"
					alignItems="center"
					mb={ 2 }
				>
					<Stack
						direction="row"
						justifyContent="flex-start"
						alignItems="center"
						spacing={ 1 }
					>
						<SvgIcon
							variant="cardIcon"
							component={ components[orderType] }
							sx={ {
								width: "28px",
								height: "28px",
								stroke: (theme) => theme.palette.type[orderType === "1" ? "washing" : "repair"]
							} }
						/>

						<Typography variant="cardBold">최종 견적</Typography>
					</Stack>
				</Stack>

				<Grid container mb={ 2 }>
					{ detail.map(({ itmId, grpNm, itmNm, dcYn, amnt, cnt }) => {
						const name = grpNm ? `[${ grpNm }] ${ itmNm }` : `${ itmNm }`;

						return (
							<React.Fragment key={ name }>
								<Grid xs={ 9 }>
									<Stack
										direction="row"
										alignItems="center"
										spacing={ .8 }
									>
										<Typography variant="cardContext">{ name }{ cnt > 1 && ` - ${ cnt }개` }</Typography>

										{ dcYn === "Y" && (
											<Chip label="실속" variant="outlined" sx={ { height: 18 } } />
										) }
									</Stack>
								</Grid>

								<Grid xs={ 3 }>
									<Typography variant="cardContext" sx={ { textAlign: "right" } }>{ amnt.toLocaleString() } 원</Typography>
								</Grid>
							</React.Fragment>
						);
					}) }

					{ deliveryCost > 0 && (
						<React.Fragment>
							<Grid xs={ 9 }>
								<Stack
									direction="row"
									alignItems="center"
									spacing={ .8 }
								>
									<Typography variant="cardContext">입고 후불 배송비</Typography>
								</Stack>
							</Grid>

							<Grid xs={ 3 }>
								<Typography variant="cardContext" sx={ { textAlign: "right" } }>{ (deliveryCost).toLocaleString() } 원</Typography>
							</Grid>
						</React.Fragment>
					) }

					{ deliverDivision === "1" && outputDeliveryCost > 0 && (
						<React.Fragment>
							<Grid xs={ 9 }>
								<Stack
									direction="row"
									alignItems="center"
									spacing={ .8 }
								>
									<Typography variant="cardContext">수취 선불 배송비</Typography>
								</Stack>
							</Grid>

							<Grid xs={ 3 }>
								<Typography variant="cardContext" sx={ { textAlign: "right" } }>{ (outputDeliveryCost).toLocaleString() } 원</Typography>
							</Grid>
						</React.Fragment>
					) }

					{ orderType === "1" && serviceTotal !== total && (
						<React.Fragment>
							<Grid xs={ 9 }>
								<Stack
									direction="row"
									alignItems="center"
									spacing={ .8 }
								>
									<Typography variant="cardContext">변동</Typography>
								</Stack>
							</Grid>

							<Grid xs={ 3 }>
								<Typography variant="cardContext" sx={ { textAlign: "right" } }>
									{ total < serviceTotal && `- ${ Math.abs(total - serviceTotal).toLocaleString() } 원` }
									{ total > serviceTotal && `+ ${ (total - serviceTotal).toLocaleString() } 원` }
								</Typography>
							</Grid>
						</React.Fragment>
					) }
				</Grid>

				<Divider />

				<Stack
					direction="row"
					justifyContent="flex-end"
					alignItems="center"
					mt={ 2 }
					spacing={ 1 }
				>
					<Typography variant="cardBold" sx={ { color: (theme) => theme.palette.type.washing } }>{ (totalCost).toLocaleString() } 원</Typography>
				</Stack>
			</CardContent>
		</Card>
	);
}

const DetailChip = styled((props) => (
	<Chip
		variant="outlined"
		color="primary"
		{ ...props }
	/>
))(() => ({
	height: 18,
	letterSpacing: 1,
}));

export default CompleteEstimateCard;