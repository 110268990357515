import React, { createContext, useContext, useMemo, useState } from "react";

export const PublicUserStateContext = createContext({
	user: {},
	accessToken: "",
	firebaseToken: "",
	loginFlag: false
});

export const PublicUserDispatchContext = createContext({
	setLoggedIn: () => {},
	setLoggedOut: () => {}
});

export function PublicAuthContextProvider({ children }) {
	const [user, setUser] = useState({
		user: {},
		loginFlag: false
	});

	function setLoggedIn(user) {
		setUser((prev) => ({
			user: {
				...prev.user,
				...user
			},
			loginFlag: true
		}));
	}

	function setLoggedOut() {
		setUser({
			loginFlag: false,
			user: {},
		});
	}

	const dispatch = useMemo(() => ({ setLoggedIn, setLoggedOut }), []);

	return (
		<PublicUserDispatchContext.Provider value={ dispatch }>
			<PublicUserStateContext.Provider value={ user }>
				{ children }
			</PublicUserStateContext.Provider>
		</PublicUserDispatchContext.Provider>
	);
}

export function usePublicUserState() {
	return useContext(PublicUserStateContext);
}

export function usePublicUserDispatch() {
	return useContext(PublicUserDispatchContext);
}