import { Container, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { logout } from "../../api/admin/common";
import RoundLoadingButton from "../../components/common/RoundLoadingButton";
import { useAdminUserState } from "../../hooks/admin/adminAuthContextProvider";
import { modals } from "../../hooks/Modals";
import useModal from "../../hooks/useModal";
import { adminAccessToken } from "../../lib/authToken";
import Role from "../../lib/Role";

function Admin() {
	const navigate = useNavigate();
	const { role } = useAdminUserState();
  const { openModal } = useModal();

  function logoutOnClick() {
    openModal(modals.cardModal, {
      title: "로그아웃",
      content: (
        <Typography variant="body2">로그아웃을 하시겠습니까?</Typography>
      ),
      onSubmit: async (onClose, complete) => {
        await logout();
        adminAccessToken.setToken("");
        window.location.replace("/admin/login");
      },
    });
  }

  return (
    <Container maxWidth="sm" sx={{ padding: "30px", height: "100vh" }}>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        rowSpacing={ 2 }
        sx={ { height: "100%" } }
      >
	      { role === Role.ROLE_ADMIN && (
		      <React.Fragment>
			      <Grid xs={ 12 }>
				      <RoundLoadingButton
					      size="large"
					      variant="contained"
					      component={ Link }
					      to="/admin/brand"
					      fullWidth
				      >
					      브랜드
				      </RoundLoadingButton>
			      </Grid>

			      <Grid xs={ 12 }>
				      <RoundLoadingButton
					      size="large"
					      variant="contained"
					      component={ Link }
					      to="/admin/tent"
					      fullWidth
				      >
					      텐트
				      </RoundLoadingButton>
			      </Grid>

			      <Grid xs={ 12 }>
				      <RoundLoadingButton
					      size="large"
					      variant="contained"
					      component={ Link }
					      to="/admin/amount"
					      fullWidth
				      >
					      가격
				      </RoundLoadingButton>
			      </Grid>
		      </React.Fragment>
	      ) }

        <Grid xs={ 12 }>
          <RoundLoadingButton
            size="large"
            variant="contained"
            component={ Link }
            to="/admin/estimate"
            fullWidth
          >
            견적
          </RoundLoadingButton>
        </Grid>

        <Grid xs={ 12 }>
          <RoundLoadingButton
            size="large"
            variant="contained"
            component={ Link }
            to="/admin/order/step1"
            fullWidth
          >
            주문
          </RoundLoadingButton>
        </Grid>

	      { role === Role.ROLE_ADMIN && (
		      <Grid xs={ 12 }>
			      <RoundLoadingButton
				      size="large"
				      variant="contained"
				      component={ Link }
				      to="/admin/branch"
				      fullWidth
			      >
				      지점
			      </RoundLoadingButton>
		      </Grid>
	      ) }

	      { role === Role.ROLE_ADMIN && (
		      <Grid xs={ 12 }>
			      <RoundLoadingButton
				      size="large"
				      variant="contained"
				      component={ Link }
				      to="/admin/banner"
				      fullWidth
			      >
				      배너
			      </RoundLoadingButton>
		      </Grid>
	      ) }

        <Grid xs={ 12 }>
          <RoundLoadingButton
            size="large"
            variant="contained"
            fullWidth
            onClick={ logoutOnClick }
          >
            로그아웃
          </RoundLoadingButton>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Admin;