import React, { useRef, useState } from "react";
import { Box, Skeleton, Stack, Typography } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import FilterSmall, { FILTER_TYPE_NAME, getFilterData } from "../../../components/admin/order/FilterSmall";
import Step0Card from "../../../components/admin/order/Step0Card";
import { GET_COMPLETE_HISTORY_STEP, useCompleteOrderStep } from "../../../query/admin/orderQuery";

function Step0() {
	const enabled = useRef([FILTER_TYPE_NAME.NICKNAME, FILTER_TYPE_NAME.RECEPTION_NUMBER, FILTER_TYPE_NAME.DELIVERY_TYPE, FILTER_TYPE_NAME.CANCEL]);
	const [filter, setFilter] = useState(getFilterData(enabled.current));
	const {
		      isLoading,
		      data,
	      } = useCompleteOrderStep(filter);
	const queryClient = useQueryClient();

	function submit(filter) {
		setFilter((prev) => ({
			...prev,
			...filter
		}));
	}

	async function refreshQuery() {
		await queryClient.invalidateQueries([...GET_COMPLETE_HISTORY_STEP, filter]);
	}

	return (
		<Stack spacing={ 2 }>
			<FilterSmall handleSubmit={ submit } enabled={ enabled.current } />

			{ !isLoading && data.length === 0 && (
				<Box py={ 2 }>
					<Typography align="center" variant="context">주문 내역이 없어요</Typography>
				</Box>
			) }

			{ isLoading ? (
				<Skeleton variant="rounded" width="100%" height="200px" />
			) : (
				data.map((item) => (
					<Step0Card
						key={ item.ordrId }
						orderId={ item.ordrId }
						userId={ item.usrId }
						nickname={ item.usrNcknm }
						orderType={ item.ordrTp }
						orderTypeName={ item.ordrTpNm }
						brandName={ item.brndNm }
						tentName={ item.tntNm }
						receptionNumber={ item.rcptnNo }
						branchId={ item.brnchId }
						branchName={ item.brnchNm }
						cancelFlag={ item.csStts === "1"}
						total={ item.ttlAmnt }
						deliverDivision={ item.dlvrDvsn }
						deliverDivisionName={ item.dlvrDvsnNm }
						deliveryCost={ item.dlvryCst }
						deliveryReceptionFlag={ item.dlvrRcptYn === "Y" }
						deliveryName={ item.dlvryNm }
						invoiceNumber={ item.invceNo }
						deliveryStartDate={ item.dlvryStrtDtm }
						confirmType={ item.rcvCnfrmTp }
						confirmTypeName={ item.rcvCnfrmTpNm }
						date={ item.hstry }
						deliveryType={ item.dlvryTp }
						deliveryTypeName={ item.dlvryTpNm }
						requestPickUpFlag={ item.rqstPckUpYn === "Y" }
						requestPickUpDate={ item.rqstPckUpDtm }
						receivingDeliveryCost={ item.rcvngDlvryCst }
						refundFlag={ item.rfndYn === "Y" }
						refreshQuery={ refreshQuery }
					/>
				))
			) }
		</Stack>
	);
}

export default Step0;