import { Chip, Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Unstable_Grid2";
import React from "react";

function EstimateItems({ data, shownPrice = true, isComplete, total }) {
	const realPrice = data.reduce((acc, cur) => {
		return acc + cur.amnt;
	}, 0);

	return (
		<Grid container mb={ 1 }>
			{ data.map(({ grpNm, itmNm, dcYn, amnt, cnt }) => {
				const name = grpNm ? `[${grpNm}] ${itmNm}` : `${itmNm}`;

				return (
					<React.Fragment key={ name }>
						<Grid xs={ shownPrice ? 9 : 12 }>
							<Stack
								direction="row"
								alignItems="center"
								spacing={ .8 }
							>
								<Typography variant="cardContext">{ name }{ cnt > 1 && ` - ${ cnt }개` }</Typography>

								{ dcYn === "Y" && (
									<Chip label="실속" variant="outlined" sx={ { height: 18 } } />
								) }
							</Stack>
						</Grid>

						{ shownPrice && amnt && (
							<Grid xs={ 3 }>
								<Typography variant="cardContext" sx={ { textAlign: "right" } }>{ amnt.toLocaleString() } 원</Typography>
							</Grid>
						) }
					</React.Fragment>
				);
			}) }

			{ isComplete && realPrice !== total && (
				<React.Fragment>
					<Grid xs={ 9 }>
						<Typography variant="cardContext">변동</Typography>
					</Grid>

					<Grid xs={ 3 }>
						<Typography variant="cardContext" sx={ { textAlign: "right" } }>
							{ total < realPrice && `- ${ Math.abs(total - realPrice).toLocaleString() } 원` }
							{ total > realPrice && `+ ${ (total - realPrice).toLocaleString() } 원` }
						</Typography>
					</Grid>
				</React.Fragment>
			) }
		</Grid>
	);
}

export default EstimateItems;