import { Box, Button, Dialog, DialogActions, DialogContent, Divider, Link, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useEffect, useRef, useState } from "react";
import {
	Navigation,
	Pagination,
	A11y,
	Autoplay,
} from "swiper";
import {
	Swiper,
	SwiperSlide
} from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/effect-fade";
import { setItem } from "../../../lib/NoticeModal";

function NoticeModal({ onClose, bannerList, preview = false, ...props }) {
	const [init, setInit] = useState(false);
	const dialogRef = useRef();
	const [maxHeight, setMaxHeight] = useState(0);

	function oneDayHide() {
		if(!preview) {
			for(let banner of bannerList) {
				setItem(banner.id);
			}
		}

		onClose();
	}

	useEffect(() => {
		setInit(true);
	}, []);

	useEffect(() => {
		if(init) {
			getMaxHeight();

			async function getMaxHeight() {
				const dialog = dialogRef.current.querySelector(".MuiPaper-root");
				const WIDTH = parseFloat(window.getComputedStyle(dialog).width);

				setMaxHeight(await getImageMaxHeight(WIDTH));
			}
		}
	}, [init]);

	async function getImageMaxHeight(maxWidth) {
		let imageHeights = [];

		for(let { image } of bannerList) {
			const { width, height } = await getImage(image);

			imageHeights.push(height * (maxWidth / width));
		}

		return Math.max(...imageHeights);
	}

	async function getImage(imageSrc) {
		return new Promise((resolve) => {
			const image = new Image();

			image.src = "/api/user/imagePath/" + imageSrc;
			image.onload = () => {
				resolve({
					width: image.width,
					height: image.height
				});
			}
		});
	}

  return (
		<Dialog
			open
			fullWidth
			maxWidth="xs"
			PaperProps={{
				sx: { borderTopLeftRadius: 0, borderTopRightRadius: 0 }
			}}
			ref={ dialogRef }
		>
			<NoticeModalContent>
				<Box>
					<SwiperWrap
						navigation
						loop
						centeredSlides
						autoplay={ {
							delay: 3 * 1000,
							disableOnInteraction: false
						} }
						pagination={ {
							type: "bullets",
							clickable: true
						} }
						modules={ [Autoplay, Pagination, Navigation, A11y] }
						maxheight={ init ? maxHeight : 0 }
					>
						{ bannerList.map(({ id, image }) => (
							<SwiperSlide key={ id }>
								<ImageBox>
									<img src={ `/api/user/imagePath/${ image }` } />
								</ImageBox>
							</SwiperSlide>
						)) }
					</SwiperWrap>
				</Box>
			</NoticeModalContent>

			<NoticeModalActions>
				{/*<Button*/}
				{/*	fullWidth*/}
				{/*	onClick={ oneDayHide }*/}
				{/*>*/}
				{/*	오늘 하루 보지 않기*/}
				{/*</Button>*/}

				<Typography onClick={ oneDayHide } >오늘 하루 보지 않기</Typography>

				<Divider orientation="vertical" variant="middle" flexItem />

				{/*<Button*/}
				{/*	fullWidth*/}
				{/*	onClick={ () => onClose() }*/}
				{/*>*/}
				{/*	닫기*/}
				{/*</Button>*/}
				<Typography onClick={ () => onClose() }>닫기</Typography>
			</NoticeModalActions>
		</Dialog>
  );
}

const NoticeModalContent = styled(DialogContent)(() => ({
	"&&&": {
		padding: 0,
	},
	"& img": {
		display: "block",
		width: "100%",
		height: "auto"
	}
}));

const NoticeModalActions = styled(DialogActions)(({ theme }) => {
	const cardBold = theme.components.MuiTypography.variants.find((item) => item.props.variant === "cardBold").style;

	return {
		"&&&": {
			padding: 0,
			justifyContent: "center",
			"& > *": {
				margin: 0,
				// "&.MuiDivider-root": {
				// 	borderColor: "white"
				// }
			}
		},
		"& > .MuiTypography-root": {
			...cardBold,
			fontWeight: 400,
			width: "100%",
			textAlign: "center",
			cursor: "pointer",
			padding: "15px 0",
			margin: 0,
			backgroundColor: theme.palette.secondary.main,
			transition: "all ease .15s",
			"&:hover": {
				backgroundColor: theme.palette.secondary.dark
			}
		},
	};
});

const SwiperWrap = styled(Swiper)(({ maxheight }) => ({
	height: maxheight || "100%",
	"& .swiper-wrapper": {
		display: "flex",
		alignItems: "center",
	},
	"& .swiper-button-prev, & .swiper-button-next": {
		color: "#EAEAEA",
		opacity: ".8",
		transform: "scale(.8)",
		transition: "all .2s ease",
		"&::after": {
			fontSize: "30px",
			fontWeight: 700
		},
		"&:hover": {
			color: "#E8410B",
			opacity: "1",
			transform: "scale(1)"
		}
	},
	"& .swiper-pagination": {
		"&.swiper-pagination-fraction": {
			width: "auto",
			fontSize: "14px",
			color: "#B9B9B9",
			lineHeight: "22px",
			position: "absolute",
			left: "auto",
			right: 0,
			padding: "2px 10px",
			backgroundColor: "#00000057",
			borderRadius: "20px",
			marginRight: "10px"
		},
		"& .swiper-pagination-bullet": {
			backgroundColor: "#fff",
			opacity: ".3"
		},
		"& .swiper-pagination-bullet.swiper-pagination-bullet-active": {
			backgroundColor: "#E8410B",
			opacity: "1",
			border: "1px solid rgba(255, 255, 255, .2)"
		}
	}
}));

const ImageBox = styled(Box)(() => ({
	width: "100%",
	height: "100%",
	display: "flex",
	alignItems: "center"
}));

export default NoticeModal;