// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
	apiKey: "AIzaSyAarvouB27g9RJX4eDKkxtMqr3FiQ3qIYQ",
	authDomain: "web-otcrs-33d7c.firebaseapp.com",
	projectId: "web-otcrs-33d7c",
	storageBucket: "web-otcrs-33d7c.appspot.com",
	messagingSenderId: "372420279692",
	appId: "1:372420279692:web:97a15391e9ce351bc4eaa9"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const firebaseAuth = getAuth(app);
export const firebaseDB = getFirestore(app);