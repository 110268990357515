import { Card, CardContent, Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Unstable_Grid2";
import React from "react";
import { LocalShippingRounded } from "@mui/icons-material";

function DeliveryInfoCard({ date, deliveryName, invoiceNumber, deliverDivision, deliverDivisionName, cost }) {
	return (
		<Card>
			<CardContent>
				<Stack
					direction="row"
					alignItems="center"
					justifyContent="space-between"
					mb={ 2 }
				>
					<Stack direction="row" spacing={ 1 }>
						<LocalShippingRounded fill="none" />
						<Typography variant="cardBold">배송 정보</Typography>
					</Stack>
				</Stack>

				<Grid container>
					<React.Fragment>
						<Grid xs={ 3 }>
							<Typography variant="cardContext" type="title">택배사</Typography>
						</Grid>

						<Grid xs={ 9 }>
							<Typography variant="cardContext">{ deliveryName }</Typography>
						</Grid>
					</React.Fragment>

					<React.Fragment>
						<Grid xs={ 3 }>
							<Typography variant="cardContext" type="title">송장번호</Typography>
						</Grid>

						<Grid xs={ 9 }>
							<Typography variant="cardContext">{ invoiceNumber }</Typography>
						</Grid>
					</React.Fragment>

					<React.Fragment>
						<Grid xs={ 3 }>
							<Typography variant="cardContext" type="title">배송비 지불</Typography>
						</Grid>

						<Grid xs={ 9 }>
							<Typography variant="cardContext">{ deliverDivisionName } { deliverDivision !== "3" && `(${ (cost).toLocaleString() }원)`}</Typography>
						</Grid>
					</React.Fragment>

					{/*<React.Fragment>*/}
					{/*	<Grid xs={ 3 }>*/}
					{/*		<Typography variant="cardContext" type="title">배송 시작일</Typography>*/}
					{/*	</Grid>*/}

					{/*	<Grid xs={ 9 }>*/}
					{/*		<Typography variant="cardContext">{ date }</Typography>*/}
					{/*	</Grid>*/}
					{/*</React.Fragment>*/}
				</Grid>
			</CardContent>
		</Card>
	);
}

export default DeliveryInfoCard;