import React from "react";
import image1 from "../../assets/images/main_bg/bg_01.png";
import image2 from "../../assets/images/main_bg/bg_02.png";
import image3 from "../../assets/images/main_bg/bg_03.png";
import image4 from "../../assets/images/main_bg/bg_04.png";

function useRandomMainImage() {
	const backgroundImages = [image1, image2, image3, image4];
	const randomIndex = Math.floor(Math.random() * backgroundImages.length);

	return backgroundImages[randomIndex];
}

export default useRandomMainImage;