import { Container, Divider, Typography } from "@mui/material";
import React, { useEffect, useRef } from "react";
import UserAddress from "../../components/public/profile/UserAddress";
import UserInfoCard from "../../components/public/profile/UserInfoCard";
import { usePublicUserDispatch, usePublicUserState } from "../../hooks/public/publicAuthContextProvider";
import { getRealtimeUser } from "../../lib/firestore/User";

function UserProfile() {
	const { user } = usePublicUserState();
	const { setLoggedIn } = usePublicUserDispatch();
	const unsubscribeRef = useRef(null);

	useEffect(() => {
		getRealtimeUser(unsubscribeRef, (userData) => {
			setLoggedIn(userData);
		});

		return () => {
			unsubscribeRef.current?.();
		};
	}, []);

	return (
		<Container variant="contextRoot">
			<Container variant="contextBox">
				<Typography variant="title" mb={ 7 }>{ user.nickname } 고객님, 안녕하세요!</Typography>

				<Typography variant="subTitle" mb={ 2 }>계정 관리</Typography>
				<UserInfoCard />

				<Divider sx={{ marginY: 4 }} />

				<Typography variant="subTitle" mb={ 2 }>배송지 관리</Typography>
				<UserAddress />
			</Container>
		</Container>
	);
}

export default UserProfile;