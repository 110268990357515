import React, { useMemo } from "react";
import {
	Box,
	Card,
	CardMedia,
	Container,
	Divider,
	Stack,
	Typography,
} from "@mui/material";
import TopImage from "../../../assets/images/top_img/top_02.jpg";
import Grid from "@mui/material/Unstable_Grid2";
import HistoryContents from "../../../components/public/HistoryContents";

function History () {
	const historyContents = useMemo(() => ([
		{
			year: 2023,
			hist: [
				{ month: 1, text: "용인점 신규 입점" },
				{ month: 5, text: "벤처 확인 기업 인증" },
				{ month: 7, text: "텐트세탁소 웹 출시" },
			],
		},
		{
			year: 2022,
			hist: [
				{ month: 7, text: "폴리, 면 통합 발수제 출시" },
				{ month: 12, text: "남양주 2호점 신규 입점" },
			],
		},
		{
			year: 2021,
			hist: [
				{ month: 6, text: "평택점 신규 입점" },
			],
		},
		{
			year: 2020,
			hist: [
				{ month: 9, text: "면전용 발수제 개발" },
				{ month: 9, text: "MBC 오늘저녁 JOB학사전 출연" },
			],
		},
		{
			year: 2019,
			hist: [
				{ month: 4, text: "남양주 1호점 신규 입점" },
				{ month: 5, text: "매거진 더카라반 텐트세탁소 소개" },
				{ month: 9, text: "KBS 생생 정보통 출연" },
			],
		},
		{
			year: 2017,
			hist: [
				{ month: 3, text: "극한직업 방송 출연" },
				{ month: 3, text: "KBS2 VJ특공대 출연" },
				{ month: 3, text: "MBC 오늘저녁 대박의 신 출연" },
			],
		},
		{
			year: 2016,
			hist: [
				{ month: 1, text: "아웃도어 전용 세재 개발" },
				{ month: 10, text: "채널A 서민갑부 출연" },
			],
		},
		{
			year: 2015,
			hist: [
				{ month: 1, text: "㈜마운틴티씨에스 법인 전환" },
				{ month: 1, text: "폴리 전용 발수제 개발 및 서비스 개시" },
				{ month: 10, text: "KBS2 VJ특공대 출연" },
				{ month: 11, text: "KBS 생생정보통 출연" },
			],
		},
		{
			year: 2014,
			hist: [
				{ month: 8, text: "YTN ‘황금나침반’ 출연" },
				{ month: 8, text: "KBS2 VJ특공대 출연" },
				{ month: 11, text: "MBC 경제메거진M 출연" },
				{ month: 12, text: "텐트세탁소 상표 등록" },
			],
		},
		{
			year: 2013,
			hist: [
				{ month: 2, text: "마운틴 TCS 설립" },
				{ month: 8, text: "텐트 세척 방법 및 세척 설비에 관한 특허 출헌" },
				{ month: 8, text: "SBS cnbc ‘소상공인의눈’ 출연" },
				{ month: 11, text: "KBS2 ‘굿모닝 대한민국’ 출연" },
			],
		},
		{
			year: 2012,
			hist: [
				{ month: 10, text: "네이버 카페 오픈" },
			],
		},
	]), []);

	return (
		<Container variant="contextRoot">
			<Card variant="topImage">
				<CardMedia
					image={ TopImage }
					title="마운틴티씨에스"
					sx={ { height: "200px" } }
				/>
			</Card>

			<Container variant="contextBox">
				<Typography variant="title">HISTORY</Typography>


				<Stack direction="column" sx={ { marginLeft: "52px" } } spacing={ 5 }>
					{ historyContents.map(({ year, hist }, index) => (
						<React.Fragment key={ year }>
							<HistoryContents year={ year } hist={ hist } />

							{ index < historyContents.length - 1 && <Divider /> }
						</React.Fragment>
					)) }
				</Stack>
			</Container>
		</Container>
	);
}

export default History;