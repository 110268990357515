import { Box, Button, Card, CardActions, CardContent, Chip, Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useQueryClient } from "@tanstack/react-query";
import React, { useCallback, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { getImage as getImageData } from "../../../api/admin/banner";
import { modals } from "../../../hooks/Modals";
import useModal from "../../../hooks/useModal";
import { USE_BANNER_QUERY_KEY, useDeleteBannerMutate } from "../../../query/admin/bannerQuery";
import { Toast } from "../../public/Toast";
import UpdateBanner from "../UpdateBanner";

function BannerItem({ startNewBanner, bannerId, imagePath, startDate, endDate, display }) {
	const [startUpdate, setStartUpdate] = useState(false);
	const [thumbnail, setThumbnail] = useState();
	const { deleteBannerMutate } = useDeleteBannerMutate();
	const queryClient = useQueryClient();
	const { openModal } = useModal();

	useEffect(() => {
		getImage();
	}, [imagePath]);

	const getImage = useCallback(async () => {
		const blob = await getImageData(imagePath);
		const reader = new FileReader();

		reader.onloadend = () => {
			setThumbnail(reader.result);
		};

		reader.readAsDataURL(blob);
	}, [imagePath]);

	function handleImageClick() {
		if(thumbnail) {
			openModal(modals.imageModal, {
				imageSrc: thumbnail,
				width: isMobile ? 80 : 40,
			});
		}
	}

	function handleStartUpdate() {
		setStartUpdate(true);
	}

	function handleCancelUpdate() {
		setStartUpdate(false);
	}

	function handleDelete() {
		openModal(modals.confirmModal, {
			submitTitle: "삭제",
			content: "배너를 삭제하시겠어요?",
			onSubmit: (onClose, setLoadingDone) => {
				deleteBannerMutate(bannerId, {
					onSuccess: () => {
						queryClient.invalidateQueries([...USE_BANNER_QUERY_KEY]);
						Toast.success("삭제가 완료되었어요.");
						onClose();
					},
					onError: () => {
						onClose();
						openModal(modals.confirmModal, {
							title: "죄송합니다, 삭제가 실패했습니다",
							content: "잠시 후에 다시 시도해주시기 바랍니다.",
							closeTitle: "닫기",
						});
					},
					onSettled: () => {
						setLoadingDone();
					}
				});
			}
		});
	}

	return (
		!startUpdate ? (
			<Card>
				<CardContent>
					<Stack direction="row" spacing={ 1.5 }>
						<ImageBox>
							{ thumbnail && (
								<Image src={ thumbnail } onClick={ handleImageClick } />
							) }
						</ImageBox>

						<Stack
							spacing={ 1 }
							justifyContent="space-evenly"
							alignItems="flex-start"
						>
							<Box>
								<Typography variant="cardContext" type="title">노출 기간</Typography>
								<Typography variant="cardContext">{ startDate } ~ { endDate }</Typography>
							</Box>

							<Chip
								label={ display ? "표출 함" : "표출 안 함" }
								color={ display ? "primary" : "secondary" }
								variant="outlined"
							/>
						</Stack>
					</Stack>
				</CardContent>

				<CardActions variant="adminStepCard">
					<Button size="small" variant="outlined" color="secondary" disabled={ startNewBanner } onClick={ handleDelete }>삭제</Button>
					<Button size="small" variant="contained" color="secondary" disabled={ startNewBanner } onClick={ handleStartUpdate }>수정</Button>
				</CardActions>
			</Card>
		) : (
			<UpdateBanner
				type="update"
				cancel={ handleCancelUpdate }
				initData={{
					bannerId,
					startDate,
					endDate,
					display,
					imagePath,
					imageThumbnail: thumbnail
				}}
			/>
		)
	);
}

const ImageBox = styled(Box)(({ theme }) => ({
	position: "relative",
	minWidth: 100,
	maxWidth: 100,
	height: 100,
	backgroundColor: "#292B2B",
	borderWidth: 1,
	borderStyle: "solid",
	borderColor: theme.palette.divider,
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
}));

const Image = styled(Box)(({ src }) => ({
	width: "100%",
	height: "100%",
	background: `url(${ src })`,
	backgroundRepeat: "no-repeat",
	backgroundSize: "contain",
	backgroundPosition: "center",
	cursor: src ? "pointer" : "default"
}));

export default BannerItem;