import { useTheme } from "@mui/material";
import { styled } from "@mui/material/styles";
import { toast, ToastContainer } from "react-toastify";
import { isMobile } from "react-device-detect";
import { PriorityHighRounded, CheckRounded } from "@mui/icons-material";

export const StyledToastContainer = styled(ToastContainer)(() => {
	const { typography: { fontFamily } } = useTheme();

	return ({
		padding: isMobile && "0 30px 30px",
		"&.Toastify__toast-container--bottom-center": {
			bottom: !isMobile && 0,
		},
		"& .Toastify__toast:not(:last-child)": {
			marginBottom: isMobile ? 10 : 8,
		},
		"& .Toastify__toast": {
			fontFamily: fontFamily,
			fontSize: 15,
			borderRadius: ".5rem",
			minHeight: 50,
			"& .Toastify__toast-icon": {
				width: "fit-content",
				borderRadius: 5,
				backgroundColor: "rgba(255, 255, 255, .25)",
				"& .MuiSvgIcon-root": {
					transform: "scale(.8)",
				},
			},
			"&.Toastify__toast-theme--colored": {
				backdropFilter: "blur(5px)",
				"&.Toastify__toast--info": {
					background: `rgba(52, 152, 219, ${ isMobile ? .6 : .9 })`,
					"& .Toastify__toast-icon .MuiSvgIcon-root": {
						transform: "scale(.8) rotate(180deg)"
					},
				},
				"&.Toastify__toast--success": {
					background: `rgba(80, 190, 83, ${ isMobile ? .6 : .9 })`,
				},
				"&.Toastify__toast--error": {
					background: `rgba(211, 97, 85, ${ isMobile ? .6 : .9 })`,
				},
			},
		},
	});
});

const defaultOptions = {
	position: "bottom-center",
	autoClose: 3000,
	// autoClose: false,
	// limit: 1,
	hideProgressBar: true,
	newestOnTop: true,
	closeOnClick: true,
	pauseOnFocusLoss: false,
	draggable: false,
	pauseOnHover: true,
	progress: undefined,
	theme: "colored",
	closeButton: false,
};

export const Toast = {
	info: (message, option = {}) => {
		toast.info(message, {
			...defaultOptions,
			...option,
			icon: <PriorityHighRounded />,
		});
	},
	success: (message, option = {}) => {
		toast.success(message, {
			...defaultOptions,
			...option,
			icon: <CheckRounded />,
		});
	},
	error: (message, option = {}) => {
		toast.error(message, {
			...defaultOptions,
			...option,
			icon: <PriorityHighRounded />,
		});
	},
};