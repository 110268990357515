const KEY = "estimate";
const SAVE_DAY = 7;

export function setItem({ tentInfo, select, dcYn, total }) {
	let beforeItems = getData();

	if(beforeItems.length) {
		const nowDate = new Date();

		setSort(beforeItems);
		// 같은 텐트, 7일 이상 된 내역 제외
		beforeItems = beforeItems.filter(({ date, tentId }) => {
			const oldDate = new Date(date);
			const diff = Math.abs(nowDate.getTime() - oldDate.getTime());
			const dayDiff = diff / (1000 * 60 * 60 * 24);

			return !(tentId === tentInfo.tntId || dayDiff > SAVE_DAY);
		});

		if(beforeItems.length === 3) {
			beforeItems.pop(); // 3개 이상 시 가장 오래 된 데이터 제외
		}
	}

	beforeItems.unshift({
		date: getDateTime(),
		tentId: tentInfo.tntId,
		tentInfo,
		select,
		dcYn,
		total
	});
	setData(beforeItems);
}

export function deleteItem(tentId) {
	const beforeItems = getData().filter((item) => item.tentId !== tentId);

	setData(beforeItems);
}

export function getItems() {
	return getData();
}

export function getItem(tentId) {
	return getData().filter((item) => item.tentId === tentId)[0];
}

function getData() {
	return JSON.parse(localStorage.getItem(KEY) || "[]");
}

function setData(items) {
	localStorage.setItem(KEY, JSON.stringify(items));
}

function getDateTime(now = new Date()) {
	const year = now.getFullYear();
	const month = now.getMonth() + 1;
	const day = now.getDate();
	const hours = now.getHours();
	const minutes = now.getMinutes();
	const seconds = now.getSeconds();

	return `${year}-${_(month)}-${_(day)} ${_(hours)}:${_(minutes)}:${_(seconds)}`;
}

function _(number) {
	return number.toString().padStart(2, "0");
}

function setSort(items) {
	items.sort((a, b) => new Date(b.date) - new Date(a.date));
}