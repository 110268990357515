import { Box, Button, Card, CardActions, CardContent, Chip, Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useCallback, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { getImage as getImageData } from "../../../api/admin/banner";
import { modals } from "../../../hooks/Modals";
import useModal from "../../../hooks/useModal";
import { isEmptyString } from "../../../lib/libs";

function GhostBannerItem({ imagePath, startDate, endDate, display, enabled, ...props }) {
	const [thumbnail, setThumbnail] = useState();

	useEffect(() => {
		if(!isEmptyString(imagePath)) {
			getImage();
		}
	}, [imagePath]);

	const getImage = useCallback(async () => {
		const blob = await getImageData(imagePath);
		const reader = new FileReader();

		reader.onloadend = () => {
			setThumbnail(reader.result);
		};

		reader.readAsDataURL(blob);
	}, [imagePath]);

	if(!enabled) {
		return ;
	}

	return (
		<Wrap { ...props }>
			<CardContent>
				<Stack direction="row" spacing={ 1.5 }>
					<ImageBox>
						{ thumbnail && (
							<Image src={ thumbnail } />
						) }
					</ImageBox>

					<Stack
						spacing={ 1 }
						justifyContent="space-evenly"
						alignItems="flex-start"
					>
						<Box>
							<Typography variant="cardContext" type="title">노출 기간</Typography>
							<Typography variant="cardContext">{ startDate } ~ { endDate }</Typography>
						</Box>

						<Chip
							label={ display ? "표출 함" : "표출 안 함" }
							color={ display ? "primary" : "secondary" }
							variant="outlined"
						/>
					</Stack>
				</Stack>
			</CardContent>
		</Wrap>
	);
}

const Wrap = styled(Card)(({ theme }) => ({
	border: `2px solid ${theme.palette.primary.main}`,
	position: "absolute",
	left: 0,
	opacity: .3,
	userSelect: "none",
}));

const ImageBox = styled(Box)(({ theme }) => ({
	position: "relative",
	minWidth: 100,
	maxWidth: 100,
	height: 100,
	backgroundColor: "#292B2B",
	borderWidth: 1,
	borderStyle: "solid",
	borderColor: theme.palette.divider,
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
}));

const Image = styled(Box)(({ src }) => ({
	width: "100%",
	height: "100%",
	background: `url(${ src })`,
	backgroundRepeat: "no-repeat",
	backgroundSize: "contain",
	backgroundPosition: "center",
	cursor: src ? "pointer" : "default"
}));

export default GhostBannerItem;