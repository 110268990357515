import { Box, Button, Card, CardActions, CardContent, Chip, Divider, Stack, Tooltip, Typography } from "@mui/material";
import React, { useState } from "react";
import { modals } from "../../../hooks/Modals";
import useModal from "../../../hooks/useModal";
import { isEmptyString } from "../../../lib/libs";
import {
	useSetPaymentCompleteMutation,
	useSetWorkingStartMutation,
} from "../../../query/admin/orderQuery";
import { useEstimateFixImages, useRequestImages } from "../../../query/getImageQuery";
import CompareEstimateCard from "../../common/CompareEstimateCard";
import ImageUpload from "../../public/ImageUpload";
import { Toast } from "../../public/Toast";
import CardSubTitle from "./CardSubTitle";
import CardTitle from "./CardTitle";
import ContentField from "./ContentField";

function Step6Card({
	orderId,
	userId,
	nickname,
	orderType,
	date,
	brandName,
	tentName,
	imagePath,
	requestContent,
	estimateFixImagePath,
	estimateFixContent,
	totalAmount,
	receptionNumber,
	details,
	deliveryCost,
	outputDeliveryCost,
	paymentComplete,
	branchName,
	refreshQuery
}) {
	const [open, setOpen] = useState(false);
	const {
		      isRequestImagesLoading,
		      requestImagesData,
	      } = useRequestImages(orderId, imagePath, open, true);
	const {
		      isEstimateFixImagesLoading,
		      estimateFixImagesData,
	      } = useEstimateFixImages(orderId, estimateFixImagePath, open, true);
	const { openModal } = useModal();
	const { mutate } = useSetPaymentCompleteMutation();
	const { startWorkingMutate } = useSetWorkingStartMutation();

	function handlePaymentComplete() {
		openModal(modals.confirmModal, {
			title: "결제 확인",
			submitTitle: "확인",
			content: (
				<Typography
					variant="context"
					sx={{
						"& > strong": {
							fontSize: 17
						}
					}}
				>
					입금자 명 <strong>"{ nickname }"</strong> 님으로 <strong>{ (totalAmount + deliveryCost + outputDeliveryCost).toLocaleString() }원</strong>이 입금된 것을 확인하셨나요?
				</Typography>
			),
			onSubmit: async (onClose, setLoadingDone) => {
				mutate(orderId, {
					onSuccess: () => {
						Toast.success("저장이 완료되었어요");
						refreshQuery();
						// queryClient.invalidateQueries([...GET_HISTORY_STEP, 6]);
						onClose();
					},
					onError: () => {
						onClose();
						openModal(modals.confirmModal, {
							title: "죄송합니다, 저장이 실패했습니다",
							content: "잠시 후에 다시 시도해주시기 바랍니다.",
							closeTitle: "닫기"
						});
					},
					onSettled: () => {
						setLoadingDone();
					}
				});
			}
		});
	}

	function handleStartWorking() {
		openModal(modals.confirmModal, {
			submitTitle: "작업 시작",
			content: (
				<Typography variant="context" >작업을 시작하시겠어요?</Typography>
			),
			onSubmit: async (onClose, setLoadingDone) => {
				startWorkingMutate(orderId, {
					onSuccess: () => {
						Toast.success("저장이 완료되었어요");
						// queryClient.invalidateQueries([...GET_HISTORY_STEP, 6]);
						refreshQuery();
						onClose();
					},
					onError: () => {
						onClose();
						openModal(modals.confirmModal, {
							title: "죄송합니다, 저장이 실패했습니다",
							content: "잠시 후에 다시 시도해주시기 바랍니다.",
							closeTitle: "닫기"
						});
					},
					onSettled: () => {
						setLoadingDone();
					}
				});
			}
		});
	}

	return (
		<Card>
			<CardContent>
				<CardTitle
					orderType={ orderType }
					date={ date }
					nickname={ nickname }
					userId={ userId }
				/>

				<CardSubTitle
					receptionNumber={ receptionNumber }
					brandName={ brandName }
					tentName={ tentName }
					branchName={ branchName }
					open={ open }
					setOpen={ setOpen }
				/>

				{ open && (
					<React.Fragment>
						<Box mb={ 2 }>
							<CompareEstimateCard
								orderType={ orderType }
								beforeDetail={ details[2].detail }
								beforeTotal={ details[2].total }
								detail={ details[3].detail }
								total={ details[3].total }
								deliveryCost={ deliveryCost }
								outputDeliveryCost={ outputDeliveryCost }
								paymentFlag={ paymentComplete }
							/>
						</Box>

						{ (!isRequestImagesLoading && requestImagesData.length > 0) || !isEmptyString(requestContent) ? (
							<Divider>
								<Chip label="고객님의 요청사항" variant="outlined" />
							</Divider>
						) : (<></>) }

						{ !isRequestImagesLoading && requestImagesData.length > 0 && (
							<Box mt={ 2 }>
								<ImageUpload
									readOnly
									images={ requestImagesData }
								/>
							</Box>
						) }

						{ requestContent && (
							<Box mt={ 2 }>
								<ContentField value={ requestContent } />
							</Box>
						) }

						{ (!isEstimateFixImagesLoading && estimateFixImagesData.length > 0) || !isEmptyString(estimateFixContent) ? (
							<Box mt={ 3 }>
								<Divider>
									<Chip label="견적 확정 안내사항" variant="outlined" />
								</Divider>
							</Box>
						) : (<></>) }

						{ !isEstimateFixImagesLoading && estimateFixImagesData.length > 0 && (
							<Box mt={ 2 }>
								<ImageUpload
									readOnly
									images={ estimateFixImagesData }
								/>
							</Box>
						) }

						{ estimateFixContent && (
							<Box mt={ 2 }>
								<ContentField title="안내사항" value={ estimateFixContent } />
							</Box>
						) }
					</React.Fragment>
				) }
			</CardContent>

			<CardActions variant="adminStepCard">
				<Stack
					direction="row"
					spacing={ 1 }
				>
					<Button variant="contained" size="small" disabled={ paymentComplete } onClick={ handlePaymentComplete }>결제 확인</Button>

					{ paymentComplete ? (
						<Button variant="contained" size="small" onClick={ handleStartWorking }>작업 시작</Button>
					) : (
						<Tooltip
							arrow
							placement="left"
							title="결제가 확인된 뒤에 작업을 시작하실 수 있어요"
						>
						<span>
							<Button variant="contained" size="small" disabled>작업 시작</Button>
						</span>
						</Tooltip>
					) }
				</Stack>
			</CardActions>
		</Card>
	);
}

export default Step6Card;