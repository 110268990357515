const ADMIN_DEFAULT_URL = "/api/admin";

export const LOGIN_DEFAULT_URL = ADMIN_DEFAULT_URL + "/login";
export const SIGN_UP_DEFAULT_URL = ADMIN_DEFAULT_URL + "/signUp";
export const BRAND_DEFAULT_URL = ADMIN_DEFAULT_URL + "/brand";
export const TENT_DEFAULT_URL = ADMIN_DEFAULT_URL + "/tent";
export const AMOUNT_DEFAULT_URL = ADMIN_DEFAULT_URL + "/amount";
export const ORDER_DEFAULT_URL = ADMIN_DEFAULT_URL + "/order";
export const SAVE_ESTIMATE_DEFAULT_URL = ADMIN_DEFAULT_URL + "/saveEstimate";
export const FIX_ESTIMATE_DEFAULT_URL = ADMIN_DEFAULT_URL + "/fixEstimate";
export const REQUEST_ADD_TENT_DEFAULT_URL = ADMIN_DEFAULT_URL + "/requestAddTent";
export const BRANCH_DEFAULT_URL = ADMIN_DEFAULT_URL + "/branch";
export const PICK_UP_SERVICE_DEFAULT_URL = ADMIN_DEFAULT_URL + "/pickUpService";
export const BANNER_DEFAULT_URL = ADMIN_DEFAULT_URL + "/banner";