import { Box, Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import { GET_ORDER_HISTORY_KEY } from "../../../query/public/orderHistoryDetailQuery";
import BackgroundBox from "../../common/BackgroundBox";
import CompareEstimateCard from "../../common/CompareEstimateCard";
import BranchCard from "./BranchCard";
import DeliveryAddressCard from "./DeliveryAddressCard";
import BackgroundImage from "../../../assets/images/history/background.png";

function Step6AccordionContent({ items,	isCurrentStep }) {
	return (
		<Stack>
			<BackgroundBox mb={ 4 }>
				<ul>
					<li>
						<Typography variant="context">고객님의 텐트를 { items.ordrTpNm }을 위해 대기 중이에요</Typography>
					</li>

					<li>
						<Typography variant="context">{ items.ordrTpNm }은 <strong>결제가 확인된 후</strong>에 진행됩니다</Typography>
					</li>

					{/*{ items.dlvryTp === "1" && (*/}
					{/*	<li>*/}
					{/*		{ items.dlvryTp === "1" ? (*/}
					{/*			<Typography variant="context">배송은 기본 <strong>착불</strong>로 진행되며, 선불 택배를 원하신다면 업체로 문의해주세요</Typography>*/}
					{/*		) : (*/}
					{/*			<Typography variant="context">배송은 기본 <strong>착불</strong>로 진행되며, 선불 택배를 원하신다면 업체로 문의해주세요</Typography>*/}
					{/*		) }*/}
					{/*	</li>*/}
					{/*) }*/}

					{ items.dlvrDvsn === "1" && (
						<React.Fragment>
							<li>
								<Typography variant="context">최종 견적 카드에 표시된 <strong>"수취 선불 배송비"</strong>는 접수하실 때 선택한 주소지를 기준으로 책정했습니다</Typography>
							</li>

							<li>
								<Typography variant="context">배송지를 <strong>제주도/도서산간</strong> 지역으로 변경 시 <strong>추가 배송비</strong>가 발생할 수 있습니다</Typography>
							</li>
						</React.Fragment>
					) }
				</ul>
			</BackgroundBox>

			<Box>
				<BranchCard
					branchId={ items.brnchId }
					branchName={ items.brnchNm }
					address={ items.addr }
					addressDetail={ items.addrDtl }
					zoneCode={ items.znCd }
					bankName={ items.bnkNm }
					bankAccount={ items.bnkAccnt }
					bankAccountHolder={ items.bnkAccntHldr }
					telNumber={ items.brnchTelNo }
					queryKey={ GET_ORDER_HISTORY_KEY }
					updateFlag={ false }
					cardInfoShow
				/>
			</Box>

			<Box mt={ 2 }>
				<CompareEstimateCard
					orderType={ items.ordrTp }
					beforeDetail={ items.details[2].detail }
					beforeTotal={ items.details[2].total }
					detail={ items.details[3].detail }
					total={ items.details[3].total }
					deliveryCost={ items.rcvngDlvrDvsn === "2" && Number(items.rcvngDlvryCst) }
					outputDeliveryCost={ items.dlvrDvsn === "1" && Number(items.dlvryCst) }
					paymentFlag={ items.pymntYn === "Y" }
					showIcon
				/>
			</Box>

			<Box mt={ 2 }>
				{ items.dlvryTp === "1" && (
					<DeliveryAddressCard updateFlag={ isCurrentStep } />
				) }
			</Box>
		</Stack>
	);
}

export default Step6AccordionContent;