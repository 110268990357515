import axios from "../axios";
import qs from "query-string";
import { firebaseAuth } from "../../firebaseAuth";
import { setCookie, removeCookie } from "../../hooks/cookies";
import { ESTIMATE_DEFAULT_URL } from "./defaultUrl";

export async function getBrands() {
	const res = await axios.post(ESTIMATE_DEFAULT_URL + "/listAll/getBrand", {});
	return res?.data;
}

export async function getTent(brndId) {
	const res = await axios.post(ESTIMATE_DEFAULT_URL + "/listAll/getTent", qs.stringify({ brndId }));
	return res?.data;
}

export async function getEstimate(tntId, otherTent) {
	const DELAY_KEY = "delayDate";
	const userId = await firebaseAuth?.currentUser.uid;
	const res = await axios.post(ESTIMATE_DEFAULT_URL + "/getEstimate", qs.stringify({ userId, tntId, otherTent }));

	if(res?.data.cd === "0") {
		const expires = res.data.expiresDate;

		setCookie(DELAY_KEY, expires, {
			expires: new Date(expires),
			path: "/"
		});
	} else {
		removeCookie(DELAY_KEY);
	}

	return res?.data;
}

export async function getBranch() {
	const res = await axios.post(ESTIMATE_DEFAULT_URL + "/listAll/getBranch");
	return res?.data;
}

export async function setOrder(formData) {
	const res = await axios.post(ESTIMATE_DEFAULT_URL + "/setOrder", formData, {
		headers: { "Content-Type": "multipart/form-data" }
	});
	return res?.data;
}