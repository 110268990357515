import React, {
	useEffect,
	useState
} from "react";
import styled from "styled-components";
import {
	FormHelperText,
	IconButton,
	InputBase,
	Paper
} from "@mui/material";
import {
	AddRounded,
	RemoveRounded
} from "@mui/icons-material";

function NumberInput2({ setValue, value = 0, amount }) {
	const [count, setCount] = useState(value);

	function handleClick(value) {
		setCount((prev) => prev + value);
		setValue(value);
	}

	return (
		<FormWrap>
			<NumberButton
				onClick={ () => handleClick(-1) }
				disabled={ count <= 0 }
			>
				<RemoveRounded />
			</NumberButton>

			<Input value={ count } readOnly />

			<NumberButton
				onClick={ () => handleClick(1) }
				disabled={ count >= 9 }
			>
				<AddRounded />
			</NumberButton>

			{ count > 1 && amount > 0 && (
				<PriceLabel>
					{ (count * amount).toLocaleString() }
				</PriceLabel>
			) }
		</FormWrap>
	);
}

const FormWrap = styled((props) => (
	<Paper componant={ "form" } { ...props } />
)) (() => ({
	"&&&": {
		borderRadius: 0,
		alignItems: "center",
		display: "flex",
		width: "100%",
		margin: "auto",
		border: "1.5px solid #5c5c5c",
		position: "relative"
	}
}));

const NumberButton = styled(IconButton)(() => ({
	"&&&.Mui-disabled": {
		"& .MuiSvgIcon-root": {
			fill: "#8c321a"
		}
	},
	"&&&": {
		minWidth: 0,
		padding: 0,
		"& .MuiSvgIcon-root": {
			fill: "#E8410B"
		}
	}
}));

const Input = styled(InputBase) (() => ({
	"& .MuiInputBase-input": {
		textAlign: "center",
		fontSize: ".8rem"
	}
}));

const PriceLabel = styled((props) => (
	<FormHelperText { ...props } />
)) (() => ({
	position: "absolute",
	top: "95%",
	left: "50%",
	transform: "translate(-50%, 0)",
	"&&&": {
		fontSize: "11px",
	}
}));

export default NumberInput2;