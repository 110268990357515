import React, { useMemo } from "react";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";
import { useMenuInfo } from "../../lib/menuStructure";

function SEOMeta() {
	const defaultMeta = useMemo(() => ({
		title: "텐트세탁소",
		description: "텐트세탁소는 자체 개발한 환경부 인증받은 세제와 발수제를 사용하여 텐트 세탁, 수선, 리폼 서비스를 제공합니다.",
		url: "https://텐트세탁소.com",
		image: "/titleImage.png"
	}), []);

	const { title } = useMenuInfo();

	const plusTitle = defaultMeta.title + (title?.length > 0 ? " | " + title : "");

	return (
		<Helmet>
			<title>{ plusTitle }</title>
			<meta name="title" content={ plusTitle } />
			<meta name="description" content={ defaultMeta.description } />

			{ /* Open Graph / Facebook */ }
			<meta property="og:type" content="website" />
			<meta property="og:url" content={ defaultMeta.url } />
			<meta property="og:title" content={ defaultMeta.title } />
			<meta property="og:description" content={ defaultMeta.description } />
			<meta property="og:image" content={ defaultMeta.image } />

			{ /* Twitter	*/ }
			<meta property="twitter:url" content={ defaultMeta.url } />
			<meta property="twitter:title" content={ defaultMeta.title } />
			<meta property="twitter:description" content={ defaultMeta.description } />
			<meta property="twitter:image" content={ defaultMeta.image } />
		</Helmet>
	);
}

export default SEOMeta;