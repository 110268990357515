import React, { useCallback } from "react";
import {
	Stack,
	SvgIcon
} from "@mui/material";
import { ReactComponent as TelIcon } from "../../assets/images/icon/tel.svg";
import { ReactComponent as InstaIcon } from "../../assets/images/icon/instagram.svg";
import CafeIcon from "../../assets/images/icon/naver_cafe.png";
import BlogIcon from "../../assets/images/icon/naver_blog.png";
import KakaoIcon from "../../assets/images/icon/kakao.png";
import { styled } from "@mui/material/styles";

function BranchInfo({ info }) {
	const { tel, cafe, blog, kakao, insta } = info;

	function goMobileTel() {
		document.location.href = `tel:${ tel }`;
	}

	function goCafe() {
		window.open(cafe);
	}

	function goBlog() {
		window.open(blog);
	}

	function goKakao() {
		window.open(kakao);
	}

	function goInsta() {
		window.open(insta);
	}

	return (
		<StyledStack direction="row" alignItems="center" spacing={ 3 }>
			{ tel && (
				<SvgIcon component={ TelIcon } onClick={ goMobileTel } />
			) }
			{ cafe && (
				<img
					src={ CafeIcon }
					onClick={ goCafe }
					alt="카페 바로가기"
					style={ {
						width: "24px",
						height: "24px"
					} }
				/>
			) }
			{ blog && (
				<img
					src={ BlogIcon }
					onClick={ goBlog }
					alt="블로그 바로가기"
					style={ {
						width: "24px",
						height: "24px"
					} }
				/>
			) }
			{ kakao && (
				<img
					src={ KakaoIcon }
					onClick={ goKakao }
					alt="카카오톡 바로가기"
					style={ {
						width: "24px",
						height: "24px"
					} }
				/>
			) }
			{ insta && (
				<SvgIcon
					component={ InstaIcon }
					onClick={ goInsta }
					sx={ { width: "24px", height: "24px" } }
				/>
			) }
		</StyledStack>
	);
}

const StyledStack = styled(Stack)(() => ({
	"& > *": {
		cursor: "pointer",
		transition: "all .3s ease !important"
	},
	"& > *:hover": {
		transform: "scale(1.1)"
	}
}));

export default React.memo(BranchInfo);