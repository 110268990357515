import { styled } from "@mui/material/styles";
import React, { useEffect, useImperativeHandle, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { isEmptyObject } from "../../../lib/libs";
import DndBannerItem from "./DndBannerItem";
import GhostBannerItem from "./GhostBannerItem";

function DndBanner({ bannerItems, getUpdateOrder }, ref) {
	const [items, setItems] = useState(bannerItems);
	const [enabled, setEnabled] = useState(false);
	const [placeholderProps, setPlaceholderProps] = useState({});

	useImperativeHandle(ref, () => ({
		getUpdateOrderItem: (data) => {
			if(JSON.stringify(data) === JSON.stringify(items)) {
				return null;
			} else {
				return items.map(({ bnnrId, dsplySqnc }, index) => ({
					bannerId: bnnrId,
					displaySequence: index + 1
				}));
			}
		}
	}));

	useEffect(() => {
		const animation = requestAnimationFrame(() => setEnabled(true));

		return () => {
			cancelAnimationFrame(animation);
			setEnabled(false);
		}
	}, []);

	function getDraggedElement(draggableId) {
		return document.querySelector(`[data-rbd-draggable-id='${draggableId}']`);
	}

	function onDragStart({ draggableId, mode, source, type }) {
		const draggedElement = getDraggedElement(draggableId);

		if(!draggedElement) {
			return ;
		}

		const { clientHeight, clientWidth } = draggedElement;
		const clientY = [...draggedElement.parentNode.children]
			.slice(0, source.index)
			.reduce((acc, cur) => {
				const marginBottom = parseFloat(window.getComputedStyle(cur).marginBottom);

				return acc + cur.clientHeight + marginBottom;
			}, 0);

		const targetItem = items.find((item) => item.bnnrId === parseFloat(draggableId))

		setPlaceholderProps({
			targetItem,
			clientHeight,
			clientWidth,
			clientY
		});
	}

	function onDragUpdate({ draggableId, destination, source }) {
		if(!destination) {
			return ;
		}

		const draggedElement = getDraggedElement(draggableId);

		if(!draggedElement) {
			return ;
		}

		const elementArray = [...draggedElement.parentNode.children];
		const [targetElement] = elementArray.splice(source.index, 1);

		elementArray.splice(destination.index, 0, targetElement);

		const clientY = elementArray
			.slice(0, destination.index)
			.reduce((acc, cur) => {
				const marginBottom = parseFloat(window.getComputedStyle(cur).marginBottom);

				return acc + cur.clientHeight + marginBottom;
			}, 0);

		setPlaceholderProps((prev) => ({
			...prev,
			clientY
		}));
	}

	function onDragEnd({ source, destination }) {
		// source: { index, droppableId } => 대상 아이템
		// destination: { index, droppableId } => 대상 아이템이 놓인 위치, container 밖에 놓으면 null

		setPlaceholderProps({});

		if(!destination) {
			return ;
		}

		if(source.index === destination.index) {
			return ;
		}

		const _items = [...items];
		const [targetItem] = _items.splice(source.index, 1);

		_items.splice(destination.index, 0, targetItem); // destination index로부터 아이템 삭제 없이 target item 추가
		setItems(_items);
	}

	if(!enabled) {
		return null;
	}

	return (
		<DragDropContext
			onDragStart={ onDragStart }
			onDragUpdate={ onDragUpdate }
			onDragEnd={ onDragEnd }
		>
			<Droppable droppableId="banner">
				{/* Drop 가능한 영역 */}

				{ (provided, snapshot) => (
					<DroppableWrap ref={ provided.innerRef } { ...provided.droppableProps }>
						{ items.map((item, index) => (
							<Draggable key={ item.bnnrId } draggableId={ `${item.bnnrId}_banner` } index={ index }>
								{/* Drag 가능한 컴포넌트 영역 */}

								{ (provided, snapshot) => (
									<DndBannerItem
										imagePath={ item.imgPth }
										startDate={ item.strtDtm }
										endDate={ item.endDtm }
										display={ item.dsplyYn === "Y" }
										isDragging={ snapshot.isDragging }
										ref={ provided.innerRef }
										{ ...provided.draggableProps }
										{ ...provided.dragHandleProps }
									/>
								) }
							</Draggable>
						)) }

						{ provided.placeholder }

						<GhostBannerItem
							enabled={ !isEmptyObject(placeholderProps) && snapshot.isDraggingOver }
							imagePath={ placeholderProps.targetItem?.imgPth }
							startDate={ placeholderProps.targetItem?.strtDtm }
							endDate={ placeholderProps.targetItem?.endDtm }
							display={ placeholderProps.targetItem?.dsplyYn === "Y" }
							sx={{
								top: placeholderProps.clientY,
								height: placeholderProps.clientHeight,
								width: placeholderProps.clientWidth,
							}}
						/>
					</DroppableWrap>
				) }
			</Droppable>
		</DragDropContext>
	);
}

const DroppableWrap = styled("div")(() => ({
	position: "relative",
	display: "flex",
	flexDirection: "column",
	marginTop: 16,
	"& > *": {
		marginBottom: 12
	}
}));

export default React.forwardRef(DndBanner);