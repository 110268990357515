import {
	Box, Button,
	Card, CardActions,
	CardContent,
	Chip,
	Divider, Stack, Typography,
} from "@mui/material";
import React, { useState } from "react";
import { modals } from "../../../hooks/Modals";
import useModal from "../../../hooks/useModal";
import { getUser } from "../../../lib/firestore/User";
import { isEmptyString } from "../../../lib/libs";
import { useCancelOrderMutate, useRequestWorkMutate } from "../../../query/admin/orderQuery";
import { useEstimateFixImages, useRequestImages } from "../../../query/getImageQuery";
import CompareEstimateCard from "../../common/CompareEstimateCard";
import ImageUpload from "../../public/ImageUpload";
import { Toast } from "../../public/Toast";
import CardSubTitle from "./CardSubTitle";
import CardTitle from "./CardTitle";
import ContentField from "./ContentField";

function Step5Card({
	orderId,
	userId,
	nickname,
	orderType,
	date,
	brandName,
	tentName,
	imagePath,
	requestContent,
	estimateFixImagePath,
	estimateFixContent,
	receptionNumber,
	details,
	deliveryCost,
	outputDeliveryCost,
	branchName,
	repairContent,
	orderTypeName,
	refreshQuery
}) {
	const [open, setOpen] = useState(false);
	const {
		      isRequestImagesLoading,
		      requestImagesData,
	      } = useRequestImages(orderId, imagePath, open, true);
	const {
		      isEstimateFixImagesLoading,
		      estimateFixImagesData,
	      } = useEstimateFixImages(orderId, estimateFixImagePath, open, true);
	const { cancelOrderMutate } = useCancelOrderMutate();
	const { requestWorkMutate } = useRequestWorkMutate();
	const { openModal } = useModal();

	function handleCancelWorking() {
		openModal(modals.confirmModal, {
			title: `${ nickname } 고객님의 ${ orderTypeName }을 취소하시겠어요?`,
			content: (
				<Box>
					<Typography variant="dialogContent" sx={{ "& > strong" : { color: "#E8410B" } }}>
						<strong>
							{ orderTypeName } 취소 시 복구가 불가능합니다.
						</strong>
					</Typography>
				</Box>
			),
			submitTitle: `${ orderTypeName } 취소`,
			closeTitle: "닫기",
			onSubmit: async(firstClose, setLoadingDone) => {
				const user = (await getUser(userId)).data();

				cancelOrderMutate({ orderId, phoneNumber: user.phoneNumber }, {
					onSuccess: async () => {
						Toast.success(`${ nickname } 고객님의 ${ orderTypeName }이 취소되었어요.`);
						refreshQuery();
						firstClose();
					},
					onError: () => {
						firstClose();
						openModal(modals.confirmModal, {
							title: "죄송합니다, 취소가 실패했습니다",
							content: "잠시 후에 다시 시도해주시기 바랍니다.",
							closeTitle: "닫기"
						});
					},
					onSettled: () => {
						setLoadingDone();
					}
				});
			}
		});
	}

	function handleRequestWorking() {
		openModal(modals.confirmModal, {
			title: `${ nickname } 고객님의 ${ orderTypeName }을 요청하시겠어요?`,
			submitTitle: `${ orderTypeName } 요청`,
			content: (
				<Box>
					<Typography variant="dialogContent" sx={{ "& > strong" : { color: "#E8410B" } }}>
						<strong>
							세탁 요청이 완료된 뒤에는 { orderTypeName } 취소가 불가능합니다.
						</strong>
					</Typography>
				</Box>
			),
			onSubmit: async (firstClose, setLoadingDone) => {
				requestWorkMutate({ orderId }, {
					onSuccess: async () => {
						Toast.success(`${ nickname } 고객님의 ${ orderTypeName }이 요청되었어요.`);
						refreshQuery();
						firstClose();
					},
					onError: () => {
						firstClose();
						openModal(modals.confirmModal, {
							title: "죄송합니다, 요청이 실패했습니다",
							content: "잠시 후에 다시 시도해주시기 바랍니다.",
							closeTitle: "닫기",
						});
					},
					onSettled: () => {
						setLoadingDone();
					},
				});
			},
		});
	}

	return (
		<Card>
			<CardContent>
				<CardTitle
					orderType={ orderType }
					date={ date }
					nickname={ nickname }
					userId={ userId }
				/>

				<CardSubTitle
					receptionNumber={ receptionNumber }
					brandName={ brandName }
					tentName={ tentName }
					branchName={ branchName }
					open={ open }
					setOpen={ setOpen }
				/>

				{ open && (
					<React.Fragment>
						<Box mb={ 2 }>
							<CompareEstimateCard
								orderType={ orderType }
								beforeDetail={ details[2].detail }
								beforeTotal={ details[2].total }
								detail={ details[3].detail }
								total={ details[3].total }
								deliveryCost={ deliveryCost }
								outputDeliveryCost={ outputDeliveryCost }
								showPaymentYn={ false }
							/>
						</Box>

						{ (!isRequestImagesLoading && requestImagesData.length > 0) || !isEmptyString(requestContent) ? (
							<Divider>
								{/*<ArrowIconChip label="고객님의 요청사항" />*/ }
								<Chip label="고객님의 요청사항" variant="outlined" />
							</Divider>
						) : (<></>) }

						{ !isRequestImagesLoading && requestImagesData.length > 0 && (
							<Box mt={ 2 }>
								<ImageUpload
									readOnly
									images={ requestImagesData }
								/>
							</Box>
						) }

						{ requestContent && (
							<Box mt={ 2 }>
								<ContentField value={ requestContent } />
							</Box>
						) }

						{ orderType === "2" && repairContent && (
							<Box mt={ 2 }>
								<Divider>
									<Chip label="수선 가견적 안내사항" variant="outlined" />
								</Divider>

								<Box mt={ 2 }>
									<ContentField value={ repairContent } title="" />
								</Box>
							</Box>
						) }

						{ (!isEstimateFixImagesLoading && estimateFixImagesData.length > 0) || !isEmptyString(estimateFixContent) ? (
							<Box mt={ 3 }>
								<Divider>
									{/*<ArrowIconChip label="견적 확정 안내사항" />*/ }
									<Chip label="견적 확정 안내사항" variant="outlined" />
								</Divider>
							</Box>
						) : (<></>) }

						{ !isEstimateFixImagesLoading && estimateFixImagesData.length > 0 && (
							<Box mt={ 2 }>
								<ImageUpload
									readOnly
									images={ estimateFixImagesData }
								/>
							</Box>
						) }

						{ estimateFixContent && (
							<Box mt={ 2 }>
								<ContentField title="안내사항" value={ estimateFixContent } />
							</Box>
						) }
					</React.Fragment>
				) }
			</CardContent>

			<CardActions variant="adminStepCard">
				<Stack direction="row" spacing={ 1 }>
					<Button
						variant="contained"
						size="small"
						color="secondary"
						onClick={ handleCancelWorking }
					>
						{ orderTypeName } 취소
					</Button>
					<Button
						variant="contained"
						size="small"
						color="primary"
						onClick={ handleRequestWorking }
					>
						{ orderTypeName } 요청
					</Button>
				</Stack>
			</CardActions>
		</Card>
	);
}

export default Step5Card;