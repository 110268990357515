import { Box, Container, Divider, SvgIcon, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import Venture from "../../assets/images/certified/venture_emblem.png";
import usePopupBanner from "../../hooks/public/usePopupBanner";
import useRandomMainImage from "../../hooks/public/useRandomMainImage";
import { ReactComponent as Washing } from "../../assets/images/history/07_washing.svg";
import { ReactComponent as Repair } from "../../assets/images/history/07_repair.svg";

function Home() {
	const navigate = useNavigate();
	const [backgroundImageSrc] = useState(useRandomMainImage());

	usePopupBanner();

	function onClickIndex(type) {
		localStorage.setItem("before_page", `/tent/${type}`);
		navigate(`/tent/${type}`);
	}

	return (
		<Container variant="contextRoot">
			<SideLeftWrap>
				<WashingSideIndex onClick={ () => onClickIndex("estimate") }>
					<SvgIcon component={ Washing } fontSize="small" sx={{ fill: "none" }} />
					<Typography variant="cardBold">세탁 견적받기</Typography>
				</WashingSideIndex>

				<RepairSideIndex onClick={ () => onClickIndex("repair") }>
					<SvgIcon component={ Repair } fontSize="small" sx={{ fill: "none" }} />
					<Typography variant="cardBold">수선 견적받기</Typography>
				</RepairSideIndex>
			</SideLeftWrap>

			<BackgroundImage src={ backgroundImageSrc || "" } alt="텐트세탁소 배경" />
			<VentureImage src={ Venture }  alt="벤처 인증 기업 마크"/>
		</Container>
	);
}

const BackgroundImage = styled("img")`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100vh;
  object-fit: cover;
`;

const VentureImage = styled("img")`
  width: 74px;
  position: ${ isMobile ? "fixed" : "absolute" };
  bottom: 20px;
  left: 20px;
  border-radius: 2px;
  background-color: rgb(255 255 255 / 20%);
  padding: 4px;
`;

const SideLeftWrap = styled(Box)(() => ({
	position: isMobile ? "fixed" : "absolute",
	left: 0,
	top: 90,
	zIndex: 100,
	display: "flex",
	flexDirection: "column",
	alignItems: "flex-start",
	gap: 15
}));

const SideIndex = styled(Box)(() => ({
	userSelect: "none",
	display: "flex",
	alignItems: "center",
	color: "#fff",
	stroke: "#fff",
	writingMode: "vertical-lr",
	borderRadius: "0 4px 4px 0",
	cursor: "pointer",
	letterSpacing: "-1px",
	backdropFilter: "blur(2px)",
	"&:hover": {
		"& > *": {
			borderLeftWidth: "15px !important",
		}
	},
	"& > *": {
		transition: "all ease .2s",
		border: "solid transparent",
		borderWidth: 10,
		boxSizing: "content-box",
		"&.MuiSvgIcon-root": {
			borderTopRightRadius: 4,
			transform: "scaleX(1.05)",
			transition: "all ease .2s",
		},
		"&.MuiTypography-root": {
			backgroundColor: "rgba(98, 98, 98, .6)",
			borderBottomRightRadius: 4
		}
	},
}));

const WashingSideIndex = styled(SideIndex)(({ theme }) => ({
	"& > .MuiSvgIcon-root": {
		backgroundColor: "rgba(232, 65, 11, .6)"
	},
	"&:hover": {
		"& > .MuiSvgIcon-root": {
			backgroundColor: theme.palette.type.washing
		},
	},
}));

const RepairSideIndex = styled(SideIndex)(({ theme }) => ({
	"& > .MuiSvgIcon-root": {
		backgroundColor: "rgba(7, 117, 59, .6)"
	},
	"&:hover": {
		"& > .MuiSvgIcon-root": {
			backgroundColor: theme.palette.type.repair,
		},
	},
}));

export default Home;