import * as ADMIN_API from "../api/admin/auth";
import * as PUBLIC_API from "../api/public/auth";
import { useQuery } from "@tanstack/react-query";
import {
	useEffect,
	useState
} from "react";
import {
	adminAccessToken,
	accessToken,
	firebaseToken
} from "../lib/authToken";
import { firebaseAuth } from "../firebaseAuth";
import { useLocation } from "react-router-dom";

export function useAdminSilentRefresh({ onSuccess, onError }) {
	useSilentRefreshQuery({
		queryKey: ["admin", "refresh"],
		API: ADMIN_API.silentRefresh,
		token: adminAccessToken,
		onError,
		onSuccess
	});
}

export function usePublicSilentRefresh({ onSuccess, onError, enabled }) {
	useSilentRefreshQuery({
		queryKey: ["auth", "refresh"],
		API: PUBLIC_API.silentRefresh,
		token: accessToken,
		onError,
		onSuccess,
		enabled
	});

	useEffect(() => {
		if(!enabled) {
			onError();
		}
	}, [])
}

function useSilentRefreshQuery({ queryKey, API, token, onError, onSuccess, enabled = true }) {
	const [refreshStop, setRefreshStop] = useState(false);

	useQuery(queryKey, API, {
		refetchOnWindowFocus: false,
		refetchOnMount: false,
		refetchOnReconnect: false,
		// refetchInterval: refreshStop ? false : 60 * 1000 * 1.5, // 1분 30초
		refetchInterval: refreshStop ? false : 60  * 1000 * 29, // 29분
		refetchIntervalInBackground: true,
		enabled,
		// retry: 2,
		// retryDelay: 500,
		onError: () => {
			setRefreshStop(true);
			token.setToken("");
			onError();
		},
		onSuccess: ({ cd, newAccessToken }) => {
			if(cd === "1") {
				token.setToken(newAccessToken);
				onSuccess();
			} else {
				setRefreshStop(true);
				token.setToken("");
				onError();
			}
		}
	});
}