import {
	Alert, Box,
	Button,
	ClickAwayListener,
	Container,
	FormControl,
	FormControlLabel,
	Stack,
	SvgIcon,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
	Tooltip,
	Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useQueryClient } from "@tanstack/react-query";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getBranch } from "../../../api/public/estimate2";
import { ReactComponent as Arrow } from "../../../assets/images/icon/dc_arrow.svg";
import CheckBox from "../../../components/common/CheckBox";
import StyledSelectBox from "../../../components/public/StyledSelectBox";
import Footer2 from "../../../components/public/estimate/Footer2";
import NumberInput2 from "../../../components/public/estimate/NumberInput2";
import RequestBranch from "../../../components/public/estimate/RequestBranch";
import TentAutocomplete from "../../../components/public/estimate/TentAutocomplete";
import { Toast } from "../../../components/public/Toast";
import useTimer from "../../../hooks/useTimer";
import ImageUpload from "../../../components/public/ImageUpload";
import InfoTooltip from "../../../components/common/InfoTooltip";
import RequestContextField from "../../../components/public/RequestContextField";
import { modals } from "../../../hooks/Modals";
import useModal from "../../../hooks/useModal";
import { Order, setOrder } from "../../../lib/firestore/Order";
import { getCurrentUser, updateUser } from "../../../lib/firestore/User";
import { isEmptyString } from "../../../lib/libs";
import { deleteItem, getItem, setItem } from "../../../lib/saveEstimateLocalStorage";
import {
	GET_ESTIMATE_KEY,
	useBrandQuery,
	useEstimateQuery,
	useSetOrderMutation,
	useTentQuery,
} from "../../../query/public/estimateQuery2";

function Count({
	select,
	setSelect,
	id,
	type,
	amount,
	dcYn,
}) {
	if(type === "1") { // 복수
		function set(newValue) {
			setSelect((prev) => {
				const count = (prev[id]?.count || 0) + newValue;

				if(count > 0) {
					return {
						...prev,
						[id]: {
							count,
							amount: count * amount,
							dc: dcYn === "Y",
						},
					};
				} else {
					const newItemsArray = Object.entries(prev).filter(([thisId]) => thisId !== id);

					return newItemsArray.reduce((acc, [thisId, items]) => ({
						...acc,
						[thisId]: items,
					}), {});
				}
			});
		}

		return (
			<NumberInput2
				value={ select?.count }
				setValue={ set }
				amount={ amount || 0 }
			/>
		);
	} else if(type === "2") { // 단수
		function set({ target: { checked } }) {
			const count = checked ? 1 : 0;

			if(count) {
				setSelect((prev) => ({
					...prev,
					[id]: {
						count,
						amount: count * amount,
						dc: dcYn === "Y",
					},
				}));
			} else {
				setSelect((prev) => {
					const newItemsArray = Object.entries(prev).filter(([thisId]) => thisId !== id);

					return newItemsArray.reduce((acc, [thisId, items]) => ({
						...acc,
						[thisId]: items,
					}), {});
				});
			}
		}

		return (
			<CheckBox
				checked={ !!select?.count }
				onChange={ set }
			/>
		);
	}
}

function Estimate({ isAdmin = false }) {
	const { savedTentId } = useParams();
	// Brand
	const [brandId, setBrandId] = useState("");
	const { brandData } = useBrandQuery(brandId, isAdmin);
	// Tent
	const [tentInfo, setTentInfo] = useState(null);
	const {
		      isTentFetching,
		      tentData,
	      } = useTentQuery(brandId, isAdmin);
	// Other Tent
	const [checkOtherTent, setCheckOtherTent] = useState(false);
	const [otherTentInfo, setOtherTentInfo] = useState({
		brand: "",
		tent: "",
	});
	const [otherTentHelper, setOtherTentHelper] = useState(false);
	// Estimate
	const {
		      isEstimateSuccess,
		      estimateData,
	      } = useEstimateQuery(tentInfo?.tntId, isAdmin, checkOtherTent);
	// Etc Request Content
	const [etcRequestContent, setEtcRequestContent] = useState("");
	const [uploadImage, setUploadImage] = useState([]);
	// Select and Total Price
	const [select, setSelect] = useState({});
	const [otherSelect, setOtherSelect] = useState({});
	const [total, setTotal] = useState(0);
	const [dcCheck, setDcCheck] = useState(false);
	// Request
	const { openModal } = useModal();
	const branchRef = useRef(null);
	const userNameRef = useRef(null);
	const pickUpRef = useRef(null);
	const [branchData, setBranchData] = useState([]);
	const [selectDC, setSelectDC] = useState(false);
	const { setOrderMutate } = useSetOrderMutation();
	const [requestErrorMessage, setRequestErrorMessage] = useState([]);
	const navigate = useNavigate();
	const queryClient = useQueryClient();

	useEffect(() => {
		if(!isAdmin) {
			getBranchData();
		}

		if(savedTentId) {
			const {
				      dcYn,
				      select,
				      tentInfo,
				      total,
			      } = getItem(savedTentId);

			setSelect(select);
			setTentInfo(tentInfo);
			setDcCheck(dcYn === "Y");
			setTotal(total);
		}
	}, []);

	const getBranchData = useCallback(async () => {
		setBranchData(await getBranch());
	}, []);

	useTimer({
		expiresDate: !isAdmin && !checkOtherTent && estimateData?.expiresDate,
		afterExpiresCallback: () => {
			queryClient.invalidateQueries([...GET_ESTIMATE_KEY, tentInfo?.tntId]);
		},
	});

	useEffect(() => {
		let total = 0;

		for(let key of Object.keys(select)) {
			const item = select[key];

			total += item.amount;
		}

		setTotal(total);
	}, [select]);

	const dcAmount = useMemo(() => {
		const selectKeys = Object.keys(select);
		let total = 0;
		let isDC = false;

		for(let key of selectKeys) {
			const item = select[key];

			if(item.dc) {
				isDC = true;
				total += Math.round(item.amount * 0.7 * 10) / 10;
			} else {
				total += item.amount;
			}
		}

		setSelectDC(isDC);

		if(total > 0) {
			removeErrorMessage("total");
		}

		return total;
	}, [select]);

	useEffect(() => {
		removeErrorMessage("select");
	}, [otherSelect]);

	function onBrandChange(value) {
		saveLocalStorage();

		setBrandId(value);
		setTentInfo(null);
		setSelect({});
		setDcCheck(false);
		setTotal(0);
	}

	function onTentChange(newValue, e) {
		saveLocalStorage();
		removeErrorMessage("tentInfo");

		setTentInfo(newValue);
		setBrandId(newValue?.brndId || "");
		setSelect({});
		setDcCheck(false);
		setTotal(0);

		// e.preventDefault();
		// document.querySelector("input").blur();
	}

	function onOtherTentChange({ target: { checked } }) {
		setCheckOtherTent(checked);

		if(checked) {
			otherTentHelperOpen();
			saveLocalStorage();
		} else {
			otherTentHelperClose();
			removeErrorMessage("otherTentInfo");
		}
	}

	function onOtherTentInfoChange({ target: { value } }, label) {
		setOtherTentInfo((prev) => ({
			...prev,
			[label]: value,
		}));
		removeErrorMessage("otherTentInfo");
	}

	function onDcCheckChange({ target: { checked } }) {
		setDcCheck(checked);
	}

	function onEtcRequestContentsChange(value) {
		setEtcRequestContent(value);
		removeErrorMessage("etcRequestContent");
	}

	function otherTentHelperOpen() {
		setOtherTentHelper(true);
	}

	function otherTentHelperClose() {
		setOtherTentHelper(false);
	}

	function addErrorMessage(key, message) {
		removeErrorMessage(key);
		setRequestErrorMessage((prev) => ([
			...prev,
			{ key, message }
		]));
	}

	function removeErrorMessage(key) {
		setRequestErrorMessage((prev) => prev?.filter((item) => item.key !== key));
	}

	async function handleRequestEstimate() {
		if(checkOtherTent) {
			if(!otherTentInfo.tent && !otherTentInfo.brand) {
				addErrorMessage("otherTentInfo", "브랜드나 텐트 명 중 하나를 입력해주세요");
				return;
			}

			if(Object.keys(otherSelect).length < 1) {
				addErrorMessage("select", "서비스 받으실 항목을 선택해주세요");
				return;
			}
		} else {
			if(!tentInfo) {
				addErrorMessage("tentInfo", "텐트를 선택해주세요");
				return;
			}

			if(total <= 0) {
				addErrorMessage("total", "서비스 받으실 항목을 선택해주세요");
				return;
			}
		}

		await openChecklist();

		const user = (await getCurrentUser()).data();

		openModal(modals.confirmModal, {
			title: `세탁 의뢰 지점 선택${ isEmptyString(user.name) ? " 및 이름 입력" : "" }`,
			submitTitle: "요청",
			content: (
				<React.Fragment>
					{ isEmptyString(user.name) && (
						<Box mb={ 2 }>
							<TextField
								label="이름"
								fullWidth
								ref={ userNameRef }
								inputProps={ { maxLength: 10 } }
								required
							/>
						</Box>
					) }

					<RequestBranch
						ref={ {
							branchRef,
							pickUpRef,
						} }
						branchData={ branchData }
						defaultBranch={ user.favoriteBranch }
					/>
				</React.Fragment>
			),
			onSubmit: async (onClose, setLoadingDone) => {
				const userName = userNameRef.current?.getElementsByTagName("input")[0].value;
				const pickUp = pickUpRef.current?.getElementsByTagName("input")[0].checked;

				if(isEmptyString(user.name)) {
					if(isEmptyString(userName)) {
						setLoadingDone();
						Toast.error("고객님의 성함을 입력해주세요");
						return;
					} else {
						await updateUser({
							name: userName,
						});
					}
				}

				openModal(modals.deliveryTypeModal, {
					onSubmit: async (firstClose, setFirstLoadingDone, type) => {
						/**
						 * type
						 * 1: 선불 배송
						 * 2: 방문 수령
						 * 3: 후불 배송
						 */

						const order = new Order("", user.uid, user.phoneNumber);
						const formData = new FormData();

						// 방문 수거/배송 주소지
						// if(pickUp === true || type !== 2) {
						// 	await openGetAddressModal({
						// 		order,
						// 		isPickUp: pickUp === true,
						// 		beforeClose: () => {
						// 			setFirstLoadingDone();
						// 		}
						// 	});
						// }

						await openGetAddressModal({
							order,
							isPickUp: pickUp === true,
							delivery: type !== 2,
							beforeClose: () => {
								setFirstLoadingDone();
							}
						});

						for(let i = 0; i < uploadImage.length; i++) {
							formData.append("files", uploadImage[i]);
						}

						const contentsData = {
							branchId: branchRef.current.getElementsByTagName("input")[0].value,
							total: !checkOtherTent && dcCheck && selectDC ? dcAmount : total,
							id: user.uid,
							nickname: user.name || userName,
							tentId: tentInfo?.tntId,
							other: checkOtherTent,
							otherTentName: checkOtherTent ? otherTentInfo.tent : "",
							otherBrandName: checkOtherTent ? otherTentInfo.brand : "",
							type: 1,
							dcYn: dcCheck ? "Y" : "N",
							select: JSON.stringify(checkOtherTent ? otherSelect : select),
							requestContent: etcRequestContent,
							requestReceivePickUpYn: pickUp === true ? "Y" : "N",
							deliveryType: type !== 2 ? 1 : 2, // 1: 배송, 2: 방문수령
							deliveryDivision: type === 1 ? 1 : type === 3 ? 2 : "", // 1: 선불배송, 2: 착불배송
						};

						const branchInfo = branchData?.find((item) => item.value === contentsData.branchId);

						contentsData.branchName = branchInfo?.label || "";
						contentsData.address = branchInfo?.addr || "";
						contentsData.addressDetail = branchInfo?.addrDtl || "";
            contentsData.phoneNumber = user.phoneNumber;
            contentsData.brandName = checkOtherTent ? (contentsData.otherBrandName || "___") : tentInfo.brndNm;
            contentsData.tentName = checkOtherTent ? (contentsData.otherTentName || "___") : tentInfo.tntNm;

						formData.append("contentsData", new Blob([JSON.stringify(contentsData)], { type: "application/json" }));

						setOrderMutate(formData, {
							onSuccess: async ({ orderId }) => {
								order.orderId = orderId;
								await setOrder(orderId, order);

								Toast.success("주문이 완료되었어요");
								navigate(`/tent/orderHistoryDetail/${ orderId }`);
								firstClose();
							},
							onError: () => {
								firstClose();
								openModal(modals.confirmModal, {
									title: "죄송합니다, 주문이 실패했습니다",
									content: "잠시 후에 다시 시도해주시기 바랍니다.",
									closeTitle: "닫기",
								});
							},
							onSettled: () => {
								setFirstLoadingDone();
							},
						});

						if(tentInfo?.tntId) {
							deleteItem(tentInfo.tntId);
						}
					},
					beforeClose: () => {
						onClose();
					}
				});
			},
		});
	}

	async function openGetAddressModal({ isPickUp = false, delivery, order, beforeClose }) {
		let title = (isPickUp ? "방문 수거/" : "") + "배송을 희망하는 주소지를 선택하세요";

		return new Promise((resolve) => {
			openModal(modals.addressesModal, {
				title: isPickUp || delivery ? title : "주소지를 선택해주세요",
				selectedFirst: true,
				submitTitle: "선택",
				closeTitle: "취소",
				content: "주소지 변경은 작업 완료 전까지 가능합니다.",
				onSubmit: async (onClose, address) => {
					order.receiverName = address.name;
					order.receiverPhoneNumber = address.phoneNumber;
					order.zoneCode = address.zoneCode;
					order.address = address.address;
					order.addressDetail = address.addressDetail;

					resolve(order);
					onClose();
				},
				beforeClose: () => {
					beforeClose();
				}
			});
		});
	}

	function saveLocalStorage() {
		if(Object.keys(select).length) {
			setItem({
				tentInfo,
				select,
				dcYn: dcCheck ? "Y" : "N",
				total: dcCheck && selectDC ? dcAmount : total,
			});
		}
	}

	async function openChecklist() {
		return new Promise((resolve) => {
			openModal(modals.checkListModal, {
				onSubmit: (onClose) => {
					resolve();
					onClose();
				}
			});
		});
	}

	const body = useCallback(() => {
		if(estimateData?.cd === "1" || (isAdmin && isEstimateSuccess)) {
			return estimateData.list?.reduce((acc, cur, i, src) => {
				const isGroup = !isEmptyString(cur.grpNm);
				const groupCnt = isGroup ? src.filter((item) => item.grpId === cur.grpId).length : 0;
				const isGroupFirst = isGroup ? !src.filter((item) => item.grpId === cur.grpId && item.srtOrdr < cur.srtOrdr).length : false;

				const row = (
					<TableRow key={ `${ cur.itmId }_${ i }}` }>
						{ (!isGroup || isGroupFirst) && (
							<TableCell
								variant="firstGroup"
								rowSpan={ isGroup ? groupCnt : null }
								colSpan={ !isGroup ? 2 : null }
								sx={ { position: "relative" } }
							>
								{ cur.grpRmk || cur.itmRmk ? (
									<InfoTooltip rmk={ isGroup ? cur.grpRmk : cur.itmRmk }>
										{ isGroup ? cur.grpNm : cur.itmNm }
									</InfoTooltip>
								) : (
									isGroup ? cur.grpNm : cur.itmNm
								) }
							</TableCell>
						) }

						{ isGroup && (
							<TableCell variant="secondGroup" sx={ { position: "relative" } }>
								{ cur.itmRmk && <InfoTooltip rmk={ cur.itmRmk } /> }
								{ cur.itmNm }
							</TableCell>
						) }

						{ !checkOtherTent && (
							<TableCell variant="body" align="right">
								{ (cur.amnt || "").toLocaleString() }
							</TableCell>
						) }

						<TableCell variant="body" align="center" sx={ { padding: "18px 8px" } }>
							<Count
								id={ cur.itmId }
								type={ cur.itmTp }
								amount={ cur.amnt }
								select={ checkOtherTent ? otherSelect[cur.itmId] : select[cur.itmId] }
								setSelect={ checkOtherTent ? setOtherSelect : setSelect }
								setTotal={ setTotal }
								dcYn={ cur.dcYn }
							/>
						</TableCell>
					</TableRow>
				);

				return [acc, row];
			}, null);
		} else {
			return <></>;
		}
	}, [estimateData, select, otherSelect, checkOtherTent]);

	return (
		<Container variant="contextRoot">
			<Container variant="contextBox">
				<Stack spacing={ 2 } mb={ 4 }>
					{ checkOtherTent ? (
						<React.Fragment>
							<FormControl fullWidth>
								<TextField
									label="브랜드"
									value={ otherTentInfo.brand }
									onChange={ e => onOtherTentInfoChange(e, "brand") }
									error={ !otherTentInfo.tent && !otherTentInfo.brand }
									helperText={ !otherTentInfo.tent && !otherTentInfo.brand && "브랜드나 텐트 명 중 하나를 입력해주세요" }
								/>
							</FormControl>

							<FormControl fullWidth>
								<TextField
									label="텐트"
									value={ otherTentInfo.tent }
									onChange={ e => onOtherTentInfoChange(e, "tent") }
									error={ !otherTentInfo.brand && !otherTentInfo.tent }
									helperText={ !otherTentInfo.brand && !otherTentInfo.tent && "브랜드나 텐트 명 중 하나를 입력해주세요" }
								/>
							</FormControl>
						</React.Fragment>
					) : (
						<React.Fragment>
							<StyledSelectBox
								title="브랜드"
								items={ brandData }
								onChange={ onBrandChange }
								value={ brandId }
							/>

							<TentAutocomplete
								isLoading={ isTentFetching }
								items={ tentData }
								value={ tentInfo }
								onChange={ onTentChange }
							/>

							{ tentInfo && tentInfo.sz && (
								<FormControl fullWidth>
									<TextField
										label="사이즈"
										value={ tentInfo.sz }
										disabled
									/>
								</FormControl>
							) }

							{ tentInfo && tentInfo.rmk && (
								<FormControl fullWidth>
									<TextField
										label="특이사항"
										value={ tentInfo.rmk }
										disabled
										multiline
									/>
								</FormControl>
							) }
						</React.Fragment>
					) }

					{ !isAdmin && (
						<ClickAwayListener onClickAway={ otherTentHelperClose }>
							<Tooltip
								title="브랜드 및 텐트명을 입력해주세요"
								placement="bottom-end"
								PopperProps={ { disablePortal: true } }
								enterDelay={ 80 }
								open={ otherTentHelper }
								onClose={ otherTentHelperClose }
								disableFocusListener
								disableHoverListener
								disableTouchListener
							>
								<FormControlLabel
									label="내 텐트가 목록에 없어요!"
									value="start"
									labelPlacement="start"
									checked={ checkOtherTent }
									onChange={ onOtherTentChange }
									control={ <CheckBox /> }
								/>
							</Tooltip>
						</ClickAwayListener>
					) }
				</Stack>

				{/*{ !isAdmin && !checkOtherTent && (*/ }
				{/*	<UseTimer expiresDate={ estimateData?.expiresDate } />*/ }
				{/*) }*/ }

				<TableContainer
					sx={ {
						overflow: "visible",
						marginBottom: 6,
					} }
				>
					<Table>
						<TableHead
							sx={ {
								position: "sticky",
								top: isAdmin ? 0 : 60,
								zIndex: 1000,
							} }
						>
							<TableRow>
								<TableCell variant="header" colSpan={ 2 } sx={ { width: "50%" } }>항목</TableCell>
								{ !checkOtherTent && (
									<TableCell variant="header" sx={ { width: "25%" } }>단가</TableCell>
								) }
								<TableCell variant="header" sx={ { width: "25%" } }>수량</TableCell>
							</TableRow>
						</TableHead>

						<TableBody>
							{ body() }

							{ isEstimateSuccess && (estimateData.cd === "1" || isAdmin) && (
								<TableRow>
									<TableCell variant="firstGroup" colSpan={ checkOtherTent ? 1 : 2 }>
										<InfoTooltip rmk="젖은 상태에서 세제세척이 아닌 물샤워 후 건조만 원할 때 추천드려요.">
											실속 상품
										</InfoTooltip>
									</TableCell>
									<TableCell
										variant="secondGroup"
										sx={ {
											color: "#E8410B",
											fontWeight: 700,
										} }
									>
										30% 할인
									</TableCell>
									<TableCell variant="body" align="center">
										<CheckBox
											checked={ !!dcCheck }
											onChange={ onDcCheckChange }
										/>
									</TableCell>
								</TableRow>
							) }

							{ !checkOtherTent && (
								<TableRow
									sx={ {
										position: "sticky",
										bottom: 0,
										zIndex: 1000,
									} }
								>
									<TableCell variant="header" colSpan={ 2 }>합계</TableCell>

									<TotalCell>
										<Stack
											direction="row"
											alignItems="center"
											justifyContent="flex-end"
											spacing={ 1 }
										>
											<span
												style={ {
													textDecoration: dcCheck && selectDC && "line-through",
													fontWeight: !(dcCheck && selectDC) && "bold",
													fontSize: dcCheck && selectDC && 12,
												} }
											>
												{ total.toLocaleString() }
											</span>

											{ dcCheck && selectDC && (
												<React.Fragment>
													<SvgIcon
														component={ Arrow }
														sx={ {
															width: 14,
															height: 16,
														} }
													/>
													<span style={ { fontWeight: "bold" } }>{ dcAmount.toLocaleString() }</span>
												</React.Fragment>
											) }
										</Stack>
									</TotalCell>
								</TableRow>
							) }
						</TableBody>
					</Table>
				</TableContainer>

				{ !isAdmin && (
					<React.Fragment>
						<Typography variant="title">텐트 이미지 첨부</Typography>

						<ImageUpload setImage={ setUploadImage } />

						<Box mb={ 4 }>
							<RequestContextField setText={ onEtcRequestContentsChange } />
						</Box>

						{ requestErrorMessage.length > 0 && (
							<Stack spacing={ 1 } mb={ 1 }>
								{ requestErrorMessage.map(({ key, message }) => (
									<Alert key={ key } severity="error">{ message }</Alert>
								)) }
							</Stack>
						) }

						<Button variant="contained" size="large" fullWidth onClick={ handleRequestEstimate }>접수</Button>
					</React.Fragment>
				) }

				<Footer2 />
			</Container>
		</Container>
	);
}

const TotalCell = styled((props) => (
	<TableCell variant="body" colSpan={ 2 } { ...props } />
))(() => ({
	"&&&": {
		backgroundColor: "#FFCA9A",
		color: "#494949",
		padding: "18px 8px",
	},
}));

export default Estimate;