import { arrayRemove, arrayUnion, doc, getDoc, onSnapshot, setDoc, updateDoc } from "firebase/firestore";
import { firebaseAuth, firebaseDB as DB } from "../../firebaseAuth";

const DB_NAME = "USER";

export class User {
	constructor(uid, email, nickname, name, phoneNumber, favoriteBranch, address) {
		this.uid = uid; // String: Key
		this.email = email; // String
		this.nickname = nickname; // String
		this.name = name; // String
		this.phoneNumber = phoneNumber; // String
		this.favoriteBranch = favoriteBranch; // Number
		this.address = address; // Array<Map>
	}

	toString() {
		return `${ this.uid }, ${ this.email }, ${ this.nickname }, ${ this.name }, ${ this.phoneNumber }, ${ this.favoriteBranch }, ${ this.address }`;
	}
}

export class Address {
	constructor(index, defaultFlag, zoneCode, address, addressDetail, name, phoneNumber) {
		this.index = index;
		this.defaultFlag = defaultFlag;
		this.zoneCode = zoneCode;
		this.address = address;
		this.addressDetail = addressDetail;
		this.name = name;
		this.phoneNumber = phoneNumber;
	}

	toString() {
		return `${this.index}, ${this.defaultFlag}, ${this.zoneCode}, ${this.address}, ${this.addressDetail}, ${this.receiveName}, ${this.receivePhoneNumber}`;
	}
}

export const converter = {
	toFirestore: function(user) {
		return {
			email: user.email,
			nickname: user.nickname,
			name: user.name,
			phoneNumber: user.phoneNumber,
			favoriteBranch: user.favoriteBranch,
			address: user.address,
		};
	},
	fromFirestore: function(snapshot, options) {
		const data = snapshot.data(options);
		return new User(snapshot.id, data.email, data.nickname, data.name, data.phoneNumber, data.favoriteBranch, data.address);
	},
};

export async function getUserFromEmail(email) {
	const ref = doc(DB, DB_NAME, email).withConverter(converter);

	return await getDoc(ref);
}

async function getRef() {
	const uid = await firebaseAuth?.currentUser.uid;

	return doc(DB, DB_NAME, uid);
}

async function getRefWithConverter() {
	return (await getRef()).withConverter(converter);
}

export async function getCurrentUser() {
	const ref = await getRefWithConverter();

	return await getDoc(ref);
}

export async function getUser(userId) {
	const ref = doc(DB, DB_NAME, userId).withConverter(converter);

	return await getDoc(ref);
}

// export async function getUser() {
// 	const uid = await firebaseAuth?.currentUser.uid;
// 	const q = query(collection(DB, DB_NAME), where("uid", "==", uid)).withConverter(converter);
// 	return (await getDocs(q)).docs[0];
// }

export async function setUser(user) {
	const ref = await getRefWithConverter();

	await setDoc(ref, user);
}

export async function updateUserFromEmail(data) {
	const ref = await getRef();

	await updateDoc(ref, data);
}

export async function updateUser(data) {
	const ref = await getRef();

	await updateDoc(ref, data);
}

export async function getRealtimeUser(unsubscribeRef, setData) {
	const ref = await getRefWithConverter();

	unsubscribeRef.current = onSnapshot(ref, (doc) => {
		setData(doc.data());
	});
}

export async function addAddress(address) {
	const ref = await getRef();

	await updateDoc(ref, {
		address: arrayUnion(address)
	});
}

export async function updateAddress(address) {
	const ref = await getRef();

	await updateDoc(ref, {
		address
	});
}

export async function deleteAddress(address) {
	const ref = await getRef();

	await updateDoc(ref, {
		// [`address/${index}`]: deleteField()
		address: arrayRemove(address)
	});
}