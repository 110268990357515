import React, { useState } from "react";
import { Box, Button, Card, CardActions, CardContent, Divider, Stack, Tooltip, Typography } from "@mui/material";
import { modals } from "../../../hooks/Modals";
import useModal from "../../../hooks/useModal";
import { getUser } from "../../../lib/firestore/User";
import { beforeDateString } from "../../../lib/libs";
import {
	useSetDeliveryReceptionMutation, useSetPickUpConfirmMutation, useSetRefundMutation,
	useSetRequestPickUpMutation,
} from "../../../query/admin/orderQuery";
import BackgroundBox from "../../common/BackgroundBox";
import { Toast } from "../../public/Toast";
import CardSubTitle from "./CardSubTitle";
import CardTitle from "./CardTitle";
import WaitDeliveryInfoCard from "./WaitDeliveryInfoCard";

function Step0Card({
	orderId,
	userId,
	nickname,
	orderType,
	orderTypeName,
	brandName,
	tentName,
	receptionNumber,
	branchId,
	branchName,
	cancelFlag,
	total,
	date,
	deliveryType,
	deliveryTypeName,
	requestPickUpFlag,
	requestPickUpDate,
	deliverDivision,
	deliverDivisionName,
	deliveryCost,
	receivingDeliveryCost,
	refundFlag,
	refreshQuery
}) {
	const [open, setOpen] = useState(false);
	const { setDeliveryReceptionMutate } = useSetDeliveryReceptionMutation();
	const { setRequestPickUpMutate } = useSetRequestPickUpMutation();
	const { setPickUpConfirmMutate } = useSetPickUpConfirmMutation();
	const { setRefundMutate } = useSetRefundMutation();
	const { openModal } = useModal();

	function handleReceptionDelivery() {
		openModal(modals.deliveryReceptionModal, {
			branchId: branchId,
			upTo10M: total >= 100000,
			cancelFlag,
			deliverDivision,
			deliveryCost,
			onSubmit: async (input, onClose, setLoadingDone) => {
				const user = (await getUser(userId)).data();
				const requestData = {
					...input,
					orderId,
					brandName,
					tentName,
					nickname,
					phoneNumber: user.phoneNumber,
					// typeId: input.deliveryDivision,
					// type: input.divisionName,
					// costString: input.cost.toLocaleString()
				};

				setDeliveryReceptionMutate(requestData, {
					onSuccess: async () => {
						Toast.success("저장이 완료되었어요");
						refreshQuery();
						onClose();
					},
					onError: async () => {
						onClose();
						openModal(modals.confirmModal, {
							title: "죄송합니다, 저장이 실패했습니다",
							content: "잠시 후에 다시 시도해주시기 바랍니다.",
							closeTitle: "닫기",
						});
					},
					onSettled: () => {
						setLoadingDone();
					}
				});
			}
		});
	}

	function handleRequestPickUp() {
		openModal(modals.confirmModal, {
			submitTitle: "방문수령 요청",
			content: (
				<Box>
					<Typography variant="dialogContent">방문수령을 요청하시겠어요?</Typography>
					<Typography variant="smallContext" mt={ 1 }>* 고객님께 카카오 알림톡을 발송합니다</Typography>
				</Box>
			),
			onSubmit: async (onClose, setLoadingDone) => {
				const user = (await getUser(userId)).data();
				const requestData = {
					orderId,
					brandName,
					tentName,
					nickname,
					branchName,
					branch: branchId,
					type: orderTypeName,
					phoneNumber: user.phoneNumber,
				};

				setRequestPickUpMutate(requestData, {
					onSuccess: async () => {
						Toast.success("방문수령 요청이 완료되었어요");
						refreshQuery();
						// queryClient.invalidateQueries([...GET_COMPLETE_HISTORY_STEP]);
						onClose();
					},
					onError: async () => {
						onClose();
						openModal(modals.confirmModal, {
							title: "죄송합니다, 저장이 실패했습니다",
							content: "잠시 후에 다시 시도해주시기 바랍니다.",
							closeTitle: "닫기",
						});
					},
					onSettled: () => {
						setLoadingDone();
					}
				});
			}
		});
	}

	function handleSetPickUpConfirm() {
		openModal(modals.confirmModal, {
			submitTitle: "방문수령 완료",
			content: (
				<Box>
					<Typography variant="dialogContent">방문수령이 완료되었나요?</Typography>
				</Box>
			),
			onSubmit: async (onClose, setLoadingDone) => {
				setPickUpConfirmMutate(orderId, {
					onSuccess: async () => {
						Toast.success("방문수령이 완료되었어요");
						refreshQuery();
						onClose();
					},
					onError: async () => {
						onClose();
						openModal(modals.confirmModal, {
							title: "죄송합니다, 저장이 실패했습니다",
							content: "잠시 후에 다시 시도해주시기 바랍니다.",
							closeTitle: "닫기",
						});
					},
					onSettled: () => {
						setLoadingDone();
					}
				});
			}
		});
	}

	function handleRefundCheck() {
		openModal(modals.confirmModal, {
			submitTitle: "입금 확인",
			content: (
				<Box>
					<Typography variant="context">입고 후불 배송비 <strong>{ receivingDeliveryCost.toLocaleString() }원</strong>이 입금된 것을 확인하셨나요?</Typography>
				</Box>
			),
			onSubmit: async (onClose, setLoadingDone) => {
				setRefundMutate(orderId, {
					onSuccess: async () => {
						Toast.success("저장이 완료되었어요");
						refreshQuery();
						onClose();
					},
					onError: async () => {
						onClose();
						openModal(modals.confirmModal, {
							title: "죄송합니다, 저장이 실패했습니다",
							content: "잠시 후에 다시 시도해주시기 바랍니다.",
							closeTitle: "닫기",
						});
					},
					onSettled: () => {
						setLoadingDone();
					}
				});
			}
		});
	}

	return (
		<Card>
			<CardContent>
				<CardTitle
					orderType={ orderType }
					date={ date }
					nickname={ nickname }
					userId={ userId }
					cancel={ cancelFlag }
				/>

				<CardSubTitle
					receptionNumber={ receptionNumber }
					brandName={ brandName }
					tentName={ tentName }
					branchName={ branchName }
					open={ open }
					setOpen={ setOpen }
				/>

				{ open && (
					<React.Fragment>
						{/*{ !cancelFlag && deliveryType === "1" && (*/}
						{/*	<BackgroundBox>*/}
						{/*		<Stack direction="row" spacing={ 1 }>*/}
						{/*			<Typography variant="cardBold">견적 합계:</Typography>*/}

						{/*			<Typography*/}
						{/*				variant="cardBold"*/}
						{/*				sx={ { color: (theme) => theme.palette.type.washing } }*/}
						{/*			>*/}
						{/*				{ (total).toLocaleString() } 원*/}
						{/*			</Typography>*/}
						{/*		</Stack>*/}
						{/*	</BackgroundBox>*/}
						{/*) }*/}

						<Box mt={ 2 }>
							<WaitDeliveryInfoCard
								userId={ userId }
								orderId={ orderId }
								deliveryType={ deliveryType }
								deliveryTypeName={ deliveryTypeName }
								deliveryDivision={ deliverDivision }
								deliveryDivisionName={ deliverDivisionName }
								deliveryCost={ deliveryCost }
								refreshQuery={ refreshQuery }
								disabled={ cancelFlag && !refundFlag }
								total={ total }
								cancelFlag={ cancelFlag }
							/>
						</Box>
					</React.Fragment>
				) }
			</CardContent>

			<CardActions variant="adminStepCard">
				{ deliveryType === "1" && (
					<React.Fragment>
						{ cancelFlag && receivingDeliveryCost && (
							<React.Fragment>
								<Typography variant="smallContext">환불비 { (receivingDeliveryCost).toLocaleString() } 원</Typography>

								<Divider orientation="vertical" variant="middle" flexItem sx={{ mx: 1 }} />

								<Button variant="contained" size="small" onClick={ handleRefundCheck } disabled={ refundFlag } sx={{ mr: 1 }}>입금 { refundFlag ? "완료" : "확인" }</Button>
							</React.Fragment>
						) }

						{ cancelFlag && !refundFlag ? (
							<Tooltip arrow placement="left" title="환불비가 입금된 후에 시도해주세요">
								<span>
									<Button variant="contained" size="small" disabled>
										배송 접수 완료
									</Button>
								</span>
							</Tooltip>
						) : (
							<Tooltip arrow placement="left" title="수령방법 및 배송비를 입력한 뒤 접수하세요">
								<span>
									<Button
										variant="contained"
										size="small"
										disabled={ deliveryType === "1" && (!deliveryCost || deliveryCost <= 0) }
										onClick={ handleReceptionDelivery }
									>
										배송 접수 완료
									</Button>
								</span>
							</Tooltip>
						) }
					</React.Fragment>
				) }

				{ deliveryType === "2" && (
					<React.Fragment>
						{ requestPickUpFlag && (
							<React.Fragment>
								<Typography variant="smallContext">{ beforeDateString(requestPickUpDate) } 요청 완료</Typography>

								<Divider orientation="vertical" variant="middle" flexItem sx={{ mx: 1 }}/>
							</React.Fragment>
						) }

						<Button variant="contained" size="small" onClick={ handleRequestPickUp }>방문수령 요청</Button>
						<Button variant="contained" size="small" onClick={ handleSetPickUpConfirm } disabled={ !requestPickUpFlag }>방문수령 완료</Button>
					</React.Fragment>
				) }
			</CardActions>
		</Card>
	);
}

export default Step0Card;