import LoadingButton from "@mui/lab/LoadingButton";
import { Button, DialogActions } from "@mui/material";

export default function FormModalFooter({ type, onClose, formId = "", isLoading, title = "" }) {
	return (
		<DialogActions>
			<Button
				size="small"
				variant="outlined"
				color="secondary"
				onClick={ onClose }
			>
				닫기
			</Button>

			<LoadingButton
				size="small"
				variant="contained"
				type="submit"
				form={ formId }
				loading={ isLoading }
			>
				{ type?.title || title }
			</LoadingButton>
		</DialogActions>
	);
}