import { Box, Button, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { usePublicUserState } from "../../../hooks/public/publicAuthContextProvider";
import { addAddress } from "../../../lib/firestore/User";
import UserAddressAddUpdateCard from "../../public/profile/UserAddressAddUpdateCard";
import UserAddressCard from "../../public/profile/UserAddressCard";
import { Toast } from "../Toast";

function SelectUserAddress({ selectIndex, setSelectIndex }) {
	const { user } = usePublicUserState();
	const [addFlag, setAddFlag] = useState(false);
	const [updateIndex, setUpdateIndex] = useState(null);

	function handleStartAdd() {
		setAddFlag(true);
	}

	function handleCancelAdd() {
		setAddFlag(false);
	}

	function handleUpdateStart(index) {
		setUpdateIndex(index);
	}

	function handleUpdateCancel() {
		setUpdateIndex(null);
	}

	async function handleSubmit(data) {
		if(user.address.length < 3) {
			await addAddress(data).then(() => {
				setAddFlag(false);
				Toast.success("저장되었어요");
			});
		}
	}

	return (
		<Box>
			{ user?.address?.length === 0 && !addFlag && (
				<Box py={ 2 }>
					<Typography align="center" variant="context">배송지를 추가해주세요</Typography>
				</Box>
			) }

			<Stack spacing={ 1 }>
				{ user?.address?.map((item, index) => (
					<UserAddressCard
						key={ `userAddressCard_${index}` }
						address={ item }
						index={ index }
						addFlag={ addFlag }
						updateFlag={ updateIndex !== null }
						isUpdate={ updateIndex === index }
						handleStart={ () => handleUpdateStart(index) }
						handleCancel={ handleUpdateCancel }
						select
						selected={ selectIndex === index }
						setSelect={ setSelectIndex }
					/>
				)) }
			</Stack>

			{ addFlag && (
				<UserAddressAddUpdateCard handleCancel={ handleCancelAdd } submit={ handleSubmit } />
			) }

			{ user?.address?.length < 3 && !addFlag && (
				<Button
					variant="contained"
					size="medium"
					color="secondary"
					disabled={ updateIndex !== null }
					sx={ { mt: 2 } }
					fullWidth
					onClick={ handleStartAdd }
				>
					배송지 등록
				</Button>
			) }
		</Box>
	);
}

export default SelectUserAddress;