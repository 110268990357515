import React, { useEffect, useState } from "react";
import { firebaseAuth } from "../../firebaseAuth";

function UseCurrentUser() {
	const [initializing, setInitializing] = useState(true);
	const [userId, setUserId] = useState(() => firebaseAuth.currentUser?.uid);

	useEffect(() => {
		const unsubscribe = firebaseAuth.onAuthStateChanged(user => {
			if (user) {
				setUserId(user.uid);
			} else {
				setUserId(undefined);
			}

			if (initializing) {
				setInitializing(false);
			}
		});

		return () => unsubscribe;
	}, [firebaseAuth, initializing]);

	return { userId, initializing };
}

export default UseCurrentUser;