import { Box, Divider, Stack, Typography } from "@mui/material";
import { keyframes, styled } from "@mui/material/styles";
import React from "react";
import Bubble01 from "../../assets/images/main_side/bubble_01.png";
import Bubble02 from "../../assets/images/main_side/bubble_02.png";
import Bubble03 from "../../assets/images/main_side/bubble_03.png";
import Bubble04 from "../../assets/images/main_side/bubble_04.png";
import Bubble05 from "../../assets/images/main_side/bubble_05.png";
import HandLogoImage from "../../assets/images/main_side/hand_logo.png";

function MainSideBackground() {
	return (
		<Box sx={ { display: "contents", position: "relative" } }>
			<img src={ HandLogoImage } style={ { height: "650px" } }  alt="텐트세탁소 배경"/>

			<BubblesBox>
				<img className="bubble01" src={ Bubble01 } alt="bubble" />
				<img className="bubble02" src={ Bubble02 } alt="bubble" />
				<img className="bubble03" src={ Bubble03 } alt="bubble" />
				<img className="bubble04" src={ Bubble04 } alt="bubble" />
				<img className="bubble05" src={ Bubble05 } alt="bubble" />
			</BubblesBox>

			<CompanyInfoBox>
				<Stack>
					<Stack direction="row" spacing={ 1 }>
						<Typography variant="sideContext">상호명: (주)마운틴티씨에스</Typography>
						<VerticalDivider />
						<Typography variant="sideContext">대표자: 강지혜</Typography>
						<VerticalDivider />
						<Typography variant="sideContext">통신판매신고: 제2018 수원장안-0184</Typography>
					</Stack>

					<Stack direction="row" spacing={ 1 }>
						<Typography variant="sideContext">사업자등록번호: 512-88-00200</Typography>
						<VerticalDivider />
						<Typography variant="sideContext">이메일: tcs2023@naver.com</Typography>
					</Stack>

					<Stack direction="row" spacing={ 1 }>
						<Typography variant="sideContext">대표전화: 070-4644-0174</Typography>
						<VerticalDivider />
						<Typography variant="sideContext">팩스: 0303-3444-3216</Typography>
					</Stack>

					<Stack direction="row" spacing={ 1 }>
						<Typography variant="sideContext">주소: 경기도 수원시 장안구 천천로240, 하늘연 1층</Typography>
					</Stack>
				</Stack>
			</CompanyInfoBox>
		</Box>
	);
}

const VerticalDivider = styled((props) => (
	<Divider orientation="vertical" variant="middle" flexItem { ...props } />
))(() => ({
	"&&&": {
		marginBottom: "3px",
		marginTop: "3px",
		borderColor: "#939393"
	}
}));

const CompanyInfoBox = styled(Box) (() => ({
	position: "absolute",
	bottom: "100px",
	left: "400px"
}));

const bounce = (maxY) => (keyframes`
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(${ maxY }px);
  }
  100% {
    transform: translateY(0px);
  }
`);

const BubblesBox = styled(Box)(() => ({
	"& > img": {
		position: "absolute",
		"&.bubble01": {
			width: "323px",
			top: "171px",
			left: "84px",
			animation: `${ bounce(30) } 5s linear 0s infinite`
		},
		"&.bubble02": {
			width: "263px",
			top: 0,
			left: "378px",
			animation: `${ bounce(-25) } 3s linear 0s infinite`
		},
		"&.bubble03": {
			width: "284px",
			top: "34px",
			left: "641px",
			animation: `${ bounce(20) } 4s linear 0s infinite`
		},
		"&.bubble04": {
			width: "150px",
			top: "277px",
			left: "578px",
			animation: `${ bounce(-20) } 2s linear 0s infinite`
		},
		"&.bubble05": {
			width: "308px",
			top: "391px",
			left: "699px",
			animation: `${ bounce(-20) } 4s linear 0s infinite`
		}
	}
}));

export default MainSideBackground;