import { FormControl, FormHelperText } from "@mui/material";
import React from "react";
import { useController } from "react-hook-form";
import CheckBox from "./CheckBox";

function FormCheckbox({ name, control, rules, readOnly, ...props }) {
	const {
    field: { value, onChange },
    fieldState: { error }
  } = useController({ name, control, rules });

	return (
		<FormControl error={ !!error }>
			<CheckBox
				{ ...props }
				checked={ value || false }
				onChange={ onChange }
				disabled={ readOnly }
				sx={{
					"&&&": {
						justifyContent: "left",
						width: "fit-content"
					}
				}}
			/>

			{ !!error && (
				<FormHelperText>
					{ error.message }
				</FormHelperText>
			) }
		</FormControl>
	);
}

export default React.memo(FormCheckbox);