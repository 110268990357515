import { IconButton, InputAdornment } from "@mui/material";
import React, { useState } from "react";
import RoundTextField from "./FormTextField";
import { Visibility, VisibilityOff } from "@mui/icons-material";

function RoundPasswordField({
	id,
	...props
}) {
	const [showPassword, setShowPassword] = useState(false);

	function onClickShowPassword() {
		setShowPassword((prev) => !prev);
	}

	function onMouseDownShowPassword(e) {
		e.preventDefault();
	}

	return (
		<RoundTextField
			id={ id }
			type={ showPassword ? "text" : "password" }
			InputProps={ {
				endAdornment: (
					<InputAdornment position="end">
						<IconButton
							onClick={ onClickShowPassword }
							onMouseDown={ onMouseDownShowPassword }
							edge="end"
						>
							{ showPassword ? <VisibilityOff /> : <Visibility /> }
						</IconButton>
					</InputAdornment>
				),
			} }
			{ ...props }
		/>
	);
}

export default React.memo(RoundPasswordField);