import axios from "axios";
import { accessToken as AT, adminAccessToken as ADMIN_AT, firebaseToken as FT } from "../lib/authToken";
import {
	ADMIN_AT_HEADER,
	AT_HEADER,
	FIREBASE_HEADER,
	TOKEN_PREFIX
} from "../lib/tokenConstants";
import {
	getAccessToken,
	getAdminAccessToken
} from "../lib/config/exportToken";

axios.interceptors.request.use(async function (config) {
	// 요청이 전달되기 전에 작업 수행
	const token = AT.getToken();
	const adminToken = ADMIN_AT.getToken();
	let firebaseToken;

	if(config.url.indexOf("/api/admin") < 0) {
		firebaseToken = await FT.getToken();
	}

	if(token) {
		config.headers[AT_HEADER] = TOKEN_PREFIX + token;
	}

	if(adminToken) {
		config.headers[ADMIN_AT_HEADER] = TOKEN_PREFIX + adminToken;
	}

	if(firebaseToken) {
		config.headers[FIREBASE_HEADER] = TOKEN_PREFIX + firebaseToken;
	}

	config.withCredentials = true;

	return config;
}, function (error) {
	return Promise.reject(error);
});

axios.interceptors.response.use(
	function(response) {
		return response;
	},
	async function(error) {
		const { config, response: { data: { code, scope } } } = error;

		// 로그인하지 않은 경우
		if(code === "NOT_FOUND_AT" || code === "NOT_FOUND_FB") {
			if(scope === "ADMIN") {
				// window.location.replace("/admin/login");
			} else if(scope === "PUBLIC") {
				// window.location.replace("/login");
			}
		}

		// 로그인 했지만 AT가 유효하지 않은 경우
		// header에 AT 추가해 RT 재발행
		if(code === "INVALID_AT") {
			const originalRequest = config;
			let refreshTokenUrl = "";
			let loginUrl = "";
			let token;

			if(scope === "ADMIN") {
				refreshTokenUrl = "/api/admin/refresh";
				token = ADMIN_AT;
				loginUrl = "/admin/login";
			} else if(scope === "PUBLIC") {
				refreshTokenUrl = "/api/auth/refresh";
				token = AT;
				loginUrl = "/login";
			}

			const { data: { cd }, headers } = await axios.get(refreshTokenUrl);

			if(cd === "1") {
				token.setToken(scope === "ADMIN" ? getAdminAccessToken(headers) : getAccessToken(headers));
				return axios(originalRequest); // 원래 요청했던 API 계속 진행
			} else {
				token.setToken("");
				// window.location.replace(loginUrl);
			}
		}

		if(code === "INVALID_RT") {
			let loginUrl = "";
			let token;

			if(scope === "ADMIN") {
				token = ADMIN_AT;
				loginUrl = "/admin/login";
			} else if(scope === "PUBLIC") {
				token = AT;
				loginUrl = "/login";
			}

			token.setToken("");
			// window.location.replace(loginUrl);
		}

		if(code === "INVALID_FB") {
			const originalRequest = config;

			await FT.getNewIdToken();
			return axios(originalRequest);
		}

		return Promise.reject(error);
	}
);

export default axios;