import React, { useRef, useState } from "react";
import { Box, Skeleton, Stack, Typography } from "@mui/material";
import FilterSmall, { FILTER_TYPE_NAME, getFilterData } from "../../../components/admin/order/FilterSmall";
import Step8Card from "../../../components/admin/order/Step8Card";
import { useOrderHistoryStepQuery } from "../../../query/admin/orderQuery";

function Step8() {
	const enabled = useRef([FILTER_TYPE_NAME.NICKNAME, FILTER_TYPE_NAME.DATE_RANGE, FILTER_TYPE_NAME.CANCEL]);
	const [filter, setFilter] = useState(getFilterData(enabled.current));
	const { isLoading, data } = useOrderHistoryStepQuery(8, filter);

	function submit(filter) {
		setFilter((prev) => ({
			...prev,
			...filter
		}));
	}

	return (
		<Stack spacing={ 2 }>
			<FilterSmall handleSubmit={ submit } enabled={ enabled.current } />

			{ !isLoading && data.length === 0 && (
				<Box py={ 2 }>
					<Typography align="center" variant="context">주문 내역이 없어요</Typography>
				</Box>
			) }

			{ isLoading ? (
				<Skeleton variant="rounded" width="100%" height="200px" />
			) : (
				data.map((item) => (
					<Step8Card
						key={ item.ordrId }
						userId={ item.usrId }
						nickname={ item.usrNcknm }
						orderType={ item.ordrTp }
						brandName={ item.brndNm }
						tentName={ item.tntNm }
						date={ item.hstry }
						branchName={ item.brnchNm }
						receptionNumber={ item.rcptnNo }
						cancelFlag={ item.csStts === "1" }
						detail={ item.details[3].detail }
						total={ item.details[3].total }
						deliverDivision={ item.dlvrDvsn }
						deliverDivisionName={ item.dlvrDvsnNm }
						deliveryCost={ item.dlvryCst }
						receivingDeliveryCost={ item.rcvngDlvryCst }
						deliveryReceptionFlag={ item.dlvrRcptYn === "Y" }
						deliveryName={ item.dlvryNm }
						invoiceNumber={ item.invceNo }
						deliveryType={ item.dlvryTp }
						deliveryTypeName={ item.dlvryTpNm }
						deliveryStartDate={ item.dlvryStrtDtm }
						requestPickUpDate={ item.rqstPckUpDtm }
						confirmType={ item.rcvCnfrmTp }
						receiveFlag={ item.rcvYn === "Y" }
					/>
				))
			) }
		</Stack>
	);
}

export default Step8;