import { FactCheckRounded } from "@mui/icons-material";
import { Card, CardContent, Stack, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React from "react";

function DeliveryConfirmIntoCard({ confirmFlag, confirmTypeName, deliveryType, deliveryTypeName, deliveryDate, date }) {
	return (
		<Card>
			<CardContent>
				<Stack
					direction="row"
					alignItems="center"
					justifyContent="space-between"
					mb={ 2 }
				>
					<Stack direction="row" spacing={ 1 }>
						<FactCheckRounded fill="none" />
						<Typography variant="cardBold">수령 정보</Typography>
					</Stack>
				</Stack>

				<Grid container>
					<React.Fragment>
						<Grid xs={ 4 }>
							<Typography variant="cardContext" type="title">수령 방법</Typography>
						</Grid>
						<Grid xs={ 8 }>
							<Typography variant="cardContext">{ deliveryTypeName }</Typography>
						</Grid>
					</React.Fragment>

					<React.Fragment>
						<Grid xs={ 4 }>
							<Typography variant="cardContext" type="title">{ deliveryTypeName + (deliveryType === "2" ? " 요청" : " 접수") }일</Typography>
						</Grid>
						<Grid xs={ 8 }>
							<Typography variant="cardContext">{ deliveryDate }</Typography>
						</Grid>
					</React.Fragment>

					{ confirmFlag && (
						<React.Fragment>
							<React.Fragment>
								<Grid xs={ 4 }>
									<Typography variant="cardContext" type="title">수령확인 유형</Typography>
								</Grid>
								<Grid xs={ 8 }>
									<Typography variant="cardContext">{ confirmTypeName }</Typography>
								</Grid>
							</React.Fragment>

							<React.Fragment>
								<Grid xs={ 4 }>
									<Typography variant="cardContext" type="title">수령확인일</Typography>
								</Grid>
								<Grid xs={ 8 }>
									<Typography variant="cardContext">{ date }</Typography>
								</Grid>
							</React.Fragment>
						</React.Fragment>
					) }
				</Grid>
			</CardContent>
		</Card>
	);
}

export default DeliveryConfirmIntoCard;