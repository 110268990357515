import { Box, Button, Divider, IconButton, Stack, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { modals } from "../../../hooks/Modals";
import useModal from "../../../hooks/useModal";
import { isEmptyString } from "../../../lib/libs";
import DateRange from "../../public/DateRange";
import { FreeMode, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import { ExpandMoreRounded, CloseRounded, TuneRounded } from "@mui/icons-material";

function Filter({
	enabled = [],
	handleSubmit,
}) {
	const [params, setParams] = useSearchParams();
	const { openModal } = useModal();
	const [filter, setFilter] = useState({
		...getSearchParamData(params, enabled),
	});

	useEffect(() => {
		for(let key of Object.keys(filter)) {
			if(enabled.includes(getFilterTypeName(key))) {
				const value = filter[key];

				params.set(key, JSON.stringify(value));
			}
		}

		params.set("sorting", filter.sorting);

		setParams(params);
		handleSubmit(filter);

		document.querySelector(".swiper").swiper.update();
	}, [filter]);

	function openFilterModal() {
		openModal(modals.filterModal, {
			enabled, // [TYPE_NAME]
			filterData: filter,
			setFilterData: setFilter,
			onSubmit: (filterData) => {
				setFilter((prev) => ({
					...prev,
					...filterData,
				}));
			},
		});
	}

	function handleClick(type) {
		const {
			      isEmpty: customIsEmpty,
			      getData,
			      setData,
						isRadio,
		      } = FILTER_TYPE[type];
		const data = getData(filter);
		const isEmpty = customIsEmpty !== undefined ? customIsEmpty(data) : isEmptyString(data);

		if(isRadio || isEmpty) {
			openFilterModal();
		} else {
			if(isRadio) {
				return;
			}

			if(type === FILTER_TYPE_NAME.PAYMENT) {
				setData(setFilter, false);
			} else if(type === FILTER_TYPE_NAME.DATE_RANGE) {
				setData(setFilter, ["", ""]);
			} else {
				setData(setFilter);
			}
		}
	}

	return (
		<Stack direction="row" spacing={ 1.5 }>
			<FilterSwiperWrap
				slidesPerView="auto"
				spaceBetween={ 10 }
				modules={ [FreeMode, Pagination] }
				freeMode
			>
				<SwiperSlide>
					<Item noneicon={ 1 } onClick={() => setFilter((prev) => ({...prev, sorting: prev.sorting === "desc" ? "asc" : "desc" }))}>{ filter.sorting === "desc" ? "내림차순" : "오름차순" }</Item>
				</SwiperSlide>

				{ Object.keys(FILTER_TYPE_NAME).map((typeName, index) => {
					const { getData } = FILTER_TYPE[typeName];

					return (
						enabled.includes(typeName) && (
							<SwiperSlide key={ `filterSmall_${ typeName }` }>
								<FilterItem
									type={ typeName }
									value={ getData(filter) }
									onClick={ () => handleClick(typeName) }
								/>
							</SwiperSlide>
						)
					);
				}) }
			</FilterSwiperWrap>

			{ enabled.length > 0 && (
				<FilterOpenButton size="small" disableTouchRipple onClick={ openFilterModal }>
					<TuneRounded />
				</FilterOpenButton>
			) }
		</Stack>
	);
}

export function getFilterData(enabled, filterData = FILTER_INIT) {
	const data = {
		sorting: filterData.sorting
	};

	if(enabled.includes(FILTER_TYPE_NAME.NICKNAME)) {
		data.nickname = filterData.nickname;
	}
	if(enabled.includes(FILTER_TYPE_NAME.DATE_RANGE)) {
		data.dateRange = filterData.dateRange;
	}
	if(enabled.includes(FILTER_TYPE_NAME.RECEPTION_NUMBER)) {
		data.receptionNumber = filterData.receptionNumber;
	}
	if(enabled.includes(FILTER_TYPE_NAME.PAYMENT)) {
		data.payment = filterData.payment;
	}
	if(enabled.includes(FILTER_TYPE_NAME.DELIVERY_TYPE)) {
		data.deliveryType = filterData.deliveryType;
	}
	if(enabled.includes(FILTER_TYPE_NAME.CANCEL)) {
		data.cancel = filterData.cancel;
	}
	if(enabled.includes(FILTER_TYPE_NAME.PICK_UP)) {
		data.pickUp = filterData.pickUp;
	}

	return data;
}

export function getSearchParamData(params, enabled = []) {
	const filter = {
		sorting: params.get("sorting") || FILTER_INIT.sorting
	};

	for(let enabledType of enabled) {
		let key = FILTER_TYPE_KEY[enabledType];

		filter[key] = JSON.parse(params.get(key)) || FILTER_INIT[key];
	}

	return filter;
}

export const FILTER_TYPE_NAME = {
	NICKNAME: "NICKNAME",
	DATE_RANGE: "DATE_RANGE",
	RECEPTION_NUMBER: "RECEPTION_NUMBER",
	PAYMENT: "PAYMENT",
	DELIVERY_TYPE: "DELIVERY_TYPE",
	CANCEL: "CANCEL",
	PICK_UP: "PICK_UP",
};

const FILTER_TYPE_KEY = {
	NICKNAME: "nickname",
	DATE_RANGE: "dateRange",
	RECEPTION_NUMBER: "receptionNumber",
	PAYMENT: "payment",
	DELIVERY_TYPE: "deliveryType",
	CANCEL: "cancel",
	PICK_UP: "pickUp",
};

function getFilterTypeName(key) {
	return Object.keys(FILTER_TYPE_KEY).find((item) => FILTER_TYPE_KEY[item] === key);
}

export const FILTER_INIT = {
	sorting: "desc",
	nickname: "",
	dateRange: [dayjs(new Date()).subtract(7, "day").format("YYYY-MM-DD"), dayjs(new Date()).format("YYYY-MM-DD")],
	receptionNumber: "",
	payment: "1",
	deliveryType: "1",
	cancel: "1", // 1: 전체, 2: 완료, 3: 취소
	pickUp: "1" // 1: 전체, 2: 방문수거, 3: 택배
};

export const FILTER_TYPE = {
	DATE_RANGE: {
		label: "날짜",
		isEmpty: ([start, end]) => start === "Invalid Date" || end === "Invalid Date" || isEmptyString(start) || isEmptyString(end),
		getText: ([start, end]) => `${ start } ~ ${ end }`,
		getData: (filter) => filter.dateRange,
		setData: (setFilterData, dateRange = FILTER_INIT.dateRange) => {
			setFilterData((prev) => ({
				...prev,
				dateRange,
			}));
		},
	},
	NICKNAME: {
		label: "고객명",
		getData: (filter) => filter.nickname,
		setData: (setFilterData, nickname = FILTER_INIT.nickname) => {
			setFilterData((prev) => ({
				...prev,
				nickname,
			}));
		},
	},
	RECEPTION_NUMBER: {
		label: "접수번호",
		getData: (filter) => filter.receptionNumber,
		setData: (setFilterData, receptionNumber = FILTER_INIT.receptionNumber) => {
			setFilterData((prev) => ({
				...prev,
				receptionNumber,
			}));
		},
	},
	PAYMENT: {
		label: "구분", // 전체 || 작업 대기 || 미결제
		isRadio: true,
		radioType: ["전체", "작업 대기", "미결제"],
		getText: (payment) => payment === "1" ? "전체" : (payment === "2" ? "작업 대기" : "미결제"),
		getData: (filter) => filter.payment,
		setData: (setFilterData, payment = FILTER_INIT.payment) => {
			setFilterData((prev) => ({
				...prev,
				payment,
			}));
		},
	},
	DELIVERY_TYPE: {
		label: "구분", // 전체 || 배송 || 방문
		isRadio: true,
		radioType: ["전체", "배송", "방문"],
		getText: (pickUp) => pickUp === "1" ? "전체" : (pickUp === "2" ? "배송" : "방문"),
		getData: (filter) => filter.deliveryType,
		setData: (setFilterData, deliveryType = FILTER_INIT.deliveryType) => {
			setFilterData((prev) => ({
				...prev,
				deliveryType,
			}));
		},
	},
	CANCEL: {
		label: "구분",
		isRadio: true,
		radioType: ["전체", "완료", "취소"],
		getText: (cancel) => cancel === "1" ? "전체" : (cancel === "2" ? "완료" : "취소"),
		getData: (filter) => filter.cancel,
		setData: (setFilterData, cancel = FILTER_INIT.cancel) => {
			setFilterData((prev) => ({
				...prev,
				cancel,
			}));
		},
	},
	PICK_UP: {
		label: "구분",
		isRadio: true,
		radioType: ["전체", "방문수거", "택배"],
		getText: (cancel) => cancel === "1" ? "전체" : (cancel === "2" ? "방문수거" : "택배"),
		getData: (filter) => filter.pickUp,
		setData: (setFilterData, pickUp = FILTER_INIT.pickUp) => {
			setFilterData((prev) => ({
				...prev,
				pickUp,
			}));
		},
	},
};

export function FilterItem({
	type,
	value,
	onClick,
}) {
	const {
		      label: defaultLabel,
		      isEmpty: customIsEmpty,
		      getText: customGetText,
		      isRadio,
	      } = FILTER_TYPE[type];
	const isEmpty = customIsEmpty !== undefined ? customIsEmpty(value) : isEmptyString(value);
	const text = customGetText !== undefined ? customGetText(value) : value;

	return (
		<Item onClick={ () => onClick(type) } noneicon={ isRadio ? 1 : 0 }>
			{ isEmpty ? defaultLabel : text }
			{ !isRadio && (isEmpty ? <ExpandMoreRounded /> : <CloseRounded />) }
		</Item>
	);
}

export const FilterSwiperWrap = styled(Swiper)(({ theme }) => ({
	width: "100%",
	height: 35,
	zIndex: theme.zIndex.drawer - 1,
	"& .swiper-slide": {
		width: "fit-content",
	},
}));

const Item = styled(Box)(({ theme, noneicon }) => ({
	height: "100%",
	boxSizing: "border-box",
	display: "flex",
	alignItems: "center",
	justifyContent: "space-between",
	fontSize: 12,
	backgroundColor: "#3D3D3D",
	padding: !noneicon ? "0 5px 0 10px" : "0 10px 0",
	borderRadius: 5,
	minWidth: "100%",
	width: "max-content",
	cursor: "pointer",
	transition: "background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
	"&:hover": {
		backgroundColor: "rgba(255, 255, 255, 0.08)",
	},
	"& > .MuiSvgIcon-root": {
		transform: "scale(.7)",
		fill: theme.palette.text.secondary,
	},
}));

const FilterOpenButton = styled(IconButton)(({ theme }) => ({
	backgroundColor: "#3D3D3D",
	borderRadius: 20,
	"& > .MuiSvgIcon-root": {
		fill: theme.palette.text.secondary,
	},
}));

export default Filter;