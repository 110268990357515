import qs from "query-string";
import { LOGIN_DEFAULT_URL } from "./defaultUrl";
import axios from "../axios";

export async function getKey() {
    const res = await axios.get(LOGIN_DEFAULT_URL + "/getKey", {});
    return res?.data;
}

export async function login(userData) {
    return await axios.post(LOGIN_DEFAULT_URL + "/intro", qs.stringify(userData));
}

export async function getLoginFailCount() {
    const res = await axios.get(LOGIN_DEFAULT_URL + "/getLoginFailCount");
    return Number(res?.data);
}