import { Box, SvgIcon, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";

function StepButton({ text, isActive, icon, onClick }) {
	return (
		<StepBox
			onClick={ onClick }
			text={ icon !== undefined ? text : "" }
		>
			<IconBox className={ isActive ? "active" : null }>
				{ icon === undefined ? (
					<Typography>{ text }</Typography>
				) : (
					<SvgIcon component={ icon }  sx={{ fontSize: 1 }} />
				) }
			</IconBox>
		</StepBox>
	);
}

const StepBox = styled(Box)(({ theme, text }) => ({
	flex: "1 1 auto",
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
	position: "relative",
	cursor: "pointer",
	"&:hover": {
		"& > .MuiBox-root:not(.active)": {
			backgroundColor: "#373737",
		}
	},
	"&::after": {
		content: `"${ text }"`,
		fontSize: 12,
		color: theme.palette.text.primary,
		textAlign: "center",
		whiteSpace: "pre",
		position: "absolute",
		top: "100%",
		marginTop: 8,
		fontWeight: 700
	}
}));

const IconBox = styled(Box)(({ theme }) => ({
	border: "1px solid #505050",
	backgroundColor: "#272727",
	transition: "all .1s",
	"&.active": {
		backgroundColor: "#E8410B",
		borderColor: "#E8410B",
		"& > .MuiSvgIcon-root": {
			stroke: theme.palette.text.primary
		},
	},
	borderRadius: "100px",
	padding: 6,
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	"& > .MuiSvgIcon-root": {
		stroke: "#505050",
		fill: "none",
		fontSize: "1.4rem"
	},
	"& > .MuiTypography-root": {
		width: "1.4rem",
		height: "1.4rem",
		lineHeight: "11px",
		textAlign: "center",
		fontSize: 10,
		fontWeight: 700,
		whiteSpace: "pre"
	}
}));

export default StepButton;