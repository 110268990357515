import React from "react";
import { Container } from "@mui/material";
import ContentImage from "../../../assets/images/products/saleuleu.jpeg";
import ProductFixedButton from "../../../components/public/ProductFixedButton";

function Saleuleu() {
	return (
		<Container variant="contextRoot">
			<Container variant="contextBox">
				<img src={ ContentImage } style={ { width: "100%" } } />

				<ProductFixedButton url={ "https://smartstore.naver.com/rhou2002/products/2929973208" } />
			</Container>
		</Container>
	);
}

export default Saleuleu;