import { Settings } from "@mui/icons-material";
import { Box, IconButton, ListItemIcon, Menu, MenuItem } from "@mui/material";
import React, { useState } from "react";

export function DropDownButton({ menuItems, onClick }) {
	const [anchorEl, setAnchorEl] = useState(null);

	function close() {
		setAnchorEl(null);
	}

	function open({ currentTarget }) {
		setAnchorEl(currentTarget);
	}

	function handleClick(value) {
		onClick(value);
		close();
	}

	return (
		<Box>
			<IconButton
				size="small"
				onClick={ open }
				sx={ { color: (theme) => theme.palette.text.secondary } }
			>
				<Settings size="small" />
			</IconButton>

			<Menu
				open={ Boolean(anchorEl) }
				anchorEl={ anchorEl }
				onClose={ close }
				transformOrigin={{ horizontal: 'right', vertical: 'top' }}
				anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
			>
				{ menuItems.map((item, index) => (
					<MenuItem
						key={ `menuItems_${index}` }
						onClick={ () => handleClick(item.value) }
						sx={{ padding: "6px 16px" }}
					>
							<ListItemIcon>
                  <item.icon />
							</ListItemIcon>
						{ item.label }

						{/*<ListItemText>{ item.label }</ListItemText>*/}
					</MenuItem>
				)) }
			</Menu>
		</Box>
	);
}