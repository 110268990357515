import React from "react";
import AddTentAndBrandModal from "../components/common/modal/AddTentAndBrandModal";
import ChangeDeliveryType from "../components/common/modal/ChangeDeliveryType";
import ChecklistModal from "../components/common/modal/ChecklistModal";
import DeliveryReceptionModal from "../components/common/modal/DeliveryReceptionModal";
import DeliveryTypeModal from "../components/common/modal/DeliveryTypeModal";
import FilterModal from "../components/common/modal/FilterModal";
import { useFormModalsDispatch, useFormModalsState } from "./modalProvider";
import FormModal from "../components/admin/formModal/FormModal";
import CardModal from "../components/admin/CardModal";
import ImageModal from "../components/common/modal/BigImageModal";
import ConfirmModal from "../components/common/modal/ConfirmModal";
import PostcodeModal from "../components/common/modal/PostcodeModal";
import NoticeModal from "../components/common/modal/NoticeModal";
import AddressesModal from "../components/common/modal/AddressesModal";

export const modals = {
	formModal: FormModal,
	cardModal: CardModal,
	imageModal: ImageModal,
	confirmModal: ConfirmModal,
	postcodeModal: PostcodeModal,
	noticeModal: NoticeModal,
	addressesModal: AddressesModal,
	deliveryReceptionModal: DeliveryReceptionModal,
	addTentAndBrandModal: AddTentAndBrandModal,
	filterModal: FilterModal,
	deliveryTypeModal: DeliveryTypeModal,
	checkListModal: ChecklistModal,
	changeDeliveryType: ChangeDeliveryType
};

export default function Modals() {
	const openedModals = useFormModalsState();
	const { close } = useFormModalsDispatch();

	return openedModals.map(({ Component, props }, index) => {
		const { onSubmit, beforeClose = () => {}, ...rest } = props;

		function onClose() {
			close(Component);
			beforeClose();
		}

		return (
			<Component
				{ ...rest }
				key={ `modal_${ index }` }
				onClose={ onClose }
				onSubmit={ onSubmit }
			/>
		);
	});
}