import React from "react";
import {
	Box,
	Container,
	Link,
	Stack,
	Typography
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import PointerCursorLink from "../components/common/PointerCursorLink";
import { useNavigate } from "react-router-dom";

function NotFound() {
	const navigate = useNavigate();

	function goHomePage() {
		navigate("/admin");
	}

	return (
		<Container
			maxWidth={ "md" }
			sx={{ backgroundColor: "#000" }}
		>
			<Grid
				container
				direction={ "column" }
				justifyContent={ "center" }
				alignItems={ "center" }
				spacing={ 0.5 }
				sx={ { height: "100vh", textAlign: "center" } }
			>
				<Typography variant={ "h4" } mb={ 3 }>404 ERROR</Typography>

				<Typography component={ Grid }>죄송합니다. 페이지를 찾을 수 없습니다.</Typography>
				<Typography component={ Grid }>존재하지 않는 페이지를 입력하셨거나,</Typography>
				<Typography component={ Grid }>요청하신 페이지의 주소가 변경/삭제되어 찾을 수 없습니다.</Typography>

				{/*<PointerCursorLink mt={2} variant={ "body1" } onClick={ goHomePage }>홈으로</PointerCursorLink>*/}
			</Grid>
		</Container>
	);
}

export default NotFound;