import {
	ADMIN_AT_HEADER,
	ADMIN_RT_HEADER,
	AT_HEADER,
	FIREBASE_HEADER,
	RT_HEADER
} from "../tokenConstants";

export function getAdminAccessToken(headers) {
	return getToken(headers, ADMIN_AT_HEADER);
}

export function getAccessToken(headers) {
	return getToken(headers, AT_HEADER);
}

export function getAdminRefreshToken(headers) {
	return getToken(headers, ADMIN_RT_HEADER);
}

export function getRefreshToken(headers) {
	return getToken(headers, RT_HEADER);
}

export function getFirebaseToken(headers) {
	return getToken(headers, FIREBASE_HEADER);
}

function getToken(headers, headerName) {
	return headers[headerName] || "";
}