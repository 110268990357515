import { useMutation, useQuery } from "@tanstack/react-query";
import { deleteBanner } from "../../api/admin/banner";
import * as API from "../../api/admin/banner";

export const USE_BANNER_QUERY_KEY = ["getBanner"];

export function useBannerQuery() {
	const {
		      isLoading,
		      isError,
		      isSuccess,
		      data,
	      } = useQuery([...USE_BANNER_QUERY_KEY], API.getData, {
		refetchOnWindowFocus: false,
	});

	return {
		isLoading,
		isError,
		isSuccess,
		data,
	};
}

export function useNewBannerMutate() {
	const { mutate: newBannerMutate } = useMutation({
		mutationFn: API.insert,
	});

	return { newBannerMutate };
}

export function useUpdateOrderMutate() {
	const { mutate: updateOrderMutate } = useMutation({
		mutationFn: API.updateOrder,
	});

	return { updateOrderMutate };
}



export function useDeleteBannerMutate() {
	const { mutate: deleteBannerMutate } = useMutation({
		mutationFn: API.deleteBanner
	});

	return {
		deleteBannerMutate,
	};
}