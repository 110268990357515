import {
	useMutation,
	useQuery,
} from "@tanstack/react-query";
import * as API from "../../api/admin/amount";

export const GET_TENT_AMOUNT_KEY = ["admin", "getTentInfo"];

export function useBrandQuery(brndId = "") {
	const {
					isLoading: isBrandLoading,
					isError: isBrandError,
					isSuccess: isBrandSuccess,
					data: brandData,
				} = useQuery(["admin", "amount", "getBrand"], API.getBrands, {
		enabled: brndId === "",
		staleTime: 30 * 1000,
	});

	return {
		isBrandLoading,
		isBrandError,
		isBrandSuccess,
		brandData,
	};
}

export function useTentQuery(brndId = "") {
	const {
					isLoading: isTentLoading,
					isFetching: isTentFetching,
					isError: isTentError,
					isSuccess: isTentSuccess,
					data: tentData,
				} = useQuery(["admin", "amount", "getTent", brndId], () => API.getTent(brndId), {
		staleTime: 30 * 1000,
	});

	return {
		isTentLoading,
		isTentFetching,
		isTentError,
		isTentSuccess,
		tentData,
	};
}

export function useTentInfoQuery(tntId = "") {
	const {
					isLoading: isTentInfoLoading,
					isFetching: isTentInfoFetching,
					isError: isTentInfoError,
					isSuccess: isTentInfoSuccess,
					data: tentInfoData,
				} = useQuery([...GET_TENT_AMOUNT_KEY, tntId], () => API.getTentInfo(tntId), {
		// staleTime: 30 * 1000,
		cacheTime: 0,
		placeholderData: [],
		select: (data) => {
			const getData = data.reduce((acc, cur, i, array) => {
				if(cur.grpId !== undefined) {
					const before = acc.filter((item) => item.grpId === cur.grpId);
					if(!before.length) {
						const sub = array.filter((item) => item.grpId === cur.grpId);
						const subExist = !!sub.filter((item) => item.itmId !== undefined).length;
						const changeSubUseYn = sub.map((item) => {
							return {
								...item,
								dcYn: item.dcYn === "Y",
								useYn: item.useYn === "Y"
							};
						});

						return [
							...acc,
							{
								...cur,
								sub: subExist ? changeSubUseYn : [
									{
										grpId: cur.grpId,
										itmNm: "항목 추가",
									}],
							}];
					}
				} else {
					// const before = acc.filter((item) => item.grpId === "notingGroup");
					// if(!before.length) {
					//     const sub = array.filter((item) => item.grpId === undefined);
					//     const changeUseYnSub = sub.map((item) => {
					//         return {
					//             ...item,
					//             useYn: item.useYn === "Y"
					//         };
					//     });
					//     return [
					//         ...acc,
					//         {
					//             ...cur,
					//             grpId: "notingGroup",
					//             grpNm: "기타",
					//             useYn: cur.useYn === "Y",
					//             sub: changeUseYnSub
					//         }
					//     ];
					// }

					return [
						...acc,
						{
							...cur,
							grpId: "notingGroup",
							grpNm: cur.itmNm,
							sub: [{
								...cur,
								dcYn: cur.dcYn === "Y",
								useYn: cur.useYn === "Y"
							}],
						}];
				}

				return [...acc];
			}, []);

			return getData.map((item) => {
				return {
					...item,
					dcYn: item.dcYn === "Y",
					useYn: item.useYn === "Y"
				};
			});
		},
	});

	return {
		isTentInfoLoading,
		isTentInfoFetching,
		isTentInfoError,
		isTentInfoSuccess,
		tentInfoData,
	};
}

export function useInsertGroupMutation() {
	const { mutate: insertGroupMutate } = useMutation({
		mutationFn: API.insertGroup,
	});

	return { insertGroupMutate };
}

export function useUpdateGroupMutation() {
	const { mutate: updateGroupMutate } = useMutation({
		mutationFn: API.updateGroup,
	});

	return { updateGroupMutate };
}

export function useDeleteGroupMutation() {
	const { mutate: deleteGroupMutate } = useMutation({
		mutationFn: API.deleteGroup,
	});

	return { deleteGroupMutate };
}

export function useInsertItemMutation() {
	const { mutate: insertItemMutate } = useMutation({
		mutationFn: (data) => {
			return API.insertItem({
				...data,
				dcYn: data.dcYn ? "Y" : "N",
				useYn: data.useYn ? "Y" : "N"
			});
		},
	});

	return { insertItemMutate };
}

export function useUpdateItemMutation() {
	const { mutate: updateItemMutate } = useMutation({
		mutationFn: (data) => {
			return API.updateItem({
				...data,
				dcYn: data.dcYn ? "Y" : "N",
				useYn: data.useYn ? "Y" : "N",
			});
		},
	});

	return { updateItemMutate };
}

export function useDeleteItemMutation() {
	const { mutate: deleteItemMutate } = useMutation({
		mutationFn: (data) => {
			return API.deleteItem({
				...data,
				dcYn: data.dcYn ? "Y" : "N",
				useYn: data.useYn ? "Y" : "N",
			});
		},
	});

	return { deleteItemMutate };
}