import { Alert } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { isEmptyString } from "../lib/libs";
import { getCookie } from "./cookies";

function useTimer({ expiresDate, afterExpiresCallback }) {
	const timerId = useRef(null);

	function clearTimer() {
		if(timerId.current !== null) {
			clearTimeout(timerId.current);
			timerId.current = null;
		}
	}

	useEffect(() => {
		if(!isEmptyString(expiresDate)) {
			const now = new Date();
			const thisExpiresDate = new Date(expiresDate || getCookie("delayDate"));
			const interval = Math.ceil((thisExpiresDate - now));

			timerId.current = setTimeout(afterExpiresCallback, interval);
		}

		return () => { clearTimer() };
	}, [expiresDate]);
}

export default useTimer;