import { Box, Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import BackgroundImage from "../../../assets/images/history/background.png";
import { isEmptyString } from "../../../lib/libs";
import BackgroundBox from "../../common/BackgroundBox";
import ImageUpload from "../ImageUpload";
import RequestContextField from "../RequestContextField";
import DeliveryAddressCard from "./DeliveryAddressCard";

function Step7AccordionContent({ items, workingImages, isCurrentStep }) {
	return (
		<Stack>
			<BackgroundBox mb={ 4 }>
				<ul>
					<li>
						<Typography variant="context">고객님의 텐트를 깨끗하게 { items.ordrTpNm } 중이에요!</Typography>
					</li>

					{ items.dlvrDvsn === "1" && (
						<li>
							<Typography variant="context">배송지를 <strong>제주도/도서산간</strong> 지역으로 변경 시 <strong>추가 배송비</strong>가 발생할 수 있습니다</Typography>
						</li>
					) }
				</ul>
			</BackgroundBox>

			{ items.dlvryTp === "1" && (
				<DeliveryAddressCard updateFlag={ isCurrentStep } />
			) }

			{ workingImages?.length > 0 && (
				<Box mt={ 2 }>
					<ImageUpload
						images={ workingImages }
						readOnly
					/>
				</Box>
			) }

			{ !isEmptyString(items.wrkngCntnt) && (
				<Box mt={ workingImages?.length > 0 ? 0 : 2 }>
					<RequestContextField
						defaultValue={ items.wrkngCntnt }
						title="안내사항"
						readOnly
					/>
				</Box>
			) }

			<StyledBox mt={ 4 }>
				<Image />

				<Typography>업계 최고의 경험과 제품으로<br />세탁하고 있습니다.</Typography>
			</StyledBox>
		</Stack>
	);
}

const StyledBox = styled(Box)(() => ({
	width: "100%",
	height: 150,
	position: "relative",
	"& > .MuiTypography-root": {
		fontSize: 24,
		fontWeight: 700,
		position: "absolute",
		width: "100%",
		top: "50%",
		right: "50%",
		textAlign: "center",
		transform: "translate(50%, -50%)"
	}
}));

const Image = styled(Box)(() => ({
	height: "100%",
	backgroundImage: `url(${ BackgroundImage })`,
	mixBlendMode: "luminosity",
	// mixBlendMode: "color-burn",
	backgroundRepeat: "no-repeat",
	backgroundPositionX: "-20px",
	backgroundSize: "contain"
}));

export default Step7AccordionContent;