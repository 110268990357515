import styled from "styled-components";
import { Link } from "@mui/material";

export default function PointerCursorLink (props) {
    return <PointerLink { ...props } />;
}

const PointerLink = styled(Link) (() => ({
    "&:hover": {
        cursor: "pointer"
    }
}));