import Grid from "@mui/material/Unstable_Grid2";
import React, { useMemo } from "react";
import {
	Card, CardContent, Divider, Stack, SvgIcon, Typography,
} from "@mui/material";
import {
	ReactComponent as Repair,
} from "../../assets/images/history/07_repair.svg";
import {
	ReactComponent as Washing,
} from "../../assets/images/history/07_washing.svg";
import EstimateItems from "./EstimateItems";

function EstimateCard({
	orderType,
	title = "가견적",
	detail,
	total,
	shownPrice = true,
	showIcon = false,
	repairContent,
	isComplete = false,
	receivingPrice = false,
}) {
	const components = useMemo(() => ({
		"1": Washing,
		"2": Repair,
	}), []);

	return (
		<Card>
			<CardContent sx={{ padding: "16px !important" }}>
				{ orderType === "1" && (
					<React.Fragment>
						<Stack
							direction="row"
							justifyContent="flex-start"
							alignItems="center"
							spacing={ 2 }
							mb={ 2 }
						>
							{ showIcon && orderType && (
								<SvgIcon
									variant="cardIcon"
									component={ components[orderType] }
									sx={ {
										width: "22px",
										height: "22px",
										stroke: (theme) => theme.palette.type[orderType === "1" ? "washing" : "repair"]
									} }
								/>
							) }

							<Typography variant="cardContext" color="seconds">{ title }</Typography>
						</Stack>

						{ detail.length > 0 && (
							<React.Fragment>
								<EstimateItems data={ detail } shownPrice={ shownPrice } isComplete={ isComplete } total={ total } />
							</React.Fragment>
						) }

						{ receivingPrice && (
							<Grid container mb={ 2 }>
								<Grid xs={ 9 }>
									<Typography variant="cardContext">입고 후불 배송비</Typography>
								</Grid>

								<Grid xs={ 3 }>
									<Typography variant="cardContext" sx={{ textAlign: "right" }}>{ (receivingPrice).toLocaleString() } 원</Typography>
								</Grid>
							</Grid>
						) }

						{ total && (
							<React.Fragment>
								{ orderType === "1" && detail.length > 0 && (
									<Divider />
								) }

								<Typography
									variant="cardBold"
									mt={ 2 }
									sx={ {
										textAlign: "right",
										color: (theme) => theme.palette.type.washing,
									} }
								>
									{ (total).toLocaleString() } 원
								</Typography>
							</React.Fragment>
						) }
					</React.Fragment>
				) }

				{ orderType === "2" && (
					<React.Fragment>
						<Stack spacing={ 2 }>
							<Stack
								direction="row"
								justifyContent="space-between"
								alignItems="center"
							>
								<Stack
									direction="row"
									justifyContent="flex-start"
									alignItems="center"
									spacing={ 2 }
								>
									{ showIcon && orderType && (
										<SvgIcon
											variant="cardIcon"
											component={ components[orderType] }
											sx={ {
												width: "22px",
												height: "22px",
												stroke: (theme) => theme.palette.type[orderType === "1" ? "washing" : "repair"]
											} }
										/>
									) }

									<Typography variant="cardContext" color="seconds">{ title }</Typography>
								</Stack>

								<Typography
									variant="cardBold"
									sx={ {
										color: (theme) => theme.palette.type.washing,
									} }
								>
									{ (total).toLocaleString() } 원
								</Typography>
							</Stack>

							{ repairContent && (
								<React.Fragment>
									<Divider flexItem />

									<Typography variant="cardContext">{ repairContent }</Typography>
								</React.Fragment>
							) }
						</Stack>
					</React.Fragment>
				) }
			</CardContent>
		</Card>
	);
}

export default EstimateCard;