import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import {
	SvgIcon,
	Typography
} from "@mui/material";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import {
	ReactComponent as Arrow
} from "../../assets/images/icon/accordion.svg";

function QnAAccordion({ items }) {
	const [open, setOpen] = useState(null);

	const onChange = (openIndex) => (e, newExpanded) => {
		setOpen(newExpanded ? openIndex : null);
	};

	return (
		<React.Fragment>
			{ items.map(({ title, context }, index) => (
				<StyledAccordion key={ `accordion_${ title }_${ index }` } expanded={ open === index } onChange={ onChange(index) }>
					<AccordionSummary>
						<Typography>{ title }</Typography>
					</AccordionSummary>

					<AccordionDetails>
						<Typography>{ context }</Typography>
					</AccordionDetails>
				</StyledAccordion>
			)) }
		</React.Fragment>
	);
}

const StyledAccordion = styled((props) => (
	<MuiAccordion disableGutters elevation={ 0 } square { ...props } />
))(({ theme }) => ({
	border: `1px solid ${ theme.palette.divider }`,
	borderLeftWidth: 0,
	borderRightWidth: 0,
	"&:not(:last-child)": {
		borderBottom: 0
	},
	"&:before": {
		display: "none"
	}
}));

const AccordionSummary = styled((props) => (
	<MuiAccordionSummary
		expandIcon={ <SvgIcon component={ Arrow } /> }
		{ ...props }
	/>
))(({ theme }) => ({
	backgroundColor: "transparent",
	"& .MuiAccordionSummary-expandIconWrapper": {
		transition: "all .25s ease",
		transform: "rotate(0deg)",
		"&.Mui-expanded": {
			transform: "rotate(180deg)"
		}
	},
	"&&& .MuiAccordionSummary-content": {
		marginRight: theme.spacing(3),
		"& .MuiTypography-root": {
			color: "#E8410B",
			fontSize: "16px",
			fontWeight: 700
		}
	},
	"& .MuiSvgIcon-root": {
		fill: "#EAEAEA"
	}
}));

const AccordionDetails = styled(MuiAccordionDetails)(() => ({
	padding: "8px 25px 20px",
	borderTop: "1px solid rgba(0, 0, 0, .125)",
	"&&& .MuiTypography-root": {
		color: "#B9B9B9",
		fontSize: "14px",
		lineHeight: "22px"
	}
}));

export default QnAAccordion;