import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import React from "react";
import ReactDOM from "react-dom/client";
import "./assets/fonts/pretendard-subset.css";
import "./index.css";
import App from "./App";
import {
	QueryClientProvider,
	QueryClient
} from "@tanstack/react-query";
import { CookiesProvider } from "react-cookie";
import { FormModalsProvider } from "./hooks/modalProvider";
import { PublicAuthContextProvider } from "./hooks/public/publicAuthContextProvider";
import { AdminAuthContextProvider } from "./hooks/admin/adminAuthContextProvider";
import { ThemeProvider } from "@mui/material";
import { customMuiTheme } from "./lib/muiTheme";
import { HelmetProvider } from "react-helmet-async";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import "dayjs/locale/ko";

const root = ReactDOM.createRoot(document.getElementById("root"));
const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			// refetchOnWindowFocus: false,
			retry: 0,
		}
	}
});

root.render(
	<QueryClientProvider client={ queryClient }>
		<ReactQueryDevtools />

		<HelmetProvider>
			<CookiesProvider>
				<ThemeProvider theme={ customMuiTheme }>
					<PublicAuthContextProvider>
						<AdminAuthContextProvider>
							<LocalizationProvider dateAdapter={ AdapterDayjs } adapterLocale="ko" dateFormats={{ monthAndYear: "YYYY년 MM월"}}>
								<FormModalsProvider>
									<App />
								</FormModalsProvider>
							</LocalizationProvider>
						</AdminAuthContextProvider>
					</PublicAuthContextProvider>
				</ThemeProvider>
			</CookiesProvider>
		</HelmetProvider>
	</QueryClientProvider>
);