import { Radio } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";

function RadioButton({ readOnly = false, ...props }) {
	return (
		<Radio
			icon={ <Icon /> }
			checkedIcon={ <CheckedIcon /> }
			disableRipple={ readOnly }
			sx={{ cursor: readOnly && "default" }}
			{ ...props }
		/>
	);
}

function checkBackgroundImage(fill) {
	const checkSvg = `%0A%3Csvg width='10' height='7' viewBox='0 0 10 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M3.21931 6.27997C3.21943 6.28009 3.21955 6.28021 3.21967 6.28033C3.25628 6.31694 3.29574 6.34898 3.33732 6.37644C3.62844 6.56865 4.02405 6.53661 4.28033 6.28033L9.28033 1.28033C9.57322 0.987437 9.57322 0.512563 9.28033 0.21967C8.98744 -0.0732234 8.51256 -0.0732231 8.21967 0.21967L3.75 4.68934L1.28033 2.21967C0.987437 1.92678 0.512563 1.92678 0.21967 2.21967C-0.0732233 2.51256 -0.0732233 2.98744 0.21967 3.28033L3.21931 6.27997Z' fill='%23${ fill }' /%3E%3C/svg%3E%0A`;

	return `url("data:image/svg+xml,${ checkSvg }")`;
}

const Icon = styled("span")(({ theme }) => ({
	width: 17,
	height: 17,
	backgroundColor: "transparent",
	border: "1.5px solid #5c5c5c",
	borderRadius: "50%",
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	"& .MuiSvgIcon-root": {
		fill: "#5C5C5C",
		width: 10,
		height: 7
	},
	backgroundImage: checkBackgroundImage("5c5c5c"),
	backgroundRepeat: "no-repeat",
	backgroundPosition: "center",
	"input:disabled ~ &": {
		boxShadow: "none",
		background:
			theme.palette.mode === "dark" ? "#2f2f2f" : "rgba(206,217,224,.5)",
		borderColor: "#454545"
	}
}));

const CheckedIcon = styled(Icon)(({theme}) => ({
	borderColor: theme.palette.primary.main,
	backgroundColor: theme.palette.primary.main,
	backgroundImage: checkBackgroundImage("fff"),
}));

export default RadioButton;