import {
	Box, Button,
	Card,
	CardActions,
	CardContent, Divider, InputAdornment,
	Skeleton,
	TextField,
	Typography,
} from "@mui/material";
import React, { useRef, useState } from "react";
import { modals } from "../../../hooks/Modals";
import useModal from "../../../hooks/useModal";
import { getUser } from "../../../lib/firestore/User";
import { useDeleteOrderMutation, useEstimateQuery, useSetReceiveMutation } from "../../../query/admin/orderQuery";
import { useRequestImages } from "../../../query/getImageQuery";
import EstimateCard from "../../common/EstimateCard";
import ReadOnlyDeliveryAddressCard from "../../common/ReadOnlyDeliveryAddressCard";
import ImageUpload from "../../public/ImageUpload";
import { Toast } from "../../public/Toast";
import CardSubTitle from "./CardSubTitle";
import CardTitle from "./CardTitle";
import ContentField from "./ContentField";

function Step3Card({
	orderId,
	estimateId,
	userId,
	nickname,
	orderType,
	date,
	brandName,
	tentName,
	imagePath,
	requestContent,
	totalAmount,
	branchId,
	branchName,
	repairContent,
	requestReceivePickUp,
	refreshQuery
}) {
	const [open, setOpen] = useState(false);
	const {
		      isEstimateLoading,
		      estimateData,
	      } = useEstimateQuery(estimateId, open);
	const {
		      isRequestImagesLoading,
		      requestImagesData,
	      } = useRequestImages(orderId, imagePath, open, true);
	const { mutate } = useSetReceiveMutation();
	const { setDeleteOrderMutate } = useDeleteOrderMutation();
	const costRef = useRef(null);
	const { openModal } = useModal();

	function handleReceive() {
		openModal(modals.confirmModal, {
			title: "텐트가 입고되었나요?",
			submitTitle: "입고 완료",
			content: (
				<Box>
					<Typography variant="dialogContent">착불로 받았다면 배송비를 입력해주세요</Typography>
					<Typography variant="dialogContent" mb={ 3 }>입력한 배송비는 최종 견적에 포함됩니다</Typography>
					<TextField
						label="배송비"
						type="number"
						size="small"
						fullWidth
						InputProps={{
							endAdornment: <InputAdornment position="end">원</InputAdornment>
						}}
						ref={ costRef }
					/>
				</Box>
			),
			onSubmit: async (onClose, setLoadingDone) => {
				const inputCost = costRef.current.getElementsByTagName("input")[0].value;
				const user = (await getUser(userId)).data();

				const requestData = {
					orderId,
					branchId,
					brandName,
					tentName,
					branchName,
					cost: inputCost,
					phoneNumber: user.phoneNumber,
					nickname: user.nickname,
					loginProvider: userId.split(":")[0]
				};

				mutate(requestData, {
					onSuccess: () => {
						Toast.success("저장이 완료되었어요");
						refreshQuery();
						// queryClient.invalidateQueries([...GET_ORDER_STEP, 3]);
						onClose();
					},
					onError: () => {
						onClose();
						openModal(modals.confirmModal, {
							title: "죄송합니다, 저장이 실패했습니다",
							content: "잠시 후에 다시 시도해주시기 바랍니다.",
							closeTitle: "닫기",
						});
					},
					onSettled: () => {
						setLoadingDone();
					}
				});
			}
		});
	}

	function handleDelete() {
		openModal(modals.confirmModal, {
			submitTitle: "삭제",
			content: "해당 주문을 삭제하시겠어요?",
			onSubmit: (onClose, setLoadingDone) => {
				const request = { orderId, estimateId };

				if(imagePath) {
					request.imagePath = imagePath;
				}

				setDeleteOrderMutate(request, {
					onSuccess: () => {
						refreshQuery();
						Toast.success("삭제가 완료되었어요.");
						onClose();
					},
					onError: () => {
						onClose();
						openModal(modals.confirmModal, {
							title: "죄송합니다, 삭제가 실패했습니다",
							content: "잠시 후에 다시 시도해주시기 바랍니다.",
							closeTitle: "닫기",
						});
					},
					onSettled: () => {
						setLoadingDone();
					}
				});
			}
		});
	}

	return (
		<Card>
			<CardContent>
				<CardTitle
					orderType={ orderType }
					date={ date }
					nickname={ nickname }
					userId={ userId }
				/>

				<CardSubTitle
					brandName={ brandName }
					tentName={ tentName }
					branchName={ branchName }
					open={ open }
					setOpen={ setOpen }
					noneTooltip
				/>

				{ open && (
					<React.Fragment>
						{ isEstimateLoading ? (
							<Skeleton variant="rounded" width="100%" height="100px" />
						) : (
							<EstimateCard orderType={ orderType } detail={ estimateData } total={ totalAmount } repairContent={ repairContent } />
						) }

						{ requestReceivePickUp && (
							<Box mt={ 2 }>
								<ReadOnlyDeliveryAddressCard
									orderId={ orderId }
									title="방문수거 요청 주소지"
									isPickUp
								/>
							</Box>
						) }

						{ !isRequestImagesLoading && requestImagesData.length > 0 && (
							<Box mt={ 2 }>
								<ImageUpload
									readOnly
									images={ requestImagesData }
								/>
							</Box>
						) }

						{ requestContent && (
							<Box mt={ 2 }>
								<ContentField value={ requestContent } />
							</Box>
						) }
					</React.Fragment>
				) }
			</CardContent>

			<CardActions variant="adminStepCard">
				{ requestReceivePickUp && (
					<React.Fragment>
						<Typography variant="smallContext">방문 수거 요청</Typography>

						<Divider orientation="vertical" variant="middle" flexItem sx={{ mx: 1 }}/>
					</React.Fragment>
				) }

				<Button
					variant="contained"
					size="small"
					color="secondary"
					onClick={ handleDelete }
				>
					삭제
				</Button>

				<Button variant="contained" size="small" onClick={ handleReceive }>입고 완료</Button>
			</CardActions>
		</Card>
	);
}

export default Step3Card;