import { Box, Button, Divider, Link, Skeleton, Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { logout } from "../../api/public/auth";
import { firebaseAuth } from "../../firebaseAuth";
import { modals } from "../../hooks/Modals";
import { usePublicUserState } from "../../hooks/public/publicAuthContextProvider";
import useCurrentUser from "../../hooks/public/useCurrentUser";
import useModal from "../../hooks/useModal";
import { accessToken, firebaseToken } from "../../lib/authToken";

function UserBox() {
	const { openModal } = useModal();
	const navigate = useNavigate();
	const { loginFlag } = usePublicUserState();
	const { initializing } = useCurrentUser();

	function handleMyPage() {
		navigate("/profile");
	}

	function handleLogout() {
		openModal(modals.confirmModal, {
			content: (
				<Typography variant="body2">로그아웃을 하시겠어요?</Typography>
			),
			submitTitle: "로그아웃",
			onSubmit: async () => {
				await logout();
				await firebaseAuth.signOut();

				accessToken.setToken("");
				firebaseToken.setToken("");
				window.location.replace("/");
			}
		});
	}

	function handleLogin() {
		navigate("/login");
	}

	if(initializing) {
		return (
			<Skeleton variant="rounded" width="100%" height="65px" />
		);
	}

	return (
		<WrapBox>
			{ loginFlag ? (
				<React.Fragment>
					<Typography variant="bigMenuTitle" sx={{ lineHeight: "25px", mb: 2 }}>
						안녕하세요,
						<br />
						{ firebaseAuth.currentUser?.displayName || "" } 고객님!
					</Typography>

					<Stack direction="row" spacing={ 1 }>
						<Button variant="contained" size="small" color="primary" fullWidth onClick={ handleMyPage }>마이페이지</Button>

						<Divider orientation="vertical" variant="middle" flexItem />

						<Button variant="contained" size="small" color="secondary" fullWidth onClick={ handleLogout }>로그아웃</Button>
					</Stack>
				</React.Fragment>
			) : (
				<React.Fragment>
					<Typography variant="bigMenuTitle" sx={{ lineHeight: "25px", mb: 2 }}>
						안녕하세요,
						<br />
						텐트세탁소입니다!
					</Typography>

					<Stack direction="row" spacing={ 1 }>
						<Button variant="contained" size="small" color="primary" fullWidth onClick={ handleLogin }>로그인</Button>
					</Stack>
				</React.Fragment>
			) }
		</WrapBox>
	);
}

const WrapBox = styled(Box)(({ theme }) => ({
	padding: "16px",
	backgroundColor: theme.palette.background.light,
	"& .MuiDivider-root": {
		borderColor: theme.palette.secondary.main,
		marginTop: 3,
		marginBottom: 3
	}
}));

const LinkTypography = styled((props) => (
	<Typography component={ Link } underline="none" variant="context" {...props} />
))(({ theme }) => ({
	cursor: "pointer",
	transition: "color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
	"&:hover": {
		color: theme.palette.text.primary
	}
}))

export default UserBox;