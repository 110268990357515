import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Card, CardMedia, Container, Stack, SvgIcon, Tab, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useState } from "react";
import { ReactComponent as Arrow } from "../../../assets/images/guide_step/arrow.svg";
import { ReactComponent as Step_00_01 } from "../../../assets/images/guide_step/step_00_01.svg";
import { ReactComponent as Step_00_05 } from "../../../assets/images/guide_step/step_00_05.svg";
import { ReactComponent as Step_01_02 } from "../../../assets/images/guide_step/step_01_02.svg";
import { ReactComponent as Step_02_03 } from "../../../assets/images/guide_step/step_02_03.svg";
import { ReactComponent as Step_03_04 } from "../../../assets/images/guide_step/step_03_04.svg";
import { ReactComponent as Step_04_00 } from "../../../assets/images/guide_step/step_04_00.svg";
import { ReactComponent as Step_05_00 } from "../../../assets/images/guide_step/step_05_00.svg";
import { ReactComponent as Step_06_06 } from "../../../assets/images/guide_step/step_06_06.svg";
import TopImage_WASHING from "../../../assets/images/top_img/top_06.png";
import TopImage_REPAIR from "../../../assets/images/top_img/top_07.png";
import EstimateButtons from "../../../components/public/EstimateButtons";

function getStepTitle({ stepNumber, title }) {
	return (
		<Stack alignItems="center" spacing={ 1 }>
			<Typography variant="subTitle">STEP { stepNumber }</Typography>
			<Typography variant="subTitle">{ title }</Typography>
		</Stack>
	);
}

function getStepIcon({ icon }) {
	return (
		<Stack alignItems="center">
			<SvgIcon component={ icon } variant="guideStep" />
		</Stack>
	);
}

function Guide() {
	// const { type: guideType } = useParams();
	const [tabValue, setTabValue] = useState("washing");

	// useEffect(() => {
	// 	setTabValue(guideType);
	// }, [guideType]);

	function changeTab(e, changeValue) {
		setTabValue(changeValue);
	}

	return (
		<Container variant="contextRoot">
			<Card variant="topImage">
				<CardMedia
					image={ tabValue === "washing" ? TopImage_WASHING : TopImage_REPAIR }
					sx={ { height: "200px" } }
				/>
			</Card>

			<Container variant="contextBox">
				<EstimateButtons />

				<TabContext value={ tabValue }>
					<StickyTabWrap>
						<CenterTabList onChange={ changeTab } selecttype={ tabValue }>
							<Tab label="세탁안내" value="washing" />
							<Tab label="수선안내" value="repair" />
						</CenterTabList>
					</StickyTabWrap>

					<TabContent value="washing">
						<Stack spacing={ 4 } alignItems="center">
							<StepBox>
								{ getStepTitle({ stepNumber: 1, title: "가견적" }) }

								{ getStepIcon({ icon: Step_01_02 }) }

								<StepContent>
									<li>
										<Typography variant="context">
											상단의 "세탁견적" 버튼 클릭
										</Typography>
									</li>

									<li>
										<Typography variant="context">
											텐트 선택 후 서비스 항목 선택
										</Typography>
									</li>

									<li>
										<Typography variant="context">
											가견적 확인 후 세탁 접수
										</Typography>
									</li>
								</StepContent>
							</StepBox>

							<SvgIcon component={ Arrow } />

							<StepBox>
								{ getStepTitle({ stepNumber: 2, title: "물품 발송" }) }

								{ getStepIcon({ icon: Step_02_03 }) }

								<StepContent>
									<li>
										<Typography variant="context">
											택배 접수 후 발송
										</Typography>

										<ul>
											<li>
												<Typography variant="smallContext">
													텐트가 지점에 도착 시 카카오 알림톡으로 알려드립니다.
												</Typography>
											</li>

											<li>
												<Typography variant="smallContext">
													택배 포장 내에 성함을 적은 메모지를 넣어 보내주세요.
												</Typography>
											</li>

											<li>
												<Typography variant="smallContext">
													물품을 보내실 때는 서비스를 받으실 스킨만 포장하셔서 보내주세요.
												</Typography>
											</li>

											<li>
												<Typography variant="smallContext">
													착불로 보내실 경우 최종 견적에 착불 비용이 포함됩니다.
												</Typography>
											</li>
										</ul>
									</li>
								</StepContent>
							</StepBox>

							<SvgIcon component={ Arrow } />

							<StepBox>
								{ getStepTitle({ stepNumber: 3, title: "검수 및 견적 확정" }) }

								{ getStepIcon({ icon: Step_03_04 }) }

								<StepContent>
									<li>
										<Typography variant="context">
											텐트 도착 후 순서에 따라 검수
										</Typography>
									</li>

									<li>
										<Typography variant="context">
											스킨 상태나 오염도 확인
										</Typography>
									</li>

									<li>
										<Typography variant="context">
											최종 견적 확정 후 세탁 의뢰
										</Typography>

										<ul>
											<li>
												<Typography variant="smallContext">
													최종 견적 산정 시 카카오 알림톡으로 알려드립니다.
												</Typography>
											</li>
										</ul>
									</li>
								</StepContent>
							</StepBox>

							<SvgIcon component={ Arrow } />

							<StepBox>
								{ getStepTitle({ stepNumber: 4, title: "세탁" }) }

								{ getStepIcon({ icon: Step_04_00 }) }

								<StepContent>
									<li>
										<Typography variant="context">
											부드러운 압력으로 이물질 제거
										</Typography>
									</li>

									<li>
										<Typography variant="context">
											전용 세제 사용해 세척
										</Typography>
									</li>

									<li>
										<Typography variant="context">
											24시간 자연 건조
										</Typography>
									</li>

									<li>
										<Typography variant="context">
											고객 선택 사항(발수, 방수, UV 코팅 등)
										</Typography>
									</li>
								</StepContent>
							</StepBox>

							<SvgIcon component={ Arrow } />

							<StepBox>
								{ getStepTitle({ stepNumber: 5, title: "건조" }) }

								{ getStepIcon({ icon: Step_05_00 }) }

								<StepContent>
									<li>
										<Typography variant="context">
											공장 내부의 특화된 시설로 텐트 자연 건조
										</Typography>
									</li>

									<li>
										<Typography variant="context">
											구성품 확인 후 포장
										</Typography>

										<ul>
											<li>
												<Typography variant="smallContext">
													스킨이 젖은 상태로 직사광선을 받게 되면 텐트의 수명이 단축됩니다.
												</Typography>
											</li>
										</ul>
									</li>
								</StepContent>
							</StepBox>

							<SvgIcon component={ Arrow } />

							<StepBox>
								{ getStepTitle({ stepNumber: 6, title: "배송" }) }

								{ getStepIcon({ icon: Step_06_06 }) }

								<StepContent>
									<li>
										<Typography variant="context">
											고객님께 택배 발송
										</Typography>
									</li>
								</StepContent>
							</StepBox>
						</Stack>
					</TabContent>

					<TabContent value="repair">
						<Stack spacing={ 4 } alignItems="center">
							<StepBox>
								{ getStepTitle({ stepNumber: 1, title: "가견적 의뢰" }) }

								{ getStepIcon({ icon: Step_00_01 }) }

								<StepContent>
									<li>
										<Typography variant="context">
											상단의 "수선견적" 버튼 클릭
										</Typography>
									</li>

									<li>
										<Typography variant="context">
											텐트 선택  ▷  이미지 선택  ▷  설명 입력
										</Typography>
									</li>

									<li>
										<Typography variant="context">
											가견적 의뢰
										</Typography>
									</li>
								</StepContent>
							</StepBox>

							<SvgIcon component={ Arrow } />

							<StepBox>
								{ getStepTitle({ stepNumber: 2, title: "가견적" }) }

								{ getStepIcon({ icon: Step_01_02 }) }

								<StepContent>
									<li>
										<Typography variant="context">
											사진과 설명을 토대로 가견적 산출
										</Typography>
									</li>

									<li>
										<Typography variant="context">
											견적 내역 확인 및 수선 의뢰
										</Typography>
									</li>
								</StepContent>
							</StepBox>

							<SvgIcon component={ Arrow } />

							<StepBox>
								{ getStepTitle({ stepNumber: 3, title: "물품 발송" }) }

								{ getStepIcon({ icon: Step_02_03 }) }

								<StepContent>
									<li>
										<Typography variant="context">
											택배 접수 후 발송
										</Typography>

										<ul>
											<li>
												<Typography variant="smallContext">
													텐트가 지점에 도착 시 카카오 알림톡으로 알려드립니다.
												</Typography>
											</li>

											<li>
												<Typography variant="smallContext">
													택배 포장 내에 성함을 적은 메모지를 넣어 보내주세요.
												</Typography>
											</li>

											<li>
												<Typography variant="smallContext">
													물품을 보내실 때는 서비스를 받으실 스킨만 포장하셔서 보내주세요.
												</Typography>
											</li>

											<li>
												<Typography variant="smallContext">
													착불로 보내실 경우 최종 견적에 착불 비용이 포함됩니다.
												</Typography>
											</li>
										</ul>
									</li>
								</StepContent>
							</StepBox>

							<SvgIcon component={ Arrow } />

							<StepBox>
								{ getStepTitle({ stepNumber: 4, title: "검수 및 견적 확정" }) }

								{ getStepIcon({ icon: Step_03_04 }) }

								<StepContent>
									<li>
										<Typography variant="context">
											텐트 도착 후 순서에 따라 검수 진행
										</Typography>
									</li>

									<li>
										<Typography variant="context">
											수선 부위 확인
										</Typography>
									</li>

									<li>
										<Typography variant="context">
											최종 견적 확정 후 수선 진행
										</Typography>

										<ul>
											<li>
												<Typography variant="smallContext">
													최종 견적 산정 시 카카오 알림톡으로 알려드립니다.
												</Typography>
											</li>
										</ul>
									</li>
								</StepContent>
							</StepBox>

							<SvgIcon component={ Arrow } />

							<StepBox>
								{ getStepTitle({ stepNumber: 5, title: "수선" }) }

								{ getStepIcon({ icon: Step_00_05 }) }

								<StepContent>
									<li>
										<Typography variant="context">
											수선 진행
										</Typography>
									</li>

									<li>
										<Typography variant="context">
											구성품 확인 후 포장
										</Typography>
									</li>
								</StepContent>
							</StepBox>

							<SvgIcon component={ Arrow } />

							<StepBox>
								{ getStepTitle({ stepNumber: 6, title: "배송" }) }

								{ getStepIcon({ icon: Step_06_06 }) }

								<StepContent>
									<li>
										<Typography variant="context">
											고객님께 택배 발송
										</Typography>
									</li>
								</StepContent>
							</StepBox>
						</Stack>
					</TabContent>
				</TabContext>
			</Container>
		</Container>
	);
}

const StickyTabWrap = styled(Box) (({theme}) => ({
	borderColor: theme.palette.divider,
	position: "sticky",
	top: "60px",
	backgroundColor: theme.palette.background.default,
	"& .MuiTabs-flexContainer": {
		"& .MuiButtonBase-root": {
			margin: "3px 0"
		}
	}
}));

const CenterTabList = styled((props) => (
	<TabList centered { ...props } />
))(() => ({
	"& .MuiTab-root": {
		width: "50%",
		fontSize: "16px",
		fontWeight: 600
	},
	"&[selecttype = 'washing']": {
		".Mui-selected": {
			color: "#E8410B"
		},
		".MuiTabs-indicator": {
			backgroundColor: "#E8410B"
		}
	},
	"&[selecttype = 'repair']": {
		".Mui-selected": {
			color: "#07753B"
		},
		".MuiTabs-indicator": {
			backgroundColor: "#07753B"
		}
	}
}));

const TabContent = styled(TabPanel)(() => ({
	paddingLeft: 0,
	paddingRight: 0,
	paddingBottom: 0
}));

const StepBox = styled(Box)(() => ({
	width: "100%"
}));

const StepContent = styled("ul")(() => ({
	paddingInlineStart: "18px",
	"& > li": {
		listStyleType: "decimal",
		color: "#B9B9B9",
		fontSize: "14px",
		lineHeight: "25px",
		"& > ul": {
			paddingInlineStart: "10px",
			"& > li": {
				listStyleType: "'・'"
			}
		}
	}
}));

export default Guide;