import { CssBaseline } from "@mui/material";
import React, { useEffect } from "react";
import { isMobile } from "react-device-detect";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { StyledToastContainer } from "./components/public/Toast";
import {
	Admin,
	AdminMainLayout,
	Amount,
	Branch,
	Brand,
	Login,
	SignUp,
	RequestAddTent,
	Tent,
	PickUpService, Banner,
} from "./pages/admin";
import { FixEstimate, OrderLayout, SaveEstimate, Step0, Step1, Step2, Step3, Step4, Step5, Step6, Step7, Step8, } from "./pages/admin/order";
import { PublicMainLayout, Home, KakaoCallback, NaverCallback, News, UserLogin, UserProfile } from "./pages/public";
import { Estimate, Repair, Guide, OrderHistory, OrderHistoryDetail } from "./pages/public/tent";
import { Greeting, History, Introduce } from "./pages/public/company";
import { Branch1, Branch2, Branch3, Branch4, MainShop, Questions } from "./pages/public/branch";
import { Bakkeulo, Saleuleu, TtoleuleuPlus } from "./pages/public/products";
import NotFound from "./pages/NotFound";

function App() {
	useEffect(() => {
		const htmlElement = document.getElementsByTagName("html")[0];

		htmlElement.className = isMobile ? "mobile" : "desktop";
	}, []);

	return (
		<React.Fragment>
			<CssBaseline />

			<StyledToastContainer limit={ 3 } />

			<Router>
				<Routes>
					<Route path="/" element={ <PublicMainLayout /> }>
						<Route index element={ <Home /> } />
						<Route path="xe" element={ <Home /> } />

						<Route path="company">
							<Route path="greeting" element={ <Greeting /> } />
							<Route path="history" element={ <History /> } />
							<Route path="introduce" element={ <Introduce /> } />
						</Route>

						<Route path="news" element={ <News /> } />

						<Route path="tent">
							<Route path="guide" element={ <Guide /> } />
							<Route path="estimate" element={ <Estimate /> } />
							<Route path="estimate/:savedTentId" element={ <Estimate /> } />
							<Route path="repair" element={ <Repair /> } />
							<Route path="orderHistory" element={ <OrderHistory /> } />
							<Route path="orderHistoryDetail/:orderId" element={ <OrderHistoryDetail /> } />
						</Route>

						<Route path="branch">
							<Route path="questions" element={ <Questions /> } />
							<Route path="mainShop" element={ <MainShop /> } />
							<Route path="branch1" element={ <Branch1 /> } />
							<Route path="branch2" element={ <Branch2 /> } />
							<Route path="branch3" element={ <Branch3 /> } />
							<Route path="branch4" element={ <Branch4 /> } />
						</Route>

						<Route path="products">
							<Route path="bakkeulo" element={ <Bakkeulo /> } />
							<Route path="saleuleu" element={ <Saleuleu /> } />
							<Route path="ttoleuleu_plus" element={ <TtoleuleuPlus /> } />
						</Route>

						<Route path="profile" element={ <UserProfile /> } />

						<Route path="login" element={ <UserLogin /> } />
						<Route path={ process.env.REACT_APP_KAKAO_REDIRECT_URL } element={ <KakaoCallback /> } />
						<Route path={ process.env.REACT_APP_NAVER_REDIRECT_URL } element={ <NaverCallback /> } />
						<Route path="*" element={ <NotFound /> } />
					</Route>

					<Route path="admin" element={ <AdminMainLayout /> }>
						<Route index element={ <Admin /> } />
						<Route path="brand" element={ <Brand /> } />
						<Route path="tent" element={ <Tent /> } />
						<Route path="amount" element={ <Amount /> } />
						<Route path="estimate" element={ <Estimate isAdmin /> } />
						<Route path="branch" element={ <Branch /> } />
						{/*<Route path="pickUpService" element={ <PickUpService /> } />*/}
						<Route path="banner" element={ <Banner /> } />

						<Route path="order" element={ <OrderLayout /> }>
							<Route path="step1" element={ <Step1 /> } />
							<Route path="step2" element={ <Step2 /> } />
							<Route path="step3" element={ <Step3 /> } />
							<Route path="step4" element={ <Step4 /> } />
							<Route path="step5" element={ <Step5 /> } />
							<Route path="step6" element={ <Step6 /> } />
							<Route path="step7" element={ <Step7 /> } />
							<Route path="complete" element={ <Step0 /> } />
							<Route path="step8" element={ <Step8 /> } />
						</Route>

						{/* 가견적 등록 */ }
						<Route path="order/saveEstimate/:estimateId" element={ <SaveEstimate /> } />

						{/* 확정 견적 등록 */ }
						<Route path="order/fixEstimate/:estimateId" element={ <FixEstimate /> } />

						<Route path="requestAddTent/:estimateId" element={ <RequestAddTent /> } />

						<Route path="login" element={ <Login /> } />
						<Route path="signUp" element={ <SignUp /> } />
						<Route path="*" element={ <NotFound /> } />
					</Route>
				</Routes>
			</Router>
		</React.Fragment>
	);
}

export default App;