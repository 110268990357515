import Grid from "@mui/material/Unstable_Grid2";
import React, { useMemo } from "react";
import {
	Card, CardContent, Divider, Stack, SvgIcon, Tooltip, Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import {
	ReactComponent as Repair
} from "../../assets/images/history/07_repair.svg";
import {
	ReactComponent as Washing
} from "../../assets/images/history/07_washing.svg";
import { ReactComponent as CancelMark } from "../../assets/images/icon/cancel_mark.svg";
import EstimateItems from "../common/EstimateItems";

function OrderCard({ onClick, orderType, state, date, brandName, tentId, tentName, detail, total, cancel = false, deliveryConfirm, receivingDeliveryCost = false, deliveryDivision, deliveryCost = 0 }) {
	const components = useMemo(() => ({
		"1": Washing,
		"2": Repair
	}), []);
	let title = "";
	const realPrice = detail.reduce((acc, cur) => {
		return acc + cur.amnt;
	}, 0);
	let realTotal = total;

	if(receivingDeliveryCost) {
		realTotal += receivingDeliveryCost;
	}

	if(deliveryDivision === "1" && deliveryCost) {
		realTotal += deliveryCost;
	}

	if(tentId === "99999") {
		if(brandName) {
			title += brandName;
		}
		if(tentName) {
			if(title) {
				title += " - ";
			}

			title += tentName;
		}
	} else {
		title = `${brandName} - ${tentName}`;
	}

	return (
		<ClickedCard cursorpointer={ typeof onClick === "function" ? 1 : 0 } onClick={ () => typeof onClick === "function" && onClick() }>
			<CardContent>
				<Stack
					direction="row"
					justifyContent="space-between"
					alignItems="center"
					mb={ 2 }
				>
					<Stack direction="row" alignItems="center" spacing={ 2 } sx={{ position: "relative" }}>
						<SvgIcon
							variant="cardIcon"
							component={ components[orderType] }
							sx={ { width: "24px", height: "24px", stroke: (theme) => theme.palette.type[orderType === "1" ? "washing" : "repair"] } }
						/>

						{ cancel && (
							<Tooltip arrow title="취소된 주문이에요" placement="right">
								<SvgIcon
									variant="cardIcon"
									component={ CancelMark }
									sx={ {
										position: "absolute",
										width: "34px",
										height: "34px",
										marginLeft: "-4.5px !important"
									} }
								/>
							</Tooltip>
						) }

						{ state && (
							<StateBox>
								<Typography variant="smallContext" sx={ { fontWeight: 700 } }>{ deliveryConfirm ? "수령" : (cancel && "취소") } { state }</Typography>
							</StateBox>
						) }
					</Stack>

					<Typography variant="smallContext">{ date }</Typography>
				</Stack>

				<Typography variant="cardBold" mb={ 2 }>{ title }</Typography>

				{ !cancel && (
					<React.Fragment>
						{ detail.length > 0 && (
							<EstimateItems data={ detail } />
						) }

						<Grid container mb={ 1 }>
							{ total && total !== realPrice && (
								<React.Fragment>
									<Grid xs={ 9 }>
										<Typography variant="cardContext">변동</Typography>
									</Grid>

									<Grid xs={ 3 }>
										<Typography variant="cardContext" sx={ { textAlign: "right" } }>
											{ total < realPrice && `- ${ Math.abs(total - realPrice).toLocaleString() } 원` }
											{ total > realPrice && `+ ${ (total - realPrice).toLocaleString() } 원` }
										</Typography>
									</Grid>
								</React.Fragment>
							) }

							{ receivingDeliveryCost && (
								<React.Fragment>
									<Grid xs={ 9 }>
										<Typography variant="cardContext">입고 후불 배송비</Typography>
									</Grid>

									<Grid xs={ 3 }>
										<Typography variant="cardContext" sx={ { textAlign: "right" } }>
											{ (receivingDeliveryCost).toLocaleString() } 원
										</Typography>
									</Grid>
								</React.Fragment>
							) }

							{ deliveryDivision === "1" && deliveryCost ? (
								<React.Fragment>
									<Grid xs={ 9 }>
										<Typography variant="cardContext">수취 선불 배송비</Typography>
									</Grid>

									<Grid xs={ 3 }>
										<Typography variant="cardContext" sx={ { textAlign: "right" } }>
											{ (deliveryCost).toLocaleString() } 원
										</Typography>
									</Grid>
								</React.Fragment>
							) : (<></>)}
						</Grid>

						{ total && (
							<React.Fragment>
								<Divider />

								<Typography variant="cardBold" mt={ 2 } sx={ { textAlign: "right", color: (theme) => theme.palette.type.washing } }>{ (realTotal).toLocaleString() } 원</Typography>
							</React.Fragment>
						) }
					</React.Fragment>
				) }
			</CardContent>
		</ClickedCard>
	);
}

const ClickedCard = styled(Card)(({cursorpointer}) => ({
	width: "100%",
	cursor: cursorpointer && "pointer",
	"&:hover": {
		backgroundColor: cursorpointer && "#282828"
	}
}));

const StateBox = styled("div")(({ theme }) => ({
	borderWidth: "1px",
	borderStyle: "solid",
	borderColor: theme.palette.divider,
	borderRadius: "20px",
	padding: "0 10px"
}));

export default OrderCard;