import { doc, getDoc, setDoc, updateDoc, deleteDoc, onSnapshot } from "firebase/firestore";
import { firebaseDB as DB } from "../../firebaseAuth";

const DB_NAME = "ORDER";

export class Order {
	constructor(orderId, uid, phoneNumber, receiverName = null, receiverPhoneNumber = null, zoneCode = null, address = null, addressDetail = null) {
		this.orderId = orderId; // String: key
		this.uid = uid; // String
		this.phoneNumber = phoneNumber; // String
		this.receiverName = receiverName; // String
		this.receiverPhoneNumber = receiverPhoneNumber; // String
		this.zoneCode = zoneCode; // Number
		this.address = address; // String
		this.addressDetail = addressDetail; // String
	}

	toString() {
		return `${ this.orderId }, ${ this.uid }, ${ this.phoneNumber }, ${ this.receiverName }, ${ this.receiverPhoneNumber }, ${ this.zoneCode }, ${ this.address }, ${ this.addressDetail }`;
	}
}

export const converter = {
	toFirestore: function(order) {
		return {
			orderId: order.orderId,
			uid: order.uid,
			phoneNumber: order.phoneNumber,
			receiverName: order.receiverName,
			receiverPhoneNumber: order.receiverPhoneNumber,
			zoneCode: order.zoneCode,
			address: order.address,
			addressDetail: order.addressDetail
		};
	},
	fromFirestore: function(snapshot, options) {
		const data = snapshot.data(options);
		return new Order(snapshot.id, data.uid, data.phoneNumber, data.receiverName, data.receiverPhoneNumber, data.zoneCode, data.address, data.addressDetail);
	},
};

async function getRef(orderId) {
	return doc(DB, DB_NAME, orderId);
}

async function getRefWithConverter(orderId) {
	return (await getRef(orderId)).withConverter(converter);
}

export async function getOrder(orderId) {
	const ref = await getRefWithConverter(orderId);

	return await getDoc(ref);
}

export async function setOrder(orderId, order) {
	const ref = await getRefWithConverter(orderId);

	await setDoc(ref, order);
}

export async function updateOrder(orderId, data) {
	const ref = await getRef(orderId);

	await updateDoc(ref, data);
}

export async function deleteOrder(orderId) {
	const ref = await getRef(orderId);

	await deleteDoc(ref);
}

export async function getRealtimeOrder({
	orderId,
	unsubscribeRef,
	setData,
}) {
	const ref = await getRefWithConverter(orderId);

	unsubscribeRef.current = onSnapshot(ref, (doc) => {
		setData(doc.data());
	});
}

export function hasEmptyAddress(order) {
	return order.zoneCode === null || order.address === null || order.addressDetail === null;
}