import {
	useMutation,
	useQuery,
} from "@tanstack/react-query";
import * as API from "../../api/admin/tent";

export const GET_TENT_KEY = ["admin", "tent", "getTent"];
export function useBrandsQuery() {
	const {
    isLoading: isBrandsLoading,
    isError: isBrandsError,
    isSuccess: isBrandsSuccess,
    data: brandsData,
  } = useQuery(["admin","tent", "getBrands"], API.getBrands, {
		retry: 0,
		refetchOnWindowFocus: false,
	});

	return {
		isBrandsLoading,
		isBrandsError,
		isBrandsSuccess,
		brandsData,
	};
}

export function useTentQuery(brandId) {
	const {
    isLoading: isTentLoading,
    isError: isTentError,
    isSuccess: isTentSuccess,
    data: tentData,
  } = useQuery(
		[...GET_TENT_KEY, brandId],
		() => API.getTent({ brandId }),
		{
			enabled: brandId !== "",
			retry: 0,
			refetchOnWindowFocus: false,
			placeholderData: [],
			select: (data) => {
				return data.map((item) => {
					return {
						...item,
						useYn: item.useYn === "Y"
					};
				});
			}
	});

	return {
		isTentLoading,
		isTentError,
		isTentSuccess,
		tentData: tentData,
	};
}

export function useInsertTentMutation() {
	const { mutate: insertMutate } = useMutation({
		mutationFn: (data) => {
			return API.insertTent({
				...data,
				useYn: data.useYn ? "Y" : "N"
			});
		}
	});

	return { insertMutate };
}

export function useUpdateTentMutation() {
	const { mutate: updateMutate } = useMutation({
		mutationFn: (data) => {
			return API.updateTent({
				...data,
				useYn: data.useYn ? "Y" : "N"
			});
		}
	});

	return { updateMutate };
}

export function useDeleteTentMutation() {
	const { mutate: deleteMutate } = useMutation(API.deleteTent);

	return { deleteMutate };
}