import axios from "./../axios";
import { getAdminAccessToken } from "../../lib/config/exportToken";

export async function silentRefresh() {
	const res = await axios.get("/api/admin/refresh");

	return {
		cd: res?.data.cd,
		newAccessToken: getAdminAccessToken(res.headers)
	};
}

export async function getRole() {
	const res = await axios.get("/api/admin/getRole");

	return res?.data;
}