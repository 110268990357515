import { TOKEN_PREFIX } from "./tokenConstants";
import { firebaseAuth } from "../firebaseAuth";

class AuthToken {
	token;

	constructor() {
		this.token = "";
	}

	getToken() {
		return this.token;
	}

	setToken(newToken) {
		this.token = newToken?.split(TOKEN_PREFIX)[1] || newToken || "";
	}
}

class FirebaseToken {
	token;
	#key;

	constructor(_key) {
		this.#key = _key;
	}

	async getToken() {
		return this.token ? this.token : await this.getNewIdToken();
	}

	getNewIdToken() {
		return new Promise((resolve) => {
			firebaseAuth.onAuthStateChanged(async (currentUser) => {
				if(currentUser) {
					const token = await currentUser.getIdToken(true);
					this.setToken(token);
					resolve(token);
				} else {
					this.setToken("");
					resolve(null);
				}
			});
		});
	}

	setToken(newToken) {
		// localStorage.setItem(this.#key, newToken);
		// this.token = await firebaseAuth.currentUser?.getIdToken(true);
		this.token = newToken;
	}
}

export const accessToken = new AuthToken();
export const adminAccessToken = new AuthToken();
export const firebaseToken = new FirebaseToken("auth");