import { useMutation, useQuery } from "@tanstack/react-query";
import * as ADMIN_API from "../../api/admin/estimate";
import * as API from "../../api/public/estimate2";
import { getEstimate } from "../../api/public/estimate2";

export const GET_ESTIMATE_KEY = ["getEstimate"];

function getAPI(isAdmin) {
	return isAdmin ? ADMIN_API : API;
}

export function useBrandQuery(brndId = "", isAdmin) {
	const {
		      isLoading: isBrandLoading,
		      isError: isBrandError,
		      isSuccess: isBrandSuccess,
		      data: brandData,
	      } = useQuery(["getBrand"], getAPI(isAdmin).getBrands, {
		enabled: brndId === "",
		// staleTime: 30 * 1000
		refetchOnWindowFocus: false,
	});

	return {
		isBrandLoading,
		isBrandError,
		isBrandSuccess,
		brandData,
	};
}

export function useTentQuery(brndId = "", isAdmin) {
	const {
		      isLoading: isTentLoading,
		      isFetching: isTentFetching,
		      isError: isTentError,
		      isSuccess: isTentSuccess,
		      data: tentData,
	      } = useQuery(["getTent", brndId], () => getAPI(isAdmin).getTent(brndId), {
		// enabled: brndId !== "",
		// staleTime: 30 * 1000
		refetchOnWindowFocus: false,
	});

	return {
		isTentLoading,
		isTentFetching,
		isTentError,
		isTentSuccess,
		tentData,
	};
}

export function useEstimateQuery(tntId = "", isAdmin, otherTent) {
	const {
		      isLoading: isEstimateLoading,
		      isFetching: isEstimateFetching,
		      isError: isEstimateError,
		      isSuccess: isEstimateSuccess,
		      data: estimateData,
	      } = useQuery([...GET_ESTIMATE_KEY, tntId, otherTent], () => getAPI(isAdmin).getEstimate(tntId, otherTent), {
		enabled: otherTent === false ? tntId !== "" : true,
		refetchOnWindowFocus: false,
		select: (data) => {
			if(!isAdmin) {
				return data;
			} else {
				return { list: data };
			}
		},
	});

	return {
		isEstimateLoading,
		isEstimateFetching,
		isEstimateError,
		isEstimateSuccess,
		estimateData,
	};
}

export function useSetOrderMutation() {
	const { mutate: setOrderMutate } = useMutation({
		mutationFn: API.setOrder,
	});

	return { setOrderMutate };
}