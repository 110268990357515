import React, {
	createContext,
	useCallback,
	useContext,
	useMemo,
	useState
} from "react";
import Role from "../../lib/Role";

export const AdminUserStateContext = createContext({
	role: undefined,
	branchId: undefined,
	loginFlag: false
});

export const AdminUserDispatchContext = createContext({
	setLoggedIn: () => {},
	setLoggedOut: () => {},
});

export function AdminAuthContextProvider({ children }) {
	const [user, setUser] = useState({
		role: undefined,
		branchId: undefined,
		loginFlag: false
	});

	function setLoggedIn({ branchId, role }) {
		setUser(() => ({
			role,
			branchId,
			loginFlag: true
		}));
	}

	function setLoggedOut() {
		setUser({
			role: undefined,
			branchId: undefined,
			loginFlag: false,
		});
	}

	const dispatch = useMemo(() => ({ setLoggedIn, setLoggedOut }), []);

	return (
		<AdminUserDispatchContext.Provider value={ dispatch }>
			<AdminUserStateContext.Provider value={ user }>
				{ children }
			</AdminUserStateContext.Provider>
		</AdminUserDispatchContext.Provider>
	);
}

export function useAdminUserState() {
	return useContext(AdminUserStateContext);
}

export function useAdminUserDispatch() {
	return useContext(AdminUserDispatchContext);
}