import React from "react";

function throttle(fetchData, delay = 600) {
	let timer;

	return function() {
		if(!timer) {
			timer = setTimeout(() => {
				timer = null;
				fetchData.apply(this, arguments);
			}, delay);
		}
	}
}

export default throttle;