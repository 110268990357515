import React, { useMemo } from "react";
import {
	Box,
	Card,
	CardMedia,
	Container,
	Divider,
	Stack,
	Typography
} from "@mui/material";
import useKakaoMap from "../../../hooks/public/useKakaoMap";
import TopImage01 from "../../../assets/images/branch_04/top_01.jpg";
import TopImage02 from "../../../assets/images/branch_04/top_02.jpg";
import SwiperTopCard from "../../../components/public/SwiperTopCard";
import BranchInfo from "../../../components/public/BranchInfo";
import NewsCard from "../../../components/public/NewsCard";
import YoutubeImage from "../../../assets/images/branch_04/thumbnail.jpg";

function Branch4() {
	useKakaoMap({
		locationName: "텐트세탁소 평택점",
		lat: 37.0865745,
		lng: 127.0839082
	});

	const topImages = useMemo(() => ([
		{ image: TopImage01 },
		{ image: TopImage02 }
	]), []);

	const info = useMemo(() => ({
		tel: "031-666-8271",
		cafe: "https://cafe.naver.com/tcspyeongtaek",
		insta: "https://instagram.com/tentwashing?igshid=ZDc4ODBmNjlmNQ=="
	}), []);

	return (
		<Container variant="contextRoot">
			<SwiperTopCard images={ topImages } />

			<Container variant="contextBox">
				<Stack direction="column" spacing={ 3 }>
					<BranchInfo info={ info } />

					<Divider />

					<NewsCard
						title="모든 과정을 수작업으로 텐트를 세탁하는 젊은 여성 창업자"
						channel="Youtube"
						programName="룩 프로세스 Look Process"
						date="2023.03.20"
						youtubeId="7p5iaDkE-tw"
					/>

					<Divider />

					<Typography variant="context">
						전화: { info.tel }
					</Typography>

					<Typography variant="context">
						경기도 평택시 진위면 삼봉로 222 A동
					</Typography>

					<Box
						sx={ {
							width: "calc(100% + 30px)",
							height: "200px",
							position: "relative",
							left: "-15px"
						} }
					>
						<div id="map" style={ { width: "100%", height: "100%" } } />
					</Box>
				</Stack>
			</Container>
		</Container>
	);
}

export default Branch4;