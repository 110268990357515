import { DropDownButton } from "./DropDownButton";
import {
	AddCircleRounded,
	AutoFixHighRounded,
	RemoveCircleRounded,
} from "@mui/icons-material";

export function ModifyDropDownButton({ addItem = true, updateItem = true, deleteItem = true, onClick }) {
	const ADD_ITEM = { value: "add", label: "추가", icon: AddCircleRounded };
	const UPDATE_ITEM = { value: "update", label: "수정", icon: AutoFixHighRounded };
	const DELETE_ITEM = { value: "delete", label: "삭제", icon: RemoveCircleRounded };

	function getMenuItem() {
		const array = [];

		if(addItem) {
			array.push(ADD_ITEM);
		}
		if(updateItem) {
			array.push(UPDATE_ITEM);
		}
		if(deleteItem) {
			array.push(DELETE_ITEM);
		}

		return array;
	}

	return (
		<DropDownButton
			menuItems={ getMenuItem() }
			onClick={onClick}
		>
		</DropDownButton>
	)
}