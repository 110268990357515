import { TextField } from "@mui/material";
import React from "react";
import { useController } from "react-hook-form";

function FormTextField({ name, control, rules, ...props }) {
	const { trueOnlyShowMsg, ...restProps } = props;
	const {
		      field: { value, onChange },
		      fieldState: { error },
	      } = useController({ name, control, rules });

	return (
		<TextField
			{ ...restProps }
			value={ value || "" }
			onChange={ (e) => {
				restProps?.onChange?.call(e, e);
				onChange(e);
			} }
			error={ !!error }
			helperText={ !!error ? error.message : trueOnlyShowMsg?.flag ? trueOnlyShowMsg?.msg : "" }
		/>
	);
}

export default React.memo(FormTextField);