import { Dialog, Typography } from "@mui/material";
import { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { FormColumnType, FormDialogType } from "../../../lib/config/formDialogType";
import { Toast } from "../../public/Toast";
import FormModalBody from "./FormModalBody";
import FormModalFooter from "./FormModalFooter";

export default function FormModal({ onSubmit, onClose, ...props }) {
  const { type, rowData, columnDef, ...restProps } = props;
  const FORM_ID = type.name + "_form";
  const {
    handleSubmit,
    control,
    getValues,
	  setValue
  } = useForm({
    mode: "all",
    defaultValues: getDefaultValue()
  });
  const [isLoading, setIsLoading] = useState(false);
  const setLoadingDone = useCallback(() => {
    setIsLoading(false);
  }, []);
  const showToast = useCallback(({ cd, message = "" }) => {
    const {
      type: toastType = toast.TYPE.INFO,
      content = "",
    } = type.message[cd];
		const toastCallback = Toast[toastType];

	  toastCallback(message.length ? message : content);

    // toast(message.length ? message : content, {
    //   type: toastType
    // });
  }, []);

  function getDefaultValue() {
    return columnDef.reduce((acc, cur) => {
      let value;

      if(type.name === FormDialogType.ADD.name) {
				if(cur.fType === FormColumnType.RANGE_NUMBER) {
					value = [cur.fDefaultValue || 0, cur.fDefaultValue || 0];
				} else {
					value = cur.fDefaultValue || "";
				}
      } else {
				if(cur.fType === FormColumnType.RANGE_NUMBER) {
					let rangeValue = rowData[cur.id] || "0";

					if((rangeValue.toString()).indexOf(",") >= 0) {
						value = rangeValue.split(",").map((item) => Number(item));
					} else {
						value = [Number(rangeValue), Number(rangeValue)];
					}
				} else {
					value = rowData[cur.id] || "";
				}
      }

      return {
        ...acc,
        [cur.id]: value
      };
    }, {});
  }

  function getDeleteParam(formData) {
    return columnDef.reduce((acc, cur) => {
      if(cur.fPk !== undefined && cur.fPk === true) {
        return {
          ...acc,
          [cur.id]: formData[cur.id]
        };
      }

      return { ...acc };
    }, {});
  }

  async function handledSubmit(formData) {
    setIsLoading(true);

    if(type.name === FormDialogType.DELETE.name) {
      formData = getDeleteParam(formData);
    }

    await onSubmit(formData, onClose, showToast, setLoadingDone);
  }

  return (
    <Dialog
      open
      maxWidth="xs"
      fullWidth
    >
      <Typography variant="dialogTitle">{ type.title }</Typography>

      <FormModalBody
        onSubmit={ handledSubmit }
        type={ type }
        columnDef={ columnDef }
        handleSubmit={ handleSubmit }
        control={ control }
        getValues={ getValues }
        setValue={ setValue }
        formId={ FORM_ID }
        readOnly={ isLoading }
      />

      <FormModalFooter
        type={ type }
        onClose={ onClose }
        formId={ FORM_ID }
        isLoading={ isLoading }
      />
    </Dialog>
  );
}