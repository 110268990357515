import React, {
	useMemo,
	useRef,
	useState
} from "react";
import {
	Box,
	Container,
	Stack,
	Typography
} from "@mui/material";
import ContentImage01	from "../../../assets/images/products/ttoleuleu_plus/01.jpeg";
import ContentImage02	from "../../../assets/images/products/ttoleuleu_plus/02.gif";
import ContentImage03	from "../../../assets/images/products/ttoleuleu_plus/03.gif";
import ContentImage04	from "../../../assets/images/products/ttoleuleu_plus/04.jpeg";
import ContentImage05	from "../../../assets/images/products/ttoleuleu_plus/05.gif";
import ContentImage06	from "../../../assets/images/products/ttoleuleu_plus/06.jpeg";
import ContentImage07	from "../../../assets/images/products/ttoleuleu_plus/07.gif";
import ContentImage08	from "../../../assets/images/products/ttoleuleu_plus/08.jpeg";
import ContentImage09	from "../../../assets/images/products/ttoleuleu_plus/09.gif";
import ContentImage10	from "../../../assets/images/products/ttoleuleu_plus/10.gif";
import ContentImage11	from "../../../assets/images/products/ttoleuleu_plus/11.jpeg";
import ContentImage12	from "../../../assets/images/products/ttoleuleu_plus/12.jpeg";
import ContentImage13	from "../../../assets/images/products/ttoleuleu_plus/13.jpeg";
import ContentImage14	from "../../../assets/images/products/ttoleuleu_plus/14.jpeg";
import ProductFixedButton from "../../../components/public/ProductFixedButton";
import QnAAccordion from "../../../components/public/QnAAccordion";
import ProductNav from "../../../components/public/ProductNav";
import BigImage from "../../../components/public/BigImage";

function TtoleuleuPlus() {
	const [currentTab, setCurrentTab] = useState(0);
	const tabRef = useRef([]);
	const QandA = useMemo(() => ([
		{
			title: "Q. 세탁 후 발수코팅은 필수인가요?",
			context: (
				<React.Fragment>
					비를 맞았거나 약간의 생활오염으로 인한 구입후 2년내의 텐트는 꼭 발수코팅을 하실 필요는 없습니다.
					<br />
					저희는 환경부 인증 받은 아웃도어 전용세제를 사용하므로 기본 세탁만으로는 발수코팅과 텐트스킨에 영향을 주지는 않습니다.
					<br />
					다만 2년내의 텐트라도 곰팡이오염이나 장박오염(찌든때)세탁후 특수세제로 인해 발수코팅에 영향을 주므로 발수코팅을 하시는 것을 권해드립니다.
				</React.Fragment>
			)
		},
		{
			title: "Q. 발수코팅은 언제 해야하나요?",
			context: (
				<React.Fragment>
					발수(water repellence)코팅은 외부코팅으로 물에 유입은 억제하면서 공기의 순환은 도와주는 코팅으로 영구적인 것은 아닙니다.
					<br />
					비와 자외선을 받으면 기능은 점점 저하되며 오염으로도 기능은 저하됩니다.
					<br />
					수명은 텐트구입후 2년내로 보며 2년후나 비에 텐트가 젖는 상태가 되면 발수코팅을 고려해봐야합니다.
					<br />
					곰팡이오염이나 장박오염(찌든때)세탁후에도 발수력은 저하되므로 쾌적한 텐트생활을 위해서는발수코팅을 하시는 것이 좋습니다.
				</React.Fragment>
			)
		}
	]), []);
	const navItems = useMemo(() => (["제품 설명", "사용 방법", "자주 묻는 질문"]), []);

	return (
		<Container variant="contextRoot">
			<Container variant="contextBox">
				<ProductNav
					items={ navItems }
					currentTab={ currentTab }
					setCurrentTab={ setCurrentTab }
					tabRef={ tabRef }
				/>

				<Box mb={ 5 }>
					{/* 제품 설명 */ }
					<Stack variant="imageBox" ref={ el => tabRef.current[0] = el }>
						<img src={ ContentImage01 } style={ { width: "100%" } } />
						<img src={ ContentImage02 } style={ { width: "100%" } } />
						<img src={ ContentImage03 } style={ { width: "100%" } } />
						<img src={ ContentImage04 } style={ { width: "100%" } } />
						<img src={ ContentImage05 } style={ { width: "100%" } } />
						<img src={ ContentImage06 } style={ { width: "100%" } } />
						<img src={ ContentImage07 } style={ { width: "100%" } } />
						<img src={ ContentImage08 } style={ { width: "100%" } } />
						<img src={ ContentImage09 } style={ { width: "100%" } } />
						<img src={ ContentImage10 } style={ { width: "100%" } } />
						<img src={ ContentImage11 } style={ { width: "100%" } } />
					</Stack>

					{/* 사용 방법 */ }
					<Stack variant="imageBox" ref={ el => tabRef.current[1] = el }>
						<img src={ ContentImage12 } style={ { width: "100%" } } />
						<BigImage src={ ContentImage13 } />
						<img src={ ContentImage14 } style={ { width: "100%" } } />
					</Stack>
				</Box>

				<Stack variant="imageBox" mb={ 3 } ref={ el => tabRef.current[2] = el }>
					<Typography variant="title">자주 묻는 질문</Typography>

					<QnAAccordion items={ QandA } />
				</Stack>

				<ProductFixedButton url="https://smartstore.naver.com/rhou2002/products/5919276423" />
			</Container>
		</Container>
	);
}

export default TtoleuleuPlus;