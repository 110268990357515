import React, { useMemo } from "react";
import { styled } from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import { SvgIcon, Typography } from "@mui/material";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import {
	ReactComponent as Arrow
} from "../../../assets/images/icon/accordion.svg";

function OrderHistoryDefaultAccordion({ isExpanded, onChange, stepName, stepDate, stepIcon, content }) {
	return (
		<StyledAccordion expanded={ isExpanded } onChange={ onChange }>
			<AccordionSummary>
				<SvgIcon component={ stepIcon } />
				<Typography className="name">{ stepName }</Typography>
				<Typography className="date">{ stepDate }</Typography>
			</AccordionSummary>

			<AccordionDetails>
				<Typography>{ content }</Typography>
			</AccordionDetails>
		</StyledAccordion>
	);
}

const StyledAccordion = styled((props) => (
	<MuiAccordion disableGutters elevation={ 0 } square { ...props } />
))(({ theme }) => ({
	border: `1px solid ${ theme.palette.divider }`,
	borderLeftWidth: 0,
	borderRightWidth: 0,
	borderTopWidth: 0,
	"&:last-child": {
		borderBottom: 0
	},
	"&:before": {
		display: "none"
	}
}));

const AccordionSummary = styled((props) => (
	<MuiAccordionSummary
		expandIcon={ <SvgIcon component={ Arrow } /> }
		{ ...props }
	/>
))(({ theme }) => ({
	backgroundColor: "transparent",
	padding: 0,
	"& .MuiAccordionSummary-expandIconWrapper": {
		transition: "all .25s ease",
		transform: "rotate(0deg)",
		"&.Mui-expanded": {
			transform: "rotate(180deg)"
		}
	},
	"&&& .MuiAccordionSummary-content": {
		marginRight: theme.spacing(3),
		"& .MuiSvgIcon-root": {
			fill: "none",
			stroke: theme.palette.text.primary,
			marginRight: 10
		},
		"& .MuiTypography-root": {
			"&.name": {
				color: theme.palette.text.primary,
				marginRight: 20,
				fontSize: "16px",
				fontWeight: 700,
			},
			"&.date": {
				color: theme.palette.text.secondary,
				fontSize: "10px",
				display: "flex",
				alignItems: "flex-end"
			}
		}
	},
	"& .MuiSvgIcon-root": {
		fill: "#EAEAEA"
	}
}));

const AccordionDetails = styled(MuiAccordionDetails)(() => ({
	padding: "20px 0px 20px",
}));

export default OrderHistoryDefaultAccordion;