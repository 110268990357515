import React, { useEffect, useMemo, useState } from "react";
import OrderHistoryDefaultAccordion from "./OrderHistoryDefaultAccordion";
import { ReactComponent as Step1 } from "../../../assets/images/history/01.svg";
import { ReactComponent as Step2 } from "../../../assets/images/history/02.svg";
import { ReactComponent as Step3 } from "../../../assets/images/history/03.svg";
import { ReactComponent as Step4 } from "../../../assets/images/history/04.svg";
import { ReactComponent as Step5 } from "../../../assets/images/history/05.svg";
import { ReactComponent as Step6 } from "../../../assets/images/history/06.svg";
import {
	ReactComponent as Step7_1,
} from "../../../assets/images/history/07_washing.svg";
import {
	ReactComponent as Step7_2,
} from "../../../assets/images/history/07_repair.svg";
import { ReactComponent as Step8 } from "../../../assets/images/history/08.svg";
import Step1AccordionContent from "./Step1AccordionContent";
import Step2AccordionContent from "./Step2AccordionContent";
import Step3AccordionContent from "./Step3AccordionContent";
import Step4AccordionContent from "./Step4AccordionContent";
import Step5AccordionContent from "./Step5AccordionContent";
import Step6AccordionContent from "./Step6AccordionContent";
import Step7AccordionContent from "./Step7AccordionContent";
import Step8AccordionContent from "./Step8AccordionContent";

function OrderHistoryAccordion ({ items, images, fixImages, workingImages }) {
	const [expended, setExpended] = useState(items.ordrStts);
	const stepIcon = useMemo(() => ({
		"1": { icon: Step1, content: Step1AccordionContent },
		"2": { icon: Step2, content: Step2AccordionContent },
		"3": { icon: Step3, content: Step3AccordionContent },
		"4": { icon: Step4, content: Step4AccordionContent },
		"5": { icon: Step5, content: Step5AccordionContent },
		"6": { icon: Step6, content: Step6AccordionContent },
		"7-1": { icon: Step7_1, content: Step7AccordionContent },
		"7-2": { icon: Step7_2, content: Step7AccordionContent },
		"8": { icon: Step8, content: Step8AccordionContent },
	}), []);

	useEffect(() => {
		setExpended(items.ordrStts);
	}, [items.ordrStts]);

	const onChange = (openIndex) => (e, newExpanded) => {
		setExpended(newExpanded ? openIndex : null);
	};

	return (
		<React.Fragment>
			{ items.history.map(({ stts, ordrSttsNm, sttsDtm }) => {
				const stepData = stepIcon[stts === "7" ? `${ stts }-${ items.ordrTp }` : stts];
				const ContentComponent = stepData.content;

				return (
					<OrderHistoryDefaultAccordion
						key={ ordrSttsNm }
						stepName={ ordrSttsNm }
						stepDate={ sttsDtm }
						stepIcon={ stepData.icon }
						isExpanded={ expended === stts }
						onChange={ onChange(stts) }
						content={ <ContentComponent isCurrentStep={ stts === items.ordrStts } items={ items } images={ images } fixImages={ fixImages } workingImages={ workingImages }/> }
					/>
				);
			}) }
		</React.Fragment>
	);
}

export default OrderHistoryAccordion;