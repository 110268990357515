import { useMutation, useQuery } from "@tanstack/react-query";
import { getRequestData, insertTent, updateEstimate, updateRequestAddTent } from "../../api/admin/requestAddTent";
import * as API from "../../api/admin/requestAddTent";
import Role from "../../lib/Role";

export const REQUEST_ADD_TENT_DATA_KEY = "getRequestData";
export const REQUEST_ADD_TENT_ESTIMATE_DETAIL_KEY = "getEstimateDetail";
export const GET_BRAND_DATA_KEY = "getBrandData";
export const GET_TENT_DATA_KEY = "getTentData";

export function useGetRequestData(estimateId) {
	const {
		      isLoading,
		      isError,
		      isSuccess,
		      data,
	      } = useQuery([REQUEST_ADD_TENT_DATA_KEY, estimateId], () => API.getRequestData(estimateId), {
		retry: 0,
		cacheTime: 0,
		select: (data) => data?.[0],
	});

	return {
		isLoading,
		isError,
		isSuccess,
		data,
	};
}

export function useEstimateDetail(estimateId, orderType) {
    const {
              isLoading: isEstimateDetailLoading,
              data: estimateDetailData,
          } = useQuery([REQUEST_ADD_TENT_ESTIMATE_DETAIL_KEY, estimateId, orderType], () => API.getEstimateDetail(estimateId), {
        enabled: orderType === "1",
        retry: 0,
        cacheTime: 0,
        refetchOnWindowFocus: false
    });

    return {
        isEstimateDetailLoading,
        estimateDetailData,
    };
}

export function useBrandQuery(brandId = "") {
	const {
		      isLoading: isBrandLoading,
		      isError: isBrandError,
		      isSuccess: isBrandSuccess,
		      data: brandData,
	      } = useQuery([GET_BRAND_DATA_KEY], API.getBrandData, {
		enabled: brandId === "",
		refetchOnWindowFocus: false,
	});

	return {
		isBrandLoading,
		isBrandError,
		isBrandSuccess,
		brandData,
	};
}

export function useTentQuery(brandId = "") {
	const {
		      isLoading: isTentLoading,
		      isFetching: isTentFetching,
		      isError: isTentError,
		      isSuccess: isTentSuccess,
		      data: tentData,
	      } = useQuery([GET_TENT_DATA_KEY, brandId], () => API.getTentData(brandId), {
		refetchOnWindowFocus: false,
	});

	return {
		isTentLoading,
		isTentFetching,
		isTentError,
		isTentSuccess,
		tentData,
	};
}

export function useInsertBrandMutation() {
	const { mutate: insertBrandMutate } = useMutation({
		mutationFn: API.insertBrand
	});

	return { insertBrandMutate };
}

export function useInsertTentMutation() {
	const { mutate: insertTentMutate } = useMutation({
		mutationFn: API.insertTent
	});

	return { insertTentMutate };
}

export function useUpdateEstimateMutation() {
	const { mutate: updateEstimateMutate } = useMutation({
		mutationFn: API.updateEstimate
	});

	return { updateEstimateMutate };
}

export function useUpdateRequestAddTentMutation() {
	const { mutate: updateRequestAddTentMutate } = useMutation({
		mutationFn: API.updateRequestAddTent
	});

	return { updateRequestAddTentMutate };
}