import { CircularProgress, Container, Stack, Typography } from "@mui/material";
import React from "react";

function LoadingLogin() {
	return (
		<Container variant="contextRoot">
			<Container variant="contextBox" sx={{ height: "calc(100vh - 60px)"}}>
				<Stack width="100%" height="100%" alignItems="center" justifyContent="center">
					<Typography variant="subTitle">로그인 중입니다.</Typography>
					<Typography variant="subTitle">잠시만 기다려주세요!</Typography>

					<CircularProgress sx={{ mt: 5 }} />
				</Stack>
			</Container>
		</Container>
	);
}

export default LoadingLogin;