import { firebaseAuth } from "../../firebaseAuth";
import axios from "../axios";
import qs from "query-string";
import { ORDER_HISTORY_DETAIL_DEFAULT_URL } from "./defaultUrl";

export async function getOrderHistory(orderId) {
	const userId = await firebaseAuth?.currentUser.uid;
	const res = await axios.post(ORDER_HISTORY_DETAIL_DEFAULT_URL + "/getOrderHistory", qs.stringify({ orderId, userId }));
	return res?.data;
}

export async function getBranch() {
	const res = await axios.post(ORDER_HISTORY_DETAIL_DEFAULT_URL + "/getBranch");
	return res?.data;
}

export async function updateBranch({ orderId, branchId }) {
	const res = await axios.post(ORDER_HISTORY_DETAIL_DEFAULT_URL + "/update/updateBranch", qs.stringify({ orderId, branchId }));
	return res?.data;
}

export async function updateRequestContent(formData) {
	const res = await axios.post(ORDER_HISTORY_DETAIL_DEFAULT_URL + "/updateRequestContent", formData, {
		headers: { "Content-Type": "multipart/form-data" }
	});
	return res?.data;
}

export async function requestOrder(orderId) {
	const res = await axios.post(ORDER_HISTORY_DETAIL_DEFAULT_URL + "/requestOrder", qs.stringify({ orderId }));
	return res?.data;
}

export async function requestWorking({ orderId, deliveryType }) {
	const res = await axios.post(ORDER_HISTORY_DETAIL_DEFAULT_URL + "/requestWorking", qs.stringify({ orderId, deliveryType }));
	return res?.data;
}

export async function cancelOrder({ orderId, phoneNumber }) {
	const res = await axios.post(ORDER_HISTORY_DETAIL_DEFAULT_URL + "/cancelOrder", qs.stringify({ orderId, phoneNumber }));
	return res?.data;
}

export async function customerDeliveryConfirm(orderId) {
	const res = await axios.post(ORDER_HISTORY_DETAIL_DEFAULT_URL + "/update/setCustomerDeliveryConfirm", qs.stringify({ orderId }));
	return res?.data;
}