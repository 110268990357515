import {
	useMutation,
	useQuery,
} from "@tanstack/react-query";
import * as API from "../../api/admin/brand";

export const GET_BRAND_KEY = ["admin", "getBrand"];
export function useBrandQuery() {
	const {
    isLoading: isBrandLoading,
    isError: isBrandError,
    isSuccess: isBrandSuccess,
    data: brandData,
  } = useQuery(GET_BRAND_KEY, API.getBrands, {
		retry: 0,
		refetchOnWindowFocus: false,
		select: (data) => {
			return data.map((item) => {
				return {
					...item,
					useYn: item.useYn === "Y",
				};
			});
		},
	});

	return {
		isBrandLoading,
		isBrandError,
		isBrandSuccess,
		brandData,
	};
}

export function useInsertBrandMutation() {
	const { mutate: insertMutate } = useMutation({
		mutationFn: (data) => {
			return API.insertBrand({
				...data,
				useYn: data.useYn ? "Y" : "N"
			});
		}
	});

	return {
		insertMutate,
	};
}

export function useUpdateBrandMutation() {
	const { mutate: updateMutate } = useMutation({
		mutationFn: (data) => {
			return API.updateBrand({
				...data,
				useYn: data.useYn ? "Y" : "N"
			});
		}
	});

	return {
		updateMutate,
	};
}

export function useDeleteBrandMutation() {
	const { mutate: deleteMutate } = useMutation(API.deleteBrand);

	return {
		deleteMutate,
	};
}