import * as API from "../../api/public/orderHistory";
import { useInfiniteQuery } from "@tanstack/react-query";

export function useInfiniteOrderHistory(id) {
	const SIZE = 3;

	const {
					data,
					error,
					fetchNextPage,
					hasNextPage,
					fetchPreviousPage,
					hasPreviousPage,
					isFetching,
					isFetchingNextPage,
					status,
	} = useInfiniteQuery(["orderHistory", "getOrderHistory"], ({ pageParam = 0 }) => API.getOrderHistory(SIZE, pageParam, id), {
		enabled: !!id,
		refetchOnWindowFocus: false,
		getNextPageParam: ({ nextStart, hasMore }) => {
			if(!hasMore) {
				return undefined;
			} else {
				return nextStart;
			}
		},
		// getPreviousPageParam: (_, pages) => {
		// 	const lastPage = pages[pages.length - 1];
		// 	const count = lastPage.count;
		// 	const hasMore = lastPage.hasMore;
		//
		// 	if(SIZE < count && !hasMore) {
		// 		return 0;
		// 	} else {
		// 		return undefined;
		// 	}
		// }
	});

	return {
		data,
		error,
		fetchNextPage,
		hasNextPage,
		fetchPreviousPage,
		hasPreviousPage,
		isFetching,
		isFetchingNextPage,
		status,
	};
}