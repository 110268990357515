import React, {
  useCallback,
  useState,
} from "react";
import { styled } from "@mui/material/styles";
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent, Divider,
	Typography,
} from "@mui/material";

export default function DeliveryTypeModal({ onSubmit, onClose, title, listContent = [], ...props }) {
  const [isLoading, setIsLoading] = useState(false);
  const setLoadingDone = useCallback(() => {
    setIsLoading(false);
  }, []);

  async function handledSubmit(type) {
    setIsLoading(true);

    await onSubmit(onClose, setLoadingDone, type);
  }

  function handleClose() {
    onClose();
  }

  return (
    <Dialog
      open
      maxWidth="xs"
      fullWidth
    >
	    { title && (
		    <Typography variant="dialogTitle">{ title }</Typography>
	    ) }

      <DialogContent>
	      { listContent.length > 0 ? (
					<Box className="customUl">
						<ul>
							<li>
								<Typography variant="dialogContent">작업 완료 후 텐트 수령 방법을 선택해주세요</Typography>
							</li>

							{ listContent.map((item) => (
								<li>
									<Typography variant="dialogContent">{ item }</Typography>
								</li>
							)) }
						</ul>
					</Box>
	      ) : (
		      <Box>
			      <Typography variant="dialogContent">작업 완료 후 텐트 수령 방법을 선택해주세요</Typography>
		      </Box>
	      ) }

      </DialogContent>

      <DialogActions>
	      <Button
		      size="small"
		      variant="outlined"
		      color="secondary"
		      onClick={ handleClose }
	      >
		      취소
	      </Button>

	      <Divider orientation="vertical" variant="middle" flexItem sx={{ marginLeft: "8px" }} />

	      <Button
		      size="small"
		      variant="contained"
		      disabled={ isLoading }
		      onClick={ () => handledSubmit(1) }
	      >
		      선불배송
	      </Button>

	      <Button
		      size="small"
		      variant="contained"
		      disabled={ isLoading }
		      onClick={ () => handledSubmit(3) }
	      >
		      착불배송
	      </Button>

	      <Divider orientation="vertical" variant="middle" flexItem sx={{ marginLeft: "8px" }} />

	      <Button
		      size="small"
		      variant="contained"
		      disabled={ isLoading }
		      onClick={ () => handledSubmit(2) }
	      >
		      방문수령
	      </Button>
      </DialogActions>
    </Dialog>
  );
}