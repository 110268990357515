import React, { useEffect, useState } from "react";

function UseKakaoSdk() {
    const src = `//dapi.kakao.com/v2/maps/sdk.js?appkey=${ process.env.REACT_APP_KAKAO_MAP_KEY }&autoload=false`;
    const [status, setStatus] = useState("loading");

    useEffect(() => {
        let script = document.querySelector(`script[src="${ src }"]`);

        if (!script) {
            script = document.createElement("script");
            script.src = src;
            script.async = true;
            script.setAttribute("data-status", "loading");
            document.head.appendChild(script);

            function setAttributeFromEvent({ type }) {
                script.setAttribute("data-status", type === "load" ? "ready" : "error");
            }

            script.addEventListener("load", setAttributeFromEvent);
            script.addEventListener("error", setAttributeFromEvent);
        } else {
            setStatus(script.getAttribute("data-status"));
        }

        function setStateFromEvent({ type }) {
            setStatus(type === "load" ? "ready" : "error");
        }

        script.addEventListener("load", setStateFromEvent);
        script.addEventListener("error", setStateFromEvent);

        return () => {
            if (script) {
                script.removeEventListener("load", setStateFromEvent);
                script.removeEventListener("error", setStateFromEvent);
            }
        };
    }, []);

    return status;
}

export default UseKakaoSdk;