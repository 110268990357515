import React, { useMemo } from "react";
import {
	Box,
	Card,
	CardMedia,
	Container,
	Divider,
	Stack,
	SvgIcon,
	Typography
} from "@mui/material";
import TopImage from "../../../assets/images/top_img/top_03.jpg";
import News02 from "../../../assets/images/introduce/news_02.jpg";
import { ReactComponent as Check } from "../../../assets/images/introduce/check.svg";
import NewsCard from "../../../components/public/NewsCard";
import { styled } from "@mui/material/styles";
import {
	Navigation,
	Pagination,
	A11y,
	Autoplay
} from "swiper";
import {
	Swiper,
	SwiperSlide
} from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/effect-fade";
import Certified01 from "../../../assets/images/certified/01.jpg";
import Certified02 from "../../../assets/images/certified/02.jpg";
import Certified03 from "../../../assets/images/certified/03.jpg";
import Certified04 from "../../../assets/images/certified/04.jpg";
import Certified05 from "../../../assets/images/certified/05.jpg";
import Certified06 from "../../../assets/images/certified/06.jpg";
import Certified07 from "../../../assets/images/certified/07.jpg";

function getTitle({ title, left }) {
	return (
		<Box sx={ { position: "relative" } }>
			<Typography variant="title" mb={ 1 }>{ title }</Typography>

			<SvgIcon
				component={ Check }
				sx={ {
					fill: "none",
					position: "absolute",
					top: "-20px",
					left: left + "px"
				} }
			/>
		</Box>
	);
}

function Introduce() {
	const certifiedImage = useMemo(() => ([
		{ image: Certified01, title: "벤처기업확인서" },
		{ image: Certified02, title: "법인특허증" },
		{ image: Certified03, title: "상표등록증_한글" },
		{ image: Certified04, title: "상표등록증_영어" },
		{ image: Certified05, title: "바끄로 환경인증" },
		{ image: Certified06, title: "또르르 플러스 신고증명서" },
		{ image: Certified07, title: "사르르 검사성적서" }
	]), []);

	return (
		<Container variant="contextRoot">
			<Card variant="topImage">
				<CardMedia
					image={ TopImage }
					title="introduce"
					sx={ { height: "200px" } }
				/>
			</Card>

			<Container variant="contextBox">
				<Typography variant="title">INTRODUCE</Typography>

				<Typography variant="context" mb={ 10 }>
					2013년부터 시작하여 믿을 수 있는 텐트 세탁으로 사랑받는 텐트 세탁소는 국내 유일 특허를 가진 텐트 세탁 전문업체인 (주)마운틴티시에스 브랜드입니다.
					<br /><br />
					텐트를 관리한다는 개념도 없던 시절부터 시작해서 지금의 ‘텐트세탁소’라는 고유명사를 만들어 냈습니다. 최다수의 지점을 보유하고 있으며, 후발주자들이 따라올 수 없는 아이템과 기술력으로 텐트세탁하면 ‘텐트세탁소’라는 인식을 확고히 하고 있습니다.
				</Typography>

				<Stack direction="column" spacing={ 6 } sx={ { marginBottom: "100px" } }>
					<Box>
						{ getTitle({ title: "국내 텐트관리 업체 중 최고의 브랜드", left: 222 }) }

						<Typography variant="context">
							텐트를 관리한다는 개념도 없던 시절부터 시작해서 지금의 ‘텐트세탁소’라는 고유명사를 만들어 냈습니다.
						</Typography>
					</Box>

					<Divider />

					<Box>
						{ getTitle({ title: "텐트 세탁방법 및 시설에 관한 특허 획득", left: 259 }) }

						<Typography variant="context">
							텐트에 관한 특허를 획득한 최초이자 유일한 업체입니다.
						</Typography>
					</Box>

					<Divider />

					<Box>
						{ getTitle({ title: "제품의 개발과 환경인증 획득", left: 140 }) }

						<Typography variant="context">
							환경인증을 획득한 안전한 제품을 직접 개발하고 사용합니다.
						</Typography>
					</Box>

					<Divider />

					<Box>
						{ getTitle({ title: "자동주문 시스템", left: 25 }) }

						<Typography variant="context">
							체계적인 주문접수 방식을 도입하여 신뢰할 수 있습니다.
						</Typography>
					</Box>

					<Divider />

					<Box>
						{ getTitle({ title: "원스톱 텐트관리", left: 17 }) }

						<Typography variant="context">
							텐트세탁, 발수코팅, 수선, 리폼까지 원스톱으로 진행가능하여 보다 빠른 서비스를 제공합니다.
						</Typography>
					</Box>

					<Divider />

					<Box>
						{ getTitle({ title: "텐트 업체와 AS협약", left: 115 }) }

						<Typography variant="context">
							스노우라인, 카즈미, 폴러, 블랙다이아몬드, 16피트, DOD, 테라네이션, 레저맨, 위오 등 많은 업체와 공식 AS 관계를 맺고 있습니다.
						</Typography>
					</Box>
				</Stack>

				<Typography variant="title">CERTIFIED</Typography>

				<Box>
					<Wrap
						navigation
						loop
						centeredSlides
						autoplay={ {
							delay: 3 * 1000,
							disableOnInteraction: false
						} }
						pagination={ {
							type: "fraction",
							clickable: true
						} }
						modules={ [Autoplay, Pagination, Navigation, A11y] }
					>
						{ certifiedImage.map(({ image, title }, index) => (
							<SwiperSlide key={ `certifiedImage_${ index }` }>
								<CardMedia
									image={ image }
									sx={ {
										height: "467px",
									} }
								/>

								{ title && <SubTitle>{ title }</SubTitle> }
							</SwiperSlide>
						)) }
					</Wrap>
				</Box>
			</Container>
		</Container>
	);
}

const Wrap = styled(Swiper)(() => ({
	"& .swiper-button-prev, & .swiper-button-next": {
		color: "#EAEAEA",
		opacity: ".8",
		transform: "scale(.8)",
		transition: "all .2s ease",
		"&::after": {
			fontSize: "30px",
			fontWeight: 700
		},
		"&:hover": {
			color: "#E8410B",
			opacity: "1",
			transform: "scale(1)"
		}
	},
	"& .swiper-pagination": {
		"&.swiper-pagination-fraction": {
			width: "auto",
			fontSize: "14px",
			color: "#B9B9B9",
			lineHeight: "22px",
			position: "absolute",
			left: "auto",
			right: 0,
			padding: "2px 10px",
			backgroundColor: "#000000C7",
			borderRadius: "20px",
			marginRight: "10px"
		}
	}
}));

const SubTitle = styled((props) => (
	<Typography variant="context" { ...props } />
))(({ theme }) => ({
	position: "absolute",
	bottom: 0,
	left: "50%",
	transform: "translateX(-50%)",
	backgroundColor: theme.palette.background.paper,
	padding: "5px 15px 2px",
	borderRadius: "10px 10px 0 0",
	width: "max-content"
}));

export default Introduce;