import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Unstable_Grid2";
import React, { useMemo } from "react";
import {
	Card, CardContent, Chip, Divider, Stack, SvgIcon, Typography,
} from "@mui/material";
import {
	ReactComponent as Repair,
} from "../../assets/images/history/07_repair.svg";
import {
	ReactComponent as Washing,
} from "../../assets/images/history/07_washing.svg";
import { ReactComponent as Arrow } from "../../assets/images/icon/dc_arrow.svg";

function CompareEstimateCard({
	orderType,
	beforeDetail,
	beforeTotal,
	detail,
	total,
	deliveryCost = 0,
	outputDeliveryCost = 0,
	showPaymentYn = true,
	paymentFlag,
	showIcon = false,
}) {
	const totalCost = total + deliveryCost + outputDeliveryCost;
	const serviceTotal = detail.reduce((acc, cur) => {
		return acc + cur.amnt;
	}, 0);
	const components = useMemo(() => ({
		"1": Washing,
		"2": Repair,
	}), []);

	function compare(itemId) {
		const beforeItem = beforeDetail.find(({ itmId }) => itmId === itemId);
		const thisItem = detail.find(({ itmId }) => itmId === itemId);

		if(beforeItem) {
			if(beforeItem.cnt === thisItem.cnt) {
				return {
					status: "equals",
				};
			} else {
				const count = thisItem.cnt - beforeItem.cnt;

				if(count < 0) {
					return {
						status: "remove",
						count: Math.abs(count),
					};
				} else {
					return {
						status: "add",
						count: Math.abs(count),
					};
				}
			}
		} else {
			return {
				status: "new",
			};
		}
	}

	function getDeleteItems() {
		return beforeDetail.filter(({ itmId }) => !detail.find(item => item.itmId === itmId));
	}

	function getBeforeTotal() {
		return beforeTotal || beforeDetail.reduce((acc, { amnt }) => {
			return acc + amnt;
		}, 0);
	}

	return (
		<Card>
			<CardContent>
				<Stack
					direction="row"
					justifyContent="space-between"
					alignItems="center"
					mb={ 2 }
				>
					<Stack
						direction="row"
						justifyContent="flex-start"
						alignItems="center"
						spacing={ 1 }
					>
						{ showIcon && orderType && (
							<SvgIcon
								variant="cardIcon"
								component={ components[orderType] }
								sx={ {
									width: "28px",
									height: "28px",
									stroke: (theme) => theme.palette.type[orderType === "1" ? "washing" : "repair"]
								} }
							/>
						) }

						<Typography variant="cardBold">최종 견적</Typography>
					</Stack>

					{ showPaymentYn && (
						paymentFlag ? (
							<Payment className="confirm">결제 확인 완료</Payment>
						) : (
							<Payment>
								결제 대기 중...
							</Payment>
						)
					) }
				</Stack>

				<Grid container mb={ 2 }>
					{ detail.map(({ itmId, grpNm, itmNm, dcYn, amnt, cnt }) => {
						const { status, count } = compare(itmId);
						const name = grpNm ? `[${ grpNm }] ${ itmNm }` : `${ itmNm }`;

						return (
							<React.Fragment key={ name }>
								<Grid xs={ 9 }>
									<Stack
										direction="row"
										alignItems="center"
										spacing={ .8 }
									>
										{ status === "new" && <DetailChip label="NEW" /> }

										{ status === "add" && <DetailChip label={ `+${ count }` } /> }

										{ status === "remove" && <DetailChip label={ `-${ count }` } /> }

										<Typography variant="cardContext" sx={ { color: (theme) => status !== "equals" ? theme.palette.primary.light : "#EAEAEA" } }>{ name }{ cnt > 1 && ` - ${ cnt }개` }</Typography>

										{ dcYn === "Y" && (
											<Chip label="실속" variant="outlined" sx={ { height: 18 } } />
										) }
									</Stack>
								</Grid>

								<Grid xs={ 3 }>
									<Typography variant="cardContext" sx={ { textAlign: "right" } }>{ amnt.toLocaleString() } 원</Typography>
								</Grid>
							</React.Fragment>
						);
					}) }

					{ orderType === "2" && (
						<React.Fragment>
							<Grid xs={ 9 }>
								<Stack
									direction="row"
									alignItems="center"
									spacing={ .8 }
								>
									{ total < beforeTotal && (
										<DetailChip label={ `-${ Math.abs(total - beforeTotal).toLocaleString() }원` } />
									) }

									{ total > beforeTotal && (
										<DetailChip label={ `+${ (total - beforeTotal).toLocaleString() }원` } />
									) }

									<Typography variant="cardContext" sx={ { color: (theme) => (total < beforeTotal || total > beforeTotal) ? theme.palette.primary.light : "#EAEAEA" } }>수선</Typography>
								</Stack>
							</Grid>
							<Grid xs={ 3 }>
								<Typography variant="cardContext" sx={ { textAlign: "right" } }>{ total.toLocaleString() } 원</Typography>
							</Grid>
						</React.Fragment>
					) }

					{ getDeleteItems().map(({ itmId, grpNm, itmNm, dcYn, cnt }) => {
						const name = grpNm ? `[${ grpNm }] ${ itmNm }` : `${ itmNm }`;

						return (
							<React.Fragment key={ itmId }>
								<Grid xs={ 9 }>
									<Stack
										direction="row"
										alignItems="center"
										spacing={ .8 }
									>
										<Typography
											variant="cardContext"
											sx={ {
											color: "#838383",
											textDecoration: "line-through",
											} }
										>
											{ name }{ cnt > 1 && ` - ${ cnt }개` }
										</Typography>

										{ dcYn === "Y" && (
											<Chip label="실속" variant="outlined" sx={ { height: 18 } } />
										) }
									</Stack>
								</Grid>
							</React.Fragment>
						);
					}) }

					{ deliveryCost > 0 && (
						<React.Fragment>
							<Grid xs={ 9 }>
								<Stack
									direction="row"
									alignItems="center"
									spacing={ .8 }
								>
									<DetailChip label="NEW" />

									<Typography variant="cardContext" color={ (theme) => theme.palette.primary.light }>입고 후불 배송비</Typography>
								</Stack>
							</Grid>

							<Grid xs={ 3 }>
								<Typography variant="cardContext" sx={ { textAlign: "right" } }>{ (deliveryCost).toLocaleString() } 원</Typography>
							</Grid>
						</React.Fragment>
					) }

					{ outputDeliveryCost > 0 && (
						<React.Fragment>
							<Grid xs={ 9 }>
								<Stack
									direction="row"
									alignItems="center"
									spacing={ .8 }
								>
									<DetailChip label="NEW" />

									<Typography variant="cardContext" color={ (theme) => theme.palette.primary.light }>수취 선불 배송비</Typography>
								</Stack>
							</Grid>

							<Grid xs={ 3 }>
								<Typography variant="cardContext" sx={ { textAlign: "right" } }>{ (outputDeliveryCost).toLocaleString() } 원</Typography>
							</Grid>
						</React.Fragment>
					) }

					{ orderType === "1" && serviceTotal !== total && (
						<React.Fragment>
							<Grid xs={ 9 }>
								<Stack
									direction="row"
									alignItems="center"
									spacing={ .8 }
								>
									<DetailChip label="NEW" />

									<Typography variant="cardContext" sx={ { color: (theme) => theme.palette.primary.light } }>변동</Typography>
								</Stack>
							</Grid>

							<Grid xs={ 3 }>
								<Typography variant="cardContext" sx={ { textAlign: "right" } }>
									{ total < serviceTotal && `- ${ Math.abs(total - serviceTotal).toLocaleString() } 원` }
									{ total > serviceTotal && `+ ${ (total - serviceTotal).toLocaleString() } 원` }
								</Typography>
							</Grid>
						</React.Fragment>
					) }
				</Grid>

				<Divider />

				<Stack
					direction="row"
					justifyContent="flex-end"
					alignItems="center"
					mt={ 2 }
					spacing={ 1 }
				>
					{ getBeforeTotal() !== totalCost && (
						<React.Fragment>
							<Typography
								variant="cardContext"
								sx={ {
								color: (theme) => theme.palette.text.secondary,
								textDecoration: "line-through",
							} }
							>
								{ getBeforeTotal().toLocaleString() } 원
							</Typography>

							<SvgIcon
								component={ Arrow }
								sx={ {
									width: 14,
									height: 16,
								} }
							/>
						</React.Fragment>
					) }

					<Typography variant="cardBold" sx={ { color: (theme) => theme.palette.type.washing } }>{ (totalCost).toLocaleString() } 원</Typography>
				</Stack>
			</CardContent>
		</Card>
	);
}

const Payment = styled("div")(({ theme }) => ({
	fontSize: "13px",
	fontWeight: 400,
	color: theme.palette.text.primary,
	lineHeight: "22px",
	backgroundColor: "#373737",
	padding: "2px 10px",
	borderRadius: 3,
	border: "1px solid #616161",
	"&.confirm": {
		fontWeight: 700,
		backgroundColor: "rgba(232, 65, 11, 0.15)",
		borderColor: "#E8410B",
		color: "#E8410B",
	},
}));

const DetailChip = styled((props) => (
	<Chip
		variant="outlined"
		color="primary"
		{ ...props }
	/>
))(() => ({
	height: 18,
	letterSpacing: 1,
}));

export default CompareEstimateCard;