import { Chip, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useEffect } from "react";
import { isMobile } from "react-device-detect";
import { useAdminUserState } from "../../../hooks/admin/adminAuthContextProvider";
import Role from "../../../lib/Role";
import { ExpandMoreRounded } from "@mui/icons-material";

function CardSubTitle({
	receptionNumber,
	brandName,
	tentName,
	branchName = "",
	open,
	setOpen,
	noneTooltip,
}) {
	const { role } = useAdminUserState();
	const chipLabel = branchName + (receptionNumber ? ` - ${ receptionNumber }` : "");
	const title = `${ brandName } - ${ tentName }`;
	const length = isMobile ? 25 : 28;

	return (
		<Stack
			direction="row"
			spacing={ 1 }
			mb={ open ? 3 : 1 }
		>
			<Chip
				label={ chipLabel }
				// variant="outlined"
				// color="primary"
				sx={ { fontWeight: 700 } }
			/>

			<Stack
				direction="row"
				justifyContent="space-between"
				alignItems="center"
				sx={ { width: "100%" } }
			>
				{ (chipLabel + title).length <= length || noneTooltip === true ? (
					<Typography variant="cardBold">{ title }</Typography>
				) : (
					<Tooltip title={ title } arrow>
						<Typography variant="cardBold">{ title.substring(0, length - chipLabel.length ) }...</Typography>
					</Tooltip>
				) }

				<ExpandButton size="small" more={ open ? 1 : 0 } onClick={ () => setOpen((prev) => !prev) }>
					<ExpandMoreRounded />
				</ExpandButton>
			</Stack>
		</Stack>
	);
}

const ExpandButton = styled(IconButton)(({ more }) => ({
	backgroundColor: "rgba(255,255,255,0.15)",
	padding: 1,
	"& > .MuiSvgIcon-root": {
		transition: "all 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
		transform: more && "rotate(180deg)",
	},
}));

export default CardSubTitle;