import React, { useCallback, useEffect, useState } from "react";
import { Alert, Button, Stack, Typography } from "@mui/material";
import EstimateCard from "../../common/EstimateCard";
import RequestContextField from "../RequestContextField";
import ImageUpload from "../ImageUpload";
import { Toast } from "../Toast";
import Footer from "./Footer";
import {
	GET_ORDER_HISTORY_KEY,
	useUpdateRequestContent,
} from "../../../query/public/orderHistoryDetailQuery";
import useModal from "../../../hooks/useModal";
import { modals } from "../../../hooks/Modals";
import { useParams } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { equalsArray } from "../../../lib/libs";
import { GET_REQUEST_IMAGES_KEY } from "../../../query/getImageQuery";

function Step1AccordionContent ({ items, images, isCurrentStep }) {
	const { orderId } = useParams();
	const [defaultImages, setDefaultImages] = useState([]);
	const [sendImages, setSendImages] = useState([]);
	const [requestContent, setRequestContent] = useState(items.rqstCntnt);
	const [errorMessage, setErrorMessage] = useState("");
	const [changeFlag, setChangeFlag] = useState(false);
	const { openModal } = useModal();
	const { updateRequestContentMutate } = useUpdateRequestContent();
	const queryClient = useQueryClient();

	const addImagesHandler = useCallback((newImages) => {
		setSendImages([...newImages]);
	}, []);

	useEffect(() => {
		if(!equalsArray(images, defaultImages)) {
			setDefaultImages([...images]);
			setChangeFlag(false); // 서버에서 가져온 이미지 목록이 변경되었을 때
		}
	}, [images]);

	useEffect(() => {
		if(!changeFlag) {
			const fileNames = sendImages.map((file) => {
				return file.name;
			});

			if(!equalsArray(images, fileNames)) {
				setDefaultImages([...images]);
			}

			setRequestContent(items.rqstCntnt);
		}
	}, [changeFlag]);

	function handleRequest () {
		if (!requestContent) {
			setErrorMessage("요청사항을 입력해주세요");
			return;
		}

		openModal(modals.confirmModal, {
			submitTitle: "저장",
			content: (
				<Typography variant="dialogContent">요청사항을 저장하시겠어요?</Typography>
			),
			onSubmit: async (onClose, setLoadingDone) => {
				const formData = new FormData();

				for (let i = 0; i < sendImages.length; i++) {
					formData.append("files", sendImages[i]);
				}

				const contentsData = {
					content: requestContent,
					orderId,
					imagePath: items.rqstImgPth,
				};

				formData.append("contentsData", new Blob([JSON.stringify(contentsData)], { type: "application/json" }));

				updateRequestContentMutate(formData, {
					onSuccess: () => {
						Toast.success("저장이 완료되었어요");
						queryClient.invalidateQueries([...GET_ORDER_HISTORY_KEY, orderId]);
						queryClient.invalidateQueries([...GET_REQUEST_IMAGES_KEY, orderId]);
						onClose();
					},
					onError: () => {
						onClose();
						openModal(modals.confirmModal, {
							title: "죄송합니다, 저장이 실패했습니다",
							content: "잠시 후에 다시 시도해주시기 바랍니다.",
							closeTitle: "닫기",
						});
					},
					onSettled: () => {
						setLoadingDone();
					},
				});
			},
		});
	}

	function changeRequestContent (newValue) {
		setRequestContent(newValue);
		setErrorMessage("");
	}

	return (
		<Stack>
			<ImageUpload
				readOnly={ !changeFlag }
				images={ defaultImages }
				setImage={ addImagesHandler }
			/>

			<RequestContextField
				readOnly={ !changeFlag }
				defaultValue={ requestContent }
				setText={ changeRequestContent }
			/>

			{ errorMessage !== "" && (
				<Alert severity="error" sx={ { marginBottom: 1 } }>{ errorMessage }</Alert>
			) }

			{ isCurrentStep && (
				<Stack direction="row" spacing={ 1 } mt={ 4 }>
					<Button
						variant="contained"
						size="large"
						color="secondary"
						fullWidth
						onClick={ () => setChangeFlag((prev) => !prev) }
					>
						{ changeFlag ? "취소" : "요청사항 수정" }
					</Button>

					{ changeFlag && (
						<Button
							variant="contained"
							size="large"
							color="washing"
							fullWidth
							onClick={ handleRequest }
						>
							저장
						</Button>
					) }
				</Stack>
			) }

			<Footer />
		</Stack>
	);
}

export default Step1AccordionContent;