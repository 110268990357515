import { JavascriptOutlined } from "@mui/icons-material";
import qs from "query-string";
import axios from "../axios";
import { ORDER_DEFAULT_URL } from "./defaultUrl";

export async function getStep(currentStep, branchId = "", filter = {}) {
	const res = await axios.get(ORDER_DEFAULT_URL + "/getStep", {
		params: {
			currentStep, branchId, ...filter
		},
		paramsSerializer: (params) => qs.stringify(params)
	});
	return res?.data;
}

export async function getHistoryStep(currentStep, branchId = "", filter) {
	const res = await axios.get(ORDER_DEFAULT_URL + "/getHistoryStep", {
		params: {
			currentStep,
			branchId,
			...filter
		},
		paramsSerializer: (params) => qs.stringify(params)
	});
	return res?.data;
}

export async function getCompleteStep(branchId = "", filter) {
	const res = await axios.get(ORDER_DEFAULT_URL + "/getCompleteStep", {
		params: {
			branchId,
			...filter
		},
		paramsSerializer: (params) => qs.stringify(params)
	});
	return res?.data;
}

export async function getEstimate(estimateId) {
	const res = await axios.post(ORDER_DEFAULT_URL + "/listAll/getEstimate", qs.stringify({ estimateId }));
	return res?.data;
}

export async function setReceive(requestData) {
	const res = await axios.post(ORDER_DEFAULT_URL + "/setReceive", qs.stringify({ ...requestData }));
	return res?.data;
}

export async function setPaymentComplete(orderId) {
	const res = await axios.post(ORDER_DEFAULT_URL + "/update/paymentComplete", qs.stringify({ orderId }));
	return res?.data;
}

export async function setWorkingStart(orderId) {
	const res = await axios.post(ORDER_DEFAULT_URL + "/setStartWorking", qs.stringify({ orderId }));
	return res?.data;
}

export async function updateWorkingContent(formData) {
	const res = await axios.post(ORDER_DEFAULT_URL + "/updateWorkingContent", formData, {
		headers: { "Content-Type": "multipart/form-data" }
	});
	return res?.data;
}

export async function setWorkingComplete(orderId) {
	const res = await axios.post(ORDER_DEFAULT_URL + "/setWorkingComplete", qs.stringify({ orderId }));
	return res?.data;
}

export async function getDefaultDeliveryId(branchId) {
	const res = await axios.post(ORDER_DEFAULT_URL + "/listAll/getDefaultDeliveryId", qs.stringify({ branchId }));
	return res?.data?.[0]?.dlvryId;
}

export async function getDeliveryDivision() {
	const res = await axios.post(ORDER_DEFAULT_URL + "/listAll/getDeliveryDivision");
	return res?.data;
}

export async function getDeliveryList() {
	const res = await axios.post(ORDER_DEFAULT_URL + "/listAll/getDeliveryList");
	return res?.data;
}

export async function setDeliveryReception(data) {
	const res = await axios.post(ORDER_DEFAULT_URL + "/setDeliveryReception", qs.stringify(data));
	return res?.data;
}

export async function setRequestPickUp(data) {
	const res = await axios.post(ORDER_DEFAULT_URL + "/setRequestPickUp", qs.stringify(data));
	return res?.data;
}

export async function setPickUpConfirm(orderId) {
	const res = await axios.post(ORDER_DEFAULT_URL + "/setPickUpConfirm", qs.stringify({ orderId }));
	return res?.data;
}

export async function setRefund(orderId) {
	const res = await axios.post(ORDER_DEFAULT_URL + "/update/setRefund", qs.stringify({ orderId }));
	return res?.data;
}

export async function changeDeliveryType({ orderId, deliveryType, deliveryDivision, cost }) {
	const res = await axios.post(ORDER_DEFAULT_URL + "/update/changeDeliveryType", qs.stringify({ orderId, deliveryType, deliveryDivision, cost }));
	return res?.data;
}

export async function deleteOrder({ orderId, estimateId, imagePath = "" }) {
	const res = await axios.post(ORDER_DEFAULT_URL + "/deleteOrder", qs.stringify({ orderId, estimateId, imagePath }));
	return res?.data;
}

export async function cancelOrder({ orderId, phoneNumber }) {
	const res = await axios.post(ORDER_DEFAULT_URL + "/cancelOrder", qs.stringify({ orderId, phoneNumber }));
	return res?.data;
}

export async function requestOrder({ orderId }) {
	const res = await axios.post(ORDER_DEFAULT_URL + "/requestOrder", qs.stringify({ orderId }));
	return res?.data;
}

export async function requestWork({ orderId }) {
	const res = await axios.post(ORDER_DEFAULT_URL + "/requestWork", qs.stringify({ orderId }));
	return res?.data;
}