import axios from "./../axios";
import { getAccessToken } from "../../lib/config/exportToken";
import { AUTH_DEFAULT_URL } from "./defaultUrl";

function getState() {
	return Math.random().toString(36).substring(3, 15);
}

function getParameter(provider) {
	const CLIENT_ID = process.env["REACT_APP_" + provider + "_CLIENT_ID"];
	const REDIRECT_URL = process.env["REACT_APP_" + provider + "_REDIRECT_URL"];
	const REDIRECT_URI = `${window.location.origin}/${REDIRECT_URL}`;

	return `client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&response_type=code`;
}

export function getKakaoAuthUri() {
	return `https://kauth.kakao.com/oauth/authorize?` + getParameter("KAKAO");
}

export function getNaverAuthUri() {
	const STATE = getState();

	return `https://nid.naver.com/oauth2.0/authorize?state=${STATE}&` + getParameter("NAVER");
}

export async function silentRefresh() {
	const res = await axios.get(AUTH_DEFAULT_URL + "/refresh");

	return {
		cd: res?.data.cd,
		newAccessToken: getAccessToken(res.headers)
	};
}

export async function login() {
	return await axios.get(AUTH_DEFAULT_URL + "/login");
}

export async function getCurrentUser() {
	const res = await axios.get(AUTH_DEFAULT_URL + "/getCurrentUser");
	return res?.data;
}

export async function logout() {
	const res = await axios.get(AUTH_DEFAULT_URL + "/logout");
	return res?.data;
}