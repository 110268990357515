import React, {
  useCallback,
  useState,
} from "react";
import { styled } from "@mui/material/styles";
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent, Divider,
	Typography,
} from "@mui/material";

export default function ConfirmModal({ onSubmit, onClose, title, closeTitle = "취소", submitTitle, submitTitle2, content, ...props }) {
  const [isLoading, setIsLoading] = useState(false);
  const setLoadingDone = useCallback(() => {
    setIsLoading(false);
  }, []);

  async function handledSubmit(type) {
    setIsLoading(true);

    await onSubmit(onClose, setLoadingDone, type);
  }

  function handleClose() {
    onClose();
  }

  return (
    <Dialog
      open
      maxWidth="xs"
      fullWidth
    >
      { title && <Typography variant="dialogTitle">{ title }</Typography> }

      <DialogContent>
        <Box>
          { content }
        </Box>
      </DialogContent>

      <DialogActions>
        { closeTitle && (
          <Button
            size="small"
            variant="outlined"
            color="secondary"
            onClick={ handleClose }
          >
            { closeTitle }
          </Button>
        ) }

	      { submitTitle2 && (
					<Divider orientation="vertical" variant="middle" flexItem sx={{ marginLeft: "8px" }} />
	      ) }

        { submitTitle && (
          <Button
            size="small"
            variant="contained"
            disabled={ isLoading }
            onClick={ () => handledSubmit(1) }
          >
            { submitTitle }
          </Button>
        ) }

        { submitTitle2 && (
          <Button
            size="small"
            variant="contained"
            disabled={ isLoading }
            onClick={ () => handledSubmit(2) }
          >
            { submitTitle2 }
          </Button>
        ) }
      </DialogActions>
    </Dialog>
  );
}