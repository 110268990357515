import { Card, CardContent, InputBase, Typography } from "@mui/material";
import React from "react";

function ContentField({ value, title = "요청사항" }) {
	return (
		<Card variant="secondary" sx={ { position: "relative" } }>
			<CardContent>
				<Typography variant="cardBold" mb={ 1 }>{ title }</Typography>

				<InputBase
					fullWidth
					multiline
					readOnly
					minRows={ 1 }
					sx={ { fontSize: 15, color: "#B9B9B9", lineHeight: "22px" } }
					value={ value }
				/>
			</CardContent>
		</Card>
	);
}

export default ContentField;