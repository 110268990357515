import React, { useEffect } from "react";
import { styled } from "@mui/material/styles";
import {
	Box,
	Tab,
	Tabs
} from "@mui/material";
import { isMobile } from "react-device-detect";
import useScrollNavigation from "../../hooks/useScrollNavigation";

function ProductNav({ items, currentTab, setCurrentTab, tabRef }) {
	function changeTab(newIndex) {
		const newTabContent = tabRef.current[newIndex];
		const elementPosition = newTabContent.getBoundingClientRect().top;

		setCurrentTab(newIndex);

		if(isMobile) {
			const top = elementPosition + window.pageYOffset - 110;

			window.scrollTo({ top });
		} else {
			const body = document.getElementsByClassName("simplebar-content-wrapper")[0];
			const top = elementPosition + body.scrollTop - 110;

			body.scrollTo({ top });
		}
	}

	useScrollNavigation(tabRef, setCurrentTab);

	return (
		<StickyTabWrap>
			<CenterTabs value={currentTab}>
				{
					items.map((title, index) => {
						return (
							<Tab
								key={`${title}_${index}`}
								label={title}
								onClick={() => changeTab(index)}
								value={index}
								wrapped
							/>
						);
					})
				}
			</CenterTabs>
		</StickyTabWrap>
	);
}

const StickyTabWrap = styled(Box) (({theme}) => ({
	borderColor: theme.palette.divider,
	position: "sticky",
	top: "60px",
	backgroundColor: theme.palette.background.default,
	marginBottom: "15px",
	zIndex: 1,
	"& .MuiTabs-flexContainer": {
		"& .MuiButtonBase-root": {
			margin: "3px 0"
		}
	}
}));

const CenterTabs = styled((props) => (
	<Tabs
		variant={"scrollable"}
		scrollButtons={"auto"}
		allowScrollButtonsMobile
		{ ...props }
	/>
))(() => ({
	"& .MuiTab-root": {
		fontSize: "16px",
		fontWeight: 600
	},
	"& .Mui-selected": {
		color: "#E8410B"
	},
	"& .MuiTabs-indicator": {
		backgroundColor: "#E8410B"
	}
}));

export default ProductNav;