import {
	Alert,
	Box,
	Button,
	Card,
	CardActions,
	CardContent,
	InputLabel,
	Stack,
	Switch,
	Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Unstable_Grid2";
import { DatePicker } from "@mui/x-date-pickers";
import { useQueryClient } from "@tanstack/react-query";
import dayjs from "dayjs";
import React, { useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import { modals } from "../../hooks/Modals";
import useModal from "../../hooks/useModal";
import { USE_BANNER_QUERY_KEY, useNewBannerMutate } from "../../query/admin/bannerQuery";
import BackgroundBox from "../common/BackgroundBox";
import { Toast } from "../public/Toast";

function UpdateBanner({ type = "add", initData = {}, cancel }) {
	const todayRef = useRef(new Date());
	const dateFormatRef = useRef("YYYY-MM-DD");
	const [bannerItem, setBannerItem] = useState(() => {
		return type === "add" ? ({
			startDate: dayjs(todayRef.current),
			endDate: dayjs(todayRef.current).add(7, "day"),
			image: "",
			imageThumbnail: "",
			display: false,
		}) : ({
			...initData,
			startDate: dayjs(initData?.startDate),
			endDate: dayjs(initData?.endDate)
		});
	});
	const [error, setError] = useState([]);
	const { newBannerMutate } = useNewBannerMutate();
	const { openModal } = useModal();
	const queryClient = useQueryClient();

	function addError(key, message) {
		removeError(key);

		setError((prev) => ([
			...prev,
			{ key, message }
		]));
	}

	function removeError(key) {
		setError((prev) => prev?.filter((item) => item.key !== key));
	}

	function handleSaveNewBanner() {
		if(type === "add" && !bannerItem.image) {
			addError("image", "이미지를 추가해주세요");
			return ;
		}

		if(bannerItem.startDate === null || !bannerItem.startDate?.isValid()) {
			addError("startDate", "시작일을 확인해주세요");
			return ;
		}

		if(bannerItem.endDate === null || !bannerItem.endDate?.isValid()) {
			addError("startDate", "종료을 확인해주세요");
			return ;
		}

		const formData = new FormData();
		const contentsData = {
			type,
			startDate: bannerItem.startDate.format(dateFormatRef.current),
			endDate: bannerItem.endDate.format(dateFormatRef.current),
			displayYn: bannerItem.display ? "Y" : "N"
		};

		if(contentsData.type === "update") {
			contentsData.bannerId = initData.bannerId;
			contentsData.imagePath = initData.imagePath;
		}

		formData.append("files", bannerItem?.image);
		formData.append("contentsData", new Blob([JSON.stringify(contentsData)], { type: "application/json" }));

		newBannerMutate(formData, {
			onSuccess: async () => {
				Toast.success("저장이 완료되었어요");
				cancel();
				queryClient.invalidateQueries([...USE_BANNER_QUERY_KEY]);
			},
			onError: () => {
				openModal(modals.confirmModal, {
					title: "죄송합니다, 저장이 실패했습니다",
					content: "잠시 후에 다시 시도해주시기 바랍니다.",
					closeTitle: "닫기",
				});
			},
		});
	}

	function handleImageChange({ target }) {
		const file = target.files[0];

		if(!file.type.match(/image\/(jpg|jpeg|png)$/)) {
			Toast.error("jpg, jpeg, png 이미지만 추가해주세요");
			return;
		}

		const reader = new FileReader();
		reader.readAsDataURL(file);

		reader.onload = () => {
			removeError("image");

			setBannerItem((prev) => ({
				...prev,
				imageThumbnail: reader.result || "",
				image: file
			}));
		};

		target.value = "";
	}

	function handleBackgroundClick() {
		if(bannerItem.imageThumbnail) {
			openModal(modals.imageModal, {
				imageSrc: bannerItem.imageThumbnail,
				width: isMobile ? 80 : 40,
			});
		}
	}

	function getDayjsFormat(day) {
		return dayjs(dayjs(day).format(dateFormatRef.current));
	}

	function handleStartDateChange(newDate) {
		removeError("startDate");

		setBannerItem((prev) => ({
			...prev,
			startDate: newDate,
		}));

		if(bannerItem.endDate !== null && getDayjsFormat(newDate).isAfter(bannerItem.endDate.format(dateFormatRef.current))) {
			setBannerItem((prev) => ({
				...prev,
				endDate: null,
			}));
		}
	}

	function handleEndDateChange(newDate) {
		removeError("endDate");

		setBannerItem((prev) => ({
			...prev,
			endDate: newDate,
		}));
	}

	return (
		<Card>
			<CardContent>
				<BackgroundBox my={ 2 }>
					<ul>
						<li>
							<Typography variant="context">가로, 세로가 <strong>444px 이상</strong>인 이미지를 권장합니다.</Typography>
						</li>
						<li>
							<Typography variant="context">여러 배너를 동시에 표출해야 할 경우 이미지 크기를 통일하시는 것을 권장합니다.</Typography>
						</li>
						<li>
							<Typography variant="context">세로의 길이가 너무 긴 형태의 이미지는 스크롤이 생기므로 지양해주세요.</Typography>
						</li>
					</ul>
				</BackgroundBox>

				<Stack direction="row" spacing={ 1.5 }>
					<ImageBox>
						{ bannerItem.imageThumbnail && (
							<Image src={ bannerItem.imageThumbnail } onClick={ handleBackgroundClick } />
						) }

						<ButtonBox>
							<Button component="label" fullWidth variant="contained" color="secondary" size="small">
								이미지 수정

								<input type="file" accept="image/jpg,image/png,image/jpeg" onChange={ handleImageChange } hidden />
							</Button>
						</ButtonBox>
					</ImageBox>

					<Grid
						container sx={ {
						"& .MuiGrid2-direction-xs-row": {
							display: "flex",
							alignItems: "center",
						},
					} }
					>
						<Grid xs={ 3 }>
							<InputLabel shrink>시작일</InputLabel>
						</Grid>

						<Grid xs={ 9 }>
							<DatePicker
								format="YYYY-MM-DD"
								views={ ["year", "month", "day"] }
								value={ bannerItem.startDate }
								onChange={ handleStartDateChange }
								shouldDisableDate={ day => {
									return type === "add" ? getDayjsFormat(day).isBefore(dayjs(todayRef.current).format(dateFormatRef.current)) : null;
								} }
								slotProps={{
									mobilePaper: {

									}
								}}
								localeText={{
									toolbarTitle: bannerItem.startDate.isValid() ? bannerItem.startDate.format("YYYY") : "____"
								}}
							/>
						</Grid>

						<Grid xs={ 3 }>
							<InputLabel shrink>종료일</InputLabel>
						</Grid>

						<Grid xs={ 9 }>
							<DatePicker
								format="YYYY-MM-DD"
								views={ ["year", "month", "day"] }
								value={ bannerItem.endDate }
								onChange={ handleEndDateChange }
								showDaysOutsideCurrentMonth
								shouldDisableDate={ day => {
									return bannerItem.startDate !== null ? getDayjsFormat(day).isBefore(bannerItem.startDate.format(dateFormatRef.current)) : null;
								} }
								localeText={{
									toolbarTitle: bannerItem.endDate?.isValid() ? bannerItem.endDate.format("YYYY") : "____"
								}}
							/>
						</Grid>

						<Grid xs={ 3 }>
							<InputLabel shrink>표출</InputLabel>
						</Grid>

						<Grid xs={ 9 }>
							<Switch
								checked={ bannerItem.display }
								onChange={ ({ target: { checked } }) => {
									setBannerItem((prev) => ({
										...prev,
										display: checked,
									}));
								} }
							/>
						</Grid>
					</Grid>
				</Stack>

				{ error.length > 0 && (
					<Stack spacing={ 1 } mt={ 1 }>
						{ error.map(({ key, message }) => (
							<Alert key={ key } severity="error">{ message }</Alert>
						)) }
					</Stack>
				) }
			</CardContent>

			<CardActions variant="adminStepCard">
				<Button size="small" variant="outlined" color="secondary" onClick={ cancel }>취소</Button>
				<Button size="small" variant="contained" color="secondary" onClick={ handleSaveNewBanner }>저장</Button>
			</CardActions>
		</Card>
	);
}

const ImageBox = styled(Box)(({ theme, src }) => ({
	position: "relative",
	minWidth: 100,
	maxWidth: 100,
	height: 180,
	backgroundColor: "#292B2B",
	borderWidth: 1,
	borderStyle: "solid",
	borderColor: theme.palette.divider,
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
}));

const Image = styled(Box)(({ src }) => ({
	width: "100%",
	height: "100%",
	background: `url(${ src })`,
	backgroundRepeat: "no-repeat",
	backgroundSize: "contain",
	backgroundPosition: "center",
	cursor: src ? "pointer" : "default"
}));

const ButtonBox = styled(Box)(() => ({
	position: "absolute",
	bottom: 0,
	width: "100%",
	padding: "0 5px 5px",
}));

export default UpdateBanner;