import { Box, Slider, Stack, Typography } from "@mui/material";
import React from "react";
import { useController } from "react-hook-form";

function FormRangeSlider({ name, control, rules, rangeUnit, ...props }) {
	const {
		      field: { value, onChange },
		      fieldState: { error },
	      } = useController({ name, control, rules });

	function getValueText(value) {
		return `${ value }${ rangeUnit }`;
	}

	function handleChange(e, newValue) {
		onChange(newValue);
		props?.onChange?.call();
	}

	function getTextRange() {
		return value[0] === value[1] ? value[0] : `${value[0]} ~ ${value[1]}`;
	}

	return (
		<Stack direction="row" alignItems="center" spacing={ 3 }>
			<Slider
				value={ value }
				getAriaValueText={ getValueText }
				valueLabelFormat={ getValueText }
				onChange={ handleChange }
				valueLabelDisplay="auto"
				{ ...props }
			/>

			<Box sx={{ minWidth: 45 }}>
				<Typography variant="smallContext" sx={{ width: "max-content", float: "right" }}>{ getTextRange() + rangeUnit }</Typography>
			</Box>
		</Stack>
	);
}

export default FormRangeSlider;