import { Edit, RemoveCircle } from "@mui/icons-material";
import {
	Box,
	Button,
	Container,
	IconButton,
	Skeleton,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { createColumnHelper, flexRender, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import React, { useMemo, useState } from "react";
import CheckBox from "../../components/common/CheckBox";
import StyledSelectBox from "../../components/public/StyledSelectBox";
import { modals } from "../../hooks/Modals";
import useModal from "../../hooks/useModal";
import { FormColumnType, FormDialogType } from "../../lib/config/formDialogType";
import {
	GET_TENT_KEY,
	useBrandsQuery,
	useDeleteTentMutation,
	useInsertTentMutation,
	useTentQuery,
	useUpdateTentMutation,
} from "../../query/admin/tentQuery";

export default function Tent() {
	const [brandId, setBrandId] = useState("");
	const { brandsData } = useBrandsQuery();
	const {
		      isTentLoading,
		      tentData,
	      } = useTentQuery(brandId);
	const { insertMutate } = useInsertTentMutation();
	const { updateMutate } = useUpdateTentMutation();
	const { deleteMutate } = useDeleteTentMutation();
	const queryClient = useQueryClient();
	const { openModal } = useModal();

	function addModalOpen() {
		openModal(modals.formModal, {
			type: FormDialogType.ADD,
			columnDef: getColumnDef(),
			onSubmit: async (formData, onClose, showToast, complete) => {
				insertMutate({
					...formData,
					brndId: brandId,
				}, {
					onSuccess: ({ cd }) => {
						if(cd === "1") {
							onClose();
							return queryClient.invalidateQueries(GET_TENT_KEY);
						}
					},
					onSettled: (data, error) => {
						complete();
						showToast({ cd: (error === null) ? data.cd : 0 });
					},
				});
			},
		});
	}

	function EditRowCell({ info, rowData }) {
		function editModalOpen() {
			openModal(modals.formModal, {
				type: FormDialogType.EDIT,
				columnDef: getColumnDef(),
				rowData,
				onSubmit: (formData, onClose, showToast, complete) => {
					updateMutate(formData, {
						onSuccess: ({ cd }) => {
							if(cd === "1") {
								onClose();
								return queryClient.invalidateQueries(GET_TENT_KEY);
							}
						},
						onSettled: (data, error) => {
							complete();
							showToast({ cd: (error === null) ? data.cd : 0 });
						},
					});
				},
			});
		}

		function deleteModalOpen() {
			openModal(modals.formModal, {
				type: FormDialogType.DELETE,
				columnDef: getColumnDef(),
				rowData,
				onSubmit: (formData, onClose, showToast, complete) => {
					deleteMutate(formData, {
						onSuccess: ({ cd }) => {
							if(cd === "1") {
								onClose();
								return queryClient.invalidateQueries(GET_TENT_KEY);
							}
						},
						onSettled: (data, error) => {
							complete();
							showToast({ cd: (error === null) ? data.cd : 0 });
						},
					});
				},
			});
		}

		return (
			<Stack
				direction="row"
				justifyContent="center"
				alignItems="center"
				spacing={ .5 }
			>
				<IconButton size="small" onClick={ editModalOpen }>
					<Edit sx={ { color: (theme) => theme.palette.text.secondary } } />
				</IconButton>

				<IconButton size="small" onClick={ deleteModalOpen }>
					<RemoveCircle sx={ { color: (theme) => theme.palette.text.secondary } } />
				</IconButton>
			</Stack>
		);
	}

	const columnDefs = useMemo(() => {
		const ch = new createColumnHelper();

		return [
			ch.accessor("brndId", {
				cell: info => info.getValue(),
				fEditable: false,
				fPk: true,
			}),
			ch.accessor("tntId", {
				header: "텐트 ID",
				cell: info => info.getValue(),
				fEditable: false,
				fPk: true,
			}),
			ch.accessor("tntNm", {
				header: "텐트",
				cell: info => info.getValue(),
				align: "left",
				fType: FormColumnType.TEXT,
				fRequired: true,
				fMaxByte: 100,
				size: 500,
			}),
			ch.accessor("sz", {
				header: "사이즈",
				cell: info => info.getValue(),
				fType: FormColumnType.TEXT,
				fMaxByte: 30,
			}),
			ch.accessor("rmk", {
				header: "특이사항",
				cell: info => info.getValue(),
				align: "center",
				fType: FormColumnType.MULTILINE,
				fMaxByte: 1000,
			}),
			ch.accessor("useYn", {
				header: "사용",
				cell: info => <CheckBox readOnly checked={ info.getValue() } />,
				fType: FormColumnType.CHECKBOX,
				fDefaultValue: true,
			}),
			ch.display({
				header: "편집",
				id: "edit",
				cell: info => <EditRowCell info={ info } rowData={ info?.row?.original } />,
				fEditable: false,
				size: 100,
			}),
		];
	}, []);

	const table = useReactTable({
		data: tentData || [],
		columns: columnDefs,
		getCoreRowModel: getCoreRowModel(),
		initialState: {
			columnVisibility: {
				brndId: false,
				tntId: false,
				rmk: false,
			},
		},
	});

	function onBrandChange(value) {
		setBrandId(value);
	}

	function getColumnDef() {
		return table.options.columns.reduce((acc, cur) => {
			return [...acc, { id: cur.id || cur.accessorKey, ...cur }];
		}, []);
	}

	return (
		<Container variant="contextRoot">
			<Container variant="contextBox">
				<Box mb={ 4 }>
					<StyledSelectBox
						title="브랜드"
						items={ brandsData }
						value={ brandId }
						onChange={ onBrandChange }
					/>
				</Box>

				{ brandId && isTentLoading ? (
					<Skeleton variant="rounded" width="100%" height="100%" />
				) : (
					<TableContainer
						sx={ {
							overflow: "visible",
							marginBottom: 6,
						} }
					>
						<Table>
							<TableHead
								sx={ {
									position: "sticky",
									top: 0,
									zIndex: 1000,
								} }
							>
								{ table.getHeaderGroups().map(headerGroup => (
									<TableRow key={ headerGroup.id }>
										{ headerGroup.headers.map(header => (
											<TableCell
												key={ header.id }
												variant="header"
												align="center"
												colSpan={ header.colSpan }
												sx={ { width: header.getSize() } }
											>
												{ header.isPlaceholder ? null : (flexRender(header.column.columnDef.header, header.getContext())) }
											</TableCell>
										)) }
									</TableRow>
								)) }
							</TableHead>

							<TableBody>
								{ table.getRowModel().rows.map(row => {
									return (
										<TableRow key={ row.id }>
											{ row.getVisibleCells().map((cell, index) => {
												return index === 0 ? (
													<TableCell
														key={ cell.id }
														variant="firstGroup"
														align={ cell.column.columnDef.align || "center" }
														sx={ { position: "relative" } }
													>
														{ flexRender(cell.column.columnDef.cell, cell.getContext()) }
													</TableCell>
												) : (
													<TableCell
														key={ cell.id }
														variant="body"
														align={ cell.column.columnDef.align || "center" }
													>
														{ flexRender(cell.column.columnDef.cell, cell.getContext()) }
													</TableCell>
												);
											}) }
										</TableRow>
									);
								}) }

								{ brandId && (
									<TableRow
										sx={ {
											position: "sticky",
											bottom: 0,
										} }
									>
										<TableCell colSpan={ 5 } sx={ { padding: 0 } }>
											<Button variant="contained" size="large" fullWidth onClick={ addModalOpen }>추가</Button>
										</TableCell>
									</TableRow>
								) }
							</TableBody>
						</Table>
					</TableContainer>
				) }
			</Container>
		</Container>
	);
}