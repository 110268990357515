import React, {
	useEffect,
	useState
} from "react";
import {
	Autocomplete,
	CircularProgress,
	FormControl,
	SvgIcon,
	TextField
} from "@mui/material";
import { ReactComponent as Arrow } from "../../../assets/images/icon/inputbox_arrow.svg";
import { styled } from "@mui/material/styles";

function TentAutocomplete({ isLoading, items = [], value, onChange }) {
	const [select, setSelect] = useState(value);

	useEffect(() => {
		setSelect(value);
	}, [value]);

	function renderInput(params) {
		return (
			<TextField
				{ ...params }
				label={ "텐트" }
				InputProps={ {
					...params.InputProps,
					endAdornment: (
						<React.Fragment>
							{
								isLoading ? (
									<CircularProgress
										color={ "inherit" }
										size={ 16 }
									/>
								) : null
							}
							{ params.InputProps.endAdornment }
						</React.Fragment>
					)
				} }
			/>
		);
	}

	function renderGroup(params) {
		params.children = params.children.map((child, index) => {
			return {
				...child,
				key: params.group + index
			};
		});

		return (
			<li key={ params.key }>
				<GroupHeader>{ params.group }</GroupHeader>
				<GroupItems>{ params.children }</GroupItems>
			</li>
		);
	}

	function handleChange(e, newValue) {
		onChange(newValue, e);
		e.preventDefault();
		document.querySelector(".MuiAutocomplete-input").blur();
	}

	return (
		<FormControl fullWidth>
			<StyledAuto
				options={ items }
				isOptionEqualToValue={ (option, value) => option.tntId === value.tntId && option.brndId === value.brndId }
				getOptionLabel={ option => option.tntNm || "" }
				groupBy={ option => option.brndNm }
				loading={ isLoading }
				value={ select }
				onChange={ handleChange }
				popupIcon={ <SvgIcon component={ Arrow } /> }
				renderInput={ renderInput }
				renderGroup={ renderGroup }
			/>
		</FormControl>
	);
}

const GroupHeader = styled("div")(() => ({
	position: "sticky",
	top: "-8px",
	padding: "6px 16px 6px 30px",
	backgroundColor: "#E8410B",
	fontWeight: "bold"
}));

const GroupItems = styled("ul")({
	padding: 0,
	"& li": {
		padding: "6px 16px 6px 30px !important"
	}
});

const StyledAuto = styled(Autocomplete)(({ theme }) => ({
	"&.MuiAutocomplete-hasPopupIcon": {
		"& > .MuiFormControl-root > .MuiInputBase-root": {
			paddingRight: "45px"
		},
		"&.MuiAutocomplete-hasClearIcon": {
			"& > .MuiFormControl-root > .MuiInputBase-root": {
				paddingRight: "65px"
			},
		}
	},
	"& .MuiAutocomplete-endAdornment": {
		"& .MuiButtonBase-root": {
			transition: "all .2s ease",
			"&.MuiAutocomplete-popupIndicator": {
				marginRight: "11px",
				"& .MuiSvgIcon-root": {
					width: 14,
					height: 9,
					fill: "#D9D9D9",
				},
				"&.MuiAutocomplete-popupIndicatorOpen": {
					"& .MuiSvgIcon-root": {
						fill: theme.palette.primary.dark
					}
				}
			},
			"&.MuiAutocomplete-clearIndicator": {
				"& .MuiSvgIcon-root": {
					fill: "#D9D9D9",
					fontSize: "1.5rem",
					transition: "all .2s ease"
				}
			}
		}
	}
}));

export default TentAutocomplete;