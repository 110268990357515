import {
	Alert, Box,
	Button, Card, CardContent, Chip,
	Container, Divider,
	FormControl, InputAdornment,
	Stack,
	SvgIcon,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField, Tooltip, Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { ReactComponent as Arrow } from "../../../assets/images/icon/dc_arrow.svg";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ContentField from "../../../components/admin/order/ContentField";
import CheckBox from "../../../components/common/CheckBox";
import ReadOnlyDeliveryAddressCard from "../../../components/common/ReadOnlyDeliveryAddressCard";
import StyledSelectBox from "../../../components/public/StyledSelectBox";
import NumberInput2 from "../../../components/public/estimate/NumberInput2";
import TentAutocomplete from "../../../components/public/estimate/TentAutocomplete";
import ImageUpload from "../../../components/public/ImageUpload";
import InfoTooltip from "../../../components/common/InfoTooltip";
import RequestContextField from "../../../components/public/RequestContextField";
import { Toast } from "../../../components/public/Toast";
import { modals } from "../../../hooks/Modals";
import useModal from "../../../hooks/useModal";
import { FREE_DELIVERY } from "../../../lib/config/settings";
import { getUser } from "../../../lib/firestore/User";
import { isEmptyString } from "../../../lib/libs";
import {
	useEstimateQuery,
	useThisEstimateQuery,
	useBrandQuery,
	useTentQuery,
	useSaveMutate,
} from "../../../query/admin/fixEstimateQuery";
import { useRequestImages } from "../../../query/getImageQuery";

function Count({ select, setSelect, id, type, amount, dcYn }) {
	if(type === "1") { // 복수
		function set(newValue) {
			setSelect((prev) => {
				const count = (prev[id]?.count || 0) + newValue;

				if(count > 0) {
					return {
						...prev,
						[id]: {
							count,
							amount: count * amount,
							dc: dcYn === "Y"
						}
					};
				} else {
					const newItemsArray = Object.entries(prev).filter(([thisId]) => thisId !== id);

					return newItemsArray.reduce((acc, [thisId, items]) => ({
						...acc,
						[thisId]: items
					}), {});
				}
			});
		}

		return (
			<NumberInput2
				value={ select?.count }
				setValue={ set }
				amount={ amount }
			/>
		);
	} else if(type === "2") { // 단수
		function set({ target: { checked } }) {
			const count = checked ? 1 : 0;

			if(count) {
				setSelect((prev) => ({
					...prev,
					[id]: {
						count,
						amount: count * amount,
						dc: dcYn === "Y"
					}
				}));
			} else {
				setSelect((prev) => {
					const newItemsArray = Object.entries(prev).filter(([thisId]) => thisId !== id);

					return newItemsArray.reduce((acc, [thisId, items]) => ({
						...acc,
						[thisId]: items
					}), {});
				});
			}
		}

		return (
			<CheckBox
				checked={ !!select?.count }
				onChange={ set }
			/>
		);
	}
}

function FixEstimate() {
	const { estimateId } = useParams();
	const { data } = useThisEstimateQuery(estimateId);

	const {
		      isRequestImagesLoading,
		      requestImagesData,
	      } = useRequestImages(data?.ordrId, data?.rqstImgPth, true, true);

	// Brand
	const [brandId, setBrandId] = useState("");
	const { brandData } = useBrandQuery(brandId);
	// Tent
	const [tentInfo, setTentInfo] = useState(null);
	const { isTentFetching, tentData } = useTentQuery(brandId);
	// Estimate
	const { isEstimateSuccess, estimateData } = useEstimateQuery(tentInfo?.tntId);
	// Select and Total Price
	const [select, setSelect] = useState({});
	const [total, setTotal] = useState(0);
	const [dcCheck, setDcCheck] = useState(false);
	const [repairTotal, setRepairTotal] = useState(0);
	const [washingTotal, setWashingTotal] = useState(0);
	// Request
	const { openModal } = useModal();
	const [selectDC, setSelectDC] = useState(false);
	const [requestErrorMessage, setRequestErrorMessage] = useState([]);
	const [images, setImages] = useState([]);
	const [contents, setContents] = useState("");
	const [deliveryCost, setDeliveryCost] = useState(0);
	const { saveMutate } = useSaveMutate();
	const navigate = useNavigate();

  useEffect(() => {
		setBrandId(data?.brndId);
		setDcCheck(data?.dc);

		if(data?.detail) {
			const newMap = data.detail.reduce((acc, { itmId, qntt, amnt, dcYn }) => {
				let dc = dcYn === "Y";

				acc[itmId] = {
					dc,
					count: qntt,
					amount: (amnt * 10) / ((dc ? 0.7 : 1) * 10),
				};

				return acc;
			}, {});

			setSelect(newMap);
		}

		if(data?.ordrTp === "2") {
			setRepairTotal(data.ttlAmnt);
		}
  }, [data]);

  useEffect(() => {
    if(tentData && data) {
      setTentInfo(() => ({...tentData.find((item) => item.brndId === data.brndId && item.tntId === data.tntId)}));
    }
  }, [tentData, data]);

	function addErrorMessage(key, message) {
		removeErrorMessage(key);
		setRequestErrorMessage((prev) => ([
			...prev,
			{ key, message }
		]));
	}

	function removeErrorMessage(key) {
		setRequestErrorMessage((prev) => prev?.filter((item) => item.key !== key));
	}

	function onBrandChange(value) {
		setBrandId(value);
		setTentInfo(null);
		setSelect({});
		setDcCheck(false);
		setTotal(0);
		setWashingTotal(0)
	}

	function onTentChange(newValue) {
		removeErrorMessage("tentInfo");

		setTentInfo(newValue);
		setBrandId(newValue?.brndId || "");
		setSelect({});
		setDcCheck(false);
		setTotal(0);
		setWashingTotal(0);
	}

	function onDcCheckChange({ target: { checked } }) {
		setDcCheck(checked);
	}

	useEffect(() => {
		let total = 0;

		for(let key of Object.keys(select)) {
			total += select[key].amount;
		}

		setTotal(total);
	}, [select]);

	// useEffect(() => {
	// 	if(deliveryCost > 0) {
	// 		removeErrorMessage("deliveryCost");
	// 	}
	// }, [deliveryCost]);

  const dcAmount = useMemo(() => {
    const selectKeys = Object.keys(select);
    let total = 0;
    let isDC = false;

    for(let key of selectKeys) {
      const item = select[key];

      if(item.dc) {
        isDC = true;
	      total += Math.round(item.amount * 0.7 * 10) / 10;
      } else {
	      total += item.amount;
      }
    }

    setSelectDC(isDC);

    if(total > 0) {
        removeErrorMessage("total");
    }

    return total;
  }, [select]);

	useEffect(() => {
		const totalPrice = (dcCheck && selectDC) ? dcAmount : total

		setWashingTotal(totalPrice);

		if(totalPrice > 0) {
			removeErrorMessage("washingTotal");
		}
	}, [dcCheck, total, dcAmount]);

	const body = useCallback(() => {
		if(isEstimateSuccess) {
			return estimateData.reduce((acc, cur, i, src) => {
				const isGroup = cur.grpNm !== undefined && cur.grpNm !== "";
				const groupCnt = isGroup ? src.filter((item) => item.grpId === cur.grpId).length : 0;
				const isGroupFirst = isGroup ? !src.filter((item) => item.grpId === cur.grpId && item.srtOrdr < cur.srtOrdr).length : false;

				const row = (
					<TableRow key={ `${ cur.itmId }_${ i }}` }>
						{ (!isGroup || isGroupFirst) && (
							<TableCell
								variant="firstGroup"
								rowSpan={ isGroup ? groupCnt : null }
								colSpan={ !isGroup ? 2 : null }
								sx={ { position: "relative" } }
							>
                { cur.grpRmk || cur.itmRmk ? (
                  <InfoTooltip rmk={ isGroup ? cur.grpRmk : cur.itmRmk }>
                      { isGroup ? cur.grpNm : cur.itmNm }
                  </InfoTooltip>
                ) : (
                  isGroup ? cur.grpNm : cur.itmNm
                ) }
							</TableCell>
						) }

						{ isGroup && (
							<TableCell variant="secondGroup" sx={ { position: "relative" } }>
                { cur.itmRmk && <InfoTooltip rmk={ cur.itmRmk } /> }
                { cur.itmNm }
							</TableCell>
						) }

						<TableCell variant="body" align="right">
							{ cur.amnt.toLocaleString() }
						</TableCell>

						<TableCell variant="body" align="center" sx={ { padding: "18px 8px" } }>
							<Count
								id={ cur.itmId }
								type={ cur.itmTp }
								amount={ cur.amnt }
								select={ select[cur.itmId] }
								setSelect={ setSelect }
								setTotal={ setTotal }
								dcYn={ cur.dcYn }
							/>
						</TableCell>
					</TableRow>
				);

				return [acc, row];
			}, null);
		} else {
			return <></>;
		}
	}, [estimateData, select]);

	function handleSaveEstimate() {
		if(!tentInfo) {
			addErrorMessage("tentInfo", "텐트를 선택해주세요");
			return;
		}

		if(data.ordrTp === "1") {
			if(total <= 0) {
				addErrorMessage("total", "서비스 항목을 선택해주세요");
				return;
			}

			if(washingTotal <= 0) {
				addErrorMessage("washingTotal", "최종 견적 금액을 입력해주세요");
				return;
			}
		}

		if(data.ordrTp === "2" && repairTotal <= 0) {
			addErrorMessage("repairTotal", "최종 견적 금액을 입력해주세요");
			return;
		}

		// if(data.dlvrDvsn === "1" && deliveryCost <= 0) {
		// 	addErrorMessage("deliveryCost", "선불 배송비를 입력해주세요");
		// 	return;
		// }

		openModal(modals.confirmModal, {
			content: (
				<React.Fragment>
					최종 견적을 등록하시겠어요?
					<Typography variant="smallContext" mt={ 1 }>* 견적 등록 시 고객님께 카카오 알림톡을 발송합니다</Typography>
				</React.Fragment>
			),
			submitTitle: "등록",
			onSubmit: async (onClose, setLoadingDone) => {
				const user = (await getUser(data.usrId)).data();

				const formData = new FormData();
				const contentsData = {
					estimateId,
					total: data.ordrTp === "1" ? washingTotal : repairTotal,
					tentId: tentInfo?.tntId,
					dcYn: dcCheck ? "Y" : "N",
					select: JSON.stringify(select),
					fixContent: contents,
					orderId: data.ordrId,
					userId: data.usrId,
					branchId: data.brnchId,
					brandName: data.brndNm,
					tentName: data.tntNm,
					loginProvider: data.usrId.split(":")[0]
				};

				contentsData.phoneNumber = user.phoneNumber;
				contentsData.nickname = user.nickname;

				if(data.dlvrDvsn === "1") {
					contentsData.deliveryCost = Number(deliveryCost);
				}

				let totalPrice = Number(contentsData.total) + Number(data.rcvngDlvryCst || 0);

				if(contentsData.total < FREE_DELIVERY) {
					totalPrice += (contentsData.deliveryCost || 0);
				}

				contentsData.totalString = totalPrice.toLocaleString();

				formData.append("contentsData", new Blob([JSON.stringify(contentsData)], { type: "application/json" }));

				for(let i = 0; i <= images.length; i++) {
					formData.append("files", images[i]);
				}

				saveMutate(formData, {
					onSuccess: async () => {
						Toast.success("최종 견적이 등록되었어요");
						navigate(`/admin/order/step${ localStorage.getItem("currentStep") || 0 }`);
						onClose();
					},
					onError: () => {
						onClose();
						openModal(modals.confirmModal, {
							title: "죄송합니다, 견적 등록이 실패했습니다",
							content: "잠시 후에 다시 시도해주시기 바랍니다.",
							closeTitle: "닫기",
						});
					},
					onSettled: () => {
						setLoadingDone();
					}
				});
			}
		});
	}

	return (
		<Container variant="contextRoot">
			<Container variant="contextBox">
				<Stack spacing={ 2 } mb={ 2 }>
					<StyledSelectBox
						title="브랜드"
						items={ brandData }
						onChange={ onBrandChange }
						value={ brandId }
					/>

					<TentAutocomplete
						isLoading={ isTentFetching }
						items={ tentData }
						value={ tentInfo }
						onChange={ onTentChange }
					/>

					{ tentInfo && tentInfo.sz && (
						<FormControl fullWidth>
							<TextField
								label="사이즈"
								value={ tentInfo.sz }
								disabled
							/>
						</FormControl>
					) }

					{ tentInfo && tentInfo.rmk && (
						<FormControl fullWidth>
							<TextField
								label="특이사항"
								value={ tentInfo.rmk }
								disabled
								multiline
							/>
						</FormControl>
					) }
				</Stack>

				{ (!isRequestImagesLoading && requestImagesData?.length > 0) || !isEmptyString(data?.rqstCntnt) ? (
					<Divider>
						<Chip label="고객님의 요청사항" variant="outlined" color="primary" />
					</Divider>
				) : (<></>) }

				{ !isRequestImagesLoading && requestImagesData.length > 0 && (
					<Box mt={ 2 }>
						<ImageUpload
							readOnly
							images={ requestImagesData }
						/>
					</Box>
				) }

				{ data?.rqstCntnt && (
					<Box mt={ 2 }>
						<ContentField value={ data?.rqstCntnt } />
					</Box>
				) }

				{ data?.ordrTp === "2" && data?.rprCntnt && (
					<Box mt={ 3 }>
						<Divider>
							<Chip label="수선 가견적 안내사항" variant="outlined" color="primary" />
						</Divider>

						<Box mt={ 2 }>
							<ContentField value={ data?.rprCntnt } title="" />
						</Box>
					</Box>
				) }

				<Box mt={ 3 }>
					<Divider>
						<Tooltip placement="top" arrow title="견적 확정 시 고객님께 전달해야 할 내용을 작성하거나, 사진을 첨부해주세요">
							<Chip label="견적 확정 안내사항" variant="outlined" color="primary" />
						</Tooltip>
					</Divider>
				</Box>

				<Box mt={ 3 }>
					<ImageUpload setImage={ setImages } maxLength={ 100 } />
				</Box>

				<Box mt={ 2 } mb={ 4 }>
					<RequestContextField setText={ setContents } title="안내사항" />
				</Box>

				<Divider />

				{ data?.ordrTp === "1" && (
					<React.Fragment>
						<TableContainer sx={ { overflow: "visible", marginY: 4 } }>
							<Table>
								<TableHead sx={ { position: "sticky", top: 0, zIndex: 1000 } }>
									<TableRow>
										<TableCell variant="header" colSpan={ 2 } sx={ { width: "50%" } }>항목</TableCell>
										<TableCell variant="header" sx={ { width: "25%" } }>단가</TableCell>
										<TableCell variant="header" sx={ { width: "25%" } }>수량</TableCell>
									</TableRow>
								</TableHead>

								<TableBody>
									{ body() }

									{ isEstimateSuccess && (
										<TableRow>
											<TableCell variant="firstGroup" colSpan={ 2 }>
												<InfoTooltip rmk="젖은 상태에서 세제세척이 아닌 물샤워 후 건조만 원할 때 추천드려요.">
													실속 상품
												</InfoTooltip>
											</TableCell>
											<TableCell
												variant="secondGroup"
												sx={ {
													color: "#E8410B",
													fontWeight: 700
												} }
											>
												30% 할인
											</TableCell>
											<TableCell variant="body" align="center">
												<CheckBox
													checked={ !!dcCheck }
													onChange={ onDcCheckChange }
												/>
											</TableCell>
										</TableRow>
									) }

									<TableRow
										sx={ {
											position: "sticky",
											bottom: 0,
											zIndex: 1000
										} }
									>
										<TableCell variant="header" colSpan={ 2 }>합계</TableCell>

										<TotalCell>
											<Stack
												direction="row"
												alignItems="center"
												justifyContent="flex-end"
												spacing={ 1 }
											>
											<span
												style={ {
													textDecoration: dcCheck && selectDC && "line-through",
													fontWeight: !(dcCheck && selectDC) && "bold",
													fontSize: dcCheck && selectDC && 12
												} }
											>
												{ total.toLocaleString() }
											</span>

												{ dcCheck && selectDC && (
													<React.Fragment>
														<SvgIcon
															component={ Arrow }
															sx={ {
																width: 14,
																height: 16
															} }
														/>
														<span style={ { fontWeight: "bold" } }>{ dcAmount.toLocaleString() }</span>
													</React.Fragment>
												) }
											</Stack>
										</TotalCell>
									</TableRow>
								</TableBody>
							</Table>
						</TableContainer>

						<Card sx={{ marginY: 4 }}>
							<CardContent>
								<Typography variant="cardBold" mb={ 2 }>최종 견적 금액</Typography>

								<TextField
									type="number"
									fullWidth
									value={ washingTotal }
									onChange={ ({ target: { value }}) => setWashingTotal(value) }
									InputProps={ {
										endAdornment: <InputAdornment position="end">원</InputAdornment>,
										onWheel: e => { e.target.blur(); },
									} }
								/>
							</CardContent>
						</Card>
					</React.Fragment>
				) }

				{ data?.ordrTp === "2" && (
					<Card sx={{ marginY: 4 }}>
						<CardContent>
							<Typography variant="cardBold" mb={ 2 }>최종 견적 금액</Typography>

							<TextField
								type="number"
								fullWidth
								value={ repairTotal }
								onChange={ ({ target: { value }}) => setRepairTotal(value) }
								InputProps={ {
									endAdornment: <InputAdornment position="end">원</InputAdornment>,
									onWheel: e => {e .target.blur(); },
								} }
							/>
						</CardContent>
					</Card>
				) }

				{/* 선불일 때 */}
				{ data?.dlvrDvsn === "1" && (
					<React.Fragment>
						<Divider />

						<Box mt={ 4 } mb={ 2 }>
							<ReadOnlyDeliveryAddressCard orderId={ data?.ordrId } />
						</Box>

						<Card sx={{ marginBottom: 4 }}>
							<CardContent>
								<Typography variant="cardBold" mb={ 2 }>선불 배송비</Typography>

								<TextField
									type="number"
									fullWidth
									value={ deliveryCost }
									// value={ (data.ordrTp === "1" ? washingTotal : repairTotal) >= FREE_DELIVERY ? 0 : deliveryCost }
									// disabled={ (data.ordrTp === "1" ? washingTotal : repairTotal) >= FREE_DELIVERY }
									onChange={ ({ target: { value }}) => setDeliveryCost(value) }
									InputProps={ {
										endAdornment: <InputAdornment position="end">원</InputAdornment>,
										onWheel: e => { e.target.blur(); },
									} }
								/>

								{/*<Typography variant="cardContext" mt={ 2 }>* 최종 견적 금액이 10만원 이상 시 무료배송이므로 배송비 설정 불가</Typography>*/}
							</CardContent>
						</Card>
					</React.Fragment>
				) }

				<Divider />

				{ data?.rcvngDlvryCst && (
					<Card sx={{ marginY: 4 }}>
						<CardContent>
							<Typography variant="cardBold" mb={ 2 }>입고 후불 배송비</Typography>

							<TextField
								fullWidth
								disabled
								value={ (data?.rcvngDlvryCst || 0).toLocaleString() }
								onWheel={ e => {e.preventDefault();} }
								InputProps={ {
									endAdornment: <InputAdornment position="end">원</InputAdornment>,
								} }
							/>
						</CardContent>
					</Card>
				) }

				{ requestErrorMessage.length > 0 && (
					<Stack spacing={ 1 } mb={ 1 }>
						{ requestErrorMessage.map(({ key, message }) => (
							<Alert key={ key } severity="error">{ message }</Alert>
						)) }
					</Stack>
				) }

				<Button variant="contained" size="large" fullWidth onClick={ handleSaveEstimate }>최종 견적 등록</Button>
			</Container>
		</Container>
	);
}

const TotalCell = styled((props) => (
	<TableCell variant="body" colSpan={ 2 } { ...props } />
))(() => ({
	"&&&": {
		backgroundColor: "#FFCA9A",
		color: "#494949",
		padding: "18px 8px"
	}
}));

export default FixEstimate;