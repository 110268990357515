import { Box, Button, Stack, Typography } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import React from "react";
import { useParams } from "react-router-dom";
import { modals } from "../../../hooks/Modals";
import useModal from "../../../hooks/useModal";
import { isEmptyString } from "../../../lib/libs";
import { GET_ORDER_HISTORY_KEY, useCustomerDeliveryConfirm } from "../../../query/public/orderHistoryDetailQuery";
import BackgroundBox from "../../common/BackgroundBox";
import ReadOnlyDeliveryAddressCard from "../../common/ReadOnlyDeliveryAddressCard";
import ImageUpload from "../ImageUpload";
import RequestContextField from "../RequestContextField";
import { Toast } from "../Toast";
import BranchCard from "./BranchCard";
import DeliveryInfoCard from "./DeliveryInfoCard";
import { WarningRounded } from "@mui/icons-material";

function Step8AccordionContent({ items, workingImages }) {
	const { orderId } = useParams();
	const { openModal } = useModal();
	const { mutate } = useCustomerDeliveryConfirm();
	const queryClient = useQueryClient();
	const cancelFlag = items.csStts === "1";
	const deliveryFlag = items.dlvrRcptYn === "Y";
	const requestPickUpFlag = items.rqstPckUpYn === "Y";

	function handleDeliveryTracking() {
		window.open(items.inqryUrl.replace("##NUMBER##", items.invceNo))
	}

	function handleDeliveryConfirm() {
		openModal(modals.confirmModal, {
			title: "텐트를 받으셨나요?",
			submitTitle: "수령확인",
			content: (
				<Stack direction="row" spacing={ 1 }>
					<WarningRounded sx={{ fill: (theme) => theme.palette.primary.main }} />
					<Typography variant="dialogContent">반드시 텐트를 받으신 경우에만 수령확인을 해주세요</Typography>
				</Stack>
			),
			onSubmit: async (onClose, setLoadingDone) => {
				mutate(orderId, {
					onSuccess: async () => {
						Toast.success("감사합니다, 수령확인이 완료되었어요");
						queryClient.invalidateQueries([...GET_ORDER_HISTORY_KEY, orderId]);
						onClose();
					},
					onError: () => {
						onClose();
						openModal(modals.confirmModal, {
							title: "죄송합니다, 저장이 실패했습니다",
							content: "잠시 후에 다시 시도해주시기 바랍니다.",
							closeTitle: "닫기",
						});
					},
					onSettled: () => {
						setLoadingDone();
					}
				});
			}
		})
	}

	return (
		<Stack>
			<BackgroundBox mb={ 2 }>
				{ items.rcvYn === "Y" ? (
					<React.Fragment>
						<Typography variant="subTitle" mb={ 1 }>텐트가 고객님께 전달되었어요!</Typography>
						<Typography variant="context">텐트세탁소를 이용해주셔서 감사합니다</Typography>
					</React.Fragment>
				) : (
					<React.Fragment>
						{ cancelFlag ? (
							<Typography variant="subTitle">{ items.ordrTpNm } 취소가 완료되었어요</Typography>
						) : (
							<Typography variant="context">고객님의 텐트가 깨끗하게 { items.ordrTpNm }되었어요!</Typography>
						) }

						{ items.rcvngDlvrDvsn && (
							items.dlvryTp === "1" ? (
								<Typography variant="context">{ deliveryFlag ? "배송이 시작되었어요" : "배송이 시작되면 카카오 알림톡으로 안내해 드리겠습니다"}</Typography>
							) : (
								<Typography variant="context">{ requestPickUpFlag ? `${ items.brnchNm }에 방문해 텐트를 수령해주세요` : `텐트 수령 준비가 완료되면 카카오 알림톡으로 안내해 드리겠습니다`}</Typography>
							)
						) }
					</React.Fragment>
				) }
			</BackgroundBox>

			{ items.pymntYn === "Y" && items.deliveryType === "1" && (items.rcvYn !== "Y" || deliveryFlag || items.rcvCnfrmTp !== "2") && (
				<Box mt={ 2 }>
					<ReadOnlyDeliveryAddressCard orderId={ orderId } />
				</Box>
			) }

			{ deliveryFlag && (
				<Box mt={ 2 }>
					<DeliveryInfoCard
						deliveryName={ items.dlvryNm }
						deliverDivision={ items.dlvrDvsn }
						deliverDivisionName={ items.dlvrDvsnNm }
						invoiceNumber={ items.invceNo }
						date={ items.dlvryStrtDtm }
						cost={ items.dlvryCst }
					/>
				</Box>
			) }

			{ requestPickUpFlag && (
				<Box mt={ 2 }>
					<BranchCard
						branchId={ items.brnchId }
						branchName={ items.brnchNm }
						address={ items.addr }
						addressDetail={ items.addrDtl }
						zoneCode={ items.znCd }
						bankName={ items.bnkNm }
						bankAccount={ items.bnkAccnt }
						bankAccountHolder={ items.bnkAccntHldr }
						telNumber={ items.brnchTelNo }
					/>
				</Box>
			) }

			{ workingImages?.length > 0 && (
				<Box mt={ 2 }>
					<ImageUpload
						images={ workingImages }
						readOnly
					/>
				</Box>
			) }

			{ !isEmptyString(items.wrkngCntnt) && (
				<Box mt={ workingImages?.length > 0 ? 0 : 2 }>
					<RequestContextField
						defaultValue={ items.wrkngCntnt }
						title="안내사항"
						readOnly
					/>
				</Box>
			) }

			{ deliveryFlag && (
				<Stack
					direction="row"
					spacing={ 1 }
					mt={ 4 }
				>
					<Button
						variant="contained"
						size="large"
						fullWidth
						onClick={ handleDeliveryTracking }
					>
						배송 조회
					</Button>

					<Button
						variant="contained"
						size="large"
						fullWidth
						disabled={ items.rcvYn === "Y" }
						onClick={ handleDeliveryConfirm }
					>
						수령확인
					</Button>
				</Stack>
			) }
		</Stack>
	);
}

export default Step8AccordionContent;