import React from "react";
import { useParams } from "react-router-dom";
import useModal from "../../../hooks/useModal";
import {
	GET_ORDER_HISTORY_KEY, useCancelOrderMutate, useRequestOrderMutate,
} from "../../../query/public/orderHistoryDetailQuery";
import { useQueryClient } from "@tanstack/react-query";
import { modals } from "../../../hooks/Modals";
import { Button, Stack, Typography } from "@mui/material";
import { Toast } from "../Toast";
import Footer from "./Footer";
import EstimateCard from "../../common/EstimateCard";
import LoadingButton from "@mui/lab/LoadingButton";

function Step2AccordionContent({ items, isCurrentStep }) {
	const { orderId } = useParams();
	const { openModal } = useModal();
	const { cancelOrderMutate } = useCancelOrderMutate();
	const { requestOrderMutate } = useRequestOrderMutate();
	const queryClient = useQueryClient();

	function handleCancel() {
		openModal(modals.confirmModal, {
			content: (
				<Typography variant="dialogContent">{ items.ordrTpNm }을 취소하시겠어요?</Typography>
			),
			submitTitle: `${ items.ordrTpNm } 취소`,
			closeTitle: "닫기",
			onSubmit: async (onClose, setLoadingDone) => {
				cancelOrderMutate({ orderId }, {
					onSuccess: async () => {
						// await deleteOrder(orderId);
						await queryClient.invalidateQueries([...GET_ORDER_HISTORY_KEY, orderId]);

						Toast.success(`${ items.ordrTpNm }이 취소되었어요`);
						onClose();
					},
					onError: () => {
						onClose();
						openModal(modals.confirmModal, {
							title: "죄송합니다, 취소가 실패했습니다",
							content: "잠시 후에 다시 시도해주시기 바랍니다.",
							closeTitle: "닫기",
						});
					},
					onSettled: () => {
						setLoadingDone();
					}
				})
			},
		});
	}

	function handleRequest() {
		openModal(modals.confirmModal, {
			content: (
				<Typography variant="dialogContent">{ items.ordrTpNm }을 접수하시겠어요?</Typography>
			),
			submitTitle: `${ items.ordrTpNm } 접수`,
			onSubmit: async (onClose, setLoadingDone) => {
				requestOrderMutate(orderId, {
					onSuccess: async () => {
						await queryClient.invalidateQueries([...GET_ORDER_HISTORY_KEY, orderId]);

						Toast.success(`${ items.ordrTpNm }이 접수되었어요`);
						onClose();
					},
					onError: () => {
						onClose();
						openModal(modals.confirmModal, {
							title: "죄송합니다, 접수가 실패했습니다",
							content: "잠시 후에 다시 시도해주시기 바랍니다.",
							closeTitle: "닫기",
						});
					},
					onSettled: () => {
						setLoadingDone();
					}
				})
			},
		});
	}

	return (
		<Stack>
			<EstimateCard
				showIcon
				orderType={ items.ordrTp }
				detail={ items.details[2].detail }
				total={ items.details[2].total }
				repairContent={ items.rprCntnt }
			/>

			{ isCurrentStep && (
				<Stack direction="row" spacing={ 1 } mt={ 4 }>
					<Button
						variant="contained"
						size="large"
						color="secondary"
						fullWidth
						onClick={ handleCancel }
					>
						{ items.ordrTpNm } 취소
					</Button>

					<LoadingButton
						variant="contained"
						size="large"
						color="washing"
						fullWidth
						onClick={ handleRequest }
					>
						{ items.ordrTpNm } 접수
					</LoadingButton>
				</Stack>
			) }

			<Footer />
		</Stack>
	);
}

export default React.memo(Step2AccordionContent);