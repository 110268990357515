import axios from "../axios";
import qs from "query-string";
import { TENT_DEFAULT_URL } from "./defaultUrl";

export async function getBrands() {
    const res = await axios.post(TENT_DEFAULT_URL + "/listAll/getBrands", {});
    return res?.data;
}

export async function getTent(data) {
    const res = await axios.post(TENT_DEFAULT_URL + "/listAll/getTent", qs.stringify(data));
    return res?.data;
}

export async function insertTent(tentData) {
    const res = await axios.post(TENT_DEFAULT_URL + "/insert", qs.stringify(tentData));
    return res?.data;
}

export async function updateTent(tentData) {
    const res = await axios.post(TENT_DEFAULT_URL + "/update", qs.stringify(tentData));
    return res?.data;
}

export async function deleteTent(tentData) {
    const res = await axios.post(TENT_DEFAULT_URL + "/delete", qs.stringify(tentData));
    return res?.data;
}