import { Box, Stack, SvgIcon } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useMemo } from "react";
import { ReactComponent as Step1 } from "../../../assets/images/history/01.svg";
import { ReactComponent as Step2 } from "../../../assets/images/history/02.svg";
import { ReactComponent as Step3 } from "../../../assets/images/history/03.svg";
import { ReactComponent as Step4 } from "../../../assets/images/history/04.svg";
import { ReactComponent as Step5 } from "../../../assets/images/history/05.svg";
import { ReactComponent as Step6 } from "../../../assets/images/history/06.svg";
import { ReactComponent as Step7_2 } from "../../../assets/images/history/07_repair.svg";
import { ReactComponent as Step7_1 } from "../../../assets/images/history/07_washing.svg";
import { ReactComponent as Step8 } from "../../../assets/images/history/08.svg";

function StepIcon({ step, orderType, currentStep }) {
	const stepData = useMemo(() => ({
		"1": { component: Step1, text: "가견적\\A요청" },
		"2": { component: Step2, text: "가견적\\A완료" },
		"3": { component: Step3, text: "접수" },
		"4": { component: Step4, text: "입고" },
		"5": { component: Step5, text: "견적완료" },
		"6": { component: Step6, text: "작업대기" },
		"7-1": { component: Step7_1, text: "세탁중" },
		"7-2": { component: Step7_2, text: "수선중" },
		"8": { component: Step8, text: "완료" }
	}), []);
	const thisStep = stepData[step === 7 ? `7-${ orderType }` : step];

	return (
		<StepBox text={ thisStep.text }>
			<IconBox
				ordertype={ orderType }
				className={ currentStep == step ? "active" : currentStep < step ? "before" : "after" }
			>
				<SvgIcon
					component={ thisStep.component }
				/>
			</IconBox>
		</StepBox>
	);
}

function OrderProcessBar({ orderType, etcTentExist, currentStep }) {
	const stepNumber = [...new Array(8)];

	return (
		<Stack
			direction="row"
			justifyContent="space-between"
			alignItems="center"
			pb={ 5 }
			mb={ 3 }
		>
			{ stepNumber.map((_, index) => {
				const step = index + 1;

				if(index + 1 <= 2 && etcTentExist !== 1) {
					return <React.Fragment key={ `step${step}` } />;
				} else {
					return <StepIcon key={ `step${step}` } step={ step } orderType={ orderType } currentStep={ currentStep } />
				}
			}) }
		</Stack>
	);
}

const StepBox = styled(Box)(({ text }) => ({
	flex: "1 1 auto",
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
	position: "relative",
	"&::after": {
		content: `"${ text }"`,
		fontSize: 8,
		color: "#939393",
		textAlign: "center",
		whiteSpace: "pre",
		position: "absolute",
		top: "100%",
		marginTop: 8
	}
}));

const IconBox = styled(Box)(({ theme, ordertype }) => ({
	padding: 6,
	borderRadius: "100px",
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	border: "1px solid transparent",
	"&.after": {
		backgroundColor: "#4B4B4B",
		borderColor: "#4B4B4B",
		"& > .MuiSvgIcon-root": {
			stroke: "#EAEAEA",
			fill: "none"
		}
	},
	"&.before": {
		backgroundColor: "#272727",
		borderColor: "#505050",
		"& > .MuiSvgIcon-root": {
			stroke: "#505050",
			fill: "none"
		}
	},
	"&.active": {
		backgroundColor: theme.palette.type[ordertype === "1" ? "washing" : "repair"],
		borderColor: theme.palette.type[ordertype === "1" ? "washing" : "repair"],
		"& > .MuiSvgIcon-root": {
			stroke: "#FFF",
			fill: "none"
		}
	}
}));

export default OrderProcessBar;