import Grid from "@mui/material/Unstable_Grid2";
import { useQueryClient } from "@tanstack/react-query";
import React, {
	useEffect, useRef,
	useState,
} from "react";
import {
	Box,
	Button, Card, CardContent,
	Dialog,
	DialogActions,
	DialogContent, Stack,
	Typography,
} from "@mui/material";
import { modals } from "../../../hooks/Modals";
import useModal from "../../../hooks/useModal";
import { FormColumnType, FormDialogType } from "../../../lib/config/formDialogType";
import { isEmptyString } from "../../../lib/libs";
import {
	GET_BRAND_DATA_KEY, REQUEST_ADD_TENT_DATA_KEY,
	useBrandQuery,
	useInsertBrandMutation, useInsertTentMutation,
	useTentQuery, useUpdateEstimateMutation,
} from "../../../query/admin/requestAddTentQuery";
import TentAutocomplete from "../../public/estimate/TentAutocomplete";
import StyledSelectBox from "../../public/StyledSelectBox";
import { Toast } from "../../public/Toast";

export default function AddTentAndBrandModal({
	onSubmit,
	onClose,
	requestBrandName,
	requestTentName,
	estimateId,
	...props
}) {
	const [selectBrandId, setSelectBrandId] = useState("");
	const [selectTent, setSelectTent] = useState(null);
	const { brandData } = useBrandQuery(selectBrandId);
	const {
		      tentData,
		      isTentFetching,
	      } = useTentQuery(selectBrandId);
	const { insertBrandMutate } = useInsertBrandMutation();
	const { insertTentMutate } = useInsertTentMutation();
	const { updateEstimateMutate } = useUpdateEstimateMutation();
	const queryClient = useQueryClient();
	const insertRef = useRef({ brandId: "", tentId: "" });
	const { openModal } = useModal();
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		if(!isEmptyString(insertRef.current.brandId)) {
			setSelectBrandId(insertRef.current.brandId);
		}
	}, [brandData]);

	useEffect(() => {
		if(!isEmptyString(insertRef.current.tentId)) {
			setSelectTent(() => ({ ...tentData.find((item) => item.tntId === insertRef.current.tentId) }));
		}
	}, [tentData]);

	async function handledSubmit() {
		setIsLoading(true);

		const requestData = {
			brandId: selectBrandId,
			tentId: selectTent.tntId,
			estimateId
		};

		updateEstimateMutate(requestData, {
			onSuccess: async () => {
				Toast.success("저장이 완료되었어요");

				handleClose();
				queryClient.invalidateQueries([REQUEST_ADD_TENT_DATA_KEY, estimateId]);
			},
			onError: () => {
				openModal(modals.confirmModal, {
					title: "죄송합니다, 저장이 실패했습니다",
					content: "잠시 후에 다시 시도해주시기 바랍니다.",
					closeTitle: "닫기",
				});
			},
			onSettled: () => {
				setIsLoading(false);
			}
		});
	}

	function handleClose() {
		onClose();
	}

	function handleChangeBrand(value) {
		setSelectBrandId(value);
		setSelectTent(null);
	}

	function handleChangeTent(newValue) {
		setSelectTent(newValue);
		setSelectBrandId(newValue?.brndId || "");
	}

	function getBrandName() {
		return brandData.find((item) => item.value === selectBrandId).label;
	}

	function handleNewBrandClick() {
		openModal(modals.formModal, {
			type: FormDialogType.ADD,
			columnDef: [{
				fType: FormColumnType.TEXT,
				header: "브랜드 명",
				id: "brandName",
				fRequired: true,
				fMaxByte: 100,
				fDefaultValue: requestBrandName
			}],
			onSubmit: async (formData, onClose, showToast, complete) => {
				insertBrandMutate(formData.brandName, {
					onSuccess: async ({ brandId }) => {
						Toast.success("저장이 완료되었어요");

						insertRef.current.brandId = brandId;
						queryClient.invalidateQueries([GET_BRAND_DATA_KEY]);
						onClose();
					},
					onError: async () => {
						onClose();
						openModal(modals.confirmModal, {
							title: "죄송합니다, 저장이 실패했습니다",
							content: "잠시 후에 다시 시도해주시기 바랍니다.",
							closeTitle: "닫기",
						});
					},
					onSettled: () => {
						complete();
					}
				});
			}
		});
	}

	function handleNewTentClick() {
		openModal(modals.formModal, {
			type: FormDialogType.ADD,
			columnDef: [{
				fType: FormColumnType.TEXT,
				header: "브랜드 명",
				id: "brandName",
				fDefaultValue: getBrandName(),
				readOnly: true
			}, {
				fType: FormColumnType.TEXT,
				header: "텐트 명",
				id: "tentName",
				fRequired: true,
				fMaxByte: 100,
				fDefaultValue: requestTentName
			}, {
				fType: FormColumnType.TEXT,
				header: "텐트 사이즈",
				id: "tentSize",
				fMaxByte: 30
			}, {
				fType: FormColumnType.MULTILINE,
				header: "특이사항",
				id: "remark",
				fMaxByte: 200
			}],
			onSubmit: async (formData, onTentModalClose, showToast, complete) => {
				const requestData = {
					...formData,
					estimateId,
					brandId: selectBrandId
				};

				insertTentMutate(requestData, {
					onSuccess: async () => {
						Toast.success("저장이 완료되었어요");

						handleClose();
						onClose();

						queryClient.invalidateQueries([REQUEST_ADD_TENT_DATA_KEY, estimateId]);
					},
					onError: () => {
						handleClose();
						openModal(modals.confirmModal, {
							title: "죄송합니다, 저장이 실패했습니다",
							content: "잠시 후에 다시 시도해주시기 바랍니다.",
							closeTitle: "닫기",
						});
					},
					onSettled: () => {
						complete();
					}
				});
			}
		});
	}

	return (
		<Dialog
			open
			maxWidth="xs"
			fullWidth
		>
			<Typography variant="dialogTitle">브랜드 및 텐트 등록</Typography>

			<DialogContent>
				<Box mb={ 4 }>
					<Card variant="secondary">
						<CardContent>
							<Typography variant="cardBold" mb={ 1.5 }>요청 정보</Typography>

							<Grid container>
								<React.Fragment>
									<Grid xs={ 2 }>
										<Typography variant="cardContext" type="title">브랜드명</Typography>
									</Grid>

									<Grid xs={ 10 }>
										<Typography variant="cardContext">{ requestBrandName || "-" }</Typography>
									</Grid>
								</React.Fragment>

								<React.Fragment>
									<Grid xs={ 2 }>
										<Typography variant="cardContext" type="title">텐트명</Typography>
									</Grid>

									<Grid xs={ 10 }>
										<Typography variant="cardContext">{ requestTentName || "-" }</Typography>
									</Grid>
								</React.Fragment>
							</Grid>
						</CardContent>
					</Card>
				</Box>

				<Stack spacing={ 1.5 }>
					<Stack direction="row" spacing={ 1.5 }>
						<StyledSelectBox
							title="브랜드"
							items={ brandData }
							onChange={ handleChangeBrand }
							value={ selectBrandId }
						/>

						<Button
							variant="contained"
							color="secondary"
							sx={{ width: "40%" }}
							onClick={ handleNewBrandClick }
						>
							새 브랜드 추가
						</Button>
					</Stack>

					<Stack direction="row" spacing={ 1.5 }>
						<TentAutocomplete
							isLoading={ isTentFetching }
							items={ tentData }
							value={ selectTent }
							onChange={ handleChangeTent }
						/>

						<Button
							variant="contained"
							color="secondary"
							sx={{ width: "40%" }}
							onClick={ handleNewTentClick }
							disabled={ isEmptyString(selectBrandId) }
						>
							새 텐트 추가 및 등록
						</Button>
					</Stack>
				</Stack>
			</DialogContent>

			<DialogActions>
				<Button
					size="small"
					variant="outlined"
					color="secondary"
					onClick={ handleClose }
				>
					닫기
				</Button>

				<Button
					size="small"
					variant="contained"
					disabled={ isLoading || selectBrandId === "" || selectTent === null }
					onClick={ handledSubmit }
				>
					등록
				</Button>
			</DialogActions>
		</Dialog>
	);
}