import { Button, Dialog, DialogActions, DialogContent, Typography } from "@mui/material";
import React from "react";
import DaumPostcodeEmbed from "react-daum-postcode";

export default function PostcodeModal({ onSubmit, onClose, ...props }) {
	const theme = {
		bgColor: "#1A1B1C", //바탕 배경색
		searchBgColor: "#242424", //검색창 배경색
		contentBgColor: "#1A1B1C", //본문 배경색(검색결과,결과없음,첫화면,검색서제스트)
		pageBgColor: "#242424", //페이지 배경색
		textColor: "#BEBEBE", //기본 글자색
		queryTextColor: "#EAEAEA", //검색창 글자색
		postcodeTextColor: "#01A04C", //우편번호 글자색
		emphTextColor: "#EE4710", //강조 글자색
		outlineColor: "#292929", //테두리
	};

	async function handleComplete(data) {
		let extraAddress = "",
		    address      = data.address;

		if(data.bname !== "") {
			extraAddress += data.bname;
		}

		if(data.buildingName !== "") {
			extraAddress += extraAddress !== "" ? `, ${ data.buildingName }` : data.buildingName;
		}

		address += extraAddress !== "" ? ` (${ extraAddress })` : "";

		await onSubmit({
			zoneCode: data.zonecode,
			address,
		});
	}

	function handleClose() {
		onClose();
	}

	return (
		<Dialog
			open
			maxWidth="sm"
			fullWidth
		>
			<Typography variant="dialogTitle">우편번호 검색</Typography>

			<DialogContent id="postcodeBody" sx={ { height: 660 } }>
				<DaumPostcodeEmbed
					theme={ theme }
					onComplete={ handleComplete }
					onClose={ handleClose }
					style={ {
						height: "100%",
					} }
				/>
			</DialogContent>

			<DialogActions>
				<Button
					size="small"
					variant="contained"
					color="secondary"
					onClick={ handleClose }
				>
					닫기
				</Button>
			</DialogActions>
		</Dialog>
	);
}