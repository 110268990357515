import { Box, Button, Container, Paper, Stack, SvgIcon, Tooltip, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getKakaoAuthUri, getNaverAuthUri } from "../../api/public/auth";
import { usePublicUserState } from "../../hooks/public/publicAuthContextProvider";
import useBackLocation from "../../hooks/useBackLocation";
import Image from "../../assets/images/login/background.png";
import Logo from "../../assets/images/login/logo.png";
import KakaoSymbol from "../../assets/images/login/kakao_symbol.png";
import NaverSymbol from "../../assets/images/login/naver_symbol.png";

function UserLogin() {
	const { loginFlag } = usePublicUserState();
	const navigate = useNavigate();
	const lastLoginProviderRef = useRef(localStorage.getItem("LP"));
	const [open, setOpen] = useState(true);
	const [mouseEnter, setMouseEnter] = useState(false);
	const imageRef = useRef(null);

	useBackLocation();

	useEffect(() => {
		if(loginFlag) {
			navigate("/");
			// toast.info("이미 로그인이 되어있습니다");
		}
	}, [loginFlag]);

	useEffect(() => {
		const imageInstance = imageRef.current;

		function eventListener({ type }) {
			switch(type) {
				case "mouseenter": {
					setMouseEnter(true);
					break;
				}
				case "mouseleave": {
					setMouseEnter(false);
					break;
				}
			}
		}

		imageInstance.addEventListener("mouseenter", eventListener);
		imageInstance.addEventListener("mouseleave", eventListener);

		const timer = setTimeout(() => {
			setOpen(false);
		}, 5000);

		return () => {
			imageInstance.removeEventListener("mouseenter", eventListener);
			imageInstance.removeEventListener("mouseleave", eventListener);
			clearTimeout(timer);
		}
	}, []);

	function kakaoLogin() {
		window.location.href = getKakaoAuthUri();
	}

	function naverLogin() {
		window.location.href = getNaverAuthUri();
	}

	function getProviderName() {
		return lastLoginProviderRef.current === "K" ? "카카오" : "네이버";
	}

	return (
		<Container variant="contextRoot" sx={ { position: "relative" } }>
			<Wrap variant="contextBox">
				<TitleBox>
					<strong>정성을 다하는</strong> 텐트세탁소,
					<br />
					<strong>할 수 있다는 마음</strong>이 원동력입니다.
				</TitleBox>

				<Stack width="100%" alignItems="center" spacing={ 1.5 }>
					<Tooltip title={ `마지막으로 ${ getProviderName() }에 로그인하셨어요!` } arrow placement="top" open={ lastLoginProviderRef.current !== null ? (open ? open : mouseEnter) : false }>
						<img
							src={ Logo }
							alt="로고"
							ref={ imageRef }
							style={ {
								width: "250px",
								minHeight: "172px",
								position: "relative",
								top: "18px",
							} }
						/>
					</Tooltip>

					<KakaoLoginButton onClick={ kakaoLogin }>
						<img src={ KakaoSymbol } alt="카카오 로그인" />
						<span>카카오로 로그인하기</span>
					</KakaoLoginButton>

					<NaverLoginButton onClick={ naverLogin }>
						<img src={ NaverSymbol } alt="네이버 로그인" />
						<span>네이버로 로그인하기</span>
					</NaverLoginButton>
				</Stack>
			</Wrap>

			<BackgroundImage>
				<Backdrop />
			</BackgroundImage>
		</Container>
	);
}

const Wrap = styled(Container)(() => ({
	position: "relative",
	height: "calc(100vh - 60px)",
	zIndex: 1,
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	justifyContent: "space-between",
}));

const BackgroundImage = styled("div")(() => ({
	backgroundImage: `url(${ Image })`,
	backgroundRepeat: "no-repeat",
	backgroundPosition: "center",
	backgroundSize: "cover",
	width: "100%",
	height: "calc(100vh - 60px)",
	position: "absolute",
	top: 0,
}));

const Backdrop = styled("div")(() => ({
	width: "100%",
	height: "100%",
	backgroundColor: "rgba(0, 0, 0, .45)",
	backdropFilter: "blur(4px)",
}));

const TitleBox = styled(Box)(() => ({
	marginTop: 170,
	fontSize: 24,
	fontWeight: 400,
	lineHeight: "34px",
	textAlign: "center",
	"& > strong": {
		fontWeight: 800,
	},
}));

const DefaultButton = styled(Button)(() => ({
	display: "flex",
	justifyContent: "flex-start",
	// borderRadius: "12px",
	width: "100%",
	height: "60px",
	fontSize: 16,
	padding: "0 20px",
	"& > img": {
		height: 18,
	},
	"& > span": {
		width: "100%",
	},
}));

const KakaoLoginButton = styled(DefaultButton)(() => ({
	backgroundColor: "#FEE500",
	color: "#000000CC",
	"&:hover": {
		backgroundColor: "#FEE500",
	},
}));

const NaverLoginButton = styled(DefaultButton)(() => ({
	backgroundColor: "#03C75A",
	color: "#FFF",
	"&:hover": {
		backgroundColor: "#03C75A",
	},
}));

// const LastLoginProviderTooltip = styled((props) => (
// 	<Tooltip
// 		title="마지막으로 로그인하셨어요!"
// 		arrow
// 		PopperProps={ {
// 			popperOptions: {
// 				modifiers: [{
// 					name: "offset",
// 					enabled: true,
// 					options: {
// 						offset: [0, -20],
// 					},
// 				}],
// 			},
// 		} }
// 		{ ...props }
// 	/>
// ))(() => ({}));

export default UserLogin;