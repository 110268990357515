import { toast } from "react-toastify";

export const FormDialogType = {
  ADD: {
    title: "추가",
    name: "add",
    message: {
      1: {
        type: toast.TYPE.SUCCESS,
        content: "저장되었어요"
      },
      0: {
        type: toast.TYPE.ERROR,
        content: "저장이 실패했습니다"
      },
      dup: {
        type: toast.TYPE.ERROR,
        content: "중복 된 ID가 존재해요"
      },
    }
  },
  EDIT: {
    title: "수정",
    name: "edit",
    message: {
      1: {
        type: toast.TYPE.SUCCESS,
        content: "수정되었어요"
      },
      0: {
        type: toast.TYPE.ERROR,
        content: "수정이 실패했습니다"
      },
    }
  },
  DELETE: {
    title: "삭제",
    name: "delete",
    message: {
      1: {
        type: toast.TYPE.SUCCESS,
        content: "삭제되었어요"
      },
      0: {
        type: toast.TYPE.ERROR,
        content: "삭제가 실패했습니다"
      },
    }
  }
};

export const FormColumnType = {
  NUMBER: "number",
  TEXT: "text",
  DATE: "date",
  DATETIME: "datetime",
  SELECT: "select",
  CHECKBOX: "checkbox",
  MULTILINE: "multiline",
	ADDRESS: "address",
	RANGE_NUMBER: "rangeNumber"
};

export const FormCheckMsg = {
  required:"필수 입력 정보예요",
  maxByte: "자까지 입력할 수 있어요",
  minByte: "자부터 입력할 수 있어요",
  fixByte: "자로만 입력할 수 있어요",
  noSpace: "공백을 포함할 수 있어요",
  telNumber: "하이픈(-)을 추가한 전화번호를 올바르게 입력하세요",
};