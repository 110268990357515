import { Box, Button, Stack, Typography } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import React from "react";
import { useParams } from "react-router-dom";
import { modals } from "../../../hooks/Modals";
import useModal from "../../../hooks/useModal";
import { getCurrentUser } from "../../../lib/firestore/User";
import { isEmptyString } from "../../../lib/libs";
import {
	GET_ORDER_HISTORY_KEY,
	useCancelOrderMutate,
	useRequestWorkingMutate,
} from "../../../query/public/orderHistoryDetailQuery";
import BackgroundBox from "../../common/BackgroundBox";
import CompareEstimateCard from "../../common/CompareEstimateCard";
import ImageUpload from "../ImageUpload";
import RequestContextField from "../RequestContextField";
import { Toast } from "../Toast";
import BranchCard from "./BranchCard";
import DeliveryAddressCard from "./DeliveryAddressCard";
import DeliveryDetailInfoCard from "./DeliveryDetailInfoCard";

function Step5AccordionContent({
	items,
	fixImages,
	isCurrentStep,
}) {
	const { orderId } = useParams();
	const { openModal } = useModal();
	const { cancelOrderMutate } = useCancelOrderMutate();
	const { requestWorkingMutate } = useRequestWorkingMutate();
	const queryClient = useQueryClient();

	function handleCancelWorking() {
		openModal(modals.confirmModal, {
			title: `${ items.ordrTpNm }을 취소하시겠어요?`,
			content: (
				<Box>
					{/*<Typography variant="dialogContent">{`${ items.ordrTpNm }을 취소하시겠어요?`}</Typography>*/}
					{/*{ items.dlvryTp === "1" && items.dlvrDvsn === "1" && (*/}
					{/*	<Typography variant="dialogContent">* 취소 시 착불 배송으로 보내드립니다.</Typography>*/}
					{/*) }*/}

					<Typography variant="dialogContent">* 취소 시 착불 배송으로 보내드립니다.</Typography>
				</Box>
			),
			submitTitle: `${ items.ordrTpNm } 취소`,
			onSubmit: async (firstClose, setLoadingDone) => {
				const user = (await getCurrentUser()).data();

				cancelOrderMutate({ orderId, phoneNumber: user.phoneNumber }, {
					onSuccess: async () => {
						Toast.success(`${ items.ordrTpNm }이 취소되었어요`);
						await queryClient.invalidateQueries([...GET_ORDER_HISTORY_KEY, orderId]);
						firstClose();
					},
					onError: () => {
						firstClose();
						openModal(modals.confirmModal, {
							title: "죄송합니다, 취소가 실패했습니다",
							content: "잠시 후에 다시 시도해주시기 바랍니다.",
							closeTitle: "닫기",
						});
					},
					onSettled: () => {
						setLoadingDone();
					},
				});
			},
		});
	}

	function handleRequestWorking() {
		openModal(modals.confirmModal, {
			title: `${ items.ordrTpNm }을 요청하시겠어요?`,
			submitTitle: `${ items.ordrTpNm } 요청`,
			content: (
				<Box className="customUl">
					<ul>
						<li>
							<Typography variant="dialogContent">{ items.ordrTpNm }은 결제가 확인된 후에 진행됩니다</Typography>
						</li>

						<li>
							<Typography variant="dialogContent" sx={{ "& > strong" : { color: "#E8410B" } }}>
								<strong>
									세탁 요청이 완료된 뒤에는 { items.ordrTpNm } 취소가 불가능합니다
								</strong>
							</Typography>
						</li>
					</ul>
				</Box>
			),
			onSubmit: async (firstClose, setLoadingDone) => {
				requestWorkingMutate({ orderId }, {
					onSuccess: async () => {
						Toast.success(`${ items.ordrTpNm }이 요청되었어요`);
						await queryClient.invalidateQueries([...GET_ORDER_HISTORY_KEY, orderId]);
						firstClose();
					},
					onError: () => {
						firstClose();
						openModal(modals.confirmModal, {
							title: "죄송합니다, 요청이 실패했습니다",
							content: "잠시 후에 다시 시도해주시기 바랍니다.",
							closeTitle: "닫기",
						});
					},
					onSettled: () => {
						setLoadingDone();
					},
				});
			},
		});
	}

	// async function updateAddress(address) {
	// 	await updateOrder(orderId, {
	// 		receiverName: address.name,
	// 		receiverPhoneNumber: address.phoneNumber,
	// 		zoneCode: address.zoneCode,
	// 		address: address.address,
	// 		addressDetail: address.addressDetail,
	// 	});
	// }
	//
	// async function openGetAddressModal({ title, submitTitle, beforeClose }) {
	// 	return new Promise((resolve) => {
	// 		openModal(modals.addressesModal, {
	// 			title,
	// 			submitTitle,
	// 			closeTitle: "닫기",
	// 			beforeClose: () => {
	// 				beforeClose();
	// 			},
	// 			onSubmit: async (onClose, address, setLoadingDone) => {
	// 				resolve({
	// 					onClose,
	// 					address,
	// 					setLoadingDone,
	// 				});
	// 			},
	// 		});
	// 	});
	// }

	return (
		<Stack>
			<BackgroundBox mb={ 4 }>
				<ul>
					<li>
						<Typography variant="context"><strong>입금자 명을 고객님의 성함</strong>으로 하여 보내주세요</Typography>
					</li>

					{ items.dlvrDvsn === "1" && (
						<React.Fragment>
							<li>
								<Typography variant="context">최종 견적 카드에 표시된 <strong>"수취 선불 배송비"</strong>는 접수하실 때 선택한 주소지를 기준으로 책정했습니다</Typography>
							</li>

							<li>
								<Typography variant="context">배송지를 <strong>제주도/도서산간</strong> 지역으로 변경 시 <strong>추가 배송비</strong>가 발생할 수 있습니다</Typography>
							</li>
						</React.Fragment>
					) }
				</ul>
			</BackgroundBox>

			<Box>
				<BranchCard
					branchId={ items.brnchId }
					branchName={ items.brnchNm }
					address={ items.addr }
					addressDetail={ items.addrDtl }
					zoneCode={ items.znCd }
					bankName={ items.bnkNm }
					bankAccount={ items.bnkAccnt }
					bankAccountHolder={ items.bnkAccntHldr }
					telNumber={ items.brnchTelNo }
					queryKey={ GET_ORDER_HISTORY_KEY }
					updateFlag={ false }
					cardInfoShow
				/>
			</Box>

			<Box mt={ 2 }>
				<DeliveryDetailInfoCard
					orderId={ orderId }
					deliveryType={ items.dlvryTp }
					deliveryTypeName={ items.dlvryTpNm }
					deliverDivision={ items.dlvrDvsn }
					deliverDivisionName={ items.dlvrDvsnNm }
				/>
			</Box>

			{/*{ items.dlvryTp === "1" && (*/}
			{/*	<Box mt={ 2 }>*/}
			{/*		<DeliveryAddressCard updateFlag={ isCurrentStep }/>*/}
			{/*	</Box>*/}
			{/*) }*/}

			<Box mt={ 2 }>
				<DeliveryAddressCard updateFlag={ isCurrentStep }/>
			</Box>

			<Box mt={ 2 }>
				<CompareEstimateCard
					orderType={ items.ordrTp }
					beforeDetail={ items.details[2].detail }
					beforeTotal={ items.details[2].total }
					detail={ items.details[3].detail }
					total={ items.details[3].total }
					deliveryCost={ items.rcvngDlvrDvsn === "2" && Number(items.rcvngDlvryCst) }
					outputDeliveryCost={ items.dlvrDvsn === "1" && Number(items.dlvryCst) }
					paymentFlag={ items.pymntYn === "Y" }
					showIcon
				/>
			</Box>

			{ fixImages?.length > 0 && (
				<Box mt={ 2 }>
					<ImageUpload images={ fixImages } readOnly />
				</Box>
			) }

			{ !isEmptyString(items.estmtFxCntnt) && (
				<Box mt={ fixImages?.length > 0 ? 0 : 2 }>
					<RequestContextField
						defaultValue={ items.estmtFxCntnt }
						title="안내사항"
						readOnly
					/>
				</Box>
			) }

			{ isCurrentStep && (
				<Stack direction="row" spacing={ 1 } mt={ 4 }>
					<Button
						variant="contained"
						size="large"
						fullWidth
						color="secondary"
						onClick={ handleCancelWorking }
					>
						{ items.ordrTpNm } 취소
					</Button>

					<Button
						variant="contained"
						size="large"
						color="primary"
						fullWidth
						onClick={ handleRequestWorking }
					>
						{ items.ordrTpNm } 요청
					</Button>
				</Stack>
			) }
		</Stack>
	);
}

export default Step5AccordionContent;