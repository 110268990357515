import { Button, Stack, SvgIcon, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import imageCompression from "browser-image-compression";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { FreeMode, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import { getImage } from "../../api/common";
import { ReactComponent as Plus } from "../../assets/images/icon/plus.svg";
import BigImage from "./BigImage";

function ImageUpload ({ maxLength = 10, images, setImage = () => {}, setLoading, readOnly = false }) {
	const { pathname} = useLocation();
	const [showImages, setShowImages] = useState([]); // 이미지 미리보기
	const [postImages, setPostImages] = useState([]); // 서버로 보낼 이미지 파일
	const [errorMessage, setErrorMessage] = useState(null);

	useEffect(() => {
		async function getImages () {
			if(!images) {
				return ;
			}

			const viewImages = new Array(images.length);
			const sendImages = new Array(images.length);

			await Promise.allSettled(
				images.map(async (url, index) => {
					const blob = await getBlob(url);
					const viewImage = URL.createObjectURL(blob);
					const file = new File([blob], url, { type: blob.type });

					viewImages[index] = viewImage;
					sendImages[index] = file;

					URL.revokeObjectURL(blob);
				}),
			);

			setShowImages([...viewImages]);
			setPostImages([...sendImages]);
		}

		async function getBlob(url) {
			const isAdmin = pathname.split("/")[1] === "admin";
			return await getImage(url, isAdmin);
		}

		getImages();
	}, [images]);

	async function onChange ({ target }) {
		if (target.files.length + showImages.length > maxLength) {
			setErrorMessage(`이미지는 최대 ${ maxLength }장까지 추가할 수 있습니다`);
			return;
		}

		let isNotImageFile = false;
		[...target.files].forEach(file => {
			if (!file.type.match(/image\/(jpg|jpeg|png)$/)) {
				isNotImageFile = true;
				return false;
			}
		});

		if (isNotImageFile) {
			setErrorMessage("jpg, jpeg, png 이미지만 추가해주세요");
			return;
		}

		setErrorMessage(null);

		if(typeof setLoading === "function") {
			setLoading(true);
		}

		let imageUrlLists = [...showImages];
		let serverImages = [...postImages];

		for (let i = 0; i < target.files.length; i++) {
			const [compressed, url] = await actionImageCompress(target.files[i]);

			serverImages.push(compressed);
			imageUrlLists.push(url);
		}

		if (imageUrlLists.length > maxLength) {
			imageUrlLists = imageUrlLists.slice(0, 10);
		}

		setShowImages(imageUrlLists);
		setPostImages(serverImages);

		target.value = ""; // 같은 파일 연속으로 추가 시 인식 안 됨 해결
	}

	useEffect(() => {
		setImage(postImages);
		if(typeof setLoading === "function") {
			setLoading(false);
		}
	}, [postImages]);

	function handleImageClick (id) {
		if (!readOnly) {
			setShowImages((prev) => prev.filter((file, index) => index !== id));
			setPostImages((prev) => prev.filter((file, index) => index !== id));
			setErrorMessage(null);
		}
	}

	async function actionImageCompress (file) {
		try {
			const resizedBlob = await imageCompression(file, {
				maxSizeMB: 0.4,
				maxWidthOrHeight: 1024,
				useWebWorker: true
			});
			const compressionFile = new File([resizedBlob], file.name, { type: file.type });
			const url = await imageCompression.getDataUrlFromFile(resizedBlob);

			return [compressionFile, url];
		} catch (e) {
			console.error(e);
		}
	}

	return (
		<React.Fragment>
			<Stack direction="row" spacing={ 1 } mb={ errorMessage ? 0 : 2 }>
				{ !readOnly && (
					<AddButton component="label" disabled={ showImages.length >= maxLength }>
						{/** 모바일로 파일 첨부 시 갤러리로 이동?
						   - 안드로이드 크롬: 갤러리
						   - 안드로이드 삼성 인터넷: 카메라, 내 파일, 파일 => 파일 선택 시 최근 이미지 확인 가능
						   - ios: ...
						*/}

						<input type="file" accept="image/jpg,image/png,image/jpeg" multiple onChange={ onChange } hidden />

						<SvgIcon
							component={ Plus }
							sx={ {
								width: 17,
								height: 17,
								fill: showImages.length >= maxLength ? "#404040" : "#EAEAEA",
							} }
						/>
						<Typography variant="smallContext">{ showImages.length } / { maxLength }</Typography>
					</AddButton>
				) }

				<SwiperWrap
					slidesPerView={ readOnly ? 4.5 : 3.5 }
					spaceBetween={ 10 }
					freeMode
					modules={ [FreeMode, Pagination] }
				>
					{ showImages.map((image, index) => (
						<SwiperSlide key={ "image_" + index } style={ { cursor: "pointer" } } onClick={ () => handleImageClick(index, image) }>
							{ !readOnly ? (
								<React.Fragment>
									<DeleteBox>삭제</DeleteBox>
									<img style={ { width: "100%", height: "100%" } } src={ image } alt="미리보기" />
								</React.Fragment>
							) : (
								<BigImage src={ image } height={ 100 } alwaysOpen />
							) }
						</SwiperSlide>
					)) }
				</SwiperWrap>
			</Stack>

			{ errorMessage && (
				<Typography variant="smallContext" mb={ errorMessage ? 1 : 0 } sx={ { color: (theme) => theme.palette.error.main } }>{ errorMessage }</Typography>
			) }
		</React.Fragment>
	);
}

const AddButton = styled((props) => (
	<Button variant="outlined" color="secondary" { ...props } />
))(() => ({
	padding: "21.5px",
	borderRadius: 0,
	position: "relative",
	"& .MuiTypography-root": {
		position: "absolute",
		right: 4,
		bottom: 0,
	},
}));

const SwiperWrap = styled(Swiper)(({theme}) => ({
	width: "100%",
	height: 62,
	fontSize: 11,
	zIndex: theme.zIndex.drawer - 1
}));

const DeleteBox = styled("div")(({ theme }) => ({
	borderWidth: "1px",
	borderStyle: "solid",
	borderColor: theme.palette.divider,
	borderRadius: "20px",
	padding: "5px 10px",
	backgroundColor: "rgb(0 0 0 / .5)",
	position: "absolute",
	left: "50%",
	top: "50%",
	transform: "translate(-50%, -50%)",
	width: "max-content",
	letterSpacing: 1,
	fontWeight: 600,
	fontSize: 12,
}));

export default React.memo(ImageUpload);