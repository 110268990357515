import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from "@mui/material";

import styled from "styled-components";
import {
  useCallback,
  useState,
} from "react";
import FormModalFooter from "./formModal/FormModalFooter";
import RoundLoadingButton from "../common/RoundLoadingButton";

export default function CardModal({ onSubmit, onClose, title, submitTitle, content, ...props }) {
  const [isLoading, setIsLoading] = useState(false);
  const setLoadingDone = useCallback(() => {
    setIsLoading(false);
  }, []);

  async function handledSubmit() {
    setIsLoading(true);

    await onSubmit(onClose, setLoadingDone);
  }

  return (
    <Dialog
      open
      maxWidth="xs"
      fullWidth
    >
      <StyledDialogTitle>{ title }</StyledDialogTitle>

      <DialogContent>
        <Box>
          { content }
        </Box>
      </DialogContent>

      <DialogActions>
        <RoundLoadingButton
          size="small"
          variant="outlined"
          color="secondary"
          onClick={ onClose }
        >
          닫기
        </RoundLoadingButton>

        <RoundLoadingButton
          size="small"
          variant="contained"
          loading={ isLoading }
          onClick={ handledSubmit }
        >
          { title }
        </RoundLoadingButton>
      </DialogActions>
    </Dialog>
  );
}

const StyledDialogTitle = styled(DialogTitle) (() => ({
  "&&&": {
    fontSize: "1rem"
  }
}));