import React from "react";
import {
	Box,
	Card,
	CardActionArea,
	CardMedia,
	Typography
} from "@mui/material";
import { styled } from "@mui/material/styles";
import YouTube from "react-youtube";

function NewsCard({
	title,
	channel,
	programName,
	date,
	url,
	thumbnail,
	youtubeId
}) {
	function openNews() {
		if(url) {
			window.open(url);
		}
	}

	return (
		<Box>
			<Typography variant={ "subTitle" } sx={ { cursor: url && "pointer" } } onClick={ openNews }>
				{ title }
			</Typography>

			<NewsInfo>{ channel } '{ programName }' | { date }</NewsInfo>

			<Card onClick={ openNews }>
				{ youtubeId && (
					<YouTube
						videoId={ youtubeId }
						opts={ {
							width: "100%",
							height: "200px",
							playerVars: {
								rel: 0,
								modestbranding: 1
							}
						} }
					/>
				) }

				{ url && (
					<CardActionArea>
						<CardMedia
							image={ thumbnail }
							sx={ { height: "200px" } }
						/>
					</CardActionArea>
				) }
			</Card>
		</Box>
	);
}

const NewsInfo = styled(Typography)(() => ({
	color: "#939393",
	fontSize: "10pt",
	textAlign: "end",
	whiteSpace: "pre",
	marginBottom: "20px"
}));

export default NewsCard;