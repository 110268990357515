import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import styled from "styled-components";
import {
	Box,
	Container,
	InputLabel,
	MenuItem,
	Paper,
	Typography,
	useMediaQuery,
	Divider,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import RoundTextField from "../../components/common/FormTextField";
import { useForm } from "react-hook-form";
import {
	checkId,
	getBranch,
} from "../../api/admin/signUp";
import { addPhoneNumberHyphen } from "../../lib/libs";
import { useSignUpMutation } from "../../query/admin/signUpQuery";
import { useNavigate } from "react-router-dom";
import PointerCursorLink from "../../components/common/PointerCursorLink";
import RoundLoadingButton from "../../components/common/RoundLoadingButton";

function Login() {
	const [idCheckData, setIdCheckData] = useState("");
	const loading = useRef(false);
	const [branch, setBranch] = useState([]);
	const [signUpStatus, setSignUpStatus] = useState(null);
	const { signUpMutate } = useSignUpMutation();
	const navigate = useNavigate();
	const isMobile = useMediaQuery("(max-width: 900px)") ? 1 : 0;
	const {
		      handleSubmit,
		      control,
		      formState: { errors },
		      setError,
		      clearErrors,
		      getValues,
		      trigger,
	      } = useForm({
		mode: "all",
	});

	useEffect(() => {
		getBranch().then((data) => {
			setBranch(data);
		});
	}, []);

	async function onSubmit(data) {
		loading.current = true;

		data = {
			nm: data.nm,
			id: data.id,
			pw: data.pw,
			brnch: data.brnch,
			tel: data.tel,
			em: data.em,
		};

		signUp({ ...data }, () => {
			loading.current = false;
		});
	}

	const signUp = useCallback((signUpParam, onComplete) => {
		signUpMutate(signUpParam, {
			onSuccess: ({ success }) => {
				onComplete();
				if(success) {
					setSignUpStatus(true);
				} else {
					setSignUpStatus(false);
				}
			},
			onError: () => {
				onComplete();
				setSignUpStatus(false);
			},
		});
	}, [signUpMutate]);

	async function onCheckId() {
		if(!idCheckData) {
			const id = getValues("id");
			const cnt = await checkId(id);

			switch(cnt) {
				case 0:
					clearErrors("id");
					setIdCheckData(id);
					break;
				case 1:
					setError("id", {
						type: "existDup",
						message: "이미 사용 중인 아이디입니다.",
					});
					setIdCheckData("");
					break;
				case -1:
					setError("id", {
						type: "err",
						message: "알 수 없는 에러, 관리자에게 문의해주시기 바랍니다.",
					});
					setIdCheckData("");
			}
		}
	}

	const branchItem = useMemo(() => {
		return branch.map((item, index) => {
			return <MenuItem key={ `${ item.brnchId }_${ index }` } value={ item.brnchId }>{ item.brnchNm }</MenuItem>;
		});
	}, [branch]);

	function goLoginPage() {
		navigate("/admin/login");
	}

	function change(e) {
		e.target.value = addPhoneNumberHyphen(e.target.value);
	}

	return (
		<Container maxWidth="sm">
			<Grid
				container
				sx={ {
					minHeight: "100vh",
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
				} }
			>
				<Box sx={ { margin: "1rem" } }>
					<LoginBackground>
						{ signUpStatus !== null && signUpStatus ? (
							<Grid container>
								<Grid xs={ 12 }>
									<Typography variant="h6">회원 등록 완료</Typography>
								</Grid>

								<Grid xs={ 12 } sx={ { paddingTop: "1rem" } }>
									<Divider />
								</Grid>

								<Grid xs={ 12 } sx={ { paddingTop: "1.5rem" } }>
									<Typography variant="body2" gutterBottom>
										회원 등록이 완료되었습니다.
									</Typography>
									<Typography variant="body2">
										로그인 버튼을 클릭 시 로그인 페이지로 이동합니다.
									</Typography>
								</Grid>

								<Grid xs={ 12 } sx={ { paddingTop: "1rem" } }>
									<RoundLoadingButton
										variant="contained"
										size="large"
										onClick={ goLoginPage }
										disableElevation
										fullWidth
									>
										로그인
									</RoundLoadingButton>

								</Grid>
							</Grid>
						) : (
							<form onSubmit={ handleSubmit(onSubmit) }>
								<Grid container>
									<Grid xs={ 12 }>
										<Typography variant="h6">회원 등록</Typography>
									</Grid>

									<Grid xs={ 12 } sx={ { paddingTop: !isMobile ? "2.5rem" : "1.5rem" } }>
										<TextFieldWrap>
											<RequiredInputLabel shrink htmlFor="nmForm">이름</RequiredInputLabel>
											<RoundTextField
												id="nmForm"
												size="small"
												name="nm"
												autoComplete="off"
												control={ control }
												rules={ {
													required: "이름을 입력하세요.",
													maxLength: {
														value: 40,
														message: "40자 이하로 입력하세요.",
													},
												} }
											/>
										</TextFieldWrap>
									</Grid>

									<Grid xs={ 12 } sx={ { paddingTop: "1rem" } }>
										<TextFieldWrap>
											<RequiredInputLabel shrink htmlFor="idForm">아이디</RequiredInputLabel>
											<Grid container spacing={ 1 }>
												<Grid md={ 8 } xs={ 7 }>
													<RoundTextField
														id="idForm"
														size="small"
														name="id"
														autoComplete="off"
														fullWidth
														control={ control }
														rules={ {
															required: "아이디를 입력하세요.",
															maxLength: {
																value: 20,
																message: "20자 이하로 입력하세요.",
															},
															validate: {
																check: (value) => {
																	if(idCheckData !== "" && value !== idCheckData) {
																		setIdCheckData("");
																		return "중복 확인을 해주세요.";
																	} else if(idCheckData === "") {
																		return "중복 확인을 해주세요.";
																	}
																},
															},
														} }
														trueOnlyShowMsg={ {
															flag: !!idCheckData,
															msg: "사용할 수 있는 아이디입니다.",
														} }
													/>
												</Grid>

												<Grid md xs>
													<RoundLoadingButton
														variant="contained"
														autoComplete="off"
														disableElevation
														fullWidth
														onClick={ onCheckId }
														disabled={ !!idCheckData || errors?.id?.type === "maxLength" }
													>
														중복 확인
													</RoundLoadingButton>
												</Grid>
											</Grid>
										</TextFieldWrap>
									</Grid>

									<Grid xs={ 12 } sx={ { paddingTop: "1rem" } }>
										<TextFieldWrap>
											<RequiredInputLabel shrink htmlFor="pwForm">비밀번호</RequiredInputLabel>
											<RoundTextField
												id="pwForm"
												size="small"
												name="pw"
												type="password"
												autoComplete="off"
												control={ control }
												rules={ {
													required: "비밀번호를 입력하세요.",
													pattern: {
														value: /(?=.*\d{1,50})(?=.*[~`!@#$%\^&*()-+=]{1,50})(?=.*[a-zA-Z]{2,50}).{8,25}$/,
														message: "영문, 숫자, 특수 기호를 조합해 8자 이상 입력하세요.",
													},
												} }
												inputProps={ {
													onBlur: () => {
														trigger("pwCheck");
													},
												} }
											/>
										</TextFieldWrap>
									</Grid>

									<Grid xs={ 12 } sx={ { paddingTop: "1rem" } }>
										<TextFieldWrap>
											<RequiredInputLabel shrink htmlFor="pwCheckForm">비밀번호 확인</RequiredInputLabel>
											<RoundTextField
												id="pwCheckForm"
												size="small"
												name="pwCheck"
												type="password"
												autoComplete="off"
												control={ control }
												rules={ {
													required: "비밀번호 확인를 입력하세요.",
													validate: {
														check: (value) => {
															if(getValues("pw") !== value) {
																return "비밀번호가 일치하지 않습니다.";
															}
														},
													},
												} }
											/>
										</TextFieldWrap>
									</Grid>

									<Grid xs={ 12 } sx={ { paddingTop: "1rem" } }>
										<TextFieldWrap>
											<RequiredInputLabel shrink htmlFor="brnchForm">지점</RequiredInputLabel>
											<RoundTextField
												id="brnchForm"
												select
												size="small"
												name="brnch"
												autoComplete="off"
												control={ control }
												rules={ { required: "지점을 선택하세요." } }
											>
												{ branchItem }
											</RoundTextField>
										</TextFieldWrap>
									</Grid>

									<Grid xs={ 12 } sx={ { paddingTop: "1rem" } }>
										<TextFieldWrap>
											<RequiredInputLabel shrink htmlFor="telForm">휴대폰 번호</RequiredInputLabel>
											<RoundTextField
												id="telForm"
												size="small"
												name="tel"
												autoComplete="off"
												type="tel"
												control={ control }
												onChange={ change }
												rules={ {
													required: "휴대폰 번호를 입력하세요.",
													pattern: {
														value: /^(010|011|016|017|018|019)-[^0][0-9]{3,4}-[0-9]{4}$/g,
														message: "하이픈을 추가해 형식에 맞게 입력하세요.",
													},
												} }
											/>
										</TextFieldWrap>
									</Grid>

									<Grid xs={ 12 } sx={ { paddingTop: "1rem" } }>
										<TextFieldWrap>
											<InputLabel shrink htmlFor="emForm">이메일</InputLabel>
											<RoundTextField
												id="emForm"
												size="small"
												name="em"
												autoComplete="off"
												control={ control }
												rules={ {
													pattern: {
														value: /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i,
														message: "이메일 형식에 맞지 않습니다.",
													},
												} }
											/>
										</TextFieldWrap>
									</Grid>

									<Grid xs={ 12 } sx={ { paddingTop: "1rem" } }>
										<RoundLoadingButton
											variant="contained"
											size="large"
											type="submit"
											disableElevation
											fullWidth
											loading={ loading.current || false }
										>
											계정 등록
										</RoundLoadingButton>

										{ signUpStatus !== null && !signUpStatus && (
											<Typography noWrap variant="overline" display="inline" sx={ { color: "#EE3A85" } }>
												오류가 발생했습니다. 관리자에게 문의해주시기 바랍니다.
											</Typography>
										) }
									</Grid>

									<Grid
										xs={ 12 }
										sx={ {
											paddingTop: ".7rem",
											textAlign: "center",
										} }
									>
										<Typography variant="overline" display="inline" mr={ 1 }>
											계정이 있으십니까?
										</Typography>
										<PointerCursorLink variant="overline" underline="hover" onClick={ goLoginPage }>로그인</PointerCursorLink>
									</Grid>
								</Grid>
							</form>
						) }
					</LoginBackground>
				</Box>
			</Grid>
		</Container>
	);
}

const LoginBackground = styled((props) => (
	<Paper elevation={ 3 } { ...props } />
))(() => ({
	padding: "2.5rem",
}));

const TextFieldWrap = styled.div`
  display: flex;
  flex-direction: column;
`;

const RequiredInputLabel = styled((props) => (
	<InputLabel required { ...props } />
))(() => ({
	"&.Mui-required": {
		".MuiInputLabel-asterisk": {
			color: "#EE3A85",
			fontWeight: "bold",
		},
	},
}));

export default Login;