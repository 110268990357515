import { useFormModalsDispatch } from './modalProvider'

export default function useModal() {
  const { open, close } = useFormModalsDispatch();

  function openModal(Component, props) {
    open(Component, props);
  }

  function closeModal(Component) {
    close(Component);
  }

  return { openModal, closeModal };
}