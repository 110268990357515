const KEY = "NoticeOff";
const DEFAULT_HIDE_DAY = 1;

export function clearItems() {
	const savedData = getData();
	let allEndedFlag = true;

	for(let { noticeId, offDate } of savedData) {
		if(isFloating(noticeId)) {
			allEndedFlag = false;
			break;
		}
	}

	if(allEndedFlag) {
		localStorage.removeItem(KEY);
	}
}

export function setItem(id) {
	let data = getData();
	const beforeItem = getItem(id);

	if(beforeItem === undefined) {
		data.push({ noticeId: id, offDate: getDate() });
	} else {
		data = data.map((item) => {
			if(item.noticeId === id) {
				return { noticeId: id, offDate: getDate() };
			} else {
				return item;
			}
		});
	}

	setData(data);
}

export function isFloating(id) {
	const now = new Date();
	const item = getItem(id);

	if(item === undefined) {
		return true;
	} else {
		const offDate = item.offDate;
		const plusDate = offDate.setDate(offDate.getDate() + DEFAULT_HIDE_DAY);

		return plusDate >= now;
	}
}

export function getData() {
	return JSON.parse(localStorage.getItem(KEY) || "[]");
}

function getItem(id) {
	const item = getData().find(({ noticeId }) => noticeId === id);

	if(item === undefined) {
		return undefined;
	}

	return {
		noticeId: item.noticeId,
		offDate: new Date(item.offDate)
	};
}

function setData(items) {
	localStorage.setItem(KEY, JSON.stringify(items));
}

function getDate(now = new Date()) {
	const year = now.getFullYear();
	const month = now.getMonth() + 1;
	const day = now.getDate();

	return `${year}-${_(month)}-${_(day)}`;
}

function _(number) {
	return number.toString().padStart(2, "0");
}