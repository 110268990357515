import React from "react";
import { styled } from "@mui/material/styles";
import { Button } from "@mui/material";

function ProductFixedButton({ url }) {
	function onClick() {
		window.open(url);
	}

	return (
		<BottomButton
			variant={"contained"}
			fullWidth
			onClick={ onClick }
		>
			구매하기
		</BottomButton>
	);
}

const BottomButton = styled(Button) (() => ({
	width: "100%",
	position: "sticky",
	bottom: "0",
	borderRadius: "4px 4px 0 0",
	padding: "13px 0",
	fontSize: "16px",
	fontWeight: 700
}));

export default React.memo(ProductFixedButton);