import { validationPhoneNumber } from "../libs";
import { FormCheckMsg } from "./formDialogType";

function byteCheck(value) {
	value = value + "";

	if(value !== undefined && value !== "") {
		let b, i, c;
		for(b = i = 0; c = value.charCodeAt(i++); b += c>>7? 2 : 1);
		return b;
	} else {
		return 0;
	}
}

function maxByte(value, maxByte) {
	return byteCheck(value) <= maxByte;
}

function minByte(value, minByte) {
	return byteCheck(value) >= minByte;
}

function fixByte(value, fixByte) {
	return byteCheck(value) === fixByte;
}

function noSpace(value) {
	if(value) {
		return !value.match(/\s/g);
	}
	return true;
}

function telNumber(value) {
	if(value) {
		return /^\d{2,3}-\d{3,4}-\d{4}$/.test(value);
	}
	return true;
}

export function setRules(item) {
	let rules = {};

	if(item.fPk || item.fRequired) {
		rules.required = FormCheckMsg.required;
	}

	let validate = {};

	if(item.fMaxByte) {
		validate.maxByte = v => maxByte(v, item.fMaxByte) || item.fMaxByte + FormCheckMsg.maxByte;
	}

	if(item.fMinByte) {
		validate.minByte = v => minByte(v, item.fMinByte) || item.fMinByte + FormCheckMsg.minByte;
	}

	if(item.fFixByte) {
		validate.fixByte = v => fixByte(v, item.fFixByte) || item.fFixByte + FormCheckMsg.fixByte;
	}

	if(item.fNoSpace) {
		validate.noSpace = v => noSpace(v) || FormCheckMsg.noSpace;
	}

	if(item.fTelNumber) {
		validate.telNumber = v => telNumber(v) || FormCheckMsg.telNumber;
	}

	if(item.customRules) {
		for(let { name, fn, message } of item.customRules) {

			validate[name] = v => fn(v) || message;
		}
	}

	return {
		...rules,
		validate
	};
}