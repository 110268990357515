import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import {
	Box,
	Button, CardMedia,
	Dialog,
	DialogActions,
	DialogContent, Divider, FormControlLabel,
	Typography,
} from "@mui/material";
import CheckBox from "../CheckBox";
import Image1 from "../../../assets/images/checklist/checklist1.jpeg";
import Image2 from "../../../assets/images/checklist/checklist2.jpeg";
import {
	Navigation,
	Pagination,
	A11y,
} from "swiper";
import {
	Swiper,
	SwiperSlide
} from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/effect-fade";

export default function ChecklistModal({ onSubmit, onClose, ...props }) {
	const [check, setCheck] = useState(false);

  async function handledSubmit() {
    await onSubmit(onClose);
  }

  function handleClose() {
    onClose();
  }

  return (
    <Dialog
      open
      maxWidth="xs"
      fullWidth
    >
	    <Typography variant="dialogTitle">Check List</Typography>

      <DialogContent>
	      <CustomBox mb={ 2 }>
		      <ul>
			      <li>
				      <Typography variant="dialogContent">세탁 후 첫 피칭에서 비를 맞으면 누수 위험이 있습니다.</Typography>
				      <Typography variant="dialogContent">(3회 이상 날 좋은 날 피칭 요망)</Typography>
			      </li>
			      <li>
				      <Typography variant="dialogContent">발수 코팅 후 첫 피칭에서 비를 맞으면 발수효과를 못 느끼십니다.</Typography>
				      <Typography variant="dialogContent">(3회 이상 날 좋은 날 피칭 요망)</Typography>
			      </li>
			      <li>
				      <Typography variant="dialogContent">젖은 상태로 입고 시 이염위험이 높습니다.</Typography>
			      </li>
			      <li>
				      <Typography variant="dialogContent">곰팡이 작업 시 원단의 변질, 변색과 우레탄창 손상 위험이 있으며 곰팡이 오염 정도나 텐트 원단 재질에 따라서 포자는 사멸될 수 있지만 자국은 남을 수도 있습니다.</Typography>
			      </li>
			      <li>
				      <Typography variant="dialogContent">내부 블랙 코팅 제품이나 피그먼트 처리 되어있는 제품 세탁 시 코팅이 벗겨질 수 있습니다.</Typography>
			      </li>
			      <li>
				      <Typography variant="dialogContent">방수 코팅이 약한 제품들은 벗겨짐이 발생할 수 있습니다.</Typography>
			      </li>
			      <li>
				      <Typography variant="dialogContent">장박으로 스킨이 많이 손상된 경우 스킨 찢어짐이 발생할 수 있습니다.</Typography>
			      </li>
			      <li>
				      <Typography variant="dialogContent">장박으로 스킨이 많이 손상된 경우 세탁 후 시즈닝을 하셔도 누수 위험이 높습니다.</Typography>
			      </li>
			      <li>
				      <Typography variant="dialogContent">심실링이 떠있는 상태에서 세제 작업 시 심실링이 더 들뜰수도 있습니다.</Typography>
			      </li>
			      <li>
				      <Typography variant="dialogContent">결제는 선결제입니다.</Typography>
			      </li>
		      </ul>
	      </CustomBox>

	      <Divider />

	      <Box mt={ 2 }>
          <Typography variant="dialogContent">맡기신 구성품을 꼭 확인하시고 Check List 확인 후 접수해주세요.</Typography>
          <Typography variant="dialogContent">스킨이나 메쉬망 손상을 포함한 이상 상태는 꼭 미리 말씀해주세요.</Typography>
          <Typography variant="dialogContent">(검수 과정이나 세탁 과정에서 발견될 수도 있지만 미세한 부분은 발견하지 못할 수 있습니다.)</Typography>
	      </Box>

	      <Box my={ 2 }>
		      <Typography variant="dialogContent" mb={ 1 } sx={{ fontSize: 13 }}>[ 예시 이미지 ]</Typography>

		      <Wrap
			      navigation
			      loop
			      centeredSlides
			      autoplay={ {
				      disableOnInteraction: false
			      } }
			      pagination={ {
				      type: "fraction",
				      clickable: true
			      } }
			      modules={ [Pagination, Navigation, A11y] }
		      >
			      <SwiperSlide>
				      <CardMedia image={ Image1 } sx={{ height: "500px" }} />

				      <InformationText>방수코팅 벗겨짐</InformationText>
			      </SwiperSlide>

			      <SwiperSlide>
				      <CardMedia image={ Image2 } sx={{ height: "500px" }} />

				      <InformationText>곰팡이 오염으로 인한 우레탄창 깨짐</InformationText>
			      </SwiperSlide>
		      </Wrap>
	      </Box>

        <FormControlLabel
          label="위 내용을 모두 확인했습니다."
          control={ <CheckBox /> }
          checked={ check }
          onChange={ ({ target: { checked } }) => setCheck(checked) }
        />
      </DialogContent>

      <DialogActions>
	      <Button
		      size="small"
		      variant="outlined"
		      color="secondary"
		      onClick={ handleClose }
	      >
		      닫기
	      </Button>

	      <Button
		      size="small"
		      variant="contained"
		      onClick={ () => handledSubmit() }
		      disabled={ !check }
	      >
		      확인 완료
	      </Button>
      </DialogActions>
    </Dialog>
  );
}

const CustomBox = styled(Box)(() => ({
	"& ul": {
		margin: 0,
		paddingLeft: 17,
		listStyle: "decimal",
		"& > li": {
			margin: "15px 0",
			"&:first-of-type": {
				margin: 0,
			},
		},
	},
}));

const Wrap = styled(Swiper)(() => ({
	"& .swiper-button-prev, & .swiper-button-next": {
		color: "#EAEAEA",
		opacity: ".8",
		transform: "scale(.8)",
		transition: "all .2s ease",
		"&::after": {
			fontSize: "30px",
			fontWeight: 700
		},
		"&:hover": {
			color: "#E8410B",
			opacity: "1",
			transform: "scale(1)"
		}
	},
	"& .swiper-pagination": {
		"&.swiper-pagination-fraction": {
			width: "auto",
			fontSize: "14px",
			color: "#B9B9B9",
			lineHeight: "22px",
			position: "absolute",
			left: "auto",
			right: 0,
			padding: "2px 10px",
			backgroundColor: "#00000057",
			borderRadius: "20px",
			marginRight: "10px"
		}

		// "& .swiper-pagination-bullet": {
		// 	backgroundColor: "#fff",
		// 	opacity: ".3"
		// },
		// "& .swiper-pagination-bullet.swiper-pagination-bullet-active": {
		// 	backgroundColor: "#E8410B",
		// 	opacity: "1"
		// }
	}
}));

const InformationText = styled((props) => (
	<Typography variant="context" { ...props } />
))(({ theme }) => ({
	position: "absolute",
	top: 0,
	left: "50%",
	transform: "translateX(-50%)",
	backgroundColor: theme.palette.background.paper,
	padding: "5px 15px 2px",
	borderRadius: "0 0 10px 10px",
	width: "max-content"
}));