import {
	useMutation,
	useQuery
} from "@tanstack/react-query";
import * as API from "../../api/public/orderHistoryDetail";
import { requestWorking, updateRequestContent } from "../../api/public/orderHistoryDetail";

export const GET_ORDER_HISTORY_KEY = ["getOrderHistory"]

export function useOrderHistory(orderId) {
	const {
    isLoading,
    isError,
    isSuccess,
    data,
  } = useQuery([...GET_ORDER_HISTORY_KEY, orderId], () => API.getOrderHistory(orderId), {
		retry: 0,
		refetchOnWindowFocus: false,
		cacheTime: 0
	});

	return {
		isLoading,
		isError,
		isSuccess,
		data,
	};
}

export function useUpdateRequestContent() {
	const { mutate: updateRequestContentMutate } = useMutation({
		mutationFn: API.updateRequestContent
	});

	return { updateRequestContentMutate };
}

export function useUpdateBranch() {
	const { mutate: updateBranchMutate } = useMutation({
		mutationFn: API.updateBranch
	});

	return { updateBranchMutate };
}

export function useRequestOrderMutate() {
	const { mutate: requestOrderMutate } = useMutation({
		mutationFn: API.requestOrder
	});

	return { requestOrderMutate };
}

export function useCancelOrderMutate() {
	const { mutate: cancelOrderMutate } = useMutation({
		mutationFn: API.cancelOrder
	});

	return { cancelOrderMutate };
}

export function useRequestWorkingMutate() {
	const { mutate: requestWorkingMutate } = useMutation({
		mutationFn: API.requestWorking
	});

	return { requestWorkingMutate };
}

export function useCustomerDeliveryConfirm() {
	const { mutate } = useMutation({
		mutationFn: API.customerDeliveryConfirm
	});

	return { mutate };
}