import React, { useMemo } from "react";
import {
	Box,
	Card,
	CardMedia,
	Container,
	Divider,
	Stack,
	Typography
} from "@mui/material";
import useKakaoMap from "../../../hooks/public/useKakaoMap";
import TopImage01 from "../../../assets/images/branch_03/top_01.jpg";
import TopImage02 from "../../../assets/images/branch_03/top_02.jpg";
import TopImage03 from "../../../assets/images/branch_03/top_03.jpg";
import TopImage04 from "../../../assets/images/branch_03/top_04.jpg";
import TopImage05 from "../../../assets/images/branch_03/top_05.jpg";
import TopImage06 from "../../../assets/images/branch_03/top_06.jpg";
import TopImage07 from "../../../assets/images/branch_03/top_07.jpg";
import SwiperTopCard from "../../../components/public/SwiperTopCard";
import BranchInfo from "../../../components/public/BranchInfo";

function Branch3() {
	useKakaoMap({
		locationName: "텐트세탁소 용인점",
		lat: 37.1162457,
		lng: 127.1289429
	});

	const topImages = useMemo(() => ([
		{
			image: TopImage01,
			backgroundPositionY: "-260px"
		},
		{
			image: TopImage02
		},
		{
			image: TopImage03
		},
		{
			image: TopImage04,
			backgroundPositionY: "-25px"
		},
		{
			image: TopImage05
		},
		{
			image: TopImage06,
			backgroundPositionY: "-30px"
		},
		{
			image: TopImage07
		}
	]), []);

	const info = useMemo(() => ({
		tel: "031-335-3814",
		cafe: "https://cafe.naver.com/tcsyongin",
		kakao: "https://pf.kakao.com/_sxoazG"
	}), []);

	return (
		<Container variant={ "contextRoot" }>
			<Card variant={ "topImage" }>
				<SwiperTopCard images={ topImages } />
			</Card>

			<Container variant={ "contextBox" }>
				<Stack direction={ "column" } spacing={ 3 }>
					<BranchInfo info={info} />

					<Divider />

					<Typography variant={ "context" }>
						전화: { info.tel }
					</Typography>

					<Typography variant={ "context" }>
						경기도 용인시 처인구 남사읍 통삼로 183
					</Typography>

					<Box
						sx={ {
							width: "calc(100% + 30px)",
							height: "200px",
							position: "relative",
							left: "-15px"
						} }
					>
						<div id={ "map" } style={ { width: "100%", height: "100%" } } />
					</Box>
				</Stack>
			</Container>
		</Container>
	);
}

export default Branch3;