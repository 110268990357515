import { styled } from "@mui/material/styles";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import {
	Box,
	Card,
	CardMedia,
	Container,
	Skeleton,
	Stack,
	SvgIcon, Typography,
} from "@mui/material";
import WashingTopImage01 from "../../../assets/images/top_img/washing_top.png";
import WashingTopImage02 from "../../../assets/images/top_img/washing_top_ing.png";
import RepairTopImage01 from "../../../assets/images/top_img/repair_top.png";
import RepairTopImage02 from "../../../assets/images/top_img/repair_top_ing.png";
import { useEstimateFixImages, useRequestImages, useWorkingImages } from "../../../query/getImageQuery";
import { useOrderHistory } from "../../../query/public/orderHistoryDetailQuery";
import OrderProcessBar from "../../../components/public/orderHistoryDetail/OrderProcessBar";
import OrderHistoryAccordion from "../../../components/public/orderHistoryDetail/OrderHistoryAccordion";
import { ReactComponent as Washing } from "../../../assets/images/history/07_washing.svg";
import { ReactComponent as Repair } from "../../../assets/images/history/07_repair.svg";

function OrderHistoryDetail() {
	// ordr_stts_hstry에 주문 상태가 1인 내역이 있다면 step1(가견적 요청)부터 표출, 없으면 STEP3(접수)부터 표출 - data.etcTntExst
	const { orderId } = useParams();
	const {
		      isLoading,
		      data,
		      isError,
	      } = useOrderHistory(orderId);
	const { requestImagesData } = useRequestImages(orderId, data?.rqstImgPth);
	const { estimateFixImagesData } = useEstimateFixImages(orderId, data?.estmtFxImgPth);
	const { workingImagesData } = useWorkingImages(orderId, data?.wrkngImgPth);

	const images = useMemo(() => ({
		"1": {
			icon: Washing,
			top: {
				before: WashingTopImage01,
				ing: WashingTopImage02,
			},
		},
		"2": {
			icon: Repair,
			top: {
				before: RepairTopImage01,
				ing: RepairTopImage02,
			},
		},
	}), []);

	const title = useMemo(() => {
		if(!isLoading && !isError && Object.keys(data).length) {
			let title = "";

			if(data.tntId === "99999") {
				if(data.etcBrndNm) {
					title += data.etcBrndNm;
				}
				if(data.etcTntNm) {
					if(title) {
						title += " - ";
					}

					title += data.etcTntNm;
				}
			} else {
				title = `${ data.brndNm } - ${ data.tntNm }`;
			}

			return title;
		} else {
			return "";
		}
	}, [isLoading]);

	if(isError) {
		return (
			<div>오류가 발생했습니다.</div>
		);
	}

	return (
		<Container variant="contextRoot">
			<Card variant="topImage">
				{ isLoading ? (
					<Skeleton variant="rounded" width="100%" height="200px" />
				) : (
					<CardTitleWrap
						image={ images[data.ordrTp].top[parseInt(data.ordrStts) < 7 ? "before" : "ing"] }
						type={ data.ordrTp }
						ing={ parseInt(data.ordrStts) < 7 ? 0 : 1 }
					>
						<TopBox>
							<SvgIcon
								component={ images[data.ordrTp].icon }
								sx={ {
									fill: "none",
									width: 60,
									height: 60,
								} }
							/>
						</TopBox>
					</CardTitleWrap>
				) }
			</Card>

			<Container variant="contextBox">
				{ isLoading ? (
					<React.Fragment>
						<Skeleton variant="rounded" width="100%" height="30px" sx={ { marginBottom: 3 } } />
						<Skeleton variant="rounded" width="100%" height="78px" sx={ { marginBottom: 3 } } />
						<Skeleton variant="rounded" width="100%" height="450px" />
					</React.Fragment>
				) : (
					<React.Fragment>
						<Stack
							direction="row"
							justifyContent="flex-start"
							alignItems="center"
							spacing={ 2 }
							mb={ 3 }
						>
							{/*<SvgIcon component={ images[data.ordrTp].icon } sx={ { fill: "none" } } />*/ }

							<Typography variant="title">{ title }</Typography>
						</Stack>

						<OrderProcessBar
							orderType={ data.ordrTp }
							etcTentExist={ data.etcTntExst }
							currentStep={ data.ordrStts }
						/>

						{ !isLoading && (
							<OrderHistoryAccordion
								items={ data }
								images={ requestImagesData }
								fixImages={ estimateFixImagesData }
								workingImages={ workingImagesData }
							/>
						) }
					</React.Fragment>
				) }
			</Container>
		</Container>
	);
}

const CardTitleWrap = styled(Box)(({ image, type, ing }) => ({
	height: 200,
	position: "relative",
	borderRadius: "12px 12px 0px 0px",
	background: (type === "1" && ing) ? `linear-gradient(0deg, rgba(0, 209, 255, 0.20) -4.5%, rgba(0, 10, 255, 0.00) 84.5%), url(${image}), lightgray -241.951px -78.05px / 177.261% 215.745% no-repeat` : `url(${image})`,
	mixBlendMode: type === "1" && "hard-light",
	backgroundSize: "cover",
}));

const TopBox = styled(Box)(() => ({
	position: "absolute",
	left: "50%",
	top: "50%",
	transform: "translate(-50%, -50%)",
	padding: 20,
	borderRadius: 5,
	backgroundColor: "#D9D9D941",
	backdropFilter: "blur(2px)",
	mixBlendMode: "hard-light",
	"& .MuiSvgIcon-root > path": {
		stroke: "#EAEAEA",
	},
}));

export default OrderHistoryDetail;