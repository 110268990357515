import React, {
	useCallback,
	useEffect, useState
} from "react";
import PoI from "../../assets/images/poi.svg";
import useKakaoSdk from "../useKakaoSdk";

function useKakaoMap({
	containerId = "map",
	lat,
	lng,
	locationName = "",
	level = 3
}) {
	const status = useKakaoSdk();
	let map;

	useEffect(() => {
		if(status === "ready") {
			let imageMarker;

			function handleClick() {
				window.open(`https://map.kakao.com/link/map/${ locationName },${ lat },${ lng }`);
			}

			window.kakao.maps.load(() => {
				const { kakao } = window;

				map = new kakao.maps.Map(document.getElementById(containerId), {
					center: new kakao.maps.LatLng(lat, lng),
					// draggable: false,
					// scrollwheel: false,
					// disableDoubleClick: true,
					// disableDoubleClickZoom: true,
					level
				});

				const imageSize = new kakao.maps.Size(20, 30);
				const markerImage = new kakao.maps.MarkerImage(PoI, imageSize, {
					offset: new kakao.maps.Point(10, 30)
				});
				imageMarker = new kakao.maps.Marker({
					position: map.getCenter(),
					image: markerImage
				});
				imageMarker.setMap(map);

				kakao.maps.event.addListener(imageMarker, "click", handleClick);
			});

			return () => {
				if(imageMarker) {
					window.kakao.maps.event.removeListener(imageMarker, "click", handleClick);
				}
			};
		}
	}, [status]);

	return map;
}

export default useKakaoMap;