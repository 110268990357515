import React from "react";
import {
	Navigation,
	Pagination,
	A11y,
	Autoplay,
} from "swiper";
import {
	Swiper,
	SwiperSlide
} from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/effect-fade";
import {
	Card,
	CardMedia,
	Typography
} from "@mui/material";
import { styled } from "@mui/material/styles";

function SwiperTopCard({ images }) {
	return (
		<Card variant="topImage">
			<Wrap
				navigation
				loop
				centeredSlides
				autoplay={ {
					delay: 3 * 1000,
					disableOnInteraction: false
				} }
				pagination={ {
					type: "fraction",
					clickable: true
				} }
				modules={ [Autoplay, Pagination, Navigation, A11y] }
			>
				{ images.map(({ image, subTitle, ...options }, index) => (
					<SwiperSlide key={ "swiperSlide_" + index }>
						<CardMedia
							image={ image }
							sx={ {
								height: "200px",
								...options
							} }
						/>

						{ subTitle && (
							<SubTitle>{ subTitle }</SubTitle>
						) }
					</SwiperSlide>
				)) }
			</Wrap>
		</Card>
	);
}

const Wrap = styled(Swiper)(() => ({
	"& .swiper-button-prev, & .swiper-button-next": {
		color: "#EAEAEA",
		opacity: ".8",
		transform: "scale(.8)",
		transition: "all .2s ease",
		"&::after": {
			fontSize: "30px",
			fontWeight: 700
		},
		"&:hover": {
			color: "#E8410B",
			opacity: "1",
			transform: "scale(1)"
		}
	},
	"& .swiper-pagination": {
		"&.swiper-pagination-fraction": {
			width: "auto",
			fontSize: "14px",
			color: "#B9B9B9",
			lineHeight: "22px",
			position: "absolute",
			left: "auto",
			right: 0,
			padding: "2px 10px",
			backgroundColor: "#00000057",
			borderRadius: "20px",
			marginRight: "10px"
		}

		// "& .swiper-pagination-bullet": {
		// 	backgroundColor: "#fff",
		// 	opacity: ".3"
		// },
		// "& .swiper-pagination-bullet.swiper-pagination-bullet-active": {
		// 	backgroundColor: "#E8410B",
		// 	opacity: "1"
		// }
	}
}));

const SubTitle = styled((props) => (
	<Typography variant={ "context" } { ...props } />
))(({ theme }) => ({
	position: "absolute",
	bottom: 0,
	left: "50%",
	transform: "translateX(-50%)",
	backgroundColor: theme.palette.background.paper,
	padding: "5px 15px 2px",
	borderRadius: "10px 10px 0 0"
}));

export default React.memo(SwiperTopCard);