import React, { useState } from "react";
import { Box, Card, CardMedia, CircularProgress, Container, Stack, Typography } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import TopImage from "../../../assets/images/top_img/top_08.png";
import EstimateButtons from "../../../components/public/EstimateButtons";
import OrderCard from "../../../components/public/OrderCard";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useInfiniteOrderHistory } from "../../../query/public/orderHistoryQuery";
import useCurrentUser from "../../../hooks/public/useCurrentUser";
import { getItems } from "../../../lib/saveEstimateLocalStorage";
import { useNavigate } from "react-router-dom";

function OrderHistory () {
	const navigate = useNavigate();
	const { userId } = useCurrentUser();
	const {
		      data,
		      fetchNextPage,
		      hasNextPage,
		      status,
	      } = useInfiniteOrderHistory(userId);
	const [savedEstimateList, setSavedEstimateList] = useState(getItems());

	function nextPage () {
		if (hasNextPage) {
			fetchNextPage();
		}
	}

	return (
		<Container variant="contextRoot">
			<Card variant="topImage">
				<CardMedia
					title="introduce"
					image={ TopImage }
					sx={ { height: "200px" } }
				/>
			</Card>

			<Container variant="contextBox">
				<EstimateButtons />

				<Box>
					<Typography variant="title">주문 목록</Typography>

					<Stack
						spacing={ 2 }
						mb={ hasNextPage && 2 }
						justifyContent="center"
						alignItems="center"
					>
						{ status === "loading" && <CircularProgress /> }
						{ status === "error" && <Typography variant="context" align="center">잠시 후 다시 시도해주세요</Typography> }
						{ status === "success" && (
							data?.pages?.map((page) => page.list.map((item) => (
								<OrderCard
									key={ item.ordrId }
									onClick={ () => navigate(`/tent/orderHistoryDetail/${ item.ordrId }`) }
									orderType={ item.ordrTp }
									state={ item.ordrSttsNm }
									date={ item.rcvYn === "Y" ? item.rcvDtm : item.sttsDtm }
									tentId={ item.tntId }
									brandName={ item.tntId === "99999" ? item.etcBrndNm : item.brndNm }
									tentName={ item.tntId === "99999" ? item.etcTntNm : item.tntNm }
									detail={ item.detail }
									total={ item.ttlAmnt }
									cancel={ item.csStts === "1" }
									deliveryConfirm={ item.rcvYn === "Y" }
									receivingDeliveryCost={ item.rcvngDlvrDvsn === "2" && item.rcvngDlvryCst }
									deliveryDivision={ item.dlvrDvsn }
									deliveryCost={ item.dlvryCst }
								/>)))
						) }
						{ status === "success" && data?.pages[0]?.count < 1 && <Typography variant="context" align="center">주문 내역이 없습니다</Typography> }
					</Stack>

					{ hasNextPage && (
						<LoadingButton
							variant="contained"
							color="secondary"
							size="large"
							startIcon={ <ExpandMoreIcon /> }
							loading={ status === "loading" }
							onClick={ nextPage }
							fullWidth
						>
							더보기
						</LoadingButton>
					) }
				</Box>

				<Box mt={ 6 }>
					<Typography variant="title">최근 견적 목록</Typography>

					<Stack
						spacing={ 2 }
						justifyContent="center"
						alignItems="center"
					>
						{ savedEstimateList.length < 1 && <Typography variant="context" align="center">최근 견적 내역이 없습니다</Typography> }
						{ savedEstimateList?.map((item, index) => (
							<OrderCard
								key={ `estimate_item_${ index }` }
								onClick={ () => navigate(`/tent/estimate/${ item.tentId }`) }
								orderType="1"
								state=""
								date={ item.date }
								tentId={ item.tentId }
								brandName={ item.tentInfo.brndNm }
								tentName={ item.tentInfo.tntNm }
								detail={ [] }
								total={ item.total }
							/>
						)) }
					</Stack>
				</Box>
			</Container>
		</Container>
	);
}

export default OrderHistory;