import React, { useMemo } from "react";
import {
	Card,
	CardMedia,
	Container,
	Divider,
	Stack,
	Typography
} from "@mui/material";
import TopImage from "../../assets/images/top_img/top_03.jpg";
import NewsCard from "../../components/public/NewsCard";
import News02 from "../../assets/images/introduce/news_02.jpg";
import News04 from "../../assets/images/introduce/news_04.jpg";

function News() {
	const newsInfo = useMemo(() => ([
		{
			title: "[성공스토리 지금까지] 취미로 시작해 대한민국 최고되기",
			channel: "Youtube",
			programName: "소상공인시장tv",
			date: "2017.12.07",
			youtubeId: "WA9uoM27J8o"
		},
		{
			title: "[옆집 부자의 비밀노트] 캠핑으로 돈 버는 비법은?",
			channel: "MBC",
			programName: "생방송 오늘저녁",
			date: "2017.08.16",
			youtubeId: "5cCdxZgPN8w"
		},
		{
			title: "매출의 비결, 텐트별 맞춤세탁!",
			channel: "채널A",
			programName: "서민갑부",
			date: "2016.10.13",
			youtubeId: "IoKzsr8GZq8"
		},
		{
			title: "[사이언스 통] 텐트 관리의 과학적인 비밀",
			channel: "YTN 사이언스",
			programName: "IT 잇슈매거진",
			date: "2015.02.17",
			youtubeId: "-V5lBr-8ITY"
		},
		{
			title: "겨울 캠핑, 100배 즐기는 방법",
			channel: "KBS",
			programName: "2TV 저녁 생생정보",
			date: "2015.10.28",
			youtubeId: "qml-p3efvFo"
		},
		{
			title: "[라이프앤조이] 고쳐 쓰고 아껴 쓰는 수리!",
			channel: "YTN 사이언스",
			programName: "황금나침반",
			date: "2014.10.06",
			youtubeId: "5iNZVNqKLdU"
		},
		{
			title: "이제 텐트도 세탁하는 시대! 텐트 전문 세탁소",
			channel: "KBS",
			programName: "VJ 특공대",
			date: "2014.09.26",
			youtubeId: "ep2TPYdHvcU"
		},
		{
			title: "구멍 난 텐트 수선, 텐트 세탁소",
			channel: "KBS",
			programName: "굿모닝 대한민국",
			date: "2013.10.29",
			thumbnail: News04,
			url: "https://www.youtube.com/watch?v=rGOJAbjD3nU"
		},
		{
			title: "[기술자들] 완벽한 캠핑을 위한 텐트 기술",
			thumbnail: News02,
			channel: "YTN 사이언스",
			programName: "황금나침반",
			date: "2022.07.09",
			url: "https://youtu.be/1zJdOy3wiVk"
		},
		{
			title: "모든 과정을 수작업으로 텐트를 세탁하는 젊은 여성 창업자",
			channel: "Youtube",
			programName: "룩 프로세스 Look Process",
			date: "2023.03.20",
			youtubeId: "7p5iaDkE-tw"
		}
	]), []);

	return (
		<Container variant={"contextRoot"}>
			<Card variant={ "topImage" }>
				<CardMedia
					image={ TopImage }
					title={ "introduce" }
					sx={ { height: "200px" } }
				/>
			</Card>

			<Container variant={"contextBox"}>
				<Typography variant={ "title" }>보도 및 홍보</Typography>

				<Stack direction={ "column" } mt={ 4 } spacing={ 5 }>
					{ newsInfo.map((item, index) => (
						<React.Fragment key={item.youtubeId || item.url}>
							<NewsCard {...item}/>

							{ index < newsInfo.length - 1 && <Divider /> }
						</React.Fragment>
					)) }
				</Stack>
			</Container>
		</Container>
	);
}

export default News;