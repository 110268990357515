import {
	Box, Button,
	Card, CardActions,
	CardContent,
	Skeleton, Stack, Typography,
} from "@mui/material";
import React, { useState } from "react";
import { modals } from "../../../hooks/Modals";
import useModal from "../../../hooks/useModal";
import { useCancelOrderMutate, useRequestOrderMutate, useEstimateQuery } from "../../../query/admin/orderQuery";
import { useRequestImages } from "../../../query/getImageQuery";
import EstimateCard from "../../common/EstimateCard";
import ImageUpload from "../../public/ImageUpload";
import { Toast } from "../../public/Toast";
import CardSubTitle from "./CardSubTitle";
import CardTitle from "./CardTitle";
import ContentField from "./ContentField";

function Step2Card({
	orderId,
	estimateId,
	userId,
	nickname,
	orderType,
	date,
	brandName,
	tentName,
	imagePath,
	requestContent,
	totalAmount,
	repairContent,
	branchName,
	orderTypeName,
	refreshQuery,
}) {
	const [open, setOpen] = useState(false);
	const {
		      isEstimateLoading,
		      estimateData,
	      } = useEstimateQuery(estimateId, open);
	const {
		      isRequestImagesLoading,
		      requestImagesData,
	      } = useRequestImages(orderId, imagePath, open, true);
	const { cancelOrderMutate } = useCancelOrderMutate();
	const { requestOrderMutate } = useRequestOrderMutate();
	const { openModal } = useModal();

	function handleCancelWorking() {
		openModal(modals.confirmModal, {
			title: `${ nickname } 고객님의 ${ orderTypeName }을 취소하시겠어요?`,
			content: (
				<Box>
					<Typography variant="dialogContent" sx={{ "& > strong" : { color: "#E8410B" } }}>
						<strong>
							{ orderTypeName } 취소 시 복구가 불가능합니다.
						</strong>
					</Typography>
				</Box>
			),
			submitTitle: `${ orderTypeName } 취소`,
			closeTitle: "닫기",
			onSubmit: async(firstClose, setLoadingDone) => {
				cancelOrderMutate({ orderId }, {
					onSuccess: async () => {
						Toast.success(`${ nickname } 고객님의 ${ orderTypeName }이 취소되었어요`);
						refreshQuery();
						firstClose();
					},
					onError: () => {
						firstClose();
						openModal(modals.confirmModal, {
							title: "죄송합니다, 취소가 실패했습니다",
							content: "잠시 후에 다시 시도해주시기 바랍니다.",
							closeTitle: "닫기"
						});
					},
					onSettled: () => {
						setLoadingDone();
					}
				});
			}
		});
	}

	function handleRequestWorking() {
		openModal(modals.confirmModal, {
			title: `${ nickname } 고객님의 ${ orderTypeName }을 접수하시겠어요?`,
			submitTitle: `${ orderTypeName } 접수`,
			content: (
				<Box>
					<Typography variant="dialogContent" sx={{ "& > strong" : { color: "#E8410B" } }}>
						<strong>
							{ orderTypeName } 접수가 완료된 후에는 이전 상태로 되돌릴 수 없습니다.
						</strong>
					</Typography>
				</Box>
			),
			onSubmit: async (firstClose, setLoadingDone) => {
				requestOrderMutate({ orderId }, {
					onSuccess: async () => {
						Toast.success(`${ nickname } 고객님의 ${ orderTypeName }이 접수되었어요`);
						refreshQuery();
						firstClose();
					},
					onError: () => {
						firstClose();
						openModal(modals.confirmModal, {
							title: "죄송합니다, 접수가 실패했습니다",
							content: "잠시 후에 다시 시도해주시기 바랍니다.",
							closeTitle: "닫기",
						});
					},
					onSettled: () => {
						setLoadingDone();
					},
				});
			},
		});
	}

	return (
		<Card>
			<CardContent>
				<CardTitle
					orderType={ orderType }
					date={ date }
					nickname={ nickname }
					userId={ userId }
				/>

				<CardSubTitle
					brandName={ brandName }
					tentName={ tentName }
					branchName={ branchName }
					open={ open }
					setOpen={ setOpen }
				/>

				{ open && (
					<React.Fragment>
						{ isEstimateLoading ? (
							<Skeleton variant="rounded" width="100%" height="100px" />
						) : (
							<Box mb={ 3 }>
								<EstimateCard orderType={ orderType } detail={ estimateData } total={ totalAmount } repairContent={ repairContent } />
							</Box>
						) }

						{ !isRequestImagesLoading && requestImagesData.length > 0 && (
							<ImageUpload
								readOnly
								images={ requestImagesData }
							/>
						) }

						<ContentField value={ requestContent } />
					</React.Fragment>
				) }
			</CardContent>

			<CardActions variant="adminStepCard">
				<Stack direction="row" spacing={ 1 }>
					<Button
						variant="contained"
						size="small"
						color="secondary"
						onClick={ handleCancelWorking }
					>
						{ orderTypeName } 취소
					</Button>
					<Button
						variant="contained"
						size="small"
						color="primary"
						onClick={ handleRequestWorking }
					>
						{ orderTypeName } 접수
					</Button>
				</Stack>
			</CardActions>
		</Card>
	);
}

export default Step2Card;