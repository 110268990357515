import axios from "../axios";

const DEFAULT_URL = "/api/admin";

export async function getCurrentUser() {
    const res = await axios.get(DEFAULT_URL + "/getCurrentUser");
    return res?.data;
}

export async function logout() {
    const res = await axios.get(DEFAULT_URL + "/logout");
    return res?.data;
}