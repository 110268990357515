import React, { useEffect, useState } from "react";
import { getBanner } from "../../api/public/home";
import { clearItems, getData } from "../../lib/NoticeModal";
import { modals } from "../Modals";
import useModal from "../useModal";

function usePopupBanner() {
	const [bannerList, setBannerList] = useState([]);
	const { openModal } = useModal();

	useEffect(() => {
		clearItems(); // 종료 일자가 지난 배너 삭제
		getImage();

		async function getImage() {
			const banner = await getBanner();

			if(banner.length > 0) {
				const noticeOffList = getData();
				const floatingBannerList = banner.filter((item) => !noticeOffList.some((offBanner) => offBanner.noticeId === item.bnnrId));

				setBannerList(floatingBannerList.map((item) => ({ id: item.bnnrId, image: item.imgPth })));
			}
		}
	}, []);

	useEffect(() => {
		if(bannerList.length > 0) {
			openModal(modals.noticeModal, {
				bannerList
			});
		}
	}, [bannerList]);
}

export default usePopupBanner;