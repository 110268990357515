import { Box, Button, Container, Skeleton, Stack, TextField, Typography } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AmountTable from "../../components/admin/AmountTable";
import BackgroundBox from "../../components/common/BackgroundBox";
import EstimateCard from "../../components/common/EstimateCard";
import ImageUpload from "../../components/public/ImageUpload";
import RequestContextField from "../../components/public/RequestContextField";
import { Toast } from "../../components/public/Toast";
import { modals } from "../../hooks/Modals";
import useModal from "../../hooks/useModal";
import { isEmptyString } from "../../lib/libs";
import { GET_ORDER_STEP, useOrderHistoryStepQuery } from "../../query/admin/orderQuery";
import {
    useEstimateDetail,
    useGetRequestData,
    useUpdateRequestAddTentMutation,
} from "../../query/admin/requestAddTentQuery";
import { useRequestImages } from "../../query/getImageQuery";

function RequestAddTent() {
	const { estimateId } = useParams();
	const { data, isLoading } = useGetRequestData(estimateId);
	const [name, setName] = useState({ brandName: "", tentName: "" });
	const { estimateDetailData, isEstimateDetailLoading } = useEstimateDetail(estimateId, data?.ordrTp);
	const { requestImagesData, isRequestImagesLoading } = useRequestImages(data?.ordrId, data?.rqstImgPth, true, true);
	const { updateRequestAddTentMutate } = useUpdateRequestAddTentMutation();
	const { openModal } = useModal();
	const navigate = useNavigate();
	const queryClient = useQueryClient();

	useEffect(() => {

		if(data) {
			const brandName = data.tntId === "99999" ? data.etcBrndNm : data.brndNm;
			const tentName = data.tntId === "99999" ? data.etcTntNm : data.tntNm;

			setName((prev) => ({
				...prev,
				brandName,
				tentName
			}));
		}
	}, [data]);

	if(isLoading) {
		return <Skeleton variant="rounded" width="100%" height="200px" />;
	}

	function handleAddTentClick() {
		openModal(modals.addTentAndBrandModal, {
			requestBrandName: data?.etcBrndNm,
			requestTentName: data?.etcTntNm,
			beforeTentId: data.tntId,
			estimateId,
		});
	}

	function handleComplete() {
		openModal(modals.confirmModal, {
			content: (
				<Typography variant="dialogContent">텐트 등록을 완료하셨나요?</Typography>
			),
			submitTitle: "등록 완료",
			onSubmit: async (onClose, setLoadingDone) => {
				const requestData = {
					estimateId,
					brandId: data.brndId,
					tentId: data.tntId
				};

				updateRequestAddTentMutate(requestData, {
					onSuccess: async () => {
						onClose();
						Toast.success("저장되었어요");
						navigate("/admin/order/step1");
						queryClient.invalidateQueries([...GET_ORDER_STEP, 1]);
					},
					onError: () => {
						onClose();
						openModal(modals.confirmModal, {
							title: "죄송합니다, 저장이 실패했습니다",
							content: "잠시 후에 다시 시도해주시기 바랍니다.",
							closeTitle: "닫기"
						});
					},
					onSettled: () => {
						setLoadingDone();
					}
				});
			}
		});
	}

	return (
		<Container variant="contextRoot">
			<Container variant="contextBox">
				<Stack direction="row" spacing={ 1.5 } mb={ 2 }>
					<Stack width="100%" spacing={ 1.5 }>
						<TextField
							label="브랜드"
							variant="outlined"
							value={ name.brandName || "" }
							fullWidth
							InputProps={{
								readOnly: true
							}}
						/>

						<TextField
							label="텐트"
							variant="outlined"
							value={ name.tentName || "" }
							fullWidth
							InputProps={{
								readOnly: true
							}}
						/>
					</Stack>

					<Button
						variant="contained"
						sx={{ width: "40%" }}
						onClick={ handleAddTentClick }
					>
						등록
					</Button>
				</Stack>

        { !isEstimateDetailLoading && data?.ordrTp === "1" && (
	        <Box mb={ 1 }>
		        <EstimateCard
			        title="가견적 요청 서비스"
			        detail={ estimateDetailData }
			        orderType={ data?.ordrTp }
			        shownPrice={ false }
		        />
	        </Box>
        ) }

				{ !isRequestImagesLoading && requestImagesData.length > 0 && (
					<ImageUpload
						images={ requestImagesData }
						readOnly
					/>
				) }

				{ !isEmptyString(data.rqstCntnt) && (
					<RequestContextField
						defaultValue={ data.rqstCntnt }
						readOnly
					/>
				) }

				{ data.tntId !== "99999" && (
					<Box mt={ 4 }>
						{ data.ordrTp === "1" && (
							<AmountTable brandId={ data.brndId } tentId={ data.tntId } requestItemIds={ estimateDetailData?.map((item) => item.itmId) } />
						) }

						<Button
							variant="contained"
							size="large"
							fullWidth
							sx={{ position: "sticky", bottom: 0 }}
							onClick={ handleComplete }
						>
							텐트 등록 완료
						</Button>
					</Box>
				) }
			</Container>
		</Container>
	);
}

export default RequestAddTent;