import React from "react";
import {
	Stack,
	Typography
} from "@mui/material";

function Footer2() {
	return (
		<Stack spacing={ 1 } mt={ 6 }>
			<Typography variant={ "smallContext" }>본 견적은 가견적이며 텐트 입고 후 검수 과정에서 금액이 변동될 수 있습니다.</Typography>
			<Typography variant={ "smallContext" }>택배비는 보내주실 때 선불로 보내주시고 10만원 이상 결제 시 발송은 저희 부담으로 해드립니다.</Typography>
			<Typography variant={ "smallContext" }>입고 및 검수 후 변심으로 서비스를 받지 않을 경우 배송비는 본인이 부담하셔야 합니다.</Typography>
			<Typography variant={ "smallContext" }>입고 후 완료까지 기간은 상황에 따라 달라질 수 있지만 보통 2~3주 소요됩니다.</Typography>
		</Stack>
	);
}

export default React.memo(Footer2);