import {
	Backdrop,
	CircularProgress
} from "@mui/material";
import React from "react";

function Loading() {
	return (
		<Backdrop
			open
			sx={ { width: "100vw", height: "100vh", zIndex: (theme) => theme.zIndex.drawer + 1 } }
		>
			<CircularProgress />
		</Backdrop>
	);
}

export default Loading;