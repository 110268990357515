import { Box, Button, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { usePublicUserState } from "../../../hooks/public/publicAuthContextProvider";
import { addAddress, updateAddress } from "../../../lib/firestore/User";
import { Toast } from "../Toast";
import UserAddressAddUpdateCard from "./UserAddressAddUpdateCard";
import UserAddressCard from "./UserAddressCard";

function UserAddress() {
	const { user } = usePublicUserState();
	const [addFlag, setAddFlag] = useState(false);
	const [updateIndex, setUpdateIndex] = useState(null);

	function handleStartAdd() {
		setAddFlag(true);
	}

	function handleCancelAdd() {
		setAddFlag(false);
	}

	function handleUpdateStart(index) {
		setUpdateIndex(index);
	}

	function handleUpdateCancel() {
		setUpdateIndex(null);
	}

	async function handleSubmit(data) {
		if(user.address.length < 3) {
			let changeAddress = [...user.address];

			if(data.defaultFlag && changeAddress.length > 0) {
				changeAddress.map((item) => {
					item.defaultFlag = false;

					return item;
				});
			}

			changeAddress.unshift(data);

			await updateAddress(changeAddress).then(() => {
				setAddFlag(false);
				Toast.success("저장되었어요");
			});
		}
	}

	return (
		<Box>
			{ user?.address?.length === 0 && !addFlag && (
				<Box py={ 2 }>
					<Typography align="center" variant="context">배송지를 추가해주세요</Typography>
				</Box>
			) }

			<Stack spacing={ 1 }>
				{ user?.address?.map((item, index) => {
					// addFlag === true && disabled button
					return (
						<UserAddressCard
							key={ `userAddressCard_${index}` }
							address={ item }
							index={ index }
							addFlag={ addFlag }
							updateFlag={ updateIndex !== null }
							isUpdate={ updateIndex === index }
							handleStart={ () => handleUpdateStart(index) }
							handleCancel={ handleUpdateCancel }
						/>
					);
				}) }
			</Stack>

			{ addFlag && (
				<UserAddressAddUpdateCard handleCancel={ handleCancelAdd } submit={ handleSubmit } />
			) }

			{ user?.address?.length < 3 && !addFlag && (
				<Button
					variant="contained"
					size="medium"
					disabled={ updateIndex !== null }
					fullWidth
					sx={ { mt: 2 } }
					onClick={ handleStartAdd }
				>
					배송지 등록
				</Button>
			) }
		</Box>
	);
}

export default UserAddress;