import axios from "../axios";
import qs from "query-string";
import { AMOUNT_DEFAULT_URL } from "./defaultUrl";

export async function getBrands() {
	const res = await axios.post(AMOUNT_DEFAULT_URL + "/listAll/getBrand", {});
	return res?.data;
}

export async function getTent(brndId) {
	const res = await axios.post(AMOUNT_DEFAULT_URL + "/listAll/getTent", qs.stringify({ brndId }));
	return res?.data;
}

export async function getGroup() {
	const res = await axios.post(AMOUNT_DEFAULT_URL + "/listAll/getGroup");
	return res?.data;
}

export async function getItem(grpId) {
	const res = await axios.post(AMOUNT_DEFAULT_URL + "/listAll/getItem", qs.stringify({ grpId }));
	return res?.data;
}

export async function getTentInfo(tntId) {
	const res = await axios.post(AMOUNT_DEFAULT_URL + "/listAll/getTentInfo", qs.stringify({ tntId }));
	return res?.data;
}

export async function insertGroup(groupData) {
	const res = await axios.post(AMOUNT_DEFAULT_URL + "/insert/insertGroup", qs.stringify(groupData));
	return res?.data;
}

export async function updateGroup(groupData) {
	const res = await axios.post(AMOUNT_DEFAULT_URL + "/update/updateGroup", qs.stringify(groupData));
	return res?.data;
}

export async function deleteGroup(groupData) {
	const res = await axios.post(AMOUNT_DEFAULT_URL + "/deleteGroup", qs.stringify(groupData));
	return res?.data;
}

export async function insertItem(itemData) {
	const res = await axios.post(AMOUNT_DEFAULT_URL + "/insertItem", qs.stringify(itemData));
	return res?.data;
}

export async function updateItem(itemData) {
	const res = await axios.post(AMOUNT_DEFAULT_URL + "/updateItem", qs.stringify(itemData));
	return res?.data;
}

export async function deleteItem(itemData) {
	const res = await axios.post(AMOUNT_DEFAULT_URL + "/deleteItem", qs.stringify(itemData));
	return res?.data;
}